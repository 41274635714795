import React from "react"
import { observer } from "mobx-react-lite"
import { DLButton } from "../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import OrgDashboardColumns, {
	filterComponent,
} from "./OrgDashboardTableViewColumns"
import { DLOrgMenuGroups } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import {
	CreateProjectMethod,
	initialCreateProjectInputs,
} from "../../../../../components/combined-elements/create-project/create-project.data-props"
import ProjBasicCtxMenus from "../../../../../components/combined-elements/projects-table/ProjBasicCtxMenus"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import ProjectDetailsDialog from "../../../../../components/combined-elements/project-dialogs/ProjectDetailsDialog"
import CreateProjectDialog from "../../../../../components/combined-elements/create-project/CreateProjectDialog"
import AddHardCopyDialog from "./AddHardCopyDialog"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import styled from "styled-components"
import { PageHeader } from "../../../../../components/app-frame-elements"
import BasicCounter from "../../../../../components/app-frame-elements/sub-components/BasicCounter"
import { Table } from "@tanstack/react-table"
import DeleteProjectDialog from "../../../../../components/combined-elements/delete-project-dialog/DeleteProjectDialog"

const OrgDashboardTable = observer(
	({
		partialStore,
		contentsHeight,
		dateFormat,
		data,
		i18n,
		menuId,
	}: {
		partialStore: any
		contentsHeight: any
		dateFormat: any
		data: string
		i18n: DLI18nProps
		menuId: string
	}) => {
		const orgStore = useOrgStore()

		//
		const handleCtxMenuClose = () => {
			partialStore.setClickPoint({
				mouseX: null,
				mouseY: null,
			})
		}

		const handleContextMenu = (e: any, rowInfo: any) => {
			e.preventDefault()
			partialStore.setClickPoint({
				mouseX: e.clientX - 2,
				mouseY: e.clientY - 4,
			})
			const id = rowInfo.id
			const cabinetId = rowInfo.cabinetId
			partialStore.setSelectedProj(id)
			partialStore.setSelectedProjStatus(rowInfo.statusName)
			const projectInfo = {
				...initialCreateProjectInputs,

				cabinetId: rowInfo.cabinetId,
				cabinetName: "loading...",
				title: rowInfo.title,
				periodName: rowInfo.periodName,
				clientName: rowInfo.clientName,
				engTypeName: rowInfo.engTypeName,
				createMethod: CreateProjectMethod.byRollForward,
			}
			partialStore.setCreateProjectPresetInputs(projectInfo)
			partialStore.setRfSourceId(id)
			partialStore.setSelectedItem({ id })
			partialStore.setSelectedCabinetId(cabinetId)
		}

		const clickPoint = {
			mouseX: partialStore.clickPoint.mouseX,
			mouseY: partialStore.clickPoint.mouseY,
		}
		//
		const path = orgStore.groups.selectionPathWithNameAndFormat
		const setIdPath = orgStore.groups.setPathForSelection
		const presetInputs = orgStore.orgDashboard.createProjectPresetInputs

		const userRole = orgStore.checkin.assignedRole.shortName

		const permission = orgStore.checkin.getActionsAsObject(menuId)

		const epList = partialStore.viewEPUsers()
		const qcList = partialStore.viewQCUsers()

		const pageHeader = (table: Table<any>) => {
			const totalProjs = table.getPreFilteredRowModel().flatRows.length
			const filteredProjs = table.getFilteredRowModel().flatRows.length
			return (
				<PageHeader>
					<div className="FR">
						<BasicCounter
							label={i18n.twTotalProject || "Total Projects"}
							value={totalProjs}
						/>
						{totalProjs > filteredProjs && (
							<div className="FR AC">
								<span className="partition">|</span>
								<div className="filtered-count">
									Filtered Projects : {filteredProjs}
								</div>
								<span className="partition">|</span>
								<DLButton
									eleTestId="clear-filters"
									clickHandler={table.resetColumnFilters}
								>
									Clear Filters
								</DLButton>
							</div>
						)}
					</div>
				</PageHeader>
			)
		}
		return (
			<StyledOrgDashboardTable>
				<ReactTableV8
					tableColumns={OrgDashboardColumns(i18n)}
					data={data}
					// pageHeader={pageHeader}
					hasPagination={true}
					customFilters={(props: any) =>
						filterComponent({
							...props,
							dateFormat,
							epList,
							qcList,
						})
					}
					handleContextMenu={handleContextMenu}
					height={contentsHeight}
					i18n={i18n}
					menuId={menuId}
					pageHeader={pageHeader}
					permission={permission}
				/>
				<ProjBasicCtxMenus
					projStatus={partialStore.selectedProjStatus}
					partialStore={partialStore}
					permission={permission}
					clickPoint={clickPoint}
					handleClose={handleCtxMenuClose}
					// TODO: pending. GetClientInformationDetail doesn't have the permission related info yet
					// getProjDetail={getProjDetailForCtxMenu}
					// getProjDetailStatus={orgStore.projects.getActionStatus(
					// 	"getProjectDetails"
					// )}
					userRole={userRole}
				/>

				{partialStore.projectDetailsDialogOpen && (
					<ProjectDetailsDialog partialStore={partialStore} />
				)}
				{partialStore.openCreateProjDialog && (
					<CreateProjectDialog
						open={partialStore.openCreateProjDialog}
						setOpen={partialStore.setOpenCreateProjDialog}
						currentMenu={DLOrgMenuGroups.dashboard}
						createMethod={partialStore.createProjectMethod}
						path={path}
						setIdPath={setIdPath}
						presetInputs={presetInputs}
					/>
				)}

				{partialStore.openDeleteProjDialog && (
					<DeleteProjectDialog
						i18n={i18n}
						isOpen={partialStore.openDeleteProjDialog}
						setIsOpen={partialStore.setOpenDeleteProjDialog}
						deleteAction={() =>
							partialStore.removeProject({
								projectId: partialStore.selectedProj,
								cabinetId: partialStore.selectedCabinetId,
								handleResponse: partialStore.handleResponse,
								postWorkForSuccess: () => {
									partialStore.popItemFromProjectList(
										partialStore.selectedProj,
										""
									)
									partialStore.setOpenDeleteProjDialog(false)
									partialStore.resetSelectedItems()
								},
							})
						}
						projectInfo={partialStore.viewSelectedProjInfo()}
					/>
				)}

				{partialStore.openAddHardCopyDialog && (
					<AddHardCopyDialog
						i18n={i18n}
						partialStore={partialStore}
					/>
				)}
			</StyledOrgDashboardTable>
		)
	}
)

const StyledOrgDashboardTable = styled.div`
	table > thead > tr:first-child {
		border: 1px solid black !important;
		th {
			// background-color: red;
			// border: 1px solid black;
			padding: 0;
			.header {
				min-height: 2rem;
				padding-left: 8px;
				border-left: 1px solid black;
				justify-content: center;
				div {
					justify-content: center;
				}
			}
		}
	}
`

export default OrgDashboardTable
