import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import {
	DLIconButton,
	DLButton,
	DLContextMenuWithButton,
} from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import Icon from "@mdi/react"
import { mdiDotsHorizontal, mdiMenuDown } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Table, Column } from "@tanstack/react-table"
import BasicCounter from "../../../../../components/app-frame-elements/sub-components/BasicCounter"
import { AddEngTypeBtn } from "../dialogs/AddEngTypeDialog"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { dateRangeFilter } from "../../../../../library/react-table-utils"
import { StyledHeader } from "../../org-setup-users/sub-components/OrgSetupUserListTable"
import { DLCheckbox } from "@datalobby/components"

export default observer(function OrgSetupEngTypesTable({
	i18n,
	permission,
}: {
	i18n: DLI18nProps
	permission: PermissionAsObjectProps
}) {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.setup_eng_types
	//
	const handleContextMenu = (event: any, engTypeId: string) => {
		event.preventDefault()
		orgStore.setupEngTypes.setSelectedEngId(engTypeId)
		orgStore.setupEngTypes.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const dateFormat = store.global.dateFormat.value
	const timeZone = store.global.timeZone.uiValue
	const selectedEngTypeId = orgStore.setupEngTypes.engagementId

	const handleCheckbox = (id: string) => {
		orgStore.setupEngTypes.setSelectedEngId(id)
	}

	const pageHeader = (instance: Table<any>) => {
		const totalEngTypes = instance.getPreFilteredRowModel().flatRows.length
		const filteredEngTypes = instance.getFilteredRowModel().flatRows.length

		return (
			<StyledHeader>
				<div>{permission.create && <AddEngTypeBtn i18n={i18n} />}</div>
				<div className="FR" style={{ marginTop: 10 }}>
					<DLContextMenuWithButton
						eleTestId="folders-btn"
						menuOptions={[
							{
								label: `Edit Engagement Type Info`,
								value: "ctx-editEngType",
								available: permission.update,
								disabled: !selectedEngTypeId,
								clickEvent: () =>
									orgStore.setupEngTypes.setOpenEdit(true),
							},
							{
								label: `Delete Engagement Type`,
								value: "ctx-removeEngType",
								available: permission.delete,
								disabled: !selectedEngTypeId,
								clickEvent: () =>
									orgStore.setupEngTypes.setOpenDelete(true),
							},
						]}
						useScroll={false}
						showButton={true}
						showIcon={false}
						btnText="Item Actions"
						btnVariant="outlined"
						btnColor="primary"
						btnSize="regular"
						btnClassName="ctx-menu-styles"
						eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>
				</div>
				<div
					className="FR AC counter-container"
					style={{ marginTop: 10 }}
				>
					<BasicCounter
						label={i18n.twTotalEngType || "Total: "}
						value={totalEngTypes}
					/>

					{totalEngTypes > filteredEngTypes && (
						<div className="FR AC">
							<span className="partition">|</span>
							<div className="filtered-count">
								Filtered Engagement Types: {filteredEngTypes}
							</div>
							<span className="partition">|</span>
							<DLButton
								eleTestId="clear-filters"
								clickHandler={instance.resetColumnFilters}
							>
								Clear Filters
							</DLButton>
						</div>
					)}
				</div>
			</StyledHeader>
		)
	}
	return (
		<ReactTableV8
			tableColumns={columns1({
				selectedId: selectedEngTypeId,
				handleCheckbox,
				handleContextMenu,
				permission,
				i18n,
			})}
			data={orgStore.setupEngTypes.formattedList(dateFormat, timeZone)}
			hasPagination={true}
			customFilters={(props: any) =>
				filterComponent({ ...props, dateFormat })
			}
			handleContextMenu={(e: any, row: any) =>
				handleContextMenu(e, row.id)
			}
			height={store.ui.contentsAreaHeight}
			permission={permission}
			i18n={i18n}
			pageHeader={pageHeader}
			menuId={menuId}
		/>
	)
})

const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "createdAt":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		case "actions":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const columns1 = ({
	selectedId,
	handleCheckbox,
	handleContextMenu,
	permission,
	i18n,
}: {
	selectedId: string
	handleCheckbox: (engTypeId: string) => void
	handleContextMenu: any
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) => [
	{
		header: "",
		accessorKey: "id",
		size: 20,
		enableColumnFilter: false,
		cell: (props: any) => {
			const id = props.row.original.id
			const handleClick = () => {
				handleCheckbox(id)
			}

			return (
				<DLCheckbox
					eleTestId={`checkbox-${id}`}
					clickHandler={() => handleClick()}
					color="primary"
					isChecked={selectedId === id}
				/>
			)
		},
	},
	{
		header: i18n.twEngTypeName || "Name",
		accessorKey: "name",
		cell: renderCustomCell,
	},
	{
		header: i18n.twChecklist || "Checklist",
		accessorKey: "checklist",
		cell: renderCustomCell,
	},
	{
		header: i18n.twCreatedBy || "Created By",
		accessorKey: "createdBy",
		cell: renderCustomCell,
	},
	{
		header: i18n.twCreatedAt || "Created At",
		accessorKey: "createdAt",
		filterFn: dateRangeFilter,
		cell: renderCustomCell,
	},
	{
		header: "Action",
		// show: permission.update || permission.delete,
		cell: (props: any) => {
			const engTypeId = props.row.original.id

			return (
				<div className="FR AC JC" style={{ width: "100%" }}>
					<DLIconButton
						aria-label="more"
						clickHandler={(event: any) =>
							handleContextMenu(event, engTypeId)
						}
						eleTestId="ra-ctx-btn-on-row"
					>
						<Icon path={mdiDotsHorizontal} size={0.8} />
					</DLIconButton>
				</div>
			)
		},
	},
]
