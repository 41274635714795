import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, ConsoleLog } from "../../../../components/basic-elements"
import { DLCheckbox, DLTooltip } from "@datalobby/components"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiCheck, mdiCheckCircle, mdiAlertCircle } from "@mdi/js"
import { RoleModelProps } from "../../../../service-modules/file-module/data-models/file.data-model"
import { FileScreenDialog } from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLI18nProps } from "../../../../common-models/types/common-props"

type AssignRoleInputProps = {
	label: string
	shortName: string
	value: string
	checked: boolean
}

export default observer(function RoleAssignDialog({
	partialStore,
	roleSet,
	open,
	setOpen,
	type,
}: {
	partialStore: any
	roleSet: any
	open: boolean
	setOpen: any
	type: string
}) {
	const [defaultValues, setDefaultValues] = useState<string[]>([])
	const [inputs, setInputs] = useState<AssignRoleInputProps[]>([])
	const [ready, setReady] = useState(false)

	useEffect(() => {
		let roleList: string[] = []
		const assignedRoles: any = [] //fileInfo && fileInfo?.assignedRoles

		assignedRoles &&
			assignedRoles.map((role: RoleModelProps) => roleList.push(role.id))
		setDefaultValues(roleList)
		// set inputs for update
		let roleCheckboxes: AssignRoleInputProps[] = []
		ConsoleLog(["roleSet", roleSet])
		roleSet?.map((role: any) => {
			roleCheckboxes.push({
				label: role.name,
				shortName: role.shortName,
				value: role.roleId,
				checked: roleList.includes(role.roleId),
			})
		})
		ConsoleLog(["roleCheckboxes", roleCheckboxes])
		setInputs(roleCheckboxes)
	}, [])

	const handleInputs = (e: any) => {
		const targetRole = e.target.value
		setInputs((inputs) =>
			inputs.map((input) =>
				input.value === targetRole
					? { ...input, checked: !input.checked }
					: input
			)
		)
	}

	useEffect(() => {
		let list = [...defaultValues]
		let newList: string[] = []
		inputs.map((input) => input.checked && newList.push(input.value))

		list.sort()
		newList.sort()
		if (JSON.stringify(list) === JSON.stringify(newList)) {
			setReady(false)
		} else {
			setReady(true)
		}
	}, [inputs, defaultValues])

	const handleAssign = () => {
		let requestList: { id: string; checked: boolean }[] = []
		inputs.map((input) =>
			requestList.push({
				id: input.value,
				checked: input.checked,
			})
		)
	}

	return (
		<DLDialog
			eleTestId="assign-role-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			handleAction={handleAssign}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={`Required Role for ${type}`}
			dialogContents={
				<RoleAssignDialogForm
					inputs={inputs}
					handleInputs={handleInputs}
					fileInfo={{}}
					partialStore={partialStore}
				/>
			}
			cancelBtnText={"Cancel"}
			actionReady={ready}
			actionBtn={"Update"}
			maxWidth="sm"
			fullWidth={true}
		/>
	)
})

export const RoleAssignDialogForm = observer(
	({
		inputs,
		handleInputs,
		fileInfo,
		partialStore,
	}: {
		inputs: AssignRoleInputProps[]
		handleInputs: any
		fileInfo: any
		partialStore: any
	}) => {
		// when success all, close the dialog
		let statusList: any = []
		// inputs.map((role: any) => {
		// 	const actionStatus = partialStore.getActionStatus(
		// 		assignRoleActionPrefix + role.value
		// 	)
		// 	statusList.push(actionStatus)
		// })

		// const standByList = statusList.filter(
		// 	(item: any) => item === ActionStatus.standby
		// )

		// if (
		// 	!statusList.includes(ActionStatus.fail) &&
		// 	!statusList.includes(ActionStatus.loading) &&
		// 	standByList.length !== inputs.length
		// ) {
		// 	partialStore.setFileTreeDialogOpen(dialogName, false)
		// }

		return (
			<StyledRoleAssignDialogForm className="FR">
				<div className="file-info">
					<div>Audit Step{fileInfo.aliasId}</div>
				</div>
				<div className="input-area">
					{inputs.map((role) => {
						const condition = role.value === "id_qc" && role.checked
						// const actionStatus = partialStore.getActionStatus(
						// 	assignRoleActionPrefix + role.value
						// )
						if (
							role.shortName !== "OA" &&
							role.shortName !== "TEMP_EX" &&
							role.shortName !== "EX"
						) {
							return (
								<div
									className="FR AC role-item"
									key={role.value}
								>
									<DLCheckbox
										eleDisabled={condition}
										eleClassName="checkbox"
										value={role.value}
										eleTestId={`checkbox-${role.shortName}`}
										isChecked={role.checked}
										clickHandler={handleInputs}
										// labelText={role.label}
										color="primary"
									/>
									{condition ? (
										<DLTooltip
											title={
												"Cannot unassign QC role because there are QC sign off already"
											}
										>
											<span className="role-name">
												({role.shortName}) {role.label}
											</span>
										</DLTooltip>
									) : (
										<div className="role-name FR AC">
											{/* {actionStatus ===
											ActionStatus.loading ? (
												<DLSpinnerByIcon eleClassName="status-icon" />
											) : actionStatus ===
											  ActionStatus.success ? (
												<Icon
													path={mdiCheckCircle}
													size={0.8}
													className="status-icon success"
												/>
											) : actionStatus ===
											  ActionStatus.fail ? (
												<Icon
													path={mdiAlertCircle}
													size={0.8}
													className="status-icon fail"
												/>
											) : (
												""
											)} */}
											({role.shortName}) {role.label}
										</div>
									)}
								</div>
							)
						}
					})}
				</div>
			</StyledRoleAssignDialogForm>
		)
	}
)

const StyledRoleAssignDialogForm = styled.div`
	.file-info {
		height: 100%;
		background-color: ${(props) => props.theme.secondary};
		padding: 1rem;
		border-radius: 3px;
		margin-right: 1rem;
	}
	.input-area {
		.role-item {
			margin-bottom: 0.4rem;
			.role-name {
				transition: 0.4s;
				margin-left: 0.6rem;
				.status-icon {
					margin-right: 0.6rem;
					&.success {
						color: ${(props) => props.theme.shared.messageBlue};
					}
					&.fail {
						color: ${(props) => props.theme.shared.messageCoral};
					}
				}
			}
		}
	}
`
