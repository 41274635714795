import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog } from "../../../../components/basic-elements"
import { RoleAssignDialogForm } from "./RoleAssignDialog"
import {
	displayedProjRoles,
	findProjRoleInfo,
} from "../../../../temporary-data/project-side/find-proj-role"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import { DLAvatar, DLCheckbox } from "@datalobby/components"

export default observer(function AssignUserDialog({
	partialStore,
	open,
	setOpen,
	type,
}: {
	partialStore: any
	open: boolean
	setOpen: any
	type: string
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const [inputs, setInputs] = useState([])
	const roleSet = displayedProjRoles

	const dntFormat = store.global.getDntFormat

	const users = projStore.projUsers.formattedUsers(
		dntFormat.timeZone,
		dntFormat.dateFormat
	)

	useEffect(() => {
		let roleList: string[] = []
		const assignedRoles: any = [] //fileInfo && fileInfo?.assignedRoles

		// set inputs for update
		let roleCheckboxes: any = []
		roleSet?.map((role: any) => {
			roleCheckboxes.push({
				label: role.name,
				shortName: role.shortName,
				value: role.roleId,
				checked: roleList.includes(role.roleId),
			})
		})
		setInputs(roleCheckboxes)
	}, [])

	const handleCheckbox = () => {}
	const isSelected = () => {}

	return (
		<DLDialog
			eleTestId="assign-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			handleAction={() => {}}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={`Assign ${type}`}
			dialogContents={
				<div>
					<h3>Required role information for item - Prepare</h3>
					<RoleAssignDialogForm
						inputs={inputs}
						handleInputs={() => {}}
						fileInfo={{}}
						partialStore={partialStore}
					/>

					<ReactTableV8
						tableColumns={Columns(handleCheckbox, isSelected)}
						data={users}
						menuId="ProjInfoProjUsers"
						showPageSetting={false}
						hasPagination={false}
					/>
				</div>
			}
			cancelBtnText={"Cancel"}
			actionReady={true}
			actionBtn={"Assign"}
			maxWidth="md"
			fullWidth={true}
		/>
	)
})

const Columns = (handleCheckbox: any, isSelected: any) => [
	{
		header: "",
		accessorKey: "id",
		size: 20,
		enableColumnFilter: false,
		cell: (props: any) => {
			const id = props.row.original.id
			const handleClick = () => {
				handleCheckbox(id)
			}

			return (
				<DLCheckbox
					eleTestId={`checkbox-${id}`}
					clickHandler={() => handleClick()}
					color="primary"
					isChecked={isSelected(id)}
				/>
			)
		},
	},
	{
		header: "Type",
		accessorKey: "type",
	},
	{
		header: "Status",
		accessorKey: "status",
	},
	{
		header: "Role",
		accessorKey: "roleId",
		size: 30,
		cell: (props: any) => {
			const roleId = props.getValue()
			const roleInfo = findProjRoleInfo(roleId)
			return (
				<div className="FR AC">
					<DLAvatar
						size={18}
						fontSize={12}
						text={roleInfo?.shortName}
						eleTestId={`${roleInfo?.shortName}-avatar`}
						color={roleInfo?.color}
					/>
					<span style={{ marginLeft: 4 }}>{roleInfo?.name}</span>
				</div>
			)
		},
	},
	{
		header: "User Name",
		accessorKey: "name",
	},
	{
		header: "User Alias ID",
		accessorKey: "aliasId",
	},
]
