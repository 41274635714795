import { types } from "mobx-state-tree"

// const ProjMenuOpenState = types.model({
// 	id: types.string,
// 	open: types.boolean,
// })

const ProjRolesUiHelper = types.model({}).views((self) => ({
	// oneOrMoreMenuOpened() {
	// 	if (self.openedMenus.find(element => element.open)) {
	// 		return true
	// 	} else {
	// 		return false
	// 	}
	// },
	// oneOrMoreMenuCollapsed() {
	// 	if (self.openedMenus.find(element => !element.open)) {
	// 		return true
	// 	} else {
	// 		return false
	// 	}
	// },
	// isOpened(menuId: string) {
	// 	return self.openedMenus.find(element => element.id === menuId)?.open
	// }
}))

export default ProjRolesUiHelper
