import React, { useState } from "react"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import FileMenuToolbar from "./FileMenuToolbar"
import CtxMenusForCreate from "../context-menus/menuOptions-create"
import allMenuOptions from "../context-menus/all-menu-options"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { OldSignOffType } from "../../../../../service-modules/file-module/data-models/sign-off.data-model"
import { ProjectFilesStoreProps } from "../../../../../service-modules/file-module/data-models/files-store-props"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import {
	FileMenuViewType,
	FileScreenDialog,
} from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { ConsoleLog } from "../../../../../components/basic-elements"

export default observer(function FileMenuToolbarController({
	partialStore,
	permission,
	i18n,
}: {
	partialStore: ProjectFilesStoreProps
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	const projStore = useProjStore()
	const projId = projStore.checkin.projId

	const userId = projStore.checkin.userId
	const roleId = projStore.checkin.user.roleId

	const setShowCheckbox = partialStore.setShowCheckbox
	const setViewType = partialStore.setViewType

	//
	const setOpenedFilesDialogOpen =
		projStore.openedFiles.setOpenedFilesDialogOpen
	const expandAll = partialStore.expandAll
	//
	const setShowStatus = partialStore.setShowStatus
	const setShowComments = partialStore.setShowComments
	const setShowHistory = partialStore.setShowHistory
	//
	const downloadAllAsZip = partialStore.downloadAllAsZip

	const handleAllowDrag = () => {
		// handle allow drag = unlock folders
		// NOTE: project template has no folder lock
		const request = partialStore.structureLock ? "unlock" : "lock"
		partialStore.editStructureLock({ projId, request })
	}
	const handleShowCheckbox = () => {
		setShowCheckbox()
	}
	const handleExpandAll = () => {
		partialStore.getAllFoldersAndFiles(projId, false)
		// expandAll(true) // WARNING: Do not change the status by this line. Expand all should request all files again if all the folder are collapsed
		// but if there are some expanded folder, they are not requsted again.
		// No need to change the expanded status individually.
	}
	const handleCollapseAll = () => {
		partialStore.resetSelectedItems()
		expandAll(false)
	}
	const handleViewType = (viewType?: FileMenuViewType) => {
		setViewType(viewType)
		if (
			partialStore.flatList.filter((item: any) => item.isParent).length <
			1
		) {
			partialStore.refresh()
		}
	}
	// * ----- DRAWER related parts
	const handleCommentDrawer = () => {
		projStore.comments.setOpenDrawer()
		if (projStore.lib.drawerOpen) {
			projStore.lib.setDrawerOpen(false)
		}
		if (projStore.comments.firstCalled) {
			projStore.comments.setFirstCalled(false)
		}
	}
	const handleDiagnosisDrawer = () => {
		projStore.archive.setOpenDrawer()
		if (projStore.archive.firstCalled) {
			projStore.archive.setFirstCalled(false)
		}
	}
	const handleLibraryDrawer = () => {
		projStore.lib.setOpenedOn(partialStore.storeName)
		projStore.lib.setDrawerOpen()
		if (projStore.comments.openDrawer) {
			projStore.comments.setOpenDrawer(false)
		}
		if (projStore.lib.firstCalled) {
			projStore.lib.setFirstCalled(false)
		}
	}

	// * ----- DIALOG related parts
	const handleOpenedFilesDialog = () => {
		setOpenedFilesDialogOpen()
	}

	const handleSearchView = () => {
		if (partialStore.viewType === FileMenuViewType.search) {
			partialStore.setViewType(FileMenuViewType.tree)
		} else {
			partialStore.setViewType(FileMenuViewType.search)
		}
	}
	//
	const ctxCreateMenus = CtxMenusForCreate(partialStore, i18n)
	//
	// ----- Sign Off
	const handleSignOffByToolbar = (type: OldSignOffType) => {
		partialStore.handleSignOff({
			type,
			forCancel: false,
			userId,
			roleId,
		})
	}

	const handleUnSignOffByToolbar = (type: OldSignOffType) => {
		partialStore.handleSignOff({
			type,
			forCancel: true,
			userId,
			roleId,
		})
	}

	const handleSignOffFocusedView = () => {
		ConsoleLog("handleSignOffFocusedView")
		// formatWidth = 0 --> pending (how to restore the default value??)
		// showAttentions = false
		// showComments = false
		// show LastModified = false
		setShowStatus()
		setShowComments()
		setShowHistory()
	}

	const exportMenuOptions = [
		{
			label: "Download all as ZIP",
			value: "download-all-as-zip",
			clickEvent: () => {
				if (partialStore.storeName === DLProjSubMenus.wp) {
					partialStore.setDownloadType("WP")
					partialStore.setFileTreeDialogOpen(
						FileScreenDialog.exportFolderDialog
					)
				} else {
					downloadAllAsZip({ projId })
				}
			},
		},
	]

	// sort root folders
	const [isAsc, setIsAsc] = useState(true)
	const handleSortRootFolders = () => {
		partialStore.sortChildren({ folderId: null, isAsc })
		setIsAsc(!isAsc)
	}

	//
	const structureLock = partialStore.structureLock
	const showCheckbox = partialStore.showCheckbox
	const viewType = partialStore.viewType
	//
	const needExpandAll = partialStore.needExpandAll
	const needCollapseAll = partialStore.needCollapseAll
	//
	const countSelectedFiles = partialStore.countSelectedFiles
	//
	const checkAvailableSignOffs = partialStore.checkAvailableSignOffs()
	//
	const commentDrawerStatus = projStore.comments.drawerOpenStatus
	const diagnosisDrawerStatus = projStore.archive.drawerOpenStatus
	const libDrawerStatus = projStore.lib.drawerOpenStatus
	//
	const commentBadgeContent = projStore.comments.commentCountByMenu(
		partialStore.storeName
	)
	return (
		<FileMenuToolbar
			permission={permission}
			ctxCreateMenus={ctxCreateMenus}
			//
			structureLock={structureLock}
			showCheckbox={showCheckbox}
			viewType={viewType}
			//
			commentDrawerStatus={commentDrawerStatus}
			commentBadgeContent={commentBadgeContent}
			diagnosisDrawerStatus={diagnosisDrawerStatus}
			libDrawerStatus={libDrawerStatus}
			//
			countSelectedFiles={countSelectedFiles}
			//
			handleAllowDrag={handleAllowDrag}
			handleShowCheckbox={handleShowCheckbox}
			//
			handleExpandAll={handleExpandAll}
			handleCollapseAll={handleCollapseAll}
			needExpandAll={needExpandAll}
			needCollapseAll={needCollapseAll}
			handleSignOffFocusedView={handleSignOffFocusedView}
			//
			handleViewType={handleViewType}
			//
			handleCommentDrawer={handleCommentDrawer}
			handleDiagnosisDrawer={handleDiagnosisDrawer}
			handleOpenedFilesDialog={handleOpenedFilesDialog}
			handleLibraryDrawer={handleLibraryDrawer}
			handleSearchView={handleSearchView}
			//
			exportMenuOptions={exportMenuOptions}
			allMenuOptions={allMenuOptions}
			//
			availableSignOffs={checkAvailableSignOffs}
			handleSignOffByToolbar={handleSignOffByToolbar}
			handleUnSignOffByToolbar={handleUnSignOffByToolbar}
			//
			partialStore={partialStore}
			i18n={i18n}
			//
			isAsc={isAsc}
			handleSortRootFolders={handleSortRootFolders}
			projMenuTabStore={projStore.menuTabs}
		/>
	)
})
