// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const ProjectLockUnlock = (self: any) => ({
	setProjectLock(postAction: any) {
		// 0.
		const actionName = "setProjectLock"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.projectLock()
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					self.setProjectLockStatus("DONE")
					postAction()
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						customMessage: response.data.Message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
	setProjectUnLock(postAction: any) {
		// 0.
		const actionName = "setProjectUnLock"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.projectUnLock()
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					self.setProjectLockStatus("TODO")
					postAction()
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default ProjectLockUnlock
