import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { DLMenuId } from "../../../../components/combined-elements/file-tree/dl-file-tree-props"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { FileScreenDialog } from "../../data-models/dl-file-control-model"

/**
 *
 * @param self
 * @returns
 *
 */

const EditSignOffLock = (self: any) => ({
	editSignOffLock({
		signOffLock,
		fileId,
		menuId,
	}: {
		signOffLock: boolean
		fileId: string
		menuId: DLMenuId
	}) {
		// 0. set actionName
		const actionName = "editSignOffLock"
		// 1.
		// 2. set responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. request API call

		const targetId = idToNumber(fileId, IdType.file)

		self.updateSignOffLock(targetId, signOffLock, menuId)
			.then((response: any) => {
				ConsoleLog([actionName, response.data])
				if (response.status === 200) {
					self.reflectSignOffLock(fileId, response.data.SignOffLock)

					self.setFileTreeDialogOpen(
						FileScreenDialog.signOffLockDialog,
						false
					)

					// when success
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						customMessage: response.data.Message,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						customMessage: response.data.Message,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default EditSignOffLock
