import React, { useEffect } from "react"
// store related
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
// components
import { PageContainer } from "../../../../components/app-frame-elements"
import {
	DLSpinnerCenterAligned,
	ConsoleLog,
} from "../../../../components/basic-elements"
//
import ProjFileTreeController from "../proj-files-components/ProjFileTreeController"
import { Toolbar, NoFolderExist } from "../proj-files-components"
import {
	ActionStatus,
	DLSector,
} from "../../../../common-models/enumerations/common-enums"
import { ProjI18n } from "../../../../common-models/enumerations/translation-sheets"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { FileScreenDialog } from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { FileFolderDialog } from "../../../../components/combined-elements/file-tree/file-tree-dialogs"
import OpenedFileListDialog from "../../opened-files/OpenedFilesDialog"
import CommentDetailDialogController from "../proj-files-components/dialogs/CommentDetailDialogControllerForFiles"
import CommentCloseDialog from "../../../../components/combined-elements/comment/CommentCloseDialog"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import styled from "styled-components"
// @Noah: page setting is required

export default observer(function PermanentFiles() {
	const store = useRootStore()
	const projStore = useProjStore()
	const projId = projStore.checkin.projId
	const pfStore = projStore.pf
	const wpStore = projStore.wp
	//
	const menuId = DLProjSubMenus.pf
	const actionName = "getFirstFolders"
	const i18nSheet = ProjI18n.files
	//
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived

	const needRefresh = pfStore.needRefresh

	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)

	ConsoleLog(" ----------------------- ")
	ConsoleLog(" ----------------------- ")
	ConsoleLog(" ----------------------- ")
	ConsoleLog(" ----------------------- ")
	ConsoleLog(" ----------------------- PF rendering")

	const postGetChildrenOfFolder = (depth: number) => {
		const folderId = wpStore.viewGoToFolderByDepth(depth + 1)?.folderId
		if (folderId) {
			pfStore.toggleExpanded(`${folderId}`)
			pfStore.getChildrenOfFolder(`${folderId}`, () =>
				postGetChildrenOfFolder(depth + 1)
			)
		} else {
			pfStore.setSelectedItem(wpStore.goToFileId, false)
		}
	}

	const postAction = () => {
		const folderId = wpStore.viewGoToFolderByDepth(0)?.folderId
		console.log(folderId, "folderId")
		if (folderId) {
			pfStore.toggleExpanded(`${folderId}`)
			pfStore.getChildrenOfFolder(`${folderId}`, () =>
				postGetChildrenOfFolder(0)
			)
		}
	}

	//
	useEffect(() => {
		if (menuAccess && needRefresh) {
			// if (pfStore.flatListLength <= 0) {
			pfStore.getFirstFolders(projId, postAction)

			// NOTE: get project users if the getProjUser status is not success
			if (
				projStore.projUsers.getActionStatus("getProjUsers") !==
				ActionStatus.success
			) {
				projStore.projUsers.getProjUsers()
				projStore.roles.getRoleSet(DLSector.proj)
			}
			// NOTE: get comments if the
			if (
				projStore.comments.getActionStatus("getComments") !==
				ActionStatus.success
			) {
				projStore.comments.getComments(
					"all",
					projStore.checkin.projId,
					menuId
				)
			}
		}
	}, [menuAccess, needRefresh])

	const handleAddFolder = (folderName: string) => {
		let parentId = pfStore.selectedFolder

		if (parentId === "" || parentId === null) {
			parentId = "folderId0" // Root Folder
		}
		pfStore.addFileFolder({
			projId,
			parentId,
			title: folderName,
		})
	}

	const handleEditFolder = (folderName: string) => {
		pfStore.editFileFolder({
			folderId: pfStore.selectedFolder,
			folderName,
			postAction: pfStore.setFileTreeDialogOpen(
				FileScreenDialog.editFolder,
				false
			),
		})
	}

	const i18n = store.i18n.combineI18n(i18nSheet)
	const pfGetStatus = pfStore.getActionStatus(actionName)
	return (
		<>
			{menuAccess ? (
				<StyledPF>
					<PageContainer
						pageTools={
							<Toolbar
								partialStore={pfStore}
								permission={permission}
								i18n={i18n}
							/>
						}
						fullWidth
						noPadding
						defaultToolbarStatus="expanded"
					>
						{pfGetStatus === ActionStatus.loading &&
							pfStore.flatList.length === 0 && (
								<DLSpinnerCenterAligned />
							)}
						{pfGetStatus === ActionStatus.success &&
							pfStore.itemNotExist &&
							(permission.create ? (
								<NoFolderExist
									handlerCreateFolder={() =>
										pfStore.setFileTreeDialogOpen(
											FileScreenDialog.createFolder,
											true
										)
									}
									i18n={i18n}
								/>
							) : (
								<div
									className="FR AC JC"
									style={{ width: "100%", height: "100%" }}
								>
									{i18n.tsNoFiles || "No files"}
								</div>
							))}
						{pfGetStatus === ActionStatus.success &&
							!pfStore.itemNotExist && (
								<div className="pf-main-container FC">
									<div className="tree-list">
										<ProjFileTreeController
											partialStore={pfStore}
										/>
									</div>
								</div>
							)}

						{/* ----- Folder related ----- */}
						{/* NOTE: Do not move 'create folder dialog' to filetree controller */}
						{pfStore.fileTreeDialogOpenStatus[
							FileScreenDialog.createFolder
						] && (
							<FileFolderDialog
								actionHandler={handleAddFolder}
								type="add"
								partialStore={pfStore}
								i18n={i18n}
							/>
						)}
						{pfStore.fileTreeDialogOpenStatus[
							FileScreenDialog.editFolder
						] && (
							<FileFolderDialog
								actionHandler={handleEditFolder}
								type="edit"
								partialStore={pfStore}
								i18n={i18n}
							/>
						)}

						{/* TODO: @Noah: It seems there are two OpenedFileListDialog. Another one takes menuId and partialStore. Let's check */}
						{/* This is my old memo... but I'm not sure why I writen like this -->  TODO: below 'listDialog' should be separated per screen  */}
						{/* NOTE: Do not move 'opened files dialog' to filetree controller */}
						{projStore.openedFiles.openedFilesDialogOpen && (
							<OpenedFileListDialog menuId={menuId} />
						)}

						{pfStore.fileTreeDialogOpenStatus[
							FileScreenDialog.commentDetail
						] && (
							<CommentDetailDialogController
								partialStore={pfStore}
								i18n={i18n}
							/>
						)}

						{pfStore.commentClosingDialogOpenStatus && (
							<CommentCloseDialog
								partialStore={pfStore}
								commentId={pfStore.selectedComment}
							/>
						)}

						{/* ----- Snackbar ----- */}
						<CommonSnackbarComponent
							i18n={i18n}
							partialStore={pfStore}
						/>
					</PageContainer>
				</StyledPF>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

const StyledPF = styled.div`
	.page-toolbar {
		height: 5rem !important;
	}
`
