import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { findProjRoleNewId } from "../../../../../temporary-data/project-side/find-proj-role"
import { findProjAccessTypeId } from "../../../../../temporary-data/project-side/find-proj-accessType"
import {
	idToString,
	IdType,
} from "../../../../../library/converters/id-converter"
import { convertProjRoleForPermission } from "../../../../../library/converters/convert-role-id"

export type FetchedProjUserType = {
	UserID: number
	Name: string
	UserName: string
	Email: string
	OrganizationTitle: string
	UserType: number
	isGrayed: boolean
	RoleId: number
	Role: string
	AccessRightID: number
	AccessRight: string
	isRollForward: number
	ModifiedBy: number
	ModifiedByName: string
	ModifiedDate: any
	LastActivity: any
	ExpDate: any
	InActiveDueTo: any
	IsStrikeOut: boolean
}

// NOTE: cannot use this reorganizer for add proj user (assign proj user)
// because they have too much differnt API response
export const reorganizeProjUser = (fetchedUser: FetchedProjUserType) => {
	// console.log("fetchedUser >>>>>", fetchedUser)
	const projUser = {
		// organization side info
		id: idToString(fetchedUser.UserID, IdType.user),
		name: fetchedUser.Name,
		aliasId: fetchedUser.UserName,
		email: fetchedUser.Email,
		orgTitle: fetchedUser.OrganizationTitle || "-",
		type: fetchedUser.UserType === 1 ? "internal" : "external",
		// project side info
		status: fetchedUser.isGrayed ? "Inactive" : "Active",
		// ----- permission info
		// NOTE: WATCHOUT. Current access right is not applied on the below function
		// NOTE: current sign off has individual restriction on the screen
		// NOTE: Where is the permission related part...
		// below roleId is for displayed role
		permissionRole: convertProjRoleForPermission(
			fetchedUser.RoleId,
			fetchedUser.AccessRightID
		),
		roleId:
			fetchedUser.RoleId === 0
				? 0
				: findProjRoleNewId(fetchedUser.RoleId),
		roleNumId: fetchedUser.RoleId,
		roleName: fetchedUser.Role,
		//
		accessTypeId: findProjAccessTypeId(fetchedUser.AccessRightID),
		accessTypeName:
			fetchedUser.AccessRight === "Project & Archive Admin"
				? "Project Archive Admin"
				: fetchedUser.AccessRight,
		canRollForward: fetchedUser.isRollForward === 1 ? true : false,
		// ----- date related info
		modifiedById: idToString(fetchedUser.ModifiedBy, IdType.user),
		modifiedByName: fetchedUser.ModifiedByName,
		modifiedAt: fetchedUser.ModifiedDate,
		lastActivity: fetchedUser.LastActivity ? fetchedUser.LastActivity : "",
		expDate: fetchedUser.ExpDate,
		inactiveDueTo: fetchedUser.InActiveDueTo,
		strikedOut: fetchedUser.IsStrikeOut,
	}
	return projUser
}

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const GetProjUsers = (self: any) => ({
	getProjUsers() {
		// 0.
		const actionName = "getProjUsers"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API
		self.readProjUsers()
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					// Empty the project user list on the model
					self.resetList()
					const fetchedUsers = response.data.UsersList
					// console.log("fetchedUsers", fetchedUsers)
					let users: any[] = []
					fetchedUsers.map((user: any) => {
						users.push(reorganizeProjUser(user))
					})
					console.log("users", users)
					self.setProjUsers(users)
					// when success
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// when fail
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetProjUsers
