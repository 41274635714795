import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import { initialStore } from "./audit-program.provider"
import {
	AssignableUsers,
	AuditSteps,
} from "./data-models/audit-program.data-models"
import GetAllAuditSteps from "./view-model-actions/get-all-audit-steps"
import AddAuditStep from "./view-model-actions/add-audit-step"
import EditAuditStep from "./view-model-actions/edit-audit-step"
import RemoveAuditStep from "./view-model-actions/remove-audit-step"
import AuditStepSignOffPrepared from "./view-model-actions/audit-step-signoff-prepared"
import { getTzDate } from "../../../../library/converters/date-utc-converter"
import AuditStepSignOffReviewed from "./view-model-actions/audit-step-signoff-reviewed"

const AuditProgramViewModel = types
	.model({
		auditSteps: types.array(AuditSteps),
		assignableUsers: types.array(AssignableUsers),
		assignableWPRef: types.array(types.string),
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setAuditSteps(steps: any) {
			self.auditSteps.length = 0
			self.auditSteps = steps
		},
		setAssignableUsers(steps: any) {
			self.assignableUsers.length = 0
			self.assignableUsers = steps
		},
		setAssignableWPRef(steps: any) {
			self.assignableWPRef.length = 0
			self.assignableWPRef = steps
		},
		pushItemToAuditSteps(item: any) {
			self.auditSteps.push(item)
		},
		updateAuditStepById(item: any) {
			const index = self.auditSteps.findIndex(
				(i: any) => i.auditProgramId === item.auditProgramId
			)
			self.auditSteps[index] = item
		},
		popItemFromAuditSteps(auditProgramId: any) {
			self.auditSteps.splice(
				self.auditSteps.findIndex(
					(item) => item.auditProgramId === auditProgramId
				),
				1
			)
		},
		setNeedRefresh(request?: boolean) {
			if (request !== undefined) {
				self.needRefresh = request
			} else {
				self.needRefresh = !self.needRefresh
			}
		},
	}))
	.actions(GetAllAuditSteps)
	.actions(AddAuditStep)
	.actions(EditAuditStep)
	.actions(RemoveAuditStep)
	.actions(AuditStepSignOffPrepared)
	.actions(AuditStepSignOffReviewed)
	.views((self) => ({
		getAssignableUsers() {
			return self.assignableUsers.map((item: any) => {
				return {
					name: item.UserName,
					value: item.UserId,
				}
			})
		},
		getAssignableWPRefs() {
			return self.assignableWPRef.map((item: any) => {
				return {
					name: item,
					value: item,
				}
			})
		},
		getAuditStepById(auditProgramId: number) {
			return self.auditSteps.find(
				(item: any) => item.auditProgramId === auditProgramId
			)
		},
		formattedAuditSteps(dateFormat: string, timeZone: string) {
			function formatDate(date: string | null) {
				if (!date) {
					return ""
				}
				return getTzDate({
					date,
					timeZone,
					dateFormat,
				})
			}

			const steps = [...self.auditSteps]
			let formattedList: any[] = []
			steps.map((step: any) => {
				const formatted = {
					...step,
					preparerDueDate: formatDate(step.preparerDueDate),
					reviewerDueDate: formatDate(step.reviewerDueDate),
					preparedByAt: step.prepared
						? `${step.prepared} ${formatDate(step.preparedAtUtc)}`
						: "",
					reviewedByAt: step.reviewed
						? `${step.reviewed} ${formatDate(step.reviewedAtUtc)}`
						: "",
				}

				formattedList.push(formatted)
			})

			return formattedList
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default AuditProgramViewModel
