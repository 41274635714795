import {
	ActionStatus,
	MessageColorType,
	DLObjectFormat,
} from "../../../../common-models/enumerations/common-enums"
import { initialDLFileAsFolder } from "../../data-models/initial-file"
import {
	idToNumber,
	idToString,
	IdType,
} from "../../../../library/converters/id-converter"
import { GetFileInfoApiMenu } from "../../apis/file-system-file-apis"
import { reorganizeFile } from "./util-reorganize-file"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const SearchFileOrFolder = (self: any) => ({
	searchFileOrFolder({
		projectId,
		type,
		searchText,
		financialYear,
		fileType,
	}: {
		projectId: string
		type: number
		searchText: string
		financialYear: string
		fileType?: GetFileInfoApiMenu
	}) {
		// 0.
		const actionName = "searchFileOrFolder"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		const clientId = idToNumber(projectId, IdType.project)

		const payload = {
			FinYear: financialYear,
			Type: type,
			ClientId: clientId,
			EnteredText: searchText,
			...(fileType && { FileType: fileType }),
		}

		// console.log(actionName, projId, fileInfo)
		self.requestFileOrFolderSearch(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const fetchedData = response.data.WorkpaperDetails
					// console.log(actionName+ "__response " + response)
					// reflect the result to stored data
					// set success case response

					//
					let children: any[] = []
					if (fetchedData) {
						fetchedData.map((file: any, index: number) => {
							const parentId = idToString(
								file.ParentMediaId,
								IdType.folder
							)

							//---> If the type is folder
							if (type === 2) {
								const folderId = idToString(
									file.id, // NOTE: folder search returrn id value but no WprId and Media Id
									IdType.folder
								)

								const reOrganizedSubFolder = {
									...initialDLFileAsFolder,
									menuId: self.storeName,
									id: folderId,
									title: file.title,
									objectFormat: DLObjectFormat.folder,
									parentId:
										file.ParentMediaId !== 0
											? parentId
											: null,
									expanded: false,
									index,
								}
								children.push(reOrganizedSubFolder)
							}
							//---> If the type is file
							if (type === 1 || type === 3) {
								const setSameFormatWithCommonFileData = {
									...file,
									WPDesc: file.WpDesc,
									ActualFilePath: file.WprFilePath,
									LastModified: null,
									WPEF: file.Wref,
									WPRID: file.WprId,
									MediaID: file.MediaId,
									//-----------------
									FileSize: "",
									isOpen: false,
									OpenedBy: null,
									OpenedByUser: "",
								}
								const reorganizedFile = reorganizeFile({
									fetchedFile:
										setSameFormatWithCommonFileData,
									index,
									menuId: self.storeName,
									expanded: false,
									folderId: parentId,
								})
								console.log(
									index,
									" _reorganizedFile:",
									reorganizedFile
								)
								children.push(reorganizedFile)
							}
						})
					}
					console.log("children:", children)
					self.setSearchResults(children)

					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})
				} else if (
					response.status === 200 &&
					response.data.Status === 0 &&
					response.data.WorkpaperDetails === null
				) {
					self.setSearchResults([])
					// no results found
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: `No results found...`,
						open: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default SearchFileOrFolder
