import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { PageContainer } from "../../../../components/app-frame-elements"
import styled from "styled-components"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { ProjScreenType } from "../../../../components/combined-elements/projects-table/projects-table-props"
import SharedProjectTableV8 from "../../../../components/combined-elements/projects-table/SharedProjectTableV8"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import {
	getPageSettings,
	savePageSetting,
} from "../../../../library/get-page-settings"
import { DLContextMenu } from "../../../../components/basic-elements"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import ProjectDetailsDialog from "../../../../components/combined-elements/project-dialogs/ProjectDetailsDialog"

const RemindReportController = observer(
	({ menuId }: { menuId: DLOrgSubMenus }) => {
		const store = useRootStore()
		const orgStore = useOrgStore()
		// const menuId = DLOrgSubMenus.remind_report
		const projStatus = ProjectStatus.normal // What will be the proj status
		const actionName = "getRemindProjectList_" + projStatus
		//
		const menuAccess = orgStore.checkin.checkAccess(menuId)
		const permission = orgStore.checkin.getActionsAsObject(menuId)
		const i18nSheet = OrgI18n.projects
		const needRefresh =
			menuId === DLOrgSubMenus.remind_report
				? orgStore.projects.needRefreshForRemindReport
				: orgStore.projects.needRefreshForAdminReport
		const dntFormat = store.global.getDntFormat
		useEffect(() => {
			if (menuAccess && needRefresh) {
				// const defaultYear = getDefaultFinancialYear(menuId)
				const pageSetting = getPageSettings(menuId)
				if (pageSetting?.columnFilters) {
					const value = pageSetting?.columnFilters.filter(
						(item: any) => item.id !== "financialYear"
					)
					savePageSetting(
						"columnFilters",
						value,
						`pageSetting_${menuId}`
					)
				}
				orgStore.projects.getRemindProjectList({
					projStatus,
					year: "All Years",
					PTMAssignedOnly: menuId === DLOrgSubMenus.remind_report,
					menuId,
					dntFormat,
				})
			}
		}, [menuAccess, needRefresh])

		const loadingStatus = orgStore.projects.getActionStatus(actionName)
		const i18n = store.i18n.combineI18n(i18nSheet)
		const clickPointExist = orgStore.projects.clickPoint.mouseX !== null
		const clickPoint = {
			mouseX: orgStore.projects.clickPoint.mouseX,
			mouseY: orgStore.projects.clickPoint.mouseY,
		}

		const projId = orgStore.projects.selectedProj
		const projNumId = idToNumber(projId, IdType.project)

		console.log(orgStore.projects.remindProjList, "remindProjList")

		return (
			<>
				{menuAccess ? (
					<PageContainer
						pageTools={<div />}
						hasToolbar={false}
						fullWidth
						fixedHeight
						isLoading={loadingStatus === "LOADING"}
					>
						<StyledRemindReport>
							{loadingStatus === "SUCCESS" && (
								<SharedProjectTableV8
									partialStore={orgStore.projects}
									i18n={i18n}
									// common part 2
									projScreenType={ProjScreenType.normal}
									menuId={menuId}
									projectStatus={ProjectStatus.normal}
									permission={permission}
									hasDueInFilter
								/>
							)}
						</StyledRemindReport>

						{clickPointExist && (
							<DLContextMenu
								eleId="remind-report-ctx-menus"
								clickPoint={clickPoint}
								handleClose={() =>
									orgStore.projects.setClickPoint({
										mouseX: null,
										mouseY: null,
									})
								}
								menuOptions={[
									{
										label: "Open",
										value: "open",
										// disabled: !projectInfo?.accessible,
										available: permission.read,
										isLink: true,
										linkTo: `/project/checkin/${projNumId}`,
									},
									{
										label: "Project Information",
										value: "ctx-proj_info",
										available: permission.read,
										clickEvent: () =>
											orgStore.projects.setProjectDetailsDialogOpen(
												true
											),
									},
								]}
							/>
						)}

						{orgStore.projects.projectDetailsDialogOpen && (
							<ProjectDetailsDialog
								partialStore={orgStore.projects}
							/>
						)}
					</PageContainer>
				) : (
					<OrgNoPermissionOnMenu menuId={menuId} />
				)}
			</>
		)
	}
)

const StyledRemindReport = styled.div`
	padding: 0 1rem;
`

export default RemindReportController
