import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../common-models/common-model-actions"
import ReadAllAuditSteps from "./model-actions/read-all-audit-steps"
import CreateAuditStep from "./model-actions/create-audit-step"
import UpdateAuditStep from "./model-actions/update-audit-step"
import DeleteAuditStep from "./model-actions/delete-audit-step"
import MAuditStepSignoffPrepared from "./model-actions/m-audit-step-signoff-prepared"
import MAuditStepSignoffReviewed from "./model-actions/m-audit-step-signoff-reviewed"

const AuditProgramModel = () =>
	types
		.model({})
		.actions(ReadAllAuditSteps)
		.actions(CreateAuditStep)
		.actions(UpdateAuditStep)
		.actions(DeleteAuditStep)
		.actions(MAuditStepSignoffPrepared)
		.actions(MAuditStepSignoffReviewed)
		// common parts
		.actions(CommonModelActions)

export default AuditProgramModel
