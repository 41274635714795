import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { PageContainer } from "../../../components/app-frame-elements"
import RemoveAndRestoreDialog from "./sub-components/RemoveAndRestoreDialog"

import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../common-models/types/common-props"
import OrgTrashCtxMenus from "./sub-components/OrgTrashCtxMenus"
import { PermissionAsObjectProps } from "../../../common-models/permission"
import CommonSnackbarComponent from "../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import OrgTrashTable from "./sub-components/OrgTrashTable"
import { getPageSettings } from "../../../library/get-page-settings"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

const OrgTrash = observer(
	({
		partialStore,
		permission,
		i18n,
	}: {
		partialStore: any
		permission: PermissionAsObjectProps
		i18n: DLI18nProps
	}) => {
		const [open, setOpen] = useState(false)
		const [type, setType] = useState<"Restore" | "Delete">("Restore")
		const actionName = "getOrgTrash"
		const menuId = DLOrgSubMenus.file_trash

		const needRefresh = partialStore.needRefresh
		useEffect(() => {
			if (needRefresh) {
				const pageSetting = getPageSettings(menuId)
				const type =
					pageSetting?.columnFilters.find(
						(item: any) => item.id === "type"
					)?.value || "GROUP"
				partialStore.getOrgTrashList(type)
			}
		}, [needRefresh])
		//
		const handleOpenDialog = (type: "Restore" | "Delete") => {
			setType(type)
			setOpen(true)
		}

		const handleCheckbox = (id: string) => {
			partialStore.pushOrSpliceItem(id)
		}

		const handleRightClick = (e: any, targetId: string) => {
			e.preventDefault()
			partialStore.pushSelectedItem(targetId)
			partialStore.setClickPoint({
				mouseX: e.clientX - 2,
				mouseY: e.clientY - 4,
			})
		}

		const clickPoint = partialStore.clickPoint
		const handleClose = partialStore.resetClickPoint
		const actionStatus = partialStore.getActionStatus(actionName)

		return (
			<PageContainer
				hasToolbar={false}
				pageTools={<div />}
				fullWidth
				fixedHeight
				isLoading={actionStatus === ActionStatus.loading}
			>
				<StyledOrgTrash>
					{actionStatus === "SUCCESS" && (
						<OrgTrashTable
							partialStore={partialStore}
							handleCheckbox={handleCheckbox}
							handleRightClick={handleRightClick}
							handleOpenDialog={handleOpenDialog}
							i18n={i18n}
						/>
					)}
					{open && (
						<RemoveAndRestoreDialog
							open={open}
							setOpen={setOpen}
							type={type}
							i18n={i18n}
						/>
					)}
				</StyledOrgTrash>

				{/* ---Snackbar---- */}
				<CommonSnackbarComponent
					i18n={i18n}
					partialStore={partialStore}
				/>

				<OrgTrashCtxMenus
					clickPoint={clickPoint}
					handleClose={handleClose}
					permission={permission}
					divider={[]}
					handleOpenDialog={handleOpenDialog}
					i18n={i18n}
				/>
			</PageContainer>
		)
	}
)

const StyledOrgTrash = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.counter-container {
		.total-count {
			margin-left: 0.4rem;
		}
		.partition {
			margin: 0 1rem;
		}
		.selected-items {
			margin: 0 0.6rem;
		}
	}
`

export default OrgTrash
