import React, { useEffect } from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import ArchiveHistory from "./ArchiveHistory"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { ProjI18n } from "../../../../common-models/enumerations/translation-sheets"

export default observer(function ArchiveHistoryController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.arch_history
	const actionName = "getArchiveHistory"
	const i18nSheet = ProjI18n.archHistory
	//
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived

	const needRefresh = projStore.archiveHistory.needRefresh

	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)
	//
	useEffect(() => {
		menuAccess &&
			needRefresh &&
			projStore.archiveHistory.getArchiveHistoryList()
	}, [menuAccess, needRefresh])

	const i18n = store.i18n.combineI18n(i18nSheet)
	return (
		<>
			{menuAccess ? (
				<ArchiveHistory
					partialStore={projStore.archiveHistory}
					contentsHeight={store.ui.contentsAreaHeight}
					fetchingStatus={projStore.archiveHistory.getActionStatus(
						actionName
					)}
					i18n={i18n}
					permission={permission}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
