import { types, applySnapshot } from "mobx-state-tree"
import {
	OrgUserListItem,
	BulkUserImportProps,
} from "./data-models/org-users.data-models"
// ---------- common models
import Responses from "../../../common-models/responses"
import ResponseSnackbar from "../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../common-models/common-view-model-actions"

// ------ sub actions
import {
	GetOrgUserList,
	AddOrgUser,
	RemoveOrgUser,
	EditOrgUser,
	RestoreOrgUser,
} from "./view-model-actions"
import { initialStore } from "./org-setup-users.provider"
import { getTzDate } from "../../../library/converters/date-utc-converter"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { DLProjectModel } from "../../../service-modules/project-module/data-model/project-module-data-models"
import { ConsoleLog } from "../../../components/basic-elements"
import {
	BulkUserImportRelated,
	BulkUserImportRelatedViews,
} from "./view-model-actions/bulk-user-import-related"

const ValidateOrgUserForm = (self: any) => ({
	isDuplicatedEmail(email: any) {
		return self.orgUserList.some((user: any) => user.email === email)
	},
	isDuplicatedName(name: string) {
		return self.orgUserList.some((user: any) => user.name === name)
	},
	isDuplicatedAliasId(aliasId: string) {
		return self.orgUserList.some((user: any) => user.aliasId === aliasId)
	},
})

const OrgUsersViewModel = types
	.model({
		orgUserList: types.array(OrgUserListItem),
		totalUser: types.number,
		//
		bulkUserList: types.array(BulkUserImportProps),
		bulkUserFileReadStatus: types.enumeration<ActionStatus>(
			Object.values(ActionStatus)
		),
		//
		assignedProjList: types.array(DLProjectModel),
		//
		assignedUsersOnGroup: types.array(OrgUserListItem),
		notAssignedUsersOnGroup: types.array(OrgUserListItem),
		//
		latestPageIndex: types.number,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		resetList() {
			self.orgUserList.length = 0
		},
		setUserList(usersList: any) {
			self.orgUserList.length = 0
			self.orgUserList = usersList
		},
		pushItemToList(item: any) {
			self.orgUserList.push(item)
		},
		setTotalUser(totalNumber: any) {
			self.totalUser = totalNumber
		},
		setAssignedProjects(list: any) {
			self.assignedProjList.length = 0
			self.assignedProjList = list
		},
		resetAssignedProjects() {
			self.assignedProjList.length = 0
		},
		setLatestPageIndex(pageIndex: number) {
			self.latestPageIndex = pageIndex
		},
		// for setup Groups (becuase current group related APi has no proper data on its response)
		getUsersByGroup(groupName: string) {
			ConsoleLog(["getUsersByGroup", groupName])
			// reset existing list
			self.assignedUsersOnGroup.length = 0
			self.notAssignedUsersOnGroup.length = 0
			//
			//
			self.orgUserList.map((user: any) => {
				// if (user.assignedGroups.includes(groupName)) {
				// 	ConsoleLog("matched with group", user.name)
				// 	const assignedUser = { ...user }
				// 	ConsoleLog("assignedUser", assignedUser)
				// 	self.assignedUsersOnGroup.push(assignedUser)
				// } else {
				// 	const notAssignedUser = { ...user }
				// 	ConsoleLog("notAssignedUser", notAssignedUser)
				// 	self.notAssignedUsersOnGroup.push(notAssignedUser)
				// }
			})
		},
		updateUserData(userInfo: any, id: string) {
			const target = self.orgUserList.find((user: any) => user.id === id)
			if (target) {
				target.title = userInfo.title.value
				target.name = userInfo.name.value
				target.aliasId = userInfo.aliasId.value
				target.accessRight = userInfo.accessRight.value
				target.type = userInfo.type.value
				if (target.accessRight === "Super Admin") {
					target.isArchiveAdmin =
						userInfo.isArchiveAdmin.value === "true" ? "Yes" : "No"
				}
			}
		},
		updateUserStatus(status: string, userId: string) {
			const target = self.orgUserList.find(
				(user: any) => user.id === userId
			)
			if (target) {
				target.status = status
			}
		},
		spliceUser(userId: string) {
			const targetIndex = self.orgUserList.findIndex(
				(user: any) => user.id === userId
			)
			self.orgUserList.splice(targetIndex, 1)
		},
	}))
	// ---------
	// AGER (Add, Get, Edit, Remove)
	.actions(AddOrgUser)
	.actions(GetOrgUserList)
	.actions(EditOrgUser)
	.actions(RemoveOrgUser)
	.actions(RestoreOrgUser)
	// ------related
	.actions(ValidateOrgUserForm)
	// ----------- bulk import clients
	.actions(BulkUserImportRelated)
	.views(BulkUserImportRelatedViews)
	// common
	.views((self) => ({
		userDetail(userId: string, dateFormat: string, timeZone: string) {
			const user = JSON.parse(
				JSON.stringify(
					self.orgUserList.find((user: any) => user.id === userId) ||
						{}
				)
			)
			const formattedCreatedAt = user?.createdAt
				? getTzDate({
						date: user.createdAt,
						timeZone,
						dateFormat,
				  })
				: ""
			const formattedLastAccess = user?.lastAccess
				? getTzDate({
						date: user.lastAccess,
						timeZone,
						dateFormat,
				  })
				: ""
			// return user
			return {
				...user,
				createdAt: formattedCreatedAt,
				lastAccess: formattedLastAccess,
			}
		},
		formattedOrgUserList(dateFormat: string, timeZone: string) {
			let formattedList: any[] = []
			self.orgUserList.map((user: any) => {
				const formattedCreatedAt = user.createdAt
					? getTzDate({
							date: user.createdAt,
							timeZone,
							dateFormat,
					  })
					: ""
				const formattedLastAccess = user.lastAccess
					? getTzDate({
							date: user.lastAccess,
							timeZone,
							dateFormat,
					  })
					: ""
				formattedList.push({
					...user,
					createdAt: formattedCreatedAt,
					lastAccess: formattedLastAccess,
				})
			})

			return formattedList
		},
		get userListForRender() {
			let userList: any[] = []
			self.orgUserList.map((user: any) => {
				userList.push({
					id: user.id,
					type: user.type,
					name: user.name,
					aliasId: user.aliasId,
					email: user.email,
					title: user.title,
					status: user.status,
					accessRight: user.accessRight,
					isArchiveAdmin: user.isArchiveAdmin,
				})
			})
			return userList
		},
		exportAssignedProjects() {
			let exportList: any[] = []
			self.assignedProjList.map((project: any) => {
				exportList.push({
					statusName: project.statusName,
					financialYear: project.financialYear,
					periodName: project.periodName,
					clientName: project.clientName,
					title: project.title,
					version: project.version,
				})
			})
			return exportList
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			// ConsoleLog("initialize store - org/setupUsers")
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgUsersViewModel
