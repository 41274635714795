import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog } from "../../../components/basic-elements"
import OpenedFilesListDialogForm from "./sub-components/OpenedFilesListDialogForm"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { ProjI18n } from "../../../common-models/enumerations/translation-sheets"

export default observer(function OpenedFileListDialog({
	menuId,
}: {
	menuId: DLProjSubMenus
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const actionName = "getOpenedFiles"
	const i18nSheet = ProjI18n.openedFiles

	useEffect(() => {
		projStore.openedFiles.getOpenedFiles(menuId)
	}, [])

	const i18n = store.i18n.combineI18n(i18nSheet, "OpenedFilesDialog")
	const open = projStore.openedFiles.openedFilesDialogOpen
	const setOpen = projStore.openedFiles.setOpenedFilesDialogOpen
	const isLoading =
		projStore.openedFiles.getActionStatus(actionName) === "LOADING"

	return (
		<DLDialog
			eleTestId="opened-files-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle="Checked-Out Files (Opened Files)"
			dialogContents={
				<OpenedFilesListDialogForm
					menuId={menuId}
					partialStore={projStore.openedFiles}
					i18n={i18n}
				/>
			}
			maxWidth="md"
			fullWidth={true}
			showSpinner={isLoading}
		/>
	)
})
