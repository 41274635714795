import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { findProjRoleNumberId } from "../../../../../temporary-data/project-side/find-proj-role"
import { findProjAccessTypeAsNumber } from "../../../../../temporary-data/project-side/find-proj-accessType"
import { UpdateProjUserProps } from "../data-models/proj-setup-users.data-models"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"

export type EditProjUserProps = {
	request: UpdateProjUserProps
	userId: string
	actBy: string
	projId: string
	temporaryTargetUserAliasId?: string
}

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const EditProjUser = (self: any) => ({
	editProjUser({
		request,
		userId,
		actBy,
		projId,
		temporaryTargetUserAliasId,
	}: EditProjUserProps) {
		// 0.
		const actionName = "editProjUser"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			open: true,
		})
		// 3. call API
		let payload: any = {
			UserID: idToNumber(userId, IdType.user),
			RoleId: findProjRoleNumberId(request.roleId),
			ClientID: idToNumber(projId, IdType.project),
			// UserName: request.userName,
			// TODO: is this required prop? // Need to get userName by props for testing
			// WARNING: Do not pass the username here to request. It updates the org user info
			ModifiedBy: idToNumber(actBy, IdType.user),
			AccessRightID: findProjAccessTypeAsNumber(request.accessTypeId),
			ExpirationDate: request.expDate,
			isVerified: false,
			isRollForward: request.canRollForward ? 1 : 0,
		}

		self.updateProjUser(payload)
			.then((response: any) => {
				// console.log(actionName, "__response: ", response)
				if (response.status === 200 && response.data.Status === 1) {
					// update stored data
					self.reflectUpdate(self.selectedUser, request)
					// close the dialog
					self.setOpenEdit(false)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default EditProjUser
