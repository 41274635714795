import React, { useEffect } from "react"
import { ConsoleLog, DLContextMenu } from "../../basic-elements"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../common-models/permission"
import { CreateProjectMethod } from "../create-project/create-project.data-props"
import { ProjectStatus } from "../../../common-models/enumerations/project-related-enums"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
	IconPlaceholder,
} from "../../basic-elements/icons/common-icons"
import {
	DLOrgMenuGroups,
	DLOrgSubMenus,
} from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { idToNumber, IdType } from "../../../library/converters/id-converter"
import { ActionStatus } from "@datalobby/types"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"

export default observer(function ProjBasicCtxMenus({
	partialStore,
	permission,
	clickPoint,
	handleClose,
	projStatus,
	getProjDetail,
	getProjDetailStatus,
	userRole,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
	clickPoint: {
		mouseX: null | number
		mouseY: null | number
	}
	handleClose: () => void
	projStatus: ProjectStatus
	// TODO: pending. GetClientInformationDetail doesn't have the permission related info yet
	getProjDetail?: (projId: string) => void
	getProjDetailStatus?: ActionStatus
	// WARNING: Below line sholud be updated
	userRole?: string // this is shortName and temporary
}) {
	const orgStore = useOrgStore()
	const projId = partialStore.selectedProj
	const projNumId = idToNumber(projId, IdType.project)
	const projectInfo = partialStore.viewSelectedProjInfo(projId, projStatus)

	// useEffect(() => {
	// 	projId && getProjDetail && getProjDetail(projId)
	// }, [projId])

	// console.log("getProjDetailStatus in ctx menu:", getProjDetailStatus)

	const menuOptions = [
		{
			label: "Open",
			value: "open",
			disabled: !projectInfo?.accessible,
			available: projectInfo?.accessible && permission.read,
			isLink: true,
			linkTo: `/project/checkin/${projNumId}`,
			icon: <IconPlaceholder />,
		},
		{
			label: "Move",
			value: "ctx-proj_move",
			// WARNING: Project archive admin & project admin can do this (also SA and AA)
			available:
				(userRole === "AA" || userRole === "SA") &&
				// getProjDetailStatus === ActionStatus.success &&
				projStatus !== ProjectStatus.archived &&
				projStatus !== ProjectStatus.unarchived &&
				partialStore.storeName !== DLOrgMenuGroups.dashboard && // TODO: Update is required for this moving
				permission.move,
			// WARNING: (TBD) This moving feature will be helpful if the user lost its parent cabinet...
			clickEvent: () => {
				orgStore.groups.initializeStore()
				orgStore.groups.getOrgGroupList(
					orgStore.checkin.orgInfo.userId,
					() => {
						orgStore.groups.getOrgCabinetDetail(
							projectInfo.cabinetId
						)
						orgStore.groups.setOpenMoveDialog(true)
					}
				)
			},
			icon: <IconPlaceholder />,
		},
		{
			label: "Add Hard Copy",
			value: "ctx-add_hard_copy",
			available: partialStore.storeName === DLOrgMenuGroups.dashboard,
			clickEvent: () => partialStore.setOpenAddHardCopyDialog(true),
			icon: <IconPlaceholder />,
		},
		{
			label: "Roll Forward",
			value: "ctx-roll_forward",
			// NOTE: Arch mgmt use different ctx menu... (AM UnArch uses this basic ctx menu)
			// TODO: Is this right method? Isn't it better to use same ctx menu on the all proejcts screen?
			// disabled: projStatus !== ProjectStatus.archived,
			// WARNING: Only the user who has roll-forward permission can do that (also SA and AA)
			// NOTE: what happens if the user doesn't have the roll forward permission?
			available:
				(userRole === "AA" || userRole === "SA") &&
				// getProjDetailStatus === ActionStatus.success &&
				projStatus !== ProjectStatus.archived &&
				projStatus !== ProjectStatus.unarchived &&
				permission.create,
			clickEvent: () => {
				if (partialStore.selectedItems.length === 1) {
					partialStore.setCreateProjectMethod(
						CreateProjectMethod.byRollForward
					)
					// TODO: ProjectStatus part should be updated
					// WARNING:

					partialStore.setOpenCreateProjDialog(true)
				} else if (partialStore.selectedItems.length > 1) {
					alert(
						`Cannot do roll forward multiple projects. Please try again`
					)
				} else if (partialStore.selectedItems.length === 0) {
					alert(`Please select a project first`)
				} else {
					ConsoleLog("What happens here..??")
				}
			},
			icon: (
				<DLIcon
					name={DLIconName.duplicate}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Delete",
			value: "ctx-proj_delete",
			// WARNING: Only the project archive admin can delete the project (except the SA and AA)
			available:
				// getProjDetailStatus === ActionStatus.success &&
				partialStore.storeName === DLOrgMenuGroups.projects ||
				partialStore.storeName === DLOrgMenuGroups.dashboard
					? (userRole === "AA" || userRole === "SA") &&
					  projStatus !== ProjectStatus.archived &&
					  projStatus !== ProjectStatus.unarchived &&
					  permission.delete
					: (userRole === "AA" || userRole === "SA") &&
					  permission.delete,

			clickEvent: () => partialStore.setOpenDeleteProjDialog(true),
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Pre-Process PDFs",
			value: "ctx-pre_process_pdf",
			available: projStatus === ProjectStatus.replica && permission.read,
			// disabled: !projectInfo?.hasExportPermission,
			tooltip: !projectInfo?.hasExportPermission
				? "Readonly access is required"
				: "",
			// clickEvent: handlePreProcessPdfs,
			clickEvent: () => partialStore.setPreProcessDialog(true),
			icon: <IconPlaceholder />,
		},
		{
			label: "Project Information",
			value: "ctx-proj_info",
			available: permission.read,
			clickEvent: () => partialStore.setProjectDetailsDialogOpen(true),
			icon: <IconPlaceholder />,
		},
		{
			label: "Cabinet Info",
			value: "cabinet_info",
			available: permission.read,
			clickEvent: () => partialStore.setOpenProjLocationDialog(true),
			icon: <IconPlaceholder />,
		},
	]

	return (
		<DLContextMenu
			eleId="project-ctx-menus"
			clickPoint={clickPoint}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[1, 3]}
		/>
	)
})
