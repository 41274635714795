import React from "react"
import DLSpinner from "./DLSpinner"
import styled from "styled-components"

export default function DLSpinnerCenterAligned({
	width = "100%",
	height = "100%",
	absolute,
	backgroundOpacity,
	message,
}: {
	width?: string | number
	height?: string | number
	absolute?: boolean
	backgroundOpacity?: number
	message?: string
}) {
	return (
		<StyledSpinner
			className="spinner FR AC JC"
			style={{
				width: width,
				height: height,
				zIndex: 999,
				position: absolute ? "absolute" : undefined,
				overflow: "hidden",
				top: absolute ? 0 : undefined,
				left: absolute ? 0 : undefined,
			}}
			opacity={backgroundOpacity}
		>
			<DLSpinner message={message} />
			{backgroundOpacity && <div className="background" />}
		</StyledSpinner>
	)
}

type StyledSpinnerType = {
	opacity?: number
}

const StyledSpinner = styled.div<StyledSpinnerType>`
	.background {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: ${(props) => props.theme.background};
		opacity: ${(props) => props.opacity};
	}
`
