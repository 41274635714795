import React, { useState, useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	InputWithLabel,
	DLSingleSelect,
	DLDatepicker,
	DLTextArea,
	DLInputField,
} from "../../../../components/basic-elements"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import styled from "styled-components"
import { IdType, idToNumber } from "../../../../library/converters/id-converter"
import { format } from "date-fns"

export interface AddPBCInputProps {
	area: string
	controlRefNo: string
	description: string
	requestedDate: string
	dueDate: string
	before: number
	after: number
	recur: number
	requestedBy: string
	requestedTo: string
	notes: string
	status: string
}

export default observer(function AddNewPBCListDialog({
	open,
	setOpen,
	data,
	setData,
	selectedData,
}: {
	open: boolean
	setOpen: any
	data: any
	setData: any
	selectedData?: any
}) {
	const store = useRootStore()
	const dateFormat = store.global.dateFormat.value
	const isEditMode = selectedData && Object.keys(selectedData).length

	const [inputs, setInputs] = useState({
		area: "",
		controlRefNo: "",
		description: "",
		requestedDate: format(new Date(), dateFormat),
		dueDate: "",
		before: 0,
		after: 0,
		recur: 0,
		requestedBy: "",
		requestedTo: "",
		notes: "",
		status: "",
	})

	useEffect(() => {
		isEditMode && setInputs(selectedData)
	}, [selectedData, isEditMode])

	const handleInputs = useCallback((event: any) => {
		const name = event.target.name
		let value = event.target.value

		setInputs((inputs) => ({
			...inputs,
			[name]: value,
		}))
	}, [])

	console.log(inputs)

	const handleDateInput = (name: string, date: string) => {
		setInputs((inputs: any) => ({
			...inputs,
			[name]: date,
		}))
	}

	const handleAdd = () => {
		const fileId = data.reduce((acc: any, val: any) => {
			const id = idToNumber(val.id, IdType.file)
			acc = acc === undefined || id > acc ? id : acc
			return acc
		}, 0)
		const newData = [...data]
		newData.push({
			id: `fileId${fileId + 1}`,
			...inputs,
		})
		setData(newData)
		setOpen(false)
	}

	const handleEdit = () => {
		const newData = [...data]
		const index = data.findIndex((i: any) => i.id === selectedData?.id)
		newData[index] = inputs
		setData(newData)
		setOpen(false)
	}

	return (
		<DLDialog
			eleTestId={`${isEditMode ? "edit" : "add-new"}-pbc-dialog`}
			isOpen={open}
			setIsOpen={setOpen}
			handleAction={isEditMode ? handleEdit : handleAdd}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={`${isEditMode ? "Edit" : "Add New"} PBC List`}
			dialogContents={
				<AddPBCForm
					inputs={inputs}
					handleInputs={handleInputs}
					handleDateInput={handleDateInput}
					dateFormat={store.global.dateFormat.value}
				/>
			}
			cancelBtnText="Cancel"
			actionReady={true}
			actionBtn={isEditMode ? "Edit" : "Add"}
			maxWidth="sm"
			fullWidth={true}
		/>
	)
})

function AddPBCForm({
	inputs,
	handleInputs,
	handleDateInput,
	dateFormat,
}: {
	inputs: AddPBCInputProps
	handleInputs: any
	handleDateInput: any
	dateFormat: string
}) {
	return (
		<StyledAddPBCForm>
			<div className="input-section FR">
				<InputWithLabel label="Area">
					<DLInputField
						autoFocus
						eleTestId="area_input"
						eleFullWidth
						eleName="area"
						eleValue={inputs.area}
						eleHandleChange={handleInputs}
						elePlaceholder="Please input Area"
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel label="Control Ref Num">
					<DLInputField
						autoFocus
						eleTestId="controlRefNo_input"
						eleFullWidth
						eleName="controlRefNo"
						eleValue={inputs.controlRefNo}
						eleHandleChange={handleInputs}
						elePlaceholder="Please input Control Ref Num"
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel label="Description">
					<DLTextArea
						eleTestId="input-comment-text"
						elePlaceholder="Please input description"
						eleValue={inputs.description}
						eleHandleChange={handleInputs}
						eleName="description"
						eleClassName="assertions-text-input-area"
						eleFieldHeight={3}
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel label="Due Date">
					<DLDatepicker
						eleTestId="dueDate-date"
						eleName="dueDate"
						selected={inputs.dueDate === "" ? null : inputs.dueDate}
						onChange={(date: any) =>
							handleDateInput("dueDate", date)
						}
						format={dateFormat}
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel label="Before">
					<DLInputField
						eleTestId="input-comment-text"
						elePlaceholder="Please input before"
						eleValue={inputs.before}
						eleHandleChange={handleInputs}
						eleName="before"
						eleType="number"
						eleClassName="before-input-area"
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel label="After">
					<DLInputField
						eleTestId="input-comment-text"
						elePlaceholder="Please input after"
						eleValue={inputs.after}
						eleHandleChange={handleInputs}
						eleName="after"
						eleType="number"
						eleClassName="after-input-area"
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel label="Recur">
					<DLInputField
						eleTestId="input-comment-text"
						elePlaceholder="Please input recur"
						eleValue={inputs.recur}
						eleHandleChange={handleInputs}
						eleName="recur"
						eleType="number"
						eleClassName="recur-input-area"
					/>
				</InputWithLabel>
			</div>

			<div className="input-section FR">
				<InputWithLabel label="Requested By">
					<DLSingleSelect
						eleTestId="select-requestedBy"
						eleValue={inputs.requestedBy}
						eleOnChange={handleInputs}
						eleName="requestedBy"
						eleFullWidth
						withLabel={false}
						placeholder="Select Requested By"
						optionList={[
							{
								name: "User 1",
								value: "user-1",
							},
							{
								name: "User 2",
								value: "user-2",
							},
						]}
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel label="Requested To">
					<DLInputField
						eleTestId="input-requestedTo-text"
						elePlaceholder="Please input requested To"
						eleValue={inputs.requestedTo}
						eleHandleChange={handleInputs}
						eleName="requestedTo"
						eleClassName="requestedTo-text-input-area"
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel label="Notes">
					<DLInputField
						eleTestId="input-notes-text"
						elePlaceholder="Please input notes"
						eleValue={inputs.notes}
						eleHandleChange={handleInputs}
						eleName="notes"
						eleClassName="notes-text-input-area"
					/>
				</InputWithLabel>
			</div>

			<div className="input-section FR">
				<InputWithLabel label="Status">
					<DLSingleSelect
						eleTestId="select-status"
						eleValue={inputs.status}
						eleOnChange={handleInputs}
						eleName="status"
						eleFullWidth
						withLabel={false}
						placeholder="Select status"
						optionList={[
							{
								name: "Completed",
								value: "completed",
							},
							{
								name: "Not Provided",
								value: "not-provided",
							},
							{
								name: "In Progress",
								value: "in-progress",
							},
						]}
					/>
				</InputWithLabel>
			</div>
		</StyledAddPBCForm>
	)
}

const StyledAddPBCForm = styled.div`
	padding-left: 0.5rem;
	height: 500px;
	min-height: 50vh;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
