import { flow } from "mobx-state-tree"
import axios, { Method } from "axios"
import { ActionStatus } from "@datalobby/types/lib"
import { S3ObjMethod } from "../../../screens/organization-side/notifications/store/notifications.view-model"
// import { S3ObjMethod } from "../notifications.view-model"

const RemoveFilesFromS3 = (self: any) => ({
	removeFilesFromS3(keys: string[]) {
		// self.responses.setResponse(actionName, {
		// 	actionName,
		// 	status: ActionStatus.loading,
		// })

		keys.map((key) => {
			self.deleteFileFromS3(key).then((response: any) => {
				console.log("response---", key, response)
				let itemForGql = {
					key,
					fileName: "-",
					extension: "-",
					size: 0,
					formattedSize: "-",
				}
				if (response.status === 200) {
					self.addItemToS3FilesStatus({
						...itemForGql,
						status: ActionStatus.success,
						methodType: S3ObjMethod.delete,
					})
				} else {
					self.addItemToS3FilesStatus({
						...itemForGql,
						status: ActionStatus.fail,
						methodType: S3ObjMethod.delete,
					})
				}
			})
		})
	},
	deleteFileFromS3: flow(function* (key: string) {
		const postApiEndpoint =
			process.env.REACT_APP_CDK_RESTAPI_ENDPOINT + "post"
		const cdkRestApiKey = process.env.REACT_APP_CDK_RESTAPI_KEY

		let method: Method = "delete"
		const endpoint = postApiEndpoint + "/removeS3Obj"

		var config = {
			method,
			url: endpoint + `?key=${key}`,
			headers: {
				"x-api-key": cdkRestApiKey,
			},
		}
		const result = yield axios(config)
		console.log("deleteFileFromS3 result", result)
		return result
	}),
})

export default RemoveFilesFromS3
