import { types } from "mobx-state-tree"
import {
	CommonSelectedItem,
	SelectedItemsSharedActions,
	SelectedItemsSharedViews,
} from "../../../../../common-models/shared-store-code/selected-items-related/selected-items-shared-actions"

const ClickPoint = types.model({
	mouseX: types.union(types.null, types.number),
	mouseY: types.union(types.null, types.number),
})

const AmArchivedProjectsUiHelper = types
	.model({
		selectedProj: types.string,
		selectedCabinetId: types.string,
		clickPoint: ClickPoint,
		rightClickTarget: "",
		showUnarchivedDetails: types.optional(types.boolean, false),
		selectedProjType: "",
		//
		selectedItems: types.array(CommonSelectedItem),
		//
		// dialogs
		accessMgmtDialogOpen: types.boolean,
		archivedProjectUnarchiveDialogOpen: types.boolean,
		projectDetailsDialogOpen: types.boolean,
		removeArchivedProjectDialogOpen: types.boolean,
		unarchiveProgressDialogOpen: types.boolean,
		digitalSignatureDialogOpen: false,
		preProcessDialog: false,
		repairReplicaDialog: false,
		archiveZipRegenerationDialog: false,
		releaseForceArchiveDialog: false,
	})
	.actions(SelectedItemsSharedActions)
	.views(SelectedItemsSharedViews)
	.actions((self) => ({
		setSelectedProj(projectId: string) {
			self.selectedProj = projectId
		},
		setSelectedCabinetId(cabinetId: string) {
			self.selectedCabinetId = cabinetId
		},
		setClickPoint(clickPoint: typeof self.clickPoint) {
			self.clickPoint = clickPoint
		},
		setRightClickTarget(targetId: string) {
			self.rightClickTarget = targetId
		},
		setShowUnarchivedDetails(request: boolean) {
			self.showUnarchivedDetails = request
		},
		setSelectedProjType(type: string) {
			self.selectedProjType = type
		},
		// dialogs
		setAccessMgmtDialogOpen(request?: boolean) {
			self.accessMgmtDialogOpen = request ?? !self.accessMgmtDialogOpen
		},
		setProjectDetailsDialogOpen(request?: boolean) {
			self.projectDetailsDialogOpen =
				request ?? !self.projectDetailsDialogOpen
		},
		setRemoveArchivedProjectDialogOpen(request?: boolean) {
			self.removeArchivedProjectDialogOpen =
				request ?? !self.removeArchivedProjectDialogOpen
		},
		setArchivedProjectUnarchiveDialogOpen(request?: boolean) {
			self.archivedProjectUnarchiveDialogOpen =
				request ?? !self.archivedProjectUnarchiveDialogOpen
		},
		setUnarchiveProgressDialogOpen(request?: boolean) {
			self.unarchiveProgressDialogOpen =
				request ?? !self.unarchiveProgressDialogOpen
		},
		setDigitalSignatureDialogOpen(request?: boolean) {
			self.digitalSignatureDialogOpen =
				request ?? !self.digitalSignatureDialogOpen
		},
		setPreProcessDialog(request?: boolean) {
			self.preProcessDialog = request ?? !self.preProcessDialog
		},
		setRepairReplicaDialog(request?: boolean) {
			self.repairReplicaDialog = request ?? !self.repairReplicaDialog
		},
		setArchiveZipRegenerationDialog(request?: boolean) {
			self.archiveZipRegenerationDialog =
				request ?? !self.archiveZipRegenerationDialog
		},
		setReleaseForceArchiveDialog(request?: boolean) {
			self.releaseForceArchiveDialog =
				request ?? !self.releaseForceArchiveDialog
		},
	}))

export default AmArchivedProjectsUiHelper
