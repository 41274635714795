import { flow } from "mobx-state-tree"
import { OrgProjectApiProps } from "../../../stores/org-sub-stores/org-projects-store/orgProjects.apis"
import { idToNumber, IdType } from "../../../library/converters/id-converter"
import {
	CreateProjectInputProps,
	CreateProjectMethod,
} from "../../../components/combined-elements/create-project/create-project.data-props"
import { findProjRoleNumberId } from "../../../temporary-data/project-side/find-proj-role"
import { ConsoleLog } from "../../../components/basic-elements"

const CreateProject = (
	self: any,
	createProject: OrgProjectApiProps["apiCreateProject"]
) => ({
	createProject: flow(function* (
		userId: string,
		inputs: CreateProjectInputProps
	) {
		const actionName = "createProject"
		// 1.
		const {
			groupId,
			cabinetId,
			clientId,
			engTypeId,
			// ----------
			year,
			periodId,
			periodEndDate,
			// ----------
			expectedReportDate,
			archPolicyPeriod,
			expectedArchiveDate,
			riskAssessment, // number
			finalReportDate,
			finalArchDeadlineDate,
			// ----------
			title,
			aliasId,
			epUserId,
			creatorRole,
			// users,
			// ----------
			createMethod,
			templateId,
			copyFrom,
			// ----------
			copyContents,
			copyUsers,
			moveFsDataAsPy,
		} = inputs
		// 2.
		ConsoleLog(["(createProject) inputs", inputs])
		const groupNumId = idToNumber(groupId, IdType.group)
		const cabinetNumId = idToNumber(cabinetId, IdType.cabinet)
		const clientNumId = idToNumber(clientId, IdType.client)
		const engTypeNumId = idToNumber(engTypeId, IdType.engType)
		// ----------
		// const periodId = findPeriodNumberId(periodType)
		const periodNumId = idToNumber(periodId, IdType.period)
		// ----------
		// let epUserId = ""
		// const selectedEp = users?.find((user) => user.roleId === "id_ep")
		// if (selectedEp !== undefined) {
		// 	epUserId = selectedEp.id
		// } else {
		// 	epUserId = userId
		// }
		let roleOfCreator = ""
		if (creatorRole !== "" && creatorRole !== undefined) {
			roleOfCreator = creatorRole
		} else {
			roleOfCreator = "id_ep"
		}
		// const creator = users?.find((user) => user.id === userId)
		// if (creator !== undefined) {
		// 	roleOfCreator = creator.roleId
		// } else {
		// 	roleOfCreator = "id_ep"
		// }

		// ----------
		const archPolicyPeriodId = idToNumber(
			archPolicyPeriod,
			IdType.archPolicyPeriod
		)
		const riskAssessmentId = idToNumber(riskAssessment, IdType.ra)
		const userNumberId = idToNumber(userId, IdType.user)
		const epNumberId = idToNumber(epUserId, IdType.user)
		const roleOfCreatorAsNumber = findProjRoleNumberId(roleOfCreator)

		let templateValue = 0
		let rfSourceId: any
		if (
			createMethod === CreateProjectMethod.byProjectTemplate &&
			templateId !== ""
		) {
			const templateNumberId = idToNumber(templateId, IdType.projTemplate)
			if (typeof templateNumberId === "number") {
				templateValue = templateNumberId
			} else {
				alert(`Template ID has invalid format: ${templateId}`)
			}
		} else if (createMethod === CreateProjectMethod.byRollForward) {
			templateValue = -1
			if (copyFrom) {
				rfSourceId = idToNumber(copyFrom, IdType.project)
			} else {
				alert(`Roll Forward target not exist`)
			}
		}
		// 3.
		if (
			typeof groupNumId === "number" &&
			typeof cabinetNumId === "number" &&
			typeof clientNumId === "number" &&
			typeof engTypeNumId === "number" &&
			// ----------
			typeof periodNumId === "number" &&
			// ----------
			typeof archPolicyPeriodId === "number" &&
			typeof riskAssessmentId === "number" &&
			// ----------
			typeof templateValue === "number" &&
			typeof userNumberId === "number" &&
			typeof epNumberId === "number" &&
			typeof roleOfCreatorAsNumber === "number" &&
			typeof year === "number"
		) {
			try {
				const payload = {
					GroupID: groupNumId,
					CabinetId: cabinetNumId,
					ClientMasterID: clientNumId,
					EngagementID: engTypeNumId,
					// ---------
					YearValue: year,
					PeriodID: periodNumId,
					FiscalYearEnd: periodEndDate,
					// ---------
					expectedIssuanceDate: expectedReportDate,
					ArchiveDatePolicyId: archPolicyPeriodId,
					ExpectedArchiveDeadLineDate: expectedArchiveDate,
					RiskAssessmentId: riskAssessmentId,
					// ---------
					LinkName: title.trim(),
					ClientShortName: aliasId.trim(),
					// ---------
					TemplateId: templateValue,
					cloningClientid: rfSourceId,
					// ---------
					copy: copyContents ? 1 : 0,
					isUserRoleForwardSelected: copyUsers,
					movecurrentyeartopreviousyear: moveFsDataAsPy ? 1 : 0, // 0: false, 1: true
					// ---------
					UserID: userNumberId,
					EngagementUserId: epNumberId,
					RoleId: roleOfCreatorAsNumber,
					finalIssuanceDate: finalReportDate,
					Fee: "", // NOTE: lways empty
					archivedDeadlineDate: finalArchDeadlineDate,
				}
				const response = yield createProject(payload)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert("Invalid format props exist")
			ConsoleLog([
				groupNumId,
				cabinetNumId,
				clientNumId,
				engTypeNumId,
				// ----------
				periodNumId,
				// ----------
				archPolicyPeriodId,
				riskAssessmentId,
				// ----------
				templateValue,
				userNumberId,
				epNumberId,
				roleOfCreatorAsNumber,
			])
		}
	}),
})

export default CreateProject
