import { types } from "mobx-state-tree"
// ---------- common models

const MyAccountUiHelper = types
	.model({
		changePwDialogOpen: false,
	})
	.actions((self) => ({
		setChangePwDialogOpen(request?: boolean) {
			self.changePwDialogOpen = request ?? !self.changePwDialogOpen
		},
	}))
	.views((self) => ({}))

export default MyAccountUiHelper
