import React, { useEffect, useState } from "react"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import DLTabForProj from "./DLTabForProj"
import styled from "styled-components"

// WARNING:
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import {
	DLProjMenuGroups,
	DLProjSubMenus,
} from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { ProjI18n } from "../../../common-models/enumerations/translation-sheets"
import { ConsoleLog } from "../../basic-elements"
import keyboardJS from "keyboardjs"
import { useLocation } from "react-router-dom"

export default observer(function DLDynamicTabs() {
	const store = useRootStore()
	const projStore = useProjStore()
	const projId = projStore.checkin.projId

	const defaultUrl = projStore.checkin.defaultUrl

	let path = useLocation().pathname.split("/")

	const pathname = path[path.length - 1]

	const currentMenu = projStore.checkin.accessibleFlatMenus.find(
		(element) => element.url === pathname
	)

	useEffect(() => {
		if (currentMenu) {
			projStore.menuTabs.setCurrentMainMenu(currentMenu.id)
			projStore.menuTabs.addOpenedMenu(currentMenu)
		}
	}, [currentMenu])

	console.log("projId:", projId, "| defaultUrl:", defaultUrl)

	const i18nSheet = ProjI18n.menus

	const [value, setValue] = useState(0)

	// a11y === Accessibility
	function a11yProps(menuId: string) {
		return {
			"data-testid": `sub-menu-tab-${menuId}`,
			"aria-controls": `scrollable-auto-tabpanel-${menuId}`,
		}
	}

	// tab indicator control (This handleChagne is not related with panel change)
	// In the app frame for menus, there are no tab-panel
	// Each menu rendered by router
	const handleChange = () => {
		ConsoleLog("DLDynamicTabs Clicked in the org side", "")
	}

	const handleCloseMenu = (menuId: string) => {
		if (menuId === projStore.menuTabs.currentMainMenu) {
			const leftTabIndex = projStore.menuTabs.currentMenuIndex - 1
			const prevItem = projStore.menuTabs.openedMenuList[leftTabIndex]
			projStore.menuTabs.setCurrentMainMenu(prevItem.id)
		}

		projStore.menuTabs.removeOpenedMenu(menuId)
	}

	const handleSelectMenu = (menuId: any) => {
		keyboardJS.pause()
		projStore.menuTabs.setCurrentMainMenu(menuId)
	}

	const refreshActions = [
		{
			menuId: DLProjSubMenus.proj_info,
			refresh: () => projStore.projInfo.refresh(),
		},
		{
			menuId: DLProjSubMenus.note,
			refresh: () => projStore.notes.setNeedRefresh(true),
		},
		{
			menuId: DLProjSubMenus.board,
			refresh: () => projStore.board.refresh(),
		},
		{
			menuId: DLProjSubMenus.comments,
			refresh: () => projStore.comments.refresh(),
		},
		{
			menuId: DLProjSubMenus.wp,
			refresh: () => projStore.wp.refresh(),
		},
		{
			menuId: DLProjSubMenus.pf,
			refresh: () => projStore.pf.refresh(),
		},
		{
			menuId: DLProjSubMenus.fs_setup,
			refresh: () => projStore.fsSetup.refresh(),
		},
		{
			menuId: DLProjSubMenus.fs_ls,
			refresh: () => projStore.leadSheet.refresh(),
		},
		{
			menuId: DLProjSubMenus.tb,
			refresh: () => projStore.trialBalance.refresh(),
		},
		{
			menuId: DLProjSubMenus.fs_aje,
			refresh: () => projStore.adjustments.refresh(),
		},
		{
			menuId: DLProjSubMenus.fs_paje,
			refresh: () => projStore.passedAdjustments.refresh(),
		},
		{
			menuId: DLProjSubMenus.fs_ee,
			refresh: () => projStore.eliminatingEntry.refresh(),
		},
		{
			menuId: DLProjSubMenus.arch_diagnosis,
			refresh: () => projStore.archive.refresh(),
		},
		{
			menuId: DLProjSubMenus.arch_pre_process,
			refresh: () => projStore.archivePreProcess.refresh(),
		},
		{
			menuId: DLProjSubMenus.rationales,
			refresh: () => projStore.rationales.refresh(),
		},
		{
			menuId: DLProjSubMenus.signoff_history,
			refresh: () => projStore.signOff.refresh(),
		},
		{
			menuId: DLProjSubMenus.arch_history,
			refresh: () => projStore.archiveHistory.refresh(),
		},
		{
			menuId: DLProjSubMenus.users,
			refresh: () => projStore.projUsers.refresh(),
		},
		{
			menuId: DLProjSubMenus.notification,
			refresh: () => projStore.notificationSettings.refresh(),
		},
		{
			menuId: DLProjSubMenus.trash,
			refresh: () => projStore.trash.refresh(),
		},
		{
			menuId: DLProjSubMenus.roll_forward,
			refresh: () => projStore.projRollForward.refresh(),
		},
	]

	const handleRefresh = (menuId: string) => {
		ConsoleLog("DLDynamicTabs, handleRefresh", menuId)
		if (menuId.includes("ProjMenu_AuditProgram")) {
			// const id = menuId.split("-")[1]
			projStore.apg.setNeedRefresh(true)
		} else {
			const refreshAction = refreshActions.find(
				(item) => item.menuId === menuId
			)
			if (refreshAction) {
				refreshAction.refresh()
			}
		}
	}

	// Tabs catch the selected tab by index

	const i18n = store.i18n.combineI18n(i18nSheet, "DLDynamicTabs")

	return (
		<StyledTabs
			drawerWidth={store.ui.drawerWidth}
			collapsedDrawerWidth={store.ui.collapsedDrawerWidth}
		>
			<AppBar
				className={store.ui.isSidebarOpen ? "app-bar" : "app-bar wide"}
				position="static"
				color="default"
			>
				<Tabs
					value={
						// value
						projStore.menuTabs.currentMenuIndex
						// 	? projStore.menuTabs.currentMenuIndex + 1
						// 0
					}
					onChange={handleChange}
					variant="scrollable"
					scrollButtons="auto"
					aria-label="datalobby scrollable dynamic tabs"
					className="tabs-container FR AC"
				>
					{/* <Tab
							{...a11yProps("default-tab")}
							className="tab"
							label={
								<DLTabForProj
									id={DLProjSubMenus.proj_info}
									title="PROJECT INFORMATION"
									url="/information/project-information"
									cannotClose
									handleCloseMenu={() => {}}
									handleSelectMenu={handleSelectMenu}
									handleRefresh={() => {}}
								/>
							}
						/> */}
					{projStore.menuTabs.openedMenuList.map(
						(menu: { id: string; title: string; url: string }) => {
							// this 'menu' has only 'id', 'title' and 'isSelected' properties
							// because some information like 'has update' should be connected to store
							//FIXME: How to control the update?
							const hasUpdate = false
							// const defaultUrl = projStore.checkin.defaultUrl
							const parentUrl = store.checkin.getParentUrl(
								menu.id
							)

							return (
								<Tab
									key={menu.id}
									{...a11yProps(menu.id)}
									className="tab"
									label={
										<DLTabForProj
											id={menu.id}
											title={i18n[menu.id] || menu.title}
											hasUpdate={hasUpdate}
											url={
												defaultUrl +
												"/" +
												parentUrl +
												"/" +
												menu.url
											}
											cannotClose={
												menu.id ===
												DLProjSubMenus.proj_info
											}
											handleCloseMenu={handleCloseMenu}
											handleSelectMenu={handleSelectMenu}
											handleRefresh={handleRefresh}
											prevUrl={
												defaultUrl +
												(projStore.menuTabs
													.openedMenuList[
													projStore.menuTabs
														.currentMenuIndex - 1
												]
													? "/" +
													  store.checkin.getParentUrl(
															projStore.menuTabs
																.openedMenuList[
																projStore
																	.menuTabs
																	.currentMenuIndex -
																	1
															].id
													  ) +
													  "/" +
													  projStore.menuTabs
															.openedMenuList[
															projStore.menuTabs
																.currentMenuIndex -
																1
													  ].url
													: "information/project-information")
											}
											isCurrentMenu={
												menu.id ===
												projStore.menuTabs
													.currentMainMenu
											}
										/>
									}
								/>
							)
						}
					)}
				</Tabs>
			</AppBar>
		</StyledTabs>
	)
})

interface StyledTabsProps {
	drawerWidth: number
	collapsedDrawerWidth: number
}

const StyledTabs = styled.div<StyledTabsProps>`
	.app-bar {
		background-color: transparent;
		box-shadow: none;
		width: calc(100vw - ${(props) => props.drawerWidth}px);
		&.wide {
			width: calc(100vw - ${(props) => props.collapsedDrawerWidth}px);
		}
		.tabs-container {
			border-bottom: 1px solid ${(props) => props.theme.emphasisLight};
			color: ${(props) => props.theme.tabColor};
			height: var(--standard-unit);
			.tab {
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
				overflow: hidden;
			}
			.MuiTabs-indicator {
				height: 0px;
				background-color: ${(props) => props.theme.emphasis};
			}
			.MuiTab-root {
				padding: 2px 12px !important;
			}
			button {
				font-weight: var(--font-light);
			}
			button[aria-selected="true"] {
				background-color: #d2cfcf;
				color: #065299;
				font-weight: 700;
				.dl-tab-for-proj {
					// border-bottom: 3px solid ${(props) =>
						props.theme.emphasis};
					padding-top: 3px;
				}
				svg {
					fill: ${(props) => props.theme.orgSidebarEmphasisTxt};
				}
			}
			& div:first-child[aria-disabled] {
				border-right: 1px solid ${(props) => props.theme.shade05};
				box-shadow: 3px 0px 20px 2px ${(props) => props.theme.shade05};
				transition: 0.4s;
				&:hover {
					box-shadow: 3px 0px 20px 2px
						${(props) => props.theme.shade10};
				}
			}
			& div:last-child[aria-disabled] {
				border-left: 1px solid ${(props) => props.theme.shade05};
				box-shadow: -3px 0px 20px 2px ${(props) => props.theme.shade05};
				transition: 0.4s;
				&:hover {
					box-shadow: -3px 0px 20px 2px
						${(props) => props.theme.shade10};
				}
			}
		}
	}
`
