import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import styled from "styled-components"

interface SpinnerProps {
	eleClassName?: string
	size?: string | number
	fullScreen?: boolean
	hasBackground?: boolean
	lightBackground?: boolean
	color?: "default" | "secondary" | "primary"
	message?: string
	// variant?: string
}

/**
 * TODO: Color setting Update is required
 */

export default function DLSpinner({
	eleClassName = undefined,
	size = 40,
	fullScreen = undefined,
	hasBackground = undefined,
	lightBackground = undefined,
	color = "default",
	message,
}: // variant,
SpinnerProps) {
	return (
		<StyledSpinner
			className={`FR AC JC ${
				fullScreen && "fullScreen"
			} ${eleClassName} color-${color}`}
		>
			{hasBackground && (
				<div className={`background ${lightBackground && "light"}`} />
			)}
			<CircularProgress
				size={size}
				className="spinner FR AC"
				// variant={variant}
				// color={eleClassName}
			/>
			{message && <div className="message">{message}</div>}
		</StyledSpinner>
	)
}

const StyledSpinner = styled.div`
	position: absolute;
	z-index: 998;
	background: none;
	&.color-default {
		svg {
			color: ${(props) => props.theme.emphasis};
		}
	}
	&.fullScreen {
		width: 100vw;
		height: 100vh;
		left: 0;
		top: 0;
	.background {
		position: absolute;
		z-index: -1;
		width: 100vw;
		height: 100vh;
		opacity: 0.9;
		background-color: ${(props) => props.theme.background};
		&.light {
			opacity: 0.4;
		}
	}
	.message{
		margin-left: 5px;
		font-size: 20px;
	}
`
