import React from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import ArchPreProcessMainActionBtns from "./sub-components/ArchPreProcessMainActionBtns"
import ArchivPreProcessCancelDialog from "./sub-components/dialogs/ArchPreProcessingCancelDialog"
import Icon from "@mdi/react"
import { mdiClose } from "@mdi/js"
import { DLButton } from "../../../../components/basic-elements"
import styled from "styled-components"
import StartArchPreProcessingDialog from "./sub-components/dialogs/StartArchPreProcessingDialog"
import ArchivProjectDialog from "./sub-components/dialogs/ArchiveProjectDialog"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import PreProcessFileRow from "./sub-components/PreProcessFileRow"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"

export default observer(function ArchivePreProcess({
	partialStore,
	fetchingStatus,
	archiveDiagnosisFetchingStatus,
	isArchiveConditionsCompleted,
	permission,
	projectStatus,
	i18n,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
	archiveDiagnosisFetchingStatus: ActionStatus
	isArchiveConditionsCompleted: boolean
	permission: PermissionAsObjectProps
	projectStatus: ProjectStatus
	i18n: DLI18nProps
}) {
	const projStore = useProjStore()
	const previewPdf = (url: string) => {
		if (url.length > 0) {
			window.open(url)
		}
	}
	const downloadPdf = (url: string) => {
		if (url.length > 0) {
			const downloadWPFile = document.createElement("a")
			downloadWPFile.href = url

			downloadWPFile.setAttribute("download", "")
			document.body.appendChild(downloadWPFile)
			downloadWPFile.click()
			downloadWPFile.remove()
		}
	}

	const preProcessFiles = partialStore.preConditionData.preProcessFiles
	const userRole = projStore.checkin.user.roleId
	return (
		<PageContainer
			pageTools={<div />}
			hasToolbar={false}
			fullWidth
			isLoading={
				fetchingStatus === "LOADING" ||
				archiveDiagnosisFetchingStatus === "LOADING"
			}
		>
			{fetchingStatus === "SUCCESS" && (
				<>
					{isArchiveConditionsCompleted ? (
						<StyledArchiveDiagnosis>
							<div className="main-container">
								<div className="page-header">
									<h3>{i18n.twName}</h3>
								</div>
								<div className="file-details">
									{preProcessFiles.map((file: any) => (
										<PreProcessFileRow
											file={file}
											permission={permission}
											previewPdf={previewPdf}
											downloadPdf={downloadPdf}
											i18n={i18n}
										/>
									))}
								</div>
							</div>

							{permission.update &&
								projectStatus !== ProjectStatus.replica &&
								projectStatus !== ProjectStatus.archived && (
									<ArchPreProcessMainActionBtns
										partialStore={partialStore}
										i18n={i18n}
										projectStatus={projectStatus}
									/>
								)}

							{projectStatus === ProjectStatus.unarchived &&
								userRole === "id_oa" && (
									<div className="archive-action-area FR AC">
										<DLButton
											eleTestId="cancel-pre-process-btn"
											startIcon={
												<Icon
													path={mdiClose}
													size={0.8}
												/>
											}
											clickHandler={() =>
												partialStore.setCancelPreProcessDialogOpen(
													true
												)
											}
											disabled={
												!partialStore.preConditionData
													.isPreProcessed
											}
										>
											{i18n.twCancel}
										</DLButton>
									</div>
								)}

							{/* WARNING: for Archive management screen's use  */}
							{/* {projectStatus === ProjectStatus.archived && (
									<div>
										<div
											style={{
												position: "absolute",
												bottom: "5rem",
												color: "red",
												paddingLeft: "1rem",
											}}
										>
											This is temporary display
										</div>
										<ArchPreProcessMainActionBtns
											partialStore={partialStore}
											i18n={i18n}
											projectStatus={projectStatus}
										/>
									</div>
								)} */}
						</StyledArchiveDiagnosis>
					) : (
						<StyledAccess className="FR AC JC">
							Please complete archive diagnosis conditions and
							access this page.
						</StyledAccess>
					)}
				</>
			)}
			{/* Snackbar */}
			<CommonSnackbarComponent i18n={i18n} partialStore={partialStore} />
			{/* Dialogs */}
			{partialStore.cancelPreProcessDialogOpen && (
				<ArchivPreProcessCancelDialog i18n={i18n} />
			)}
			{partialStore.startPreProcessDialogOpen && (
				<StartArchPreProcessingDialog i18n={i18n} />
			)}
			{partialStore.archiveProjectDialogOpen && (
				<ArchivProjectDialog i18n={i18n} />
			)}
		</PageContainer>
	)
})

const StyledArchiveDiagnosis = styled.div`
	.main-container {
		padding-left: 1rem;
		padding-right: 1rem;
		.file-details {
			margin-top: 1rem;
		}
		.content-area {
			padding: 4px;
		}
		.file-status {
			flex-grow: 0;
			flex-basis: 4rem;
		}
		.file-title {
			flex-grow: 4;
			flex-basis: 16rem;
		}
		.status-name {
			flex-grow: 2;
			flex-basis: 4rem;
		}
		.view-btn {
			flex-grow: 2;
			flex-basis: 4rem;
		}
		.download-btn {
			flex-grow: 2;
			flex-basis: 4rem;
		}
	}
	.archive-action-area {
		position: absolute;
		background-color: ${(props) => props.theme.secondaryDeep};
		width: 100%;
		padding: 1rem 2rem;
		bottom: 0;
		> div {
			margin-right: 1rem;
		}
	}
`
const StyledAccess = styled.div`
	width: 100%;
	height: 100%;
`
