import React from "react"
// for store
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
// components
import {
	InputWithLabel,
	DLInputField,
	DLButton,
	DLDialog,
	ConsoleLog,
} from "../../../../../components/basic-elements"
// libraries
import sharedRegEx from "../../../../../library/sharedRegEx"
import useForm from "../../../../../library/use-form"
// styles
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { I18nDialogFormProps } from "../../../../../common-models/types/dialog.props"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

export const AddClientBtn = ({ i18n }: { i18n: DLI18nProps }) => {
	const orgStore = useOrgStore()
	return (
		<DLButton
			variant="contained"
			size="regular"
			eleClassName="top-row-btns"
			eleTestId="add-client-btn"
			startIcon={<Icon path={mdiPlus} size={1} />}
			color="primary"
			clickHandler={() =>
				orgStore.setupClients.setAddClientDialogOpen(true)
			}
		>
			Add Client
		</DLButton>
	)
}

export default observer(function AddClientDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	ConsoleLog(" -=-=-=-=- AddClientDialog -=-=-=-=- ")
	const orgStore = useOrgStore()
	const actionName = "addClient"

	const { twAdd, twCancel, twSubmit } = i18n
	const inputsSchema = {
		aliasId: { value: "", error: "", requestInput: false },
		name: { value: "", error: "", requestInput: false },
	}

	const validationSchema = {
		aliasId: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}

	const addClient = () => {
		orgStore.setupClients.addClient(inputs)
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		addClient,
		orgStore.setupClients.addClientDialogOpen // resetPoint
	)

	// ConsoleLog("AddClientDialog has been rendered")

	return (
		<DLDialog
			eleTestId="add-org-client-dialog"
			draggable
			isOpen={orgStore.setupClients.addClientDialogOpen}
			setIsOpen={orgStore.setupClients.setAddClientDialogOpen}
			handleAction={handleOnSubmit}
			showCloseBtn={true}
			dialogTitle={twAdd}
			dialogContents={
				<AddClientDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			cancelBtnText={twCancel}
			actionReady={
				isReady &&
				!orgStore.setupClients.isDuplicatedName(inputs.name.value) &&
				!orgStore.setupClients.isDuplicatedAliasId(inputs.aliasId.value)
			}
			actionBtn={twSubmit}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.setupClients.getActionStatus(actionName) !== "SUCCESS"
					? orgStore.setupClients.responses.getResponse(actionName)
							?.message
					: undefined
			}
			showSpinner={
				orgStore.setupClients.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				orgStore.setupClients.getActionStatus(actionName) === "LOADING"
			}
		/>
	)
})

const AddClientDialogForm = observer(
	({ inputs, handleOnChange, i18n }: I18nDialogFormProps) => {
		const orgStore = useOrgStore()

		return (
			<StyledAddClientDialog>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twClientId}>
						<DLInputField
							autoFocus
							eleTestId="client-alias-id-input"
							eleFullWidth
							eleName="aliasId"
							eleValue={inputs.aliasId.value}
							eleHandleChange={handleOnChange}
							elePlaceholder={i18n.twClientIdPlaceHolder}
							eleRequired
							warningMsg={
								inputs.aliasId.error ||
								(inputs.aliasId.value &&
									orgStore.setupClients.isDuplicatedAliasId(
										inputs.aliasId.value
									) &&
									(i18n.tsSameIdExist ||
										"The same ID already exist"))
							}
							warningType={
								inputs.aliasId.value &&
								orgStore.setupClients.isDuplicatedAliasId(
									inputs.aliasId.value
								)
									? "red"
									: undefined
							}
							requestInput={inputs.aliasId.requestInput}
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twClientName}>
						<DLInputField
							eleTestId="client-name-input"
							eleFullWidth
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							elePlaceholder={i18n.tsClientNamePlaceHolder}
							eleRequired
							warningMsg={
								inputs.name.error ||
								(inputs.name.value &&
									orgStore.setupClients.isDuplicatedName(
										inputs.name.value
									) &&
									"This client name already exist.")
							}
							warningType={
								inputs.name.value &&
								orgStore.setupClients.isDuplicatedName(
									inputs.name.value
								)
									? "red"
									: undefined
							}
							requestInput={inputs.name.requestInput}
						/>
					</InputWithLabel>
				</div>
			</StyledAddClientDialog>
		)
	}
)

const StyledAddClientDialog = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
