import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { DLSpinner } from "../../../../components/basic-elements"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgDashboardTable from "./sub-components/OrgDashboardTable"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"

export default observer(function OrgDashboardTableView({
	partialStore,
	contentsHeight,
	actionStatus,
	dateFormat,
	i18n,
}: {
	partialStore: any
	contentsHeight: any
	actionStatus: ActionStatus
	dateFormat: string
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const menuId = DLOrgSubMenus.dash_table_summary

	const dntFormat = store.global.getDntFormat

	// TODO: pending. GetClientInformationDetail doesn't have the permission related info yet
	// const getProjDetailForCtxMenu = (projId: string) => {
	// 	console.log("getProjDetailForCtxMenu:", projId)
	// 	orgStore.projects.setProjectDetails(initialProjectDetail) //Reset to initial
	// 	orgStore.projects.getProjectDetails(projId)
	// }

	return (
		// <PageContainer
		// 	hasToolbar={false}
		// 	pageTools={<div />}
		// 	fullWidth
		// 	// fixedHeight
		// >
		<StyledOrgDashboard>
			{/* ----- Spinner ----- */}
			{actionStatus === ActionStatus.loading && (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: contentsHeight }}
				>
					<DLSpinner />
				</div>
			)}
			{actionStatus === ActionStatus.success && (
				<OrgDashboardTable
					// NOTE: Need to compare with projects table for consistency
					// common part 1
					partialStore={partialStore}
					contentsHeight={contentsHeight}
					dateFormat={dateFormat}
					i18n={i18n}
					data={partialStore.formattedList(dntFormat)}
					menuId={menuId}
				/>
			)}
		</StyledOrgDashboard>
		// </PageContainer>
	)
})

const StyledOrgDashboard = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	height: 100%;
	.cannot-access {
		opacity: 0.3;
		cursor: not-allowed;
	}
	.partition {
		margin-right: 1rem;
		margin-left: 1rem;
	}
	table > tbody > tr > td {
		padding: 8px;
	}
`
