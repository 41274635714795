import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { DLDialog } from "../../../../../../components/basic-elements"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"
import { OrgI18n } from "../../../../../../common-models/enumerations/translation-sheets"

export default observer(function ArchConditionHelpDialog({
	conditionId,
	isOpen,
	setIsOpen,
}: {
	conditionId: string
	isOpen: boolean
	setIsOpen: () => void
}) {
	const store = useRootStore()
	const i18nSheet = OrgI18n.archPolicy

	const titlePrefix = "twTitle-"
	const descPrefix = "twDesc-"
	const helpPrefix = "tsHelp-"

	const i18n = store.i18n.combineI18n(i18nSheet)
	return (
		<DLDialog
			eleTestId="archive-condition-help-dialog"
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			showCloseBtn={true}
			dialogTitle={
				i18n[titlePrefix + conditionId]
				// (i18n?.twArchConditionDetail ||
				// 	"Archive condition details ") + parseInt(conditionId)
			}
			dialogContents={
				<StyledDialogContents>
					<div className="FC">
						<div className="description">
							{i18n[descPrefix + conditionId]}
						</div>
						<div className="help-text">
							{i18n[helpPrefix + conditionId]}
						</div>
					</div>
				</StyledDialogContents>
			}
			showOpenBtn={false}
			cancelBtnText={i18n.twCancel || "Cancel"}
			maxWidth="sm"
			fullWidth={true}
		/>
	)
})

const StyledDialogContents = styled.div`
	.description {
		margin-bottom: 1rem;
		font-size: ${(props) => props.theme.shared.textXl};
	}
	.help-text {
		font-weight: 100;
		opacity: 0.7;
	}
`
