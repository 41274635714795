import React from "react"
import {
	DLButton,
	DLContextMenuWithButton,
} from "../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { IdType, idToNumber } from "../../../../library/converters/id-converter"
import { handleDownloadExcelTemplate } from "../../../../library/bulk-import-utils/handle-download-template"
import Icon from "@mdi/react"
import { mdiArrowUp } from "@mdi/js"

export default observer(function AuditProgramActionBtns({
	partialStore,
	handleAdd,
	selectedFile,
	handleSelectFilesFromLocal,
	fromDialog,
	setOpenRoleAssign,
	setType,
	setOpenAssignUserDialog,
	wprId,
}: {
	partialStore: any
	handleAdd: any
	selectedFile: string | null
	handleSelectFilesFromLocal: (e: any) => void
	fromDialog: boolean
	setOpenRoleAssign: any
	setType: any
	setOpenAssignUserDialog: any
	wprId: number
}) {
	const handlePrepare = () => {
		let proceed = window.confirm("Do you prepare the audit step?")
		if (!proceed) {
			return
		} else {
			partialStore.auditStepSignOffPrepared({
				AuditProgramId: selectedFile,
				WorkPaperId: wprId,
			})
		}
	}

	const handleReview = () => {
		let proceed = window.confirm("Do you review the audit step?")
		if (!proceed) {
			return
		} else {
			partialStore.auditStepSignOffReviewed({
				AuditProgramId: selectedFile,
				WorkPaperId: wprId,
			})
		}
	}
	return (
		<>
			<div className="audit-program-action-area FR JSB">
				<DLButton
					eleTestId="choose-files-btn"
					startIcon={<Icon path={mdiArrowUp} size={0.8} />}
					color="primary"
					disabled
				>
					<label className="file-upload-btn-wrapper">
						Choose A file
						<input
							type="file"
							name="file"
							onChange={handleSelectFilesFromLocal}
							data-testid="file-input"
							accept=".xlsx,.xls"
						/>
					</label>
				</DLButton>
				{/* <div className="FR AC"> */}
				<DLButton
					eleTestId="template-btn"
					color="primary"
					eleClassName="MR"
					clickHandler={() =>
						handleDownloadExcelTemplate(
							[
								{
									legend: "",
									auditSteps: "",
									assertions: "",
									notes: "",
									ref: "",
									preparer: "",
									preparerDueDate: "",
									reviewer: "",
									reviewerDueDate: "",
								},
							],
							"AuditProgramTemplate"
						)
					}
				>
					Template
				</DLButton>
				<DLButton
					eleTestId="add-btn"
					color="primary"
					clickHandler={handleAdd}
					eleClassName="MR"
				>
					Add New Step
				</DLButton>
				<DLButton
					eleTestId="export-btn"
					color="primary"
					// clickHandler={() =>}
				>
					Export
				</DLButton>
				{/* </div> */}
			</div>
			{!fromDialog && (
				<div className="FR AC audit-program-action-area">
					<DLContextMenuWithButton
						eleTestId="required-role-btn"
						menuOptions={[
							{
								label: "Required Role for Prepare",
								value: "ctx-role-prepare",
								clickEvent: () => {
									setType("Prepare")
									setOpenRoleAssign((prev: any) => !prev)
								},
							},
							{
								label: "Required Role for Review",
								value: "ctx-role-reviewe",
								clickEvent: () => {
									setType("Review")
									setOpenRoleAssign((prev: any) => !prev)
								},
							},
						]}
						useScroll={false}
						showButton={true}
						showIcon={false}
						btnText="Required Role"
						btnVariant="outlined"
						btnColor="primary"
						btnClassName="MR"
						btnSize="regular"
						// eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>

					<DLContextMenuWithButton
						eleTestId="assign-user-btn"
						menuOptions={[
							{
								label: "Assign Preparer",
								value: "ctx-assign-preparer",
								clickEvent: () => {
									setType("Preparer")
									setOpenAssignUserDialog(true)
								},
							},
							{
								label: "Assign Reviewer",
								value: "ctx-assign-reviewer",
								clickEvent: () => {
									setType("Reviewer")
									setOpenAssignUserDialog(true)
								},
							},
						]}
						useScroll={false}
						showButton={true}
						showIcon={false}
						btnText="Assign Users to Item"
						btnVariant="outlined"
						btnColor="primary"
						btnClassName="MR"
						btnSize="regular"
						// eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>
					<DLButton
						eleTestId="prepare-btn"
						color="primary"
						eleClassName="MR"
						disabled={!selectedFile}
						clickHandler={handlePrepare}
					>
						Prepare
					</DLButton>
					<DLButton
						eleTestId="review-btn"
						color="primary"
						eleClassName="MR"
						disabled={!selectedFile}
						clickHandler={handleReview}
					>
						Review
					</DLButton>
				</div>
			)}
		</>
	)
})
