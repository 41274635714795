import React, { useState } from "react"
import { PageContainer } from "../../../components/app-frame-elements"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { DLButton } from "../../../components/basic-elements"
import PBCActionBtns from "./sub-components/PBCActionBtns"
import PBCTableColumns, {
	filterComponent,
} from "./sub-components/PBCTableColumns"
import { DLI18nProps } from "../../../common-models/types/common-props"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import styled from "styled-components"
import ReactTableV8 from "../../../components/basic-elements/tables/DLReactTable"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import AddNewPBCListDialog from "./sub-components/AddNewPBCListDialog"
import * as XLSX from "xlsx"
import { format } from "date-fns"

export default observer(function PreparedByClient({
	partialStore,
	contentsHeight,
	fetchingStatus,
	i18n,
}: {
	partialStore: any
	contentsHeight: any
	fetchingStatus: ActionStatus
	i18n: DLI18nProps
}) {
	const store = useRootStore()

	const dateFormat = store.global.dateFormat.value

	const [data, setData] = useState<any>([])
	const [open, setOpen] = useState(false)
	const [selectedFileData, setSelectedFileData] = useState<any>(null)

	function handleFile(e: any) {
		var file = e.target.files[0]
		var reader = new FileReader()

		reader.onload = function (e) {
			if (e.target) {
				if (
					e.target.result !== null &&
					typeof e.target.result !== "string"
				) {
					var data = new Uint8Array(e.target.result)
					var workbook = XLSX.read(data, { type: "array" })

					const jsonData = XLSX.utils.sheet_to_json(
						workbook.Sheets.Sheet1,
						{ raw: false }
					)

					console.log(jsonData, "jsonData")
					let organizedList: any = []
					const hasEmptyFields = jsonData.every((item: any) =>
						Object.keys(item).includes("Area")
					)

					if (!hasEmptyFields) {
						alert(
							"Excel file contains empty fields. Please input correct data."
						)
						return false
					}
					jsonData.map((item: any, i: any) => {
						organizedList.push({
							id: `fileId${i + 1}`,
							area: item.Area,
							controlRefNo: item.ControlRefNo,
							description: item.Description,
							dueDate: format(new Date(item.DueDate), dateFormat),
							requestedDate: format(new Date(), dateFormat),
							before: item.Before,
							after: item.After,
							recur: item.Recur,
						})
					})

					setData(organizedList)
				}
			} else {
				console.log("event target is null")
			}
		}
		reader.readAsArrayBuffer(file)
	}

	const handleSelectFilesFromLocal = (e: any) => {
		handleFile(e)
		// partialStore.setBulkClientFileReadStatus(ActionStatus.loading)
	}

	const handleRemove = (rowId: string) => {
		const filtered = data.filter((i: any) => i.id !== rowId)
		setData(filtered)
	}

	const handleEdit = (rowId: string) => {
		const filtered = data.find((i: any) => i.id === rowId)
		setSelectedFileData(filtered)
		setOpen(true)
	}

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			isLoading={fetchingStatus === "LOADING"}
		>
			<StyledPBC>
				<PBCActionBtns
					partialStore={partialStore}
					setOpen={setOpen}
					handleSelectFilesFromLocal={handleSelectFilesFromLocal}
				/>
				<div className="table-container">
					<ReactTableV8
						tableColumns={PBCTableColumns(
							handleRemove,
							handleEdit,
							partialStore,
							i18n
						)}
						customFilters={(props: any) =>
							filterComponent({ ...props, dateFormat })
						}
						data={data}
						menuId={DLProjSubMenus.pbc}
						showPageSetting={false}
						hasPagination={false}
						height={contentsHeight - 30}
						groupBy={["area"]}
					/>
				</div>
				<div className="FR JR">
					<DLButton
						eleTestId="preview-btn"
						// clickHandler={() =>}
					>
						Preview Changes
					</DLButton>
					<DLButton eleTestId="save-btn" eleClassName="ml-4">
						Save Changes
					</DLButton>
					<DLButton eleTestId="cancel-btn" eleClassName="ml-4">
						Cancel Changes
					</DLButton>
				</div>
			</StyledPBC>

			{open && (
				<AddNewPBCListDialog
					open={open}
					setOpen={setOpen}
					data={data}
					setData={setData}
					selectedData={selectedFileData}
				/>
			)}
		</PageContainer>
	)
})

const StyledPBC = styled.div`
	input[type="file"] {
		display: none;
	}
	.table-container {
		margin-top: 1rem;
	}
	.ml-4 {
		margin-left: 4px;
	}
	.rt-th {
		border-rigth: 1px solid !important;
	}
	table > thead > tr:first-child {
		border: 1px solid black !important;
		th {
			padding: 0;
			.header {
				min-height: 2rem;
				padding-left: 8px;
				border-left: 1px solid #c2c4c6;
				border-right: 1px solid #c2c4c6;
				justify-content: center;
				div {
					justify-content: center;
				}
			}
		}
	}
`
