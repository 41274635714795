import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import DLTreeNavChildRow from "../../../../../components/combined-elements/tree-nav/DLTreeNavChildRow"
import { Icon } from "@mdi/react"
import { mdiFileDocumentOutline } from "@mdi/js"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export default observer(function EngTypeRowController({
	node,
	// handleRightClick,
	canDrag,
}: {
	node: any
	// handleRightClick: (e: any, id: string, on: "policy" | "engType") => void
	canDrag: boolean
}) {
	const orgStore = useOrgStore()
	const moduleId = DLOrgSubMenus.setup_arch_policy
	//
	const handleClick = () => {}

	const handleMoveEngType = () => {
		orgStore.archPolicy.setSelectedEngType(node.id) // EngType
		orgStore.archPolicy.setSelectedPolicy(node.parentId) // Source policy
		// reset if already selected
		orgStore.archPolicy.setSelectedPolicyForEngType("") // Target policy
		orgStore.archPolicy.setOpenMoveEngTypeDialog(true)
	}

	// const canDrag = orgStore.archPolicy.canDrag
	const title = node.title
	const permission = orgStore.checkin.getActionsAsObject(moduleId)
	const actions = {
		move: {
			action: handleMoveEngType,
			tooltipText: "Move Engagement Type",
		},
	}
	return (
		<div
			onClick={handleClick}
			data-testid="eng-type-row"
			// onContextMenu={(e: any) =>
			// 	handleRightClick(e, node.id, "engType")
			// }
		>
			<DLTreeNavChildRow
				canDrag={canDrag}
				title={title}
				selected={false} // TODO: update is required
				showButtons={true}
				icon={<Icon path={mdiFileDocumentOutline} size={0.8} />}
				permission={permission}
				actions={actions}
			/>
		</div>
	)
})
