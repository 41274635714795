import React from "react"
import { observer } from "mobx-react-lite"

// dialogs
import EditOrgUserDialog from "./dialogs/EditOrgUserDialog"
import SuspendOrgUserDialog from "./dialogs/SuspendOrgUserDialog"
import RestoreOrgUserDialog from "./dialogs/RestoreOrgUserDialog"
import RemoveOrgUserDialog from "./dialogs/RemoveOrgUserDialog"
// components
import { PageContainer } from "../../../../components/app-frame-elements"
import { AddUserDialog } from "./dialogs/AddUserDialog"
import { ConsoleLog } from "../../../../components/basic-elements"
// dependencies

import OrgSetupUserListTable from "./sub-components/OrgSetupUserListTable"
import OrgSetupUsersCtxMenus from "./sub-components/OrgSetupUsersCtxMenus"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"

import { PermissionAsObjectProps } from "../../../../common-models/permission"
import styled from "styled-components"
import { DLI18nProps } from "../../../../common-models/types/common-props"

import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import BulkAddUsersDialog from "./dialogs/BulkAddUsersDialog"
import AssignedProjectsDialog from "./dialogs/AssignedProjectsDialog"

export default observer(function OrgSetupUsers({
	partialStore,
	actionStatus,
	clickPointExist,
	permission,
	i18n,
}: {
	partialStore: any
	actionStatus: ActionStatus
	clickPointExist: boolean
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	ConsoleLog(" __________ OrgSetupUsers __________ ")

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			fixedHeight
			isLoading={actionStatus === "LOADING"}
		>
			{/* ----- Contents ----- */}
			{actionStatus === "SUCCESS" && (
				<StyledSetupClients>
					<OrgSetupUserListTable
						i18n={i18n}
						permission={permission}
					/>
				</StyledSetupClients>
			)}

			{/* ---------- dialogs  ---------- */}
			{partialStore.addDialogOpen && <AddUserDialog i18n={i18n} />}
			{partialStore.editDialogOpen && <EditOrgUserDialog i18n={i18n} />}
			{partialStore.suspendDialogOpen && (
				<SuspendOrgUserDialog i18n={i18n} />
			)}
			{partialStore.restoreDialogOpen && (
				<RestoreOrgUserDialog i18n={i18n} />
			)}
			{partialStore.removeDialogOpen && (
				<RemoveOrgUserDialog i18n={i18n} />
			)}
			{partialStore.bulkAddUsersDialogOpen && (
				<BulkAddUsersDialog partialStore={partialStore} i18n={i18n} />
			)}
			{partialStore.assignedProjectsDialogOpen && (
				<AssignedProjectsDialog i18n={i18n} />
			)}

			{/* ---------- context menus ---------- */}
			{clickPointExist && (
				<OrgSetupUsersCtxMenus permission={permission} />
			)}

			{/* ---------- snackbar  ---------- */}
			<CommonSnackbarComponent i18n={i18n} partialStore={partialStore} />
		</PageContainer>
	)
})

const StyledSetupClients = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.partition {
		margin: 0 0.5rem;
	}
`
