import React from "react"
import { PageContainer } from "../../../components/app-frame-elements"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import StaticticsCard from "./sub-components/StaticticsCard"
import CommentListCard from "./sub-components/CommentListCard"
import AdjustmentsCard from "./sub-components/AdjustmentsCard"
import WPStatusTable from "./sub-components/WPStatusTable"
import AuditProgramStatusTable from "./sub-components/AuditProgramStatusTable"
import styled from "styled-components"

export default observer(function ProjectDashboard({
	partialStore,
	fetchingStatus,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
}) {
	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			isLoading={fetchingStatus === "LOADING"}
		>
			{fetchingStatus === "SUCCESS" && (
				<StyledProjectDashboard>
					<div className="FR JSB card-container">
						<StaticticsCard
							cardType="QC Comments"
							total={
								partialStore
									.viewCommentList()
									.find(
										(comment: any) =>
											comment.type === "QC Comment"
									).total
							}
						/>
						<StaticticsCard
							cardType="Review Comments"
							total={
								partialStore
									.viewCommentList()
									.find(
										(comment: any) =>
											comment.type === "Review Comment"
									).total
							}
						/>
						<StaticticsCard
							cardType="Adjustments"
							total={partialStore.adjustments.total}
						/>
					</div>
					<div className="FR JSB">
						<CommentListCard partialStore={partialStore} />
						<AdjustmentsCard partialStore={partialStore} />
					</div>
					<div className="wp-status-table">
						<WPStatusTable partialStore={partialStore} />
					</div>
					<div className="ap-status-table">
						<AuditProgramStatusTable partialStore={partialStore} />
					</div>
				</StyledProjectDashboard>
			)}
		</PageContainer>
	)
})

const StyledProjectDashboard = styled.div`
	.wp-status-table {
		margin-top: 1rem;
	}
	.ap-status-table {
		margin-top: 1rem;
	}
`
