import React from "react"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
//
import {
	ConsoleLog,
	DLButton,
	DLContextMenuWithButton,
} from "../../../../../components/basic-elements"
//
import Icon from "@mdi/react"
import { mdiMenuDown } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { OrgSetupClientsTableColumn } from "./OrgSetupClientsTableColumn"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Table, Column } from "@tanstack/react-table"
import BasicCounter from "../../../../../components/app-frame-elements/sub-components/BasicCounter"
import { AddClientBtn } from "../dialogs/AddClientDialog"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { StyledHeader } from "../../org-setup-users/sub-components/OrgSetupUserListTable"

export default observer(function OrgSetupClientsTable({
	i18n,
	setFilteredData,
	permission,
	menuId,
}: {
	i18n: DLI18nProps
	setFilteredData: any
	permission: PermissionAsObjectProps
	menuId: DLOrgSubMenus
}) {
	ConsoleLog(" __________// OrgSetupClientsTable //__________ ")

	const store = useRootStore()
	const orgStore = useOrgStore()
	// const [selectedClients, setSelectedClients] = useState<any>([])

	const handleContextMenu = (event: any, clientId: string) => {
		event.preventDefault()
		orgStore.setupClients.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
		orgStore.setupClients.setSelectedItem(clientId)
	}

	const handleCheckbox = (id: string) =>
		orgStore.setupClients.setSelectedItem(id)

	// const handleToggleAll = () => {
	// 	selectedClients.map((clientId: string) => {
	// 		handleCheckbox(clientId)
	// 	})
	// }

	const isSelected = (id: string) =>
		orgStore.setupClients.selectedItems.includes(id)

	const dateFormat = store.global.dateFormat.value
	const timeZone = store.global.timeZone.uiValue
	const selectedCount = orgStore.setupClients.selectedItems.length

	const pageHeader = (instance: Table<any>) => {
		const totalClients = instance.getPreFilteredRowModel().flatRows.length
		const filteredClients = instance.getFilteredRowModel().flatRows.length

		return (
			<StyledHeader>
				{permission.create && (
					<div className="FR">
						<AddClientBtn i18n={i18n} />

						<DLButton
							eleTestId="bulk-import-client-btn"
							clickHandler={() =>
								orgStore.setupClients.setBulkAddClientsDialogOpen(
									true
								)
							}
							variant="contained"
							color="primary"
							size="regular"
							eleClassName="top-row-btns ml-8"
						>
							{/* TODO: This button will be moved to the add client dialog  */}
							{i18n.twImportClients || "Import Clients"}
						</DLButton>
					</div>
				)}
				<div className="FR AC" style={{ marginTop: 10 }}>
					{(permission.update || permission.delete) && (
						<DLContextMenuWithButton
							eleTestId="folders-btn"
							menuOptions={[
								{
									label: `Edit Client Info`,
									value: "ctx-editClient",
									available: permission.update,
									disabled: selectedCount !== 1,
									clickEvent: () =>
										orgStore.setupClients.setEditClientDialogOpen(
											true
										),
								},
								{
									label: `Remove Client`,
									value: "ctx-removeClient",
									available: permission.delete,
									disabled: selectedCount !== 1,
									clickEvent: () =>
										orgStore.setupClients.setRemoveClientDialogOpen(
											true
										),
								},
							]}
							useScroll={false}
							showButton={true}
							showIcon={false}
							btnText="Item Actions"
							btnVariant="outlined"
							btnColor="primary"
							btnSize="regular"
							btnClassName="ctx-menu-styles"
							eleIcon={<Icon path={mdiMenuDown} size={1} />}
						/>
					)}

					<DLContextMenuWithButton
						eleTestId="info-btn"
						menuOptions={[
							{
								label: `${i18n.twClientInfo || "Client Info"}`,
								value: "ctx-clientInfo",
								available: permission.update,
								disabled: selectedCount !== 1,
								// clickEvent: () =>
								// 	orgStore.setupClients.setAssignClientToGroupDialogOpen(true),
							},
						]}
						useScroll={false}
						showButton={true}
						showIcon={false}
						btnText="Item Info"
						btnVariant="outlined"
						btnColor="primary"
						btnSize="regular"
						btnClassName="ctx-menu-styles ml-8"
						eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>
				</div>
				<div
					className="FR AC counter-container"
					style={{ marginTop: 10 }}
				>
					<BasicCounter
						label={i18n.twTotalClients || "Total Clients: "}
						value={totalClients}
					/>

					{totalClients > filteredClients && (
						<div className="FR AC">
							<span className="partition">|</span>
							<div className="filtered-count">
								Filtered Clients: {filteredClients}
							</div>
							<span className="partition">|</span>
							<DLButton
								eleTestId="clear-filters"
								clickHandler={instance.resetColumnFilters}
							>
								Clear Filters
							</DLButton>
						</div>
					)}
					{selectedCount > 0 && permission.delete && (
						<>
							<span className="partition">|</span>
							<div>selected items: </div>
							<div className="selected-items">
								<span className="selected-item">
									{selectedCount}
								</span>
							</div>
							{/* <div className="action-btns FR AC">
								<DLIconButton
									eleTestId="delete-btn"
									tooltipText="Delete"
									clickHandler={() =>
										orgStore.setupClients.setRemoveClientDialogOpen(
											true
										)
									}
								>
									<Icon path={mdiTrashCan} size={0.8} />
								</DLIconButton>
							</div> */}
						</>
					)}
					{/* <DLButton
						eleTestId="delete-all"
						clickHandler={() => deleteAllClients()}
					>
						Delete All Clients
					</DLButton> */}
				</div>
			</StyledHeader>
		)
	}

	return (
		<ReactTableV8
			tableColumns={OrgSetupClientsTableColumn(
				handleContextMenu,
				handleCheckbox,
				isSelected,
				i18n
			)}
			data={orgStore.setupClients.formattedList(dateFormat, timeZone)}
			hasPagination={true}
			customFilters={(props: any) =>
				filterComponent({ ...props, dateFormat })
			}
			handleContextMenu={(e: any, row: any) =>
				handleContextMenu(e, row.clientId)
			}
			height={store.ui.contentsAreaHeight - 60}
			permission={permission}
			i18n={i18n}
			pageHeader={pageHeader}
			menuId={menuId}
			onRowClick={(event: any, row: any) =>
				orgStore.setupClients.setSelectedItem(row.clientId)
			}
		/>
	)
})

const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "createdAt":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		case "actions":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}
