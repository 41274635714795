import { types } from "mobx-state-tree"

const OpenedFilesUiHelper = types
	.model({
		// dialogs
		openedFilesDialogOpen: false,
		openedFilesRequestAccessDialogOpen: false,
	})
	.actions((self) => ({
		// dialogs
		setOpenedFilesDialogOpen(request?: boolean) {
			self.openedFilesDialogOpen = request ?? !self.openedFilesDialogOpen
		},
		setOpenedFilesRequestAccessDialogOpen(request?: boolean) {
			self.openedFilesRequestAccessDialogOpen =
				request ?? !self.openedFilesRequestAccessDialogOpen
		},
	}))

export default OpenedFilesUiHelper
