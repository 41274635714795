// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { AssignStatus } from "../../org-clients-store/data-models/org-clients.data-models"
import { ConsoleLog } from "../../../../components/basic-elements"

const AddClientsToGroup = (self: any) => ({
	addClientsToGroup({
		groupId,
		clientList,
		type,
		postSuccess,
	}: {
		groupId: string
		clientList: any
		type: AssignStatus
		postSuccess: () => void
	}) {
		// 0.
		const actionName = "addClientsToGroup"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API Call
		const groupNumberId = idToNumber(groupId, IdType.group)
		const clientType = type === AssignStatus.unAssigned ? 1 : 2
		let payload = {
			ClientsList: clientList,
			GroupID: groupNumberId,
			Type: clientType,
		}
		self.assignClientsToGroup(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					self.updateClients(clientList, type)
					//
					self.updateAssignedClients(
						response.data.AssignedClients,
						response.data.ClientsCount,
						groupId
					)
					// if success
					// at the end of the action
					ConsoleLog(response)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
						customMessage: response.data.Message,
					})

					postSuccess()
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200,
						color: MessageColorType.orange,
						open: true,
						customMessage: response.data.Message,
						// msgCode:
						// 	ActionStatus.fail +
						// 	"_" +
						// 	actionName +
						// 	`${clientType}-${response.data.Status}`,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default AddClientsToGroup
