const DialogRelatedViews = (self: any) => ({
	isDuplicatedTemplateGroupName(name: string) {
		const templateGroups = self.projTemplates.filter(
			(item: any) =>
				item.parentId === null &&
				item.title.toLowerCase() === name.toLowerCase()
		)
		return templateGroups.length > 0
	},
	isDuplicatedTemplateName(name: string) {
		const templates = self.projTemplates.filter(
			(item: any) =>
				item.parentId !== null &&
				item.title.toLowerCase() === name.toLowerCase()
		)
		return templates.length > 0
	},
})

export default DialogRelatedViews
