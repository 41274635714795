import { PropsForKeyboardUpDown } from "../../../components/combined-elements/file-tree/dl-file-tree-props"
import { FileMenuViewType } from "../data-models/dl-file-control-model"
import { OldSignOffType } from "../data-models/sign-off.data-model"

export type SelectWithKeyboardProps = {
	itemId: string
	isParent: boolean
	shiftKey: boolean
	altKey: boolean
}

const SelectedItemsUiActions = (self: any) => ({
	setPrevTreeItem({ node, parentNode }: PropsForKeyboardUpDown) {
		let prevItem: any = undefined
		const higherSiblings = self.getHigherSiblings(node.parentId, node.id)
		const lastHigherSibling = higherSiblings[higherSiblings.length - 1]

		// const higherSiblingsOfParent =
		// 	parentNode === null
		// 		? []
		// 		: self.getHigherSiblings(parentNode.parentId, parentNode.id)

		// ConsoleLog("higherSiblings:", higherSiblings)
		// ConsoleLog("higherSiblingsOfParent:", higherSiblingsOfParent)

		// * 1. LastItem is folder
		// -- 1-1. folder has higher siblings
		// ---- 1-1-a. folder's last higher sibling is expanded
		// ------ 1-1-a-1. folder's last higher sibling has chilrdern --> select the last children of it
		// ------ 1-1-a-2. folder's last higher sibling has no children --> select the last higher sibling
		// ---- 1-1-b. folder's last higher sibling is collapsed --> select the last higher sibling
		// -- 1-2. folder has no higher siblings

		// * 2. LastItem is file
		// -- 2-1. file has higherSiblings --> select the last higherSiblings
		// -- 2-2. file has no higherSiblings --> select the parentNode

		// * 1. LastItem is folder
		if (node.isParent) {
			// -- 1-1. folder has higher siblings
			if (higherSiblings.length >= 1) {
				// ---- 1-1-a. folder's last higher sibling is expanded
				if (lastHigherSibling.expanded) {
					const children = self.getSiblings(lastHigherSibling.id)
					if (children.length >= 1) {
						// ------ 1-1-a-1. folder's last higher sibling has chilrdern --> select the last children of it
						prevItem = children[children.length - 1].id
					} else {
						// ------ 1-1-a-2. folder's last higher sibling has no children --> select the last higher sibling
						prevItem = lastHigherSibling.id
					}
				} else {
					// ---- 1-1-b. folder's last higher sibling is collapsed --> select the last higher sibling
					prevItem = lastHigherSibling.id
				}
			} else {
				// -- 1-2. folder has no higher siblings
				prevItem = undefined
			}
		} else {
			// * 2. LastItem is file
			if (higherSiblings.length >= 1) {
				// -- 2-1. file has higherSiblings --> select the last higherSiblings
				prevItem = lastHigherSibling.id
			} else {
				// -- 2-2. file has no higherSiblings --> select the parentNode
				prevItem = node.parentId
			}
		}
		//
		//
		//
		//
		self.prevTreeItem = prevItem
		// ConsoleLog(">> prev item", prevItem)
	},
	setNextTreeItem({ node, parentNode }: PropsForKeyboardUpDown) {
		let nextItem: any = undefined
		const lowerSiblings = self.getLowerSiblings(node.parentId, node.id)

		const lowerSiblingsOfParent =
			parentNode === null
				? []
				: self.getLowerSiblings(parentNode.parentId, parentNode.id)

		// ConsoleLog("lowerSiblings:", lowerSiblings)
		// ConsoleLog("lowerSiblingsOfParent:", lowerSiblingsOfParent)

		// * 1. LastItem is folder
		// -- 1-1. folder is expanded
		// ---- 1-1-a. folder has children --> select the first child
		// ---- 1-1-b. folder has no children --> select the first sibling
		// ------ 1-1-b-1. folder has no lower sibling --> select the first sibling of parent
		// -- 1-2. folder is collapsed
		// ---- 1-2-a. folder has lower siblings --> select the first lower sibling
		// ---- 1-2-b. folder has no siblings
		// ------ 1-2-b-1. folder's parent has lower siblings --> select the first sibling of parent
		// ------ 1-2-b-2. folder's parent has no lower siblings --> next item is null

		// * 2. LastItem is file
		// -- 2-1. file has lowerSiblings --> select the first lowerSiblings
		// -- 2-2. file has no lowerSiblings --> select the first sibling of parent

		// * 1. LastItem is folder
		if (node.isParent) {
			// -- 1-1. folder is expanded
			if (node.expanded) {
				// ---- 1-1-a. folder has children --> select the first child
				if (node.children.length >= 1) {
					nextItem = node.children[0].id
				} else {
					// ---- 1-1-b. folder has no children --> select the first sibling
					if (lowerSiblings.length >= 1) {
						nextItem = lowerSiblings[0].id
					} else {
						// ------ 1-1-b-1. folder has no lower sibling --> next item is the first sibling of parent
						if (lowerSiblingsOfParent.length >= 1) {
							nextItem = lowerSiblingsOfParent[0]?.id
						} else {
							nextItem = undefined
						}
					}
				}
			} else {
				// -- 1-2. folder is collapsed
				if (lowerSiblings.length >= 1) {
					// ---- 1-2-a. folder has lower siblings --> select the first lower sibling
					nextItem = lowerSiblings[0].id
				} else {
					// ---- 1-2-b. folder has no siblings
					// ------ 1-2-b-1. folder's parent has lower siblings --> select the first sibling of parent
					// ------ 1-2-b-2. folder's parent has no lower siblings --> next item is null

					if (lowerSiblingsOfParent.length >= 1) {
						nextItem = lowerSiblingsOfParent[0]?.id
					} else {
						nextItem = undefined
					}
				}
			}
		} else {
			// *2. LastItem is file
			// -- 2-1. file has lowerSiblings --> select the first lowerSiblings
			if (lowerSiblings.length >= 1) {
				nextItem = lowerSiblings[0].id
			} else {
				// -- 2-2. file has no lowerSiblings --> select the first sibling of parent
				if (lowerSiblingsOfParent.length >= 1) {
					nextItem = lowerSiblingsOfParent[0]?.id
				} else {
					nextItem = undefined
				}
			}
		}

		//
		//
		//
		//
		self.nextTreeItem = nextItem
		// ConsoleLog(">> next item", nextItem)
	},
	getItemInfo(id: string) {
		const target =
			self.viewType === FileMenuViewType.tree ||
			self.viewType === FileMenuViewType.fileTable
				? self.flatList.find((item: any) => item.id === id)
				: self.searchResults.find((item: any) => item.id === id)
		return target
	},
	/**
	 * * selected items require the actions below
	 * - 1. setSelectedItem (reset and push new one)
	 * - 2. pushSeletedItem (with duplication check)
	 * - 3. pushOrSpliceSelectedItem (for checkbox)
	 * - 4. setSelectedItemsWithKeyboard
	 * - 5. spliceSelectedItem
	 * - 6. resetSelectedItems
	 */

	//
	//
	// 1. set selected item
	setSelectedItem(itemId: string, isParent: boolean) {
		// WARNING: DO NOT VALIDATE ISPARENT BY ID PREFIX
		// NOTE: parent validation step is deprecated for project template.
		// to add root folder in the project template, project template id is requried as parentId
		// so do not validate the isParent with itemId prefix

		self.selectedItems.length = 0
		const selectedItem = {
			id: itemId,
			isParent,
		}
		self.selectedItems.push(selectedItem)
	},
	// 2. push seleted item (with duplication check)
	pushSelectedItem(itemId: string, isParent: boolean) {
		const duplicationCheck = self.selectedItems.findIndex(
			(item: any) => item.id === itemId
		)
		if (duplicationCheck === -1) {
			self.selectedItems.push({ id: itemId, isParent })
		}
	},
	// 3. push or splice selected item (for checkbox)
	pushOrSpliceSelectedItem(itemId: string, isParent: boolean) {
		const selectedItem = {
			id: itemId,
			isParent,
		}
		const itemIndex = self.selectedItems.findIndex(
			(item: any) => item.id === itemId
		)
		if (itemIndex === -1) {
			// duplicated item not exist = add item to the list = turn on the checkbox
			self.selectedItems.push(selectedItem)
		} else {
			// duplicated item exist = remove item from the list = turn off the checkbox
			self.selectedItems.splice(itemIndex, 1)
		}
	},
	// 4. set selected items with keyboard
	setSelectedItemsWithKeyboard({
		itemId,
		isParent,
		shiftKey,
		altKey,
	}: SelectWithKeyboardProps) {
		if (shiftKey && !altKey) {
			// when click with shift key
			// self.selectedItems.push(selectedItem)
			self.pushSelectedItem(itemId, isParent)
		} else if (!shiftKey && altKey) {
			// when click with alt key
			// remove item from the list
			self.spliceSelectedItem(itemId)
		} else if (!shiftKey && !altKey) {
			// when click the file row without any key
			/**
			 *
			 * -- cases --
			 * - a-1. if there is only one selected item and the item is matched with the clicked item.
			 * ---> reset the selected items
			 * - a-2. if there is only one selected item and the item is not matched with the clicked item.
			 * ---> set selected item with new one
			 * - b-1. if there are multiple selected items, and the item is in them
			 * ---> set selected item with new one
			 * - b-2. if there are multiple selected items, and the item is not in them
			 * ---> set selected item with new one
			 * - c. if there are no selected items
			 * ---> set selected item with new one
			 *
			 * --> except a-1, set selected item with new one
			 */

			const duplicationCheck = self.selectedItems.findIndex(
				(item: any) => item.id === itemId
			)
			const selectedItems = self.selectedItems.length
			if (selectedItems === 1) {
				// a-1.
				if (duplicationCheck !== -1) {
					self.resetSelectedItems()
				} else {
					self.setSelectedItem(itemId, isParent)
				}
			} else {
				self.setSelectedItem(itemId, isParent)
			}
		}
	},
	// 5. splice selected item
	spliceSelectedItem(itemId: string) {
		const targetIndex = self.selectedItems.findIndex(
			(item: any) => item.id === itemId
		)
		if (targetIndex !== -1) {
			self.selectedItems.splice(targetIndex, 1)
		} else {
			alert(
				`(spliceSelectedItem) No matched selected items. itemId: ${itemId}`
			)
		}
	},
	// 6. reset selected items
	resetSelectedItems() {
		self.selectedItems.length = 0
	},
	//
	toggleSelectAllFiles() {
		if (self.selectedItems.length <= 0) {
			// clear one more time the list for safety
			self.selectedItems.length = 0
			const allFiles = self.flatList.filter(
				(item: any) => item.isParent === false
			)
			allFiles.map((file: any) => {
				const selectedFile = {
					id: file.id,
					isParent: file.isParent,
				}
				self.selectedItems.push(selectedFile)
			})
		} else {
			self.selectedItems.length = 0
		}
	},
	//
	setDndContents(contents: any) {
		self.dndContents = contents
	},
	resetDndContents() {
		self.dndContents = undefined
	},
	setMultipleSignOffType(props: {
		type: OldSignOffType
		forCancel: boolean
	}) {
		self.multipleSignOffType = props
	},
	//
})

export default SelectedItemsUiActions
