import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

const GetAllAuditSteps = (self: any) => ({
	getAllAuditSteps(wprId: number) {
		// 0.
		const actionName = "getAllAuditSteps"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API
		self.readAllAuditSteps(wprId)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 200) {
					// Empty the project user list on the model
					const data = response.data.Data.AuditSteps.map(
						(item: any) => {
							return {
								auditProgramId: item.AuditProgramId,
								wpId: item.WorkPaperId,
								apgWPRef: item.AuditProgramWorkPaperReference,
								displayOrder: item.DisplayOrder,
								note: item.Note,
								auditSteps: item.AuditSteps,
								assertions: item.Assertions,
								legend: item.Legend,
								prepared: item.Prepared,
								reviewed: item.Reviewed,
								preparer: item.Preparer,
								reviewer: item.Reviewer,
								preparerUserId: item.PreparerUserId,
								reviewerUserId: item.ReviewerUserId,
								preparedUserId: item.PreparedUserId,
								reviewedUserId: item.ReviewedUserId,
								preparedAtUtc: item.PreparedAtUtc,
								reviewedAtUtc: item.ReviewedAtUtc,
								reviewerDueDate: item.ReviewerDueDate,
								preparerDueDate: item.PreparerDueDate,
							}
						}
					)

					console.log(data, "data1")

					self.setAuditSteps(data)
					self.setAssignableUsers(response.data.Data.AssignableUsers)
					self.setAssignableWPRef(
						response.data.Data.AssignableWorkPaperReferences
					)
					self.setNeedRefresh(false)

					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
						customMessage: "Successfully fetched audit steps",
					})
				} else {
					// when fail
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						customMessage: "Failed to fetch audit steps",
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetAllAuditSteps
