import React from "react"
import {
	DLContextMenu,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default observer(function OrgSetupGroupsTableMenus({
	i18n,
	permission,
}: {
	i18n: DLI18nProps
	permission: PermissionAsObjectProps
}) {
	ConsoleLog(" -=-=-=-=-$ OrgSetupGroupsTableMenus $-=-=-=-=- ")

	const orgStore = useOrgStore()
	const { twAssignClients, twAssignUsers, twRemoveGroup, twEditGroup } = i18n
	const menuOptions = [
		{
			label: `Edit Group Info`,
			value: "ctx-edit-group",
			available: permission.update,
			clickEvent: () => orgStore.setupGroups.setEditGroupDialogOpen(true),
		},
		{
			label: `${twAssignUsers}`,
			value: "ctx-assignUsers",
			available: permission.update,
			clickEvent: () =>
				orgStore.setupGroups.setAssignUsersToGroupDialogOpen(true),
		},
		{
			label: `${twAssignClients}`,
			value: "ctx-assignClients",
			available: permission.update,
			clickEvent: () =>
				orgStore.setupGroups.setAssignClientsToGroupDialogOpen(true),
		},
		{
			label: `${twRemoveGroup}`,
			value: "ctx-remove-group",
			available: permission.delete,
			clickEvent: () =>
				orgStore.setupGroups.setRemoveGroupDialogOpen(true),
		},
	]

	const handleClose = () => {
		orgStore.setupGroups.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="org-setup-groups-ctx-menus"
			clickPoint={{
				mouseX: orgStore.setupGroups.clickPoint.mouseX,
				mouseY: orgStore.setupGroups.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[0, 2]}
		/>
	)
})
