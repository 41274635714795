import { flow } from "mobx-state-tree"
import { OrgSetupClientsApiProps } from "../org-clients.apis"

const UpdateClient = (
	self: any,
	update: OrgSetupClientsApiProps["apiUpdate"]
) => ({
	updateClient: flow(function* (payload) {
		const actionName = "updateClient"
		try {
			const response = yield update(payload)

			// ConsoleLog("updateClient response", response)
			return response
		} catch (error) {
			// console.error("updateClient error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateClient
