import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../common-models/common-model-actions"
// separated actions
import {
	CreateOrgUser,
	ReadOrgUserList,
	UpdateOrgUser,
	DeleteOrgUser,
} from "./model-actions"
import { OrgSetupUserApiProps } from "./org-setup-users.apis"

const OrgSetupUsersModel = ({
	apiRead,
	apiCreate,
	apiUpdate,
	apiRestore,
	apiDelete,
}: OrgSetupUserApiProps) =>
	types
		.model({})
		// CRUD actions
		.actions((self, create = apiCreate) => CreateOrgUser(self, create))
		.actions((self, read = apiRead) => ReadOrgUserList(self, read))
		.actions((self, update = apiUpdate) => UpdateOrgUser(self, update))
		.actions((self, restore = apiRestore, deleteUser = apiDelete) =>
			DeleteOrgUser(self, restore, deleteUser)
		)
		// common model actions
		.actions(CommonModelActions)

export default OrgSetupUsersModel
