import React, { useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { DLDialog } from "../../../../../../components/basic-elements"
import CreateArchivePolicyForm from "./CreateArchivePolicyForm"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"

export default observer(function EditArchivePolicyDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()
	const actionName = "editArchivePolicy"
	//
	const selectedPolicyInfo = orgStore.archPolicy.selectedPolicyInfo
	const initialData = {
		title: selectedPolicyInfo.title,
		description: selectedPolicyInfo.description,
	}
	const [inputs, setInputs] = useState(initialData)
	let inputReady = inputs.title !== "" && inputs.description !== ""
	//
	const handleInputs = useCallback((event: any) => {
		let name = event.target.name
		let value = event.target.value
		setInputs((inputs: any) => ({
			...inputs,
			[name]: value,
		}))
	}, [])
	//
	const handleEdit = () => {
		const policyId = orgStore.archPolicy.selectedPolicy
		orgStore.archPolicy.editArchivePolicy(
			inputs.title,
			inputs.description,
			policyId
		)
	}

	const validations = () => {
		if (
			inputs.title !== "" &&
			// inputs.description !== "" &&
			(selectedPolicyInfo.title !== inputs.title ||
				selectedPolicyInfo.description !== inputs.description)
		) {
			return true
		} else {
			return false
		}
	}

	return (
		<DLDialog
			eleTestId="edit-archive-policy-dialog"
			isOpen={orgStore.archPolicy.openEditDialog}
			setIsOpen={orgStore.archPolicy.setOpenEditDialog}
			handleAction={handleEdit}
			showCloseBtn={true}
			dialogTitle={i18n.twEditArchPolicy}
			dialogContents={
				<CreateArchivePolicyForm
					inputs={inputs}
					handleInputs={handleInputs}
					i18n={i18n}
					actionName={actionName}
				/>
			}
			showOpenBtn={false}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={validations()}
			actionBtn={i18n.twUpdate || "Update"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.archPolicy.responses.getResponse(actionName)?.message
			}
			showSpinner={
				orgStore.archPolicy.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				orgStore.archPolicy.getActionStatus(actionName) === "LOADING"
			}
		/>
	)
})
