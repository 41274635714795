// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { CheckoutFileProps } from "../model-actions/m-checkout-file"

/**
 * WARNING: WARNING: WARNING: WARNING: this CHECK-OUT means !!! OPEN !!!
 * TODO: TODO: TODO: TODO: NEED TO CHECK IF THERE ARE NO CONFUSION BETWEEN THE CHECKIN AND CHECKOUT
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 */
const CheckoutFile = (self: any) => ({
	checkoutFile(props: CheckoutFileProps, withoutConfirm?: boolean) {
		// 0.
		const actionName = "checkoutFile"
		// 1.
		// 2.
		// if (!withoutConfirm) {
		// 	let proceed = window.confirm(
		// 		"Do you want to checkout this file? During checkout, other users cannot update this file"
		// 	)
		// 	if (!proceed) {
		// 		return
		// 	}
		// }
		// NOTE: Deactivated for waiting API update (readonly parameter is required)
		// let proceed = window.confirm(
		// 	"Do you want to checkout this file? During your checked out, other users cannot edit this file. If you want to open this file as readonly, please select 'cancel'"
		// )
		// if (!proceed) {
		// 	props.postActionForCancel && props.postActionForCancel()
		// 	return
		// }
		// alert(
		// 	"This file has been checked out. Other users cannot update this file during your checked out."
		// )

		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.mCheckoutFile(props)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// ConsoleLog([actionName, "__response ", response])
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					props.postActionForSuccess && props.postActionForSuccess()
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 1,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
						customMessage: "Successfully checked-out the file.",
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						// message: response.data.Message || response.data.message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default CheckoutFile
