import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { convertProjStatusAsEnum } from "../../../../../library/get-project-status"
import {
	idToString,
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { CreateProjectMethod } from "../../../../../components/combined-elements/create-project/create-project.data-props"

const GetProjInfo = (self: any) => ({
	getProjInfo(projId: string, postAction?: () => void) {
		// 0. set actionName
		const actionName = "getProjInfo"
		// 1. set response cases
		// self.response.setCases(getProjInfoReponseCases)
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) request API call to Model
		const projNumId = idToNumber(projId, IdType.project)
		self.readProjInfo(projNumId)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						const projInfo = response.data
						// ----- default

						self.setProjInfo(reOrganizeProjInfo(projInfo))
					}
					//
					if (postAction) {
						postAction() // for editor (this function is shared with editor)
					} else {
						// NOTE: if you need postAction also in the project side,
						// NOTE: Need update for below line
						self.setNeedRefresh(false)
					}

					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						customMessage:
							"Successfully get the project information",
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export const reOrganizeProjInfo = (projInfo: any) => {
	return {
		groupName: projInfo.GroupName,
		groupId: idToString(projInfo.GroupID, IdType.group),
		cabinetId: idToString(projInfo.CabinetId, IdType.cabinet),
		cabinetName: projInfo.CabinetName,
		//
		engPartner: projInfo.EngagementUser,
		//
		clientId: idToString(projInfo.ClientMasterID, IdType.client),
		clientName: projInfo.ClientName,
		clientAliasId: projInfo.clientShortId,

		clientAddress: projInfo.Address,
		//
		projectId: idToString(projInfo.ClientId, IdType.project),
		projTitle: projInfo.LinkName,
		projAliasId: projInfo.ClientShortName,

		statusId: projInfo.ProjectTypeId.toString(),
		statusName: convertProjStatusAsEnum(projInfo.ProjectType),

		version: projInfo.Version,

		isArchived: projInfo.Archive,
		isLocked: projInfo.IsLocked,
		//
		engTypeName: projInfo.EngagementType,
		engTypeId: idToString(projInfo.EngagementID, IdType.engType),
		//
		raName:
			projInfo.RiskAssessmentName === null
				? ""
				: projInfo.RiskAssessmentName,
		raId:
			projInfo.RiskAssessmentId === 0 ||
			projInfo.RiskAssessmentId === null
				? ""
				: idToString(projInfo.RiskAssessmentId, IdType.ra),
		//
		createdBy: projInfo.CreatedUser,
		createdAt: projInfo.createdDate || "",
		//
		periodName: projInfo.periodName,
		periodId: idToString(projInfo.periodID, IdType.period),
		//
		financialYear: projInfo.FiscalYear,
		financialYearDate: projInfo.FiscalYearEnd,
		year: projInfo.Year,
		periodEndDate: projInfo.FiscalYearEnd,
		//
		fee:
			projInfo.Fee === undefined ||
			projInfo.Fee === null ||
			projInfo.Fee === ""
				? 0
				: typeof projInfo.Fee === "number"
				? projInfo.Fee
				: parseInt(projInfo.Fee),
		archPolicyPeriodName: projInfo.ArchiveDatePolicy,
		archPolicyPeriodId: idToString(
			projInfo.ArchiveDatePolicyId,
			IdType.archPolicyPeriod
		),
		//
		expectedReportDate:
			projInfo.expectedDate === undefined ||
			projInfo.expectedDate === null
				? ""
				: projInfo.expectedDate,

		expectedArchDeadlineDate:
			projInfo.ExpectedArchiveDeadLineDate === undefined ||
			projInfo.ExpectedArchiveDeadLineDate === null
				? ""
				: projInfo.ExpectedArchiveDeadLineDate,

		finalReportDate:
			projInfo.finalDate === undefined || projInfo.finalDate === null
				? ""
				: projInfo.finalDate,
		// NOTE: because the GetClientInfo return null for archivedDeadlineDate, we need to use archivedFinalDate
		// NOTE: sometimes it return the value and sometimes not... but the archivedFinalDate returns value always...
		// NOTE: anyways, archivedDeadlineDate is not the correct wording :(  Final wording should be FinalArchiveDeadlineDate.
		finalArchDeadlineDate:
			projInfo.archivedFinalDate === undefined ||
			projInfo.archivedFinalDate === null
				? ""
				: projInfo.archivedFinalDate,
		createMethod: CreateProjectMethod.byBlank,
		//
		archiveChecklistItem1: projInfo.ArchiveChecklistItem1 || "",
		archiveChecklistItem2: projInfo.ArchiveChecklistItem2 || "",
		archiveChecklistLastUpdatedBy: projInfo.ArchiveChecklistLastUpdatedBy
			? idToString(projInfo.ArchiveChecklistLastUpdatedBy, IdType.user)
			: "",
		archiveChecklistLastUpdatedByUserAlias:
			projInfo.ArchiveChecklistLastUpdatedByUserAlias || "",
		archiveChecklistLastUpdatedByUserName:
			projInfo.ArchiveChecklistLastUpdatedByUserName || "",
		archiveChecklistLastUpdatedDate:
			projInfo.ArchiveChecklistLastUpdatedDate || "",
		hasExportAccess: projInfo.hasExportAccess || false,
	}
}

export default GetProjInfo
