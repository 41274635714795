import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import LeadSheets from "./LeadSheets"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"

export default observer(function LeadSheetsController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.fs_ls
	const actionName = "getLeadSheetUrl"

	// const isLocked = projStore.projInfo.projInfo.isLocked
	// const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	// const permission = projStore.checkin.getActionsAsObject(
	// 	menuId,
	// 	isLocked,
	// 	isArchived
	// )
	const needRefresh = projStore.leadSheet.needRefresh

	useEffect(() => {
		const role = projStore.checkin.assignedRole.roleId
		const cabinetId = projStore.projInfo.projInfo.cabinetId
		const isArchived = projStore.projInfo.projInfo.isArchived
		const finYear = projStore.projInfo.projInfo.financialYear
		let accessRight
		if (role === "existing_proj_arch_admin" || isArchived) {
			accessRight = 1
		} else {
			accessRight = 0
		}
		if (menuAccess && needRefresh) {
			projStore.leadSheet.getLeadSheetUrl(cabinetId, accessRight, finYear)
		}
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<LeadSheets
					partialStore={projStore.leadSheet}
					contentsHeight={store.ui.contentsAreaHeight}
					fetchingStatus={projStore.leadSheet.getActionStatus(
						actionName
					)}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
