import React from "react"
import { DLIconButton } from "../../../../../components/basic-elements"
//
import { mdiDotsHorizontal } from "@mdi/js"
import Icon from "@mdi/react"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { DLCheckbox } from "@datalobby/components"
import { StyledColumn } from "../../../../../components/combined-elements/projects-table/projects-table-columns/NormalProjectsTableColumns"

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	const size = props.column.getSize()
	return (
		<StyledColumn data-testid={cellId} style={{ width: size }}>
			{props.getValue()}
		</StyledColumn>
	)
}

const OrgSetupGroupsTableColumns = (
	handleContextMenu: any,
	i18n: DLI18nProps,
	handleClick: (groupId: string, type: string) => void,
	handleCheckbox: (groupId: string) => void,
	selectedId: string
) => [
	{
		header: "",
		accessorKey: "clientId",
		size: 20,
		enableColumnFilter: false,
		cell: (props: any) => {
			const id = props.row.original.groupId
			const handleClick = () => {
				handleCheckbox(id)
			}

			return (
				<DLCheckbox
					eleTestId={`checkbox-${id}`}
					clickHandler={() => handleClick()}
					color="primary"
					isChecked={selectedId === id}
				/>
			)
		},
	},
	{
		header: "S.no",
		size: 30,
		cell: (props: any) => {
			return props.row.index + 1
		},
	},
	{
		header: i18n.twName,
		accessorKey: "groupName",
		// size: 120,
		cell: renderCustomCell,
	},
	{
		header: i18n.twAssignedUsers,
		accessorKey: "userCount",
		size: 32,
		enableColumnFilter: false,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			const groupId = props.row.original.groupId
			return (
				<a
					data-testid={cellId}
					onClick={() => handleClick(groupId, "Users")}
				>
					{props.getValue()}
				</a>
			)
		},
	},
	// {
	//     header: i18n.twAssignedUsers,
	//     accessorKey: "assignedUsers",
	//     size: 240,
	//     cell: (props: any) => {
	//         const cellId = props.cell.column.id + "-cell"
	//         const itemList = props.getValue()
	//             ? props
	//                   .getValue()
	//                   .split(",")
	//                   .map((item: string) => item.trim())
	//             : []

	//         return <ShowMoreColumn itemList={itemList} cellId={cellId} />
	//     },
	// },
	{
		header: i18n.twAssignedClients,
		accessorKey: "clientsCount",
		size: 32,
		enableColumnFilter: false,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			const groupId = props.row.original.groupId
			return (
				<a
					data-testid={cellId}
					onClick={() => handleClick(groupId, "Clients")}
				>
					{props.getValue()}
				</a>
			)
		},
	},
	// {
	//     header: i18n.twAssignedClients,
	//     accessorKey: "assignedClients",
	//     size: 240,
	//     cell: (props: any) => {
	//         const cellId = props.cell.column.id + "-cell"
	//         const itemList = props.getValue()
	//             ? props
	//                   .getValue()
	//                   .split(",")
	//                   .map((item: string) => item.trim())
	//             : []
	//         return <ShowMoreColumn itemList={itemList} cellId={cellId} />
	//     },
	// },
	{
		header: "Action",
		accessorKey: "xxx",
		enableColumnFilter: false,
		size: 32,
		cell: (props: any) => {
			const groupId = props.row.original.groupId

			return (
				<div className="FR AC JC" style={{ width: "100%" }}>
					<DLIconButton
						aria-label="more"
						clickHandler={(event: any) =>
							handleContextMenu(event, groupId)
						}
						eleTestId="group-ctx-btn-on-row"
					>
						<Icon path={mdiDotsHorizontal} size={0.8} />
					</DLIconButton>
				</div>
			)
		},
	},
]

export default OrgSetupGroupsTableColumns
