import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"

export default observer(function StaticticsCard({
	cardType,
	total,
}: {
	cardType: string
	total: number
}) {
	return (
		<StyledCardComponent
			className="simple-card FC AC"
			data-testid={"card-" + cardType}
		>
			<h2>{cardType}</h2>
			<div>
				<h3>Total</h3>
				{total}
			</div>
		</StyledCardComponent>
	)
})

const StyledCardComponent = styled.div`
	width: 22rem;
	&.simple-card {
		padding: 1rem;
		height: 12rem;
		box-shadow: 0px 2px 6px 1px rgba(192, 203, 220, 0.3);
		border: 1px solid ${(props) => props.theme.shade10};
		border-radius: 4px;
		margin-bottom: 1rem;
		overflow: hidden;
		transition: 0.4s;
		:hover {
			/* box-shadow: 0px 2px 8px 1px rgba(192, 203, 220, 0.6); */
			box-shadow: 0px 2px 8px 1px ${(props) => props.theme.shade40};
			border: 1px solid ${(props) => props.theme.primaryLightDeep};
		}
	}
`
