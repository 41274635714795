import React, { useEffect } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLSpinner,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { ProjectInfoContainer } from "./ProjectInfoContainer"
import PreProcessPDF from "./PreProcessPDF"
import { requestToken } from "../../../../project-side/proj-access-control/store/proj-checkin.apis"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { DLProjectModelType } from "../../../../../service-modules/project-module/data-model/project-module-data-models"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { saveLatestProjToken } from "../../../../../library/token-utils"

export default observer(function ArchiveZipRegenerationDialog({
	partialStore,
	from,
}: {
	partialStore: any
	from: string
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const archMgmtStore = orgStore.archiveMgmt

	const dnt = store.global.getDntFormat

	const projectId = partialStore.selectedProj
	const projectInfo = partialStore.getClientInfoById(
		projectId,
		dnt.dateFormat,
		dnt.timeZone,
		dnt.timeFormat,
		from
	)

	console.log(projectInfo, "projectInfo")

	const getPreConActionName = "getPreConditionStatus"

	useEffect(() => {
		// to hide the pdf file list
		archMgmtStore.responses.setResponse(getPreConActionName, {
			actionName: getPreConActionName,
			status: ActionStatus.standby,
		})

		archMgmtStore.responses.setResponse("regenPreProcessPdfs", {
			actionName: "regenPreProcessPdfs",
			status: ActionStatus.standby,
		})

		archMgmtStore.getPreConditionStatus(projectId)
	}, [])

	const handleExportZip = () => {
		archMgmtStore.exportArchivedZipFile(projectId)
	}

	let getPreConStatus = archMgmtStore.getActionStatus(getPreConActionName)
	const preProcessStatus = archMgmtStore.preConditionData.isPreProcessed
	const exportIsWip =
		archMgmtStore.getActionStatus("exportArchivedZipFile") ===
		ActionStatus.loading

	return (
		<DLDialog
			eleTestId="export-archive-zip-dialog"
			isOpen={archMgmtStore.archiveZipRegenerationDialog}
			setIsOpen={archMgmtStore.setArchiveZipRegenerationDialog}
			showCloseBtn={true}
			dialogTitle="Export Archive ZIP"
			dialogContents={
				projectInfo ? (
					<ArchiveZipRegenerationDialogForm
						projInfo={projectInfo}
						partialStore={archMgmtStore}
						preProcessStatus={preProcessStatus}
						getPreConStatus={getPreConStatus}
						selectedProj={partialStore.selectedProj}
					/>
				) : (
					<DLSpinner />
				)
			}
			maxWidth="sm"
			fullWidth={true}
			cancelBtnText="Cancel"
			draggable
			actionBtn="Export"
			actionReady={
				getPreConStatus === ActionStatus.success &&
				preProcessStatus &&
				projectInfo?.hasExportPermission
			}
			cannotUpdate={exportIsWip}
			handleAction={handleExportZip}
		/>
	)
})

const ArchiveZipRegenerationDialogForm = ({
	projInfo,
	partialStore,
	preProcessStatus,
	getPreConStatus,
	selectedProj,
}: {
	projInfo: DLProjectModelType
	partialStore: any
	preProcessStatus: boolean
	getPreConStatus: ActionStatus
	selectedProj: string
}) => {
	return (
		<div>
			<ProjectInfoContainer projInfo={projInfo} />
			<div style={{ height: "3rem" }} />
			<PreProcessPDF
				partialStore={partialStore}
				selectedProj={selectedProj}
			/>
			<div style={{ height: "3rem" }} />
			{getPreConStatus === ActionStatus.loading &&
				"Checking Pre-process PDFs status... Please wait"}
			{getPreConStatus === ActionStatus.success &&
				!preProcessStatus &&
				"* Please generate Pre-process PDFs first to export"}
			{getPreConStatus === ActionStatus.fail &&
				"Failed to check the Pre-process PDFs status. Please try again"}
		</div>
	)
}
