import { types } from "mobx-state-tree"

import AuditProgramViewModel from "./audit-program.view-model"
import AuditProgramModel from "./audit-program.model"

export const initialStore = {
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

export const AuditProgramStore = () =>
	types
		.compose(AuditProgramModel(), AuditProgramViewModel)
		.named("AuditProgramStore")

const AuditProgramStoreInstance = AuditProgramStore().create(initialStore)

export default AuditProgramStoreInstance
