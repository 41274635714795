import {
	ActionStatus,
	MessageColorType,
	DLObjectFormat,
} from "../../../../common-models/enumerations/common-enums"
import { initialDLFileAsFolder } from "../../data-models/initial-file"
import { idToString, IdType } from "../../../../library/converters/id-converter"

const GetFirstFolders = (self: any) => ({
	getFirstFolders(projId: string, postAction?: any) {
		// 0.
		const actionName = "getFirstFolders"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const menuId = self.storeName
		self.readFirstFolders({ projId, menuId })
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// const isArchived = response.data.Archive
					const structureLock = response.data.IsLocked
					const fetchedFirstFolders = response.data.ParentFolderList
					const totalFileCount = response.data.TotalFileCount
						? response.data.TotalFileCount.toString()
						: ""

					self.setTotalFileCount(totalFileCount)
					self.setStructureLock(structureLock) // folder lock status
					fetchedFirstFolders.map((folder: any, index: number) => {
						const folderId = idToString(
							folder.ParentMediaID,
							IdType.folder
						)
						let reOrganizedFolder = {
							...initialDLFileAsFolder,
							id: folderId,
							parentId: null,
							isParent: true,
							//
							title: folder.WPDesc,
							menuId: self.storeName,
							objectFormat: DLObjectFormat.folder,
							expanded: false,
							index,
						}

						self.pushFlatItem(reOrganizedFolder)
					})

					postAction && postAction()

					// set success case response
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
	getAllFoldersAndFiles(projId: string, refreshAll: boolean = false) {
		/**
		 * refreshAll: true --> request all things again in the table view
		 * refreshAll: false --> just request folders which have 'expanded: false'
		 */
		const actionName = "getAllFoldersAndFiles"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		const menuId = self.storeName

		if (refreshAll) {
			self.readFirstFolders({ projId, menuId })
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						// const isArchived = response.data.Archive
						const structureLock = response.data.IsLocked
						const fetchedFirstFolders =
							response.data.ParentFolderList

						self.setStructureLock(structureLock) // folder lock status
						fetchedFirstFolders.map(
							(folder: any, index: number) => {
								const folderId = idToString(
									folder.ParentMediaID,
									IdType.folder
								)
								let reOrganizedFolder = {
									...initialDLFileAsFolder,
									id: folderId,
									parentId: null,
									isParent: true,
									//
									title: folder.WPDesc,
									menuId: self.storeName,
									objectFormat: DLObjectFormat.folder,
									expanded: false, // NOTE: set expanded as false
									index,
								}

								self.pushFlatItem(reOrganizedFolder)
							}
						)
						self.relayGetChildrenOfFolder()
					} else {
						// when fail to get the first folder
					}
				})
				.catch((error: Error) => {
					// when get error to get the first folder
				})
		} else {
			/**
			 * NOTE:
			 * No need to request the first folders
			 * because this 'refreshAll: false' will be used in the tree view
			 * and the tree view request the first folder at the first time when it be rendered
			 */
			self.relayGetChildrenOfFolder()
		}
	},
})

export default GetFirstFolders
