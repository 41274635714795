import React from "react"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ProjNoPermissionOnMenu from "../proj-access-control/ProjNoPermissionOnMenu"
import { ProjI18n } from "../../../common-models/enumerations/translation-sheets"
import PBCClientFiles from "./PBCClientFiles"

export default observer(function PBCClientFilesController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.pbc_client_files
	const actionName = "getPBCUrl"
	const i18nSheet = ProjI18n.pbc

	// const isLocked = projStore.projInfo.projInfo.isLocked
	// const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	// const permission = projStore.checkin.getActionsAsObject(
	// 	menuId,
	// 	isLocked,
	// 	isArchived
	// )
	// const needRefresh = projStore.pbc.needRefresh

	// useEffect(() => {
	// 	if (menuAccess && needRefresh) {
	// 		projStore.pbc.getPBCUrl()
	// 	}
	// }, [menuAccess, needRefresh])

	const i18n = store.i18n.combineI18n(i18nSheet)
	return (
		<>
			{menuAccess ? (
				<PBCClientFiles
					partialStore={projStore.pbc}
					contentsHeight={store.ui.contentsAreaHeight}
					fetchingStatus={projStore.pbc.getActionStatus(actionName)}
					i18n={i18n}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
