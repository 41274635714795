import { DnTFormatProps } from "../../../../common-models/types/common-props"
import { ProjScreenType } from "../../../../components/combined-elements/projects-table/projects-table-props"
import { getTzDate } from "../../../../library/converters/date-utc-converter"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

const OrgProjectsFormattingViews = (self: any) => ({
	formattedProjList(
		dntFormat: DnTFormatProps,
		projType: ProjScreenType,
		menuId?: DLOrgSubMenus
	) {
		if (
			menuId === DLOrgSubMenus.remind_report ||
			menuId === DLOrgSubMenus.admin_remind_report
		) {
			return self.remindProjList.slice().sort((a: any, b: any) => {
				// If either dueDays is not a number, move it to the end
				if (isNaN(a.dueDays)) return 1
				if (isNaN(b.dueDays)) return -1
				// First, compare dueDays
				if (a.dueDays < b.dueDays) return -1
				if (a.dueDays > b.dueDays) return 1
				// second, compare year
				if (a.financialYear < b.financialYear) return -1
				if (a.financialYear > b.financialYear) return 1
				// compare clientNames
				if (a.clientName < b.clientName) return -1
				if (a.clientName > b.clientName) return 1
				// If both are equal, return 0
				return 0
			})
		}

		if (
			menuId === DLOrgSubMenus.remind_archiving ||
			menuId === DLOrgSubMenus.admin_remind_archiving ||
			menuId === DLOrgSubMenus.arc_rem_norm_projs
		) {
			return self.remindArcProjList.slice().sort((a: any, b: any) => {
				// If either dueDays is not a number, move it to the end
				if (isNaN(a.dueDays)) return 1
				if (isNaN(b.dueDays)) return -1
				// First, compare dueDays
				if (a.dueDays < b.dueDays) return -1
				if (a.dueDays > b.dueDays) return 1
				// second, compare year
				if (a.financialYear < b.financialYear) return -1
				if (a.financialYear > b.financialYear) return 1
				// compare clientNames
				if (a.clientName < b.clientName) return -1
				if (a.clientName > b.clientName) return 1
				// If both are equal, return 0
				return 0
			})
		}
		let selectedProj
		if (projType === ProjScreenType.archived) {
			selectedProj = self.archivedProjList
		} else if (projType === ProjScreenType.unarchived) {
			selectedProj = self.unarchivedProjList
		} else if (projType === ProjScreenType.normal) {
			selectedProj = self.normalProjList
		} else if (projType === ProjScreenType.replica) {
			selectedProj = self.replicaProjList
		} else if (projType === ProjScreenType.roamArchived) {
			selectedProj = self.roamArchivedProjList
		} else if (projType === ProjScreenType.forceArchived) {
			selectedProj = self.forceArchivedProjList
		}

		let dateFormattedList: any[] = []

		dateFormattedList = selectedProj

		if (
			menuId === DLOrgSubMenus.assignedArchived ||
			menuId === DLOrgSubMenus.archived ||
			menuId === DLOrgSubMenus.assignedArchivedReadonlyProjects
		) {
			console.log(dateFormattedList, "dateFormattedList")
			return dateFormattedList
				?.slice()
				.sort(
					(a, b) =>
						new Date(b.archivedAt).getTime() -
						new Date(a.archivedAt).getTime()
				)
		}

		if (
			menuId === DLOrgSubMenus.assignedUnarchived ||
			menuId === DLOrgSubMenus.unarchived
		) {
			return dateFormattedList
				?.slice()
				.sort(
					(a, b) =>
						new Date(b.unarchivedAt).getTime() -
						new Date(a.unarchivedAt).getTime()
				)
		}

		// if (
		// 	menuId === DLOrgSubMenus.remind_report ||
		// 	menuId === DLOrgSubMenus.admin_remind_report ||
		// 	menuId === DLOrgSubMenus.remind_archiving ||
		// 	menuId === DLOrgSubMenus.admin_remind_archiving ||
		// 	menuId === DLOrgSubMenus.arc_rem_norm_projs
		// ) {
		// 	return dateFormattedList.sort((a, b) => {
		// 		// If either dueDays is not a number, move it to the end
		// 		if (isNaN(a.dueDays)) return 1
		// 		if (isNaN(b.dueDays)) return -1
		// 		// First, compare dueDays
		// 		if (a.dueDays < b.dueDays) return -1
		// 		if (a.dueDays > b.dueDays) return 1
		// 		// second, compare year
		// 		if (a.financialYear < b.financialYear) return -1
		// 		if (a.financialYear > b.financialYear) return 1
		// 		// compare clientNames
		// 		if (a.clientName < b.clientName) return -1
		// 		if (a.clientName > b.clientName) return 1
		// 		// If both are equal, return 0
		// 		return 0
		// 	})
		// }

		return dateFormattedList?.slice()
	},

	getTodayDateWithOrgTZ(dntFormat: DnTFormatProps) {
		return getTzDate({
			date: new Date(),
			timeZone: dntFormat.timeZone,
			dateFormat: dntFormat.dateFormat,
		})
	},
})

export default OrgProjectsFormattingViews
