import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import CommentListDialog from "../../../../../components/combined-elements/comment/CommentListDialog"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { FileScreenDialog } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import {
	DLCommentType,
	DialogActionType,
} from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function CommentListByFileDialogController({
	partialStore,
}: {
	partialStore: {
		storeName: DLProjSubMenus
		// selectedItems: SelectedFileItemProps[]
		selectedFile: string
		fileShortInfo: (
			fileId: string,
			from?: string
		) => { id: any; aliasId: any; title: any } | undefined // return id, aliasId, title
		fileTreeDialogOpenStatus: any
		setFileTreeDialogOpen: (
			dialogName: FileScreenDialog,
			request?: boolean
		) => void
		[x: string]: any
	}
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const commentStore = projStore.comments
	//
	const fileId = partialStore.selectedFile // commentStore.currentTargetObject.objectId
	const fileInfo = partialStore.fileShortInfo(
		fileId,
		"CommentListByFileDialogController"
	)
	const dialogName = FileScreenDialog.commentList
	const menuId = partialStore.storeName

	const getCommentsOfFile = () =>
		commentStore.getCommentsByObj({ objectId: fileId, menuId })

	useEffect(() => {
		ConsoleLog(["comment list dialog on", menuId, "/for file:", fileId])
		getCommentsOfFile()
	}, [])

	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived
	const permission = projStore.checkin.getActionsAsObject(
		DLProjSubMenus.comments,
		isLocked,
		isArchived
	)

	const setOpen = (request?: boolean) => {
		partialStore.setFileTreeDialogOpen(dialogName, request)
	}

	const handleOpenAddCommentDialog = () => {
		partialStore.setCommentDetailDialogMode(DialogActionType.add)
		partialStore.setCommentType(DLCommentType.normal)
		partialStore.setFileTreeDialogOpen(FileScreenDialog.commentDetail, true)
	}

	const handleOpenEditCommentDialog = (commentId: string) => {
		partialStore.setCommentDetailDialogMode(DialogActionType.edit)
		partialStore.setFileTreeDialogOpen(FileScreenDialog.commentDetail, true)
		partialStore.setSelectedComment(commentId)
	}

	const openCommentReadonlyDialog = (commentId: string) => {
		partialStore.setCommentDetailDialogMode(DialogActionType.read)
		partialStore.setFileTreeDialogOpen(FileScreenDialog.commentDetail, true)
		partialStore.setSelectedComment(commentId)
	}

	const handleCloseCommentDialog = (commentId: string) => {
		partialStore.setCommentClosingDialogOpenStatus(true)
		partialStore.setSelectedComment(commentId)
	}

	const dnt = store.global.getDntFormat

	const handleDeleteComment = (commentId: string) => {
		commentStore.removeComment({
			props: {
				commentId,
				deleteBy: projStore.checkin.userId,
			},
			handleResponse: partialStore.handleResponse,
			postWorkForSuccess: () => {
				const commentList =
					commentStore.viewCommentsOfCurrentObject(dnt)
				partialStore.reflectRemoveCommentOnFileRow(
					fileId,
					commentId,
					commentList
				)
				partialStore.setFileTreeDialogOpen(
					FileScreenDialog.commentList,
					false
				)
				// getCommentsOfFile()
				// // if (comments) // TODO: @Noah
				// // TODO: How to update the file row comment icon without refresh the file row
			},
		})
	}

	const objectInfo = {
		title: fileInfo?.title,
		aliasId: fileInfo?.aliasId,
		id: fileId,
	}

	const comments = commentStore.viewCommentsOfCurrentObject(dnt)
	const open = partialStore.fileTreeDialogOpenStatus[dialogName]

	return (
		<CommentListDialog
			open={open}
			setOpen={setOpen}
			openAddCommentDialog={handleOpenAddCommentDialog}
			openEditCommentDialog={handleOpenEditCommentDialog}
			openCloseCommentDialog={handleCloseCommentDialog}
			handleDeleteComment={handleDeleteComment}
			openCommentReadonlyDialog={openCommentReadonlyDialog}
			comments={comments}
			objectInfo={objectInfo}
			permission={permission}
		/>
	)
})
