import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 *  - low priority. pending temporary
 */
export const GetOrgMgmtList = (self: any) => ({
	getOrgMgmtList() {
		// 0.
		const actionName = "getOrgMgmtList"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readOrgMgmtList()
			// read()
			.then((response: any) => {
				// if success
				if (response.status === 200) {
					self.resetList()
					const newList = response.data.CustRes.map((item: any) => ({
						id: item.CustID.toString(),
						custName: item.CustName,
						custId: item.CustUserName,
						emailId: item.EmailID,
						organizationName: item.OrganisationName,
						createdBy: item.CreatedBy,
						custStatus: item.CustStatus,
					}))
					self.updateWholeList(newList)
					// set success response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						customMessage:
							"Successfully get the organization management list",
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						message:
							response.data.Message ||
							response.data.message ||
							"Something wrong...",
						color: MessageColorType.orange,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetOrgMgmtList
