import { ConsoleLog } from "../../../components/basic-elements"
import { FileMenuViewType } from "../data-models/dl-file-control-model"

const FileMenuUiHelperViews = (self: any) => ({
	get hoveredColumn() {
		return self.columnHighlight
	},
	isHovered(columnName: string) {
		ConsoleLog("isHovered " + columnName)
		const hoveredColumn = self.columnHighlight
		return hoveredColumn === columnName
	},
	get itemNotExist() {
		return self.flatList.length === 0
	},
	isSelected(itemId: string) {
		// ConsoleLog("item Id", itemId)
		// ConsoleLog(self.selectedItems.findIndex(item => item.id === itemId))
		return (
			self.selectedItems.findIndex((item: any) => item.id === itemId) !==
			-1
		)
	},
	isCurrentParent(parentId: string) {
		// Do not display when the multiple parents are related
		// if (self.selectedItems.length === 1) {
		let yesItIs = false
		self.selectedItems.map((item: any) => {
			const childId = item.id
			const parent = self.getItemInfo(childId)?.parentId
			if (parentId === parent) {
				yesItIs = true
			}
		})

		return yesItIs

		// } else {
		// 	return false
		// }
	},
	isExpanded(id: string) {
		const target = self.flatList.find((item: any) => item.id === id)
		if (target) {
			return target.expanded
		} else {
			console.log("(isExpanded) No matched item", id)
			return false
		}
	},
	get needExpandAll() {
		// if there are no collapsed folders, expand all is not required
		const collapsedFolders = self.flatList.filter(
			(item: any) => item.expanded === false && item.isParent
		)
		if (collapsedFolders.length === 0) {
			return false
		} else {
			return true
		}
	},
	get needCollapseAll() {
		// if there are no expanded part, collapse all is not requried
		const expandedItem = self.flatList.findIndex(
			(item: any) => item.expanded === true
		)
		if (expandedItem === -1) {
			return false
		} else {
			return true
		}
	},
	get flatListLength() {
		return self.flatList.length
	},

	get countOfFolders() {
		return self.flatList.filter((item: any) => item.isParent).length
	},
	get countOfFiles() {
		return self.flatList.filter((item: any) => !item.isParent).length
	},
	get openedDialogExist() {
		const dialogStatus = self.fileTreeDialogOpenStatus
		const statusList = Object.values(dialogStatus)
		const statusCheck = statusList.findIndex((item: any) => item === true)
		if (statusCheck === -1) {
			return false
		} else {
			return true
		}
	},

	/**
	 * Sign off conditions
	 *
	 * (estimated logic) (below is not used) (isPrepare, isReviewed attr already calculated from backend)
	 * Q. The last sign off is mine
	 * ----> True: able to cancel the sign off
	 * -------- no update --> unable to sign off again
	 * -------- file is modified --> able to sign off again
	 * -------- related sign off is modified --> able to sign off again
	 * ----> False: able to sign off
	 */

	checkAvailableSignOffs() {
		let availableSignOffs = {
			prepare: false,
			unPrepare: false,
			review: false,
			unReview: false,
		}
		if (self.selectedItems.length === 0) {
			return availableSignOffs
		}
		const noFolders =
			self.selectedItems.findIndex((item: any) => item.isParent) === -1

		let preparedList: boolean[] = []
		let reviewedList: boolean[] = []

		if (noFolders) {
			self.selectedItems.map(
				(item: { id: string; isParent: boolean }) => {
					const target =
						self.viewType === FileMenuViewType.tree ||
						self.viewType === FileMenuViewType.fileTable
							? self.flatList.find(
									(flatItem: any) => flatItem.id === item.id
							  )
							: self.searchResults.find(
									(flatItem: any) => flatItem.id === item.id
							  )
					if (target) {
						preparedList.push(target.isPrepared)
						reviewedList.push(target.isReviewed)
					} else {
						alert(
							`(checkAvailableSignOffs) cannot find the target of ${item.id}`
						)
					}
				}
			)

			// NOTE: isPrepared, isReviewed also mean the unPrepare, unReview availability
			// ? CASE 1: single selected item
			if (self.selectedItems.length === 1) {
				// * prepare
				availableSignOffs.prepare = true // This makes we can do multiple prepare
				if (preparedList.includes(true)) {
					// if there is even one "true" on the preparedList
					availableSignOffs.unPrepare = true
				} else {
					// if there is no "true"
					// availableSignOffs.prepare = true
					availableSignOffs.unPrepare = false
				}

				// * review
				availableSignOffs.review = true // This makes we can do multiple prepare
				if (reviewedList.includes(true)) {
					// if there is even one "true" on the reviewedList
					availableSignOffs.unReview = true
				} else {
					// if there is no "true"
					availableSignOffs.unReview = false
				}
			} else {
				// ? CASE 2: when the selected items equal or more than 2
				// * prepare
				availableSignOffs.prepare = true
				if (
					preparedList.includes(true) &&
					preparedList.includes(false)
				) {
					// cannot do anything
					availableSignOffs.unPrepare = false
				} else if (!preparedList.includes(true)) {
					// availableSignOffs.prepare = true
					availableSignOffs.unPrepare = false
				} else if (!preparedList.includes(false)) {
					// availableSignOffs.prepare = false
					availableSignOffs.unPrepare = true
				}

				// * review
				availableSignOffs.review = true
				if (
					reviewedList.includes(true) &&
					reviewedList.includes(false)
				) {
					// cannot do anything
					availableSignOffs.unReview = false
				} else if (!reviewedList.includes(true)) {
					// availableSignOffs.review = true
					availableSignOffs.unReview = false
				} else if (!reviewedList.includes(false)) {
					// availableSignOffs.review = false
					availableSignOffs.unReview = true
				}
			}

			return availableSignOffs
		} else {
			// if the selected items has even one folder, multiple sign off is blocked
			return availableSignOffs
		}
	},
})

export default FileMenuUiHelperViews
