import { types, applySnapshot } from "mobx-state-tree"
import {
	OrgClient,
	GroupList,
	AssignStatus,
	BulkClientsImportProps,
} from "./data-models/org-clients.data-models"

// ---------- common models
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { Responses, ResponseSnackbar } from "../../../common-models"

// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../common-models/common-view-model-actions"
import {
	GetSetupClientList,
	AddClient,
	EditClient,
	RemoveClient,
	GetGroupsForClients,
	AddGroupsToClients,
} from "./view-model-actions"
import { initialStore } from "./org-clients.provider"
import { getTzDate } from "../../../library/converters/date-utc-converter"
import {
	BulkClientImportRelated,
	BulkClientImportRelatedViews,
} from "./view-model-actions/bulk-client-import-related"

const OrgClientsViewModel = types
	.model({
		clientList: types.array(OrgClient),
		unAssinedGroupList: types.array(GroupList),
		assinedGroupList: types.array(GroupList),
		//
		bulkClientList: types.array(BulkClientsImportProps),
		bulkClientFileReadStatus: types.enumeration<ActionStatus>(
			Object.values(ActionStatus)
		),
		actionStatusUnAssignedGroup: types.enumeration<ActionStatus>(
			Object.values(ActionStatus)
		),

		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		getAllClientsId() {
			let idList: string[] = []
			self.clientList.map((client) => {
				idList.push(client.clientId)
			})
			return idList
		},
	}))
	.actions((self) => ({
		// ---------- manage the list
		resetList() {
			self.clientList.length = 0
		},
		pushItemToList(item: any) {
			self.clientList.push(item)
		},
		setClientList(clients: any) {
			self.clientList.length = 0
			self.clientList = clients
		},
		deleteClientFromList(id: string) {
			self.clientList.splice(
				self.clientList.findIndex((item: any) => item.clientId === id),
				1
			)
		},
		resetGroupList(type: string) {
			if (type === AssignStatus.unAssigned) {
				self.unAssinedGroupList.length = 0
			} else {
				self.assinedGroupList.length = 0
			}
		},
		setActionStatusUnAssignedGroup(request: ActionStatus) {
			self.actionStatusUnAssignedGroup = request
		},
		updateClientStore(
			clientAliasId: string,
			clientName: string,
			id: string
		) {
			const index = self.clientList.findIndex(
				(item: any) => item.clientId === id
			)
			self.clientList[index].clientAliasId = clientAliasId
			self.clientList[index].clientName = clientName
		},
		// ---------- assign group related actions
		pushItemToUnAssignedGroupList(item: any) {
			self.unAssinedGroupList.push(item)
		},
		pushItemToAssignedGroupList(item: any) {
			self.assinedGroupList.push(item)
		},
		changeGroupStatus(groupId: string) {
			let index = self.unAssinedGroupList.findIndex(
				(item: any) => item.id === groupId
			)
			self.unAssinedGroupList[index].selected =
				!self.unAssinedGroupList[index].selected
		},
		changeSelGroupStatus(groupId: string) {
			let index = self.assinedGroupList.findIndex(
				(item: any) => item.id === groupId
			)
			self.assinedGroupList[index].selected =
				!self.assinedGroupList[index].selected
		},
		updateGroup(groupList: any, type: AssignStatus) {
			if (type === AssignStatus.unAssigned) {
				//type - 1 is unassigned group
				groupList.map((item: any) => {
					const group = self.unAssinedGroupList.find(
						(item1: any) => item1.id === item.GroupId
					)
					if (group) {
						const reOrganizedGroup = {
							id: group.id, // [GroupID], ex) 10
							name: group.name, // [GroupName], ex) "A"
							selected: false,
						}
						self.assinedGroupList.push(reOrganizedGroup) // add to assigned group

						self.unAssinedGroupList.splice(
							// remove from unassigned group
							self.unAssinedGroupList.findIndex(
								(item1: any) => item1.id === item.GroupId
							),
							1
						)
					}
				})
			} else {
				//type - 2 is assigned group
				groupList.map((item: any) => {
					const group = self.assinedGroupList.find(
						(item1: any) => item1.id === item.GroupId
					)
					if (group) {
						const reOrganizedGroup = {
							id: group.id, // [GroupID], ex) 10
							name: group.name, // [GroupName], ex) "A"
							selected: false,
						}
						self.unAssinedGroupList.push(reOrganizedGroup) // add to unassigned group

						self.assinedGroupList.splice(
							// remove from assigned group
							self.assinedGroupList.findIndex(
								(item1: any) => item1.id === item.GroupId
							),
							1
						)
					}
				})
			}
		},
		// ---------- duplication check ----------
		isDuplicatedAliasId(aliasId: string) {
			return self.clientList.some(
				(client: any) => client.clientAliasId === aliasId
			)
		},
		isDuplicatedName(name: string) {
			return self.clientList.find(
				(client: any) => client.clientName === name
			)
		},
	}))
	// ----------- bulk import clients
	.actions(BulkClientImportRelated)
	.views(BulkClientImportRelatedViews)
	//
	// AGER (Add, Get, Edit, Remove)
	.actions(AddClient)
	.actions(GetSetupClientList)
	.actions(EditClient)
	.actions(RemoveClient)
	// Group Actions
	.actions(GetGroupsForClients)
	.actions(AddGroupsToClients)
	// ----------
	.views((self) => ({
		getClientInfoById(clientId: string) {
			const target = self.clientList.find(
				(element: any) => element.clientId === clientId
			)

			return target
		},
		viewUnassignedGroupList(groupName: string) {
			return self.unAssinedGroupList.filter((item: any) =>
				item.name.toLowerCase().includes(groupName.toLowerCase())
			)
		},
		viewAssignedGroupList(groupName: string) {
			return self.assinedGroupList.filter((item: any) =>
				item.name.toLowerCase().includes(groupName.toLowerCase())
			)
		},
		formattedList(dateFormat: string, timeZone: string) {
			let formattedList: any[] = []

			self.clientList.map((client) => {
				const formattedCreatedAt = client.createdAt
					? getTzDate({
							date: client.createdAt,
							timeZone,
							dateFormat,
					  })
					: ""
				formattedList.push({
					...client,
					createdAt: formattedCreatedAt,
				})
			})

			return formattedList
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgClientsViewModel
