const TreeNavRelatedActions = (
	self: any,
	navOpenWidth: number,
	navClosedWidth: number
) => ({
	setNavWidth(width: number) {
		self.navWidth = width
	},
	setNavOpen(request?: boolean) {
		if (request !== undefined) {
			self.navOpen = request
		} else {
			self.navOpen = !self.navOpen
		}
		if (self.navOpen === true) {
			self.navWidth = navOpenWidth
		} else {
			self.navWidth = navClosedWidth
		}
	},
	updateNavWidth(value: number) {
		self.navWidth = self.navWidth + value
	},
	setCanDrag(request?: boolean) {
		self.canDrag = request ?? !self.canDrag
	},
	setQuery(queryText: string) {
		self.query = queryText
	},
	resetQuery() {
		self.query = ""
	},
})

export default TreeNavRelatedActions
