// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../library/converters/id-converter"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { OrgProjTemplateItemType } from "../../../../organization-side/org-library/org-proj-templates/store/data-models/org-proj-templates.data-model"

const GetTempFilesInProj = (self: any) => ({
	getTempFilesInProj(folderId: string) {
		// 0.
		const actionName = "getTempFilesInProj"
		const actionNameForUi = "getTempFilesInProj_" + folderId
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		self.responses.setResponse(actionNameForUi, {
			actionName: actionNameForUi,
			status: ActionStatus.loading,
		})
		// 3.
		ConsoleLog([actionName, "folderId", folderId])
		self.readTempFilesInProj(folderId)
			.then((response: any) => {
				// WARNING:  NOTE:  TODO: this API does not return the status value...
				if (response.status === 200 && response.data.Status === 1) {
					let list: any[] = []
					const fetchedFolders = response.data.TemplateListRes
					//
					fetchedFolders.map((folder: any) => {
						const folderId = idToString(
							folder.TempFoldID,
							IdType.projTemplateGroup
						)
						if (folder.TemplateList) {
							folder.TemplateList.map((template: any) => {
								const reOrganizedTemplate = {
									id: idToString(
										template.TempId,
										IdType.projTemplate
									),

									parentId: folderId,
									title: template.TempName,
									type: OrgProjTemplateItemType.template,
								}

								list.push(reOrganizedTemplate)
							})
						}
						const reOrganizedFolder = {
							id: folderId,
							parentId: null,
							title: folder.TempFoldName,
							type: OrgProjTemplateItemType.templateGroup,
						}

						list.push(reOrganizedFolder)
					})

					self.setTemplates(list)

					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.green,
						customMessage: "Success to get the template folders",
						// customMessage:
						// 	"Something Wrong... (UPDATE IS REQUIRED)",
						open: false,
						autoHide: false,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage:
						// 	"Something Wrong... (UPDATE IS REQUIRED)",
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetTempFilesInProj
