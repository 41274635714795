import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import RecentProjects from "./RecentProjects"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"

const RecentProjectsController = observer(() => {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const actionName = "getRecentProjList"
	const menuId = DLOrgSubMenus.recent
	const i18nSheet = OrgI18n.projects
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const needRefresh = orgStore.recent.needRefresh

	useEffect(() => {
		orgStore.recent.refresh()
	}, [])

	useEffect(() => {
		if (menuAccess && needRefresh) {
			orgStore.recent.getRecentProjList(orgStore.checkin.orgInfo.userId)
		}
	}, [menuAccess, needRefresh])

	const i18n = store.i18n.combineI18n(i18nSheet)
	return (
		<>
			{menuAccess ? (
				<RecentProjects
					partialStore={orgStore.recent}
					actionStatus={orgStore.recent.getActionStatus(actionName)}
					dateFormat={store.global.dateFormat.value}
					i18n={i18n}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

export default RecentProjectsController
