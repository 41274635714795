import React, { useEffect, useState } from "react"
// for store
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
// components
import {
	DLDialog,
	DLListTransfer,
	DLDialogHeader,
} from "../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { AssignStatus } from "../../../../../stores/org-sub-stores/org-clients-store/data-models/org-clients.data-models"

export default observer(function AssignToGroupDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()
	const actionName = "addGroupsToClient"
	const clientStore = orgStore.setupClients

	const clientId = clientStore.selectedItems[0]
	const clientInfo = clientStore.getClientInfoById(clientId)

	const [group, setGroup] = useState<any>([])
	const [selectedGroup, setSelectedGroup] = useState<any>([])
	const [searchUnAssigned, setSearchUnAssigned] = useState<string>("")
	const [searchAssigned, setSearchAssigned] = useState<string>("")

	useEffect(() => {
		clientStore.getGroupsForClient(AssignStatus.unAssigned, clientId)
		clientStore.getGroupsForClient(AssignStatus.assigned, clientId)
	}, [])

	const selectHandler = (groupId: string, type: number) => {
		let tempArray = type === 1 ? [...group] : [...selectedGroup]
		if (tempArray.some((x) => x.GroupId === groupId)) {
			tempArray.splice(
				tempArray.findIndex((item: any) => item.GroupId === groupId),
				1
			)
		} else tempArray.push({ GroupId: groupId })

		if (type === 1) {
			setGroup(tempArray)
			clientStore.changeGroupStatus(groupId)
		} else {
			setSelectedGroup(tempArray)
			clientStore.changeSelGroupStatus(groupId)
		}
	}

	const handleCheckedRight = () => {
		clientStore.addGroupsToClient({
			groupList: group,
			clientId,
			type: AssignStatus.unAssigned,
			postAction: () => setGroup([]),
		})
	}

	const handleCheckedLeft = () => {
		clientStore.addGroupsToClient({
			groupList: selectedGroup,
			clientId,
			type: AssignStatus.assigned,
			postAction: () => setSelectedGroup([]),
		})
	}

	const handleSearchLeft = (event: any) => {
		const query = event.target.value
		setSearchUnAssigned(query)
	}

	const handleSearchRight = (event: any) => {
		const query = event.target.value
		setSearchAssigned(query)
	}

	const { twAssignGroup, twAssignedGroups, twNotAssignedGroups } = i18n

	const isLoading =
		clientStore.getActionStatus(actionName) === "LOADING" ||
		clientStore.getActionStatus("getGroupsForClientUNASSIGNED") ===
			"LOADING" ||
		clientStore.getActionStatus("getGroupsForClientASSIGNED") === "LOADING"

	return (
		<DLDialog
			eleTestId="assign-client-to-group-dialog"
			isOpen={clientStore.assignClientsToGroupDialogOpen}
			setIsOpen={clientStore.setAssignClientToGroupDialogOpen}
			showCloseBtn={true}
			showOpenBtn={false}
			dialogTitle={
				<DLDialogHeader
					dialogName={twAssignGroup || "Assign Group"}
					targetName={clientInfo?.clientName || ""}
				/>
			}
			// {twAssignGroup}
			dialogContents={
				<DLListTransfer
					unassignedList={clientStore.viewUnassignedGroupList(
						searchUnAssigned
					)}
					assignedList={clientStore.viewAssignedGroupList(
						searchAssigned
					)}
					selectListHandler={selectHandler}
					assignedListName={twAssignedGroups || "Assigned Groups"}
					unassignedListName={
						twNotAssignedGroups || "Not Assigned Groups"
					}
					handleCheckedRight={handleCheckedRight}
					handleCheckedLeft={handleCheckedLeft}
					handleSearchLeft={handleSearchLeft}
					handleSearchRight={handleSearchRight}
				/>
			}
			showSpinner={isLoading}
			cannotUpdate={isLoading}
			maxWidth="md"
		/>
	)
})
