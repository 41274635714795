import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { CommentDetailDialog } from "../../../../../components/combined-elements/comment"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { DLSector } from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { FileScreenDialog } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLCommentDetailFormInputProps } from "../../../../../common-models/types/comment-props"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function CommentDetailDialogController({
	partialStore,
	i18n,
}: {
	partialStore: {
		storeName: DLProjSubMenus
		fileTreeDialogOpenStatus: any
		selectedFile: string
		setFileTreeDialogOpen: (
			dialogName: FileScreenDialog,
			request?: boolean
		) => void
		[x: string]: any
	}
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = partialStore.storeName
	const commentStore = projStore.comments
	const dialogName = FileScreenDialog.commentDetail
	//
	const setOpen = (request?: boolean) =>
		partialStore.setFileTreeDialogOpen(dialogName, request)
	const mode = partialStore.commentDetailDialogMode
	const commentType = partialStore.commentType

	console.log(commentType)
	//
	const commentId = partialStore.selectedComment // TODO: is this right place?
	const objectId = partialStore.selectedFile
	//
	const objFromCommentStore = commentStore.getObjectInfo(commentId)
	const objInfo = partialStore.getItemInfo(objectId)
	const objForComment = {
		sector: DLSector.proj,
		sectorId: projStore.checkin.projId,
		belongsTo: menuId,
		parentId: objInfo ? objInfo.parentId : "",
		parentTitle: objInfo
			? partialStore.fileShortInfo(
					objInfo.parentId,
					"CommentDetailDialogController"
			  ).title
			: "",
		//
		// Note: Using objFromCommentStore because unable to fetch "selectedFile" from comment drawer
		id: objInfo ? objectId : objFromCommentStore?.id,
		aliasId: objInfo ? objInfo.aliasId : objFromCommentStore?.aliasId,
		title: objInfo ? objInfo.title : objFromCommentStore?.title,
		format: objInfo ? objInfo.objectFormat : undefined,
		extension: objInfo ? objInfo.extension : "",
	}

	const dntFormat = store.global.getDntFormat
	const commentInfo = projStore.comments.viewCommentById(commentId, dntFormat)
	//
	const handleResponse = partialStore.handleResponse
	const roles = projStore.roles.viewRoleSet2().roles
	const assignableUserList = projStore.projUsers.userListWithRoleInfo(roles)
	// TODO: proj-setup-users 에 뭐 이상한 거 잔뜩 만들어놨네.. 다 체크해서 필요없는 거 지우고 통일좀...
	// NOTE: 근데 이거 ProjCommentDetailDialogController 랑 분리되어 있어도 괜찮은가? 안되지 않나..? 컨트롤러라 괜찮은가?
	//
	const userName = localStorage.getItem("orgUserName")

	const handleAddComment = (inputs: DLCommentDetailFormInputProps) => {
		let assigneeNames: string[] = []
		inputs.assigneeIds?.map((id: string) => {
			const user = assignableUserList.find((user: any) => user.id === id)
			if (user) {
				assigneeNames.push(user.name)
			}
		})
		ConsoleLog(["-------assigneeNames, ", assigneeNames])
		projStore.comments.addComment({
			comment: {
				...inputs,
				aliasId: objInfo.aliasId,
				fileId: objectId,
			},
			menuId,
			userName: userName || "(pending)",
			fileName: objInfo ? objInfo.title : objFromCommentStore?.title,
			assigneeNames,
			postWorkForSuccess: () => {
				partialStore.setFileTreeDialogOpen(dialogName, false)
				partialStore.setFileTreeDialogOpen(
					FileScreenDialog.commentList,
					false
				)
				// this reflection is for WP, PF not for Comments
				// update is required if the comment screen also get the add feature
				partialStore.reflectAddComment(objectId, inputs.type)
			},

			handleResponse,
		})
	}
	//
	const handleEditComment = (inputs: DLCommentDetailFormInputProps) => {
		projStore.comments.editComment({
			comment: {
				...inputs,
				id: commentId,
			},
			menuId,
			postWorkForSuccess: () => {
				partialStore.setFileTreeDialogOpen(dialogName, false)
				partialStore.setFileTreeDialogOpen(
					FileScreenDialog.commentList,
					false
				)
			},

			handleResponse,
		})
	}

	// const handleCloseComment = () => {}

	useEffect(() => {
		if (projStore.roles.roleSet.roles?.length === 0) {
			projStore.roles.getRoleSet(DLSector.proj)
		}
	}, [])
	//
	// const open = projStore.comments.detailDialogOpenStatus // NOTE: 이거 코멘트 용 아닌데..!!
	const open = partialStore.fileTreeDialogOpenStatus[dialogName]

	ConsoleLog("comment etail dialog controller commentId " + commentId)

	return (
		<CommentDetailDialog
			open={open}
			setOpen={setOpen}
			//
			mode={mode}
			from={menuId}
			//
			commentId={commentId}
			commentInfo={commentInfo}
			commentType={commentType}
			//
			objectId={objectId}
			objectInfo={objForComment}
			//
			assignableUserList={assignableUserList}
			//
			addComment={handleAddComment}
			editComment={handleEditComment}
			//
			projRole={projStore.checkin.user.roleShortName}
			i18n={i18n}
		/>
	)
})
