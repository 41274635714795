import React from "react"
import { DLIconButton } from "../../../../../components/basic-elements"
import { DLAvatar, DLCheckbox } from "@datalobby/components"
import {
	StatusArea,
	FileRowComments,
	FileRowSignOffArea,
} from "../../../../../components/combined-elements/file-tree/file-row-sub-components"
import { ProgressStatus } from "../../../../../common-models/enumerations/common-enums"
import ToggleSelectAllCheckbox from "../../../../../common-models/shared-store-code/selected-items-related/ToggleSelectAllCheckbox"
import { SignOffPolicyItemProps } from "../../../../../service-modules/file-module/data-models/sign-off.data-model"
import { RoleModelProps } from "../../../../../service-modules/file-module/data-models/file.data-model"
import Icon from "@mdi/react"
import { mdiDotsHorizontal } from "@mdi/js"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"

const FileOnlyTableColumns = ({
	handleOpenCommentListOfFile,
	// signOffArea,
	handleFileStatus,
	toggleSelectAllFiles,
	handleCheckbox,
	setSelectedFile,
	isSelected,
	countSelectedFiles,
	// for signOff area
	signOffPolicy,
	getColumnSignOffs,
	singleSignOff,
	cellType,
	dateFormat,
	signOffAreaWidth,
	signOffColumnsDisplay,
	handleCtxMenuButtonClick,
	permission,
	handleClickFile,
}: {
	handleOpenCommentListOfFile: (objId: string) => void
	// signOffArea: any,
	handleFileStatus: (fileId: string, currentStatus: ProgressStatus) => void
	toggleSelectAllFiles: () => void
	handleCheckbox: (fileId: string) => void
	setSelectedFile: (fileId: string) => void
	isSelected: (fileId: string) => boolean
	countSelectedFiles: number
	// for signOff area
	signOffPolicy: SignOffPolicyItemProps[]
	getColumnSignOffs: (
		fileId: string,
		columnId: string,
		dntFormat: DnTFormatProps
	) => void
	singleSignOff: any
	cellType: any
	dateFormat: string
	signOffAreaWidth: number
	signOffColumnsDisplay: { [x: string]: boolean }
	handleCtxMenuButtonClick: any
	permission: PermissionAsObjectProps
	handleClickFile: (e: any, fileId: string) => void
}) => [
	// {
	// 	Header: "id(temp)",
	// 	accessorKey: "id"
	// },
	// {
	// 	Header: "Folder",
	// 	accessorKey: "parentId",
	// },
	{
		header: (
			<ToggleSelectAllCheckbox
				selectedItemExist={countSelectedFiles > 0}
				toggleAction={toggleSelectAllFiles}
			/>
		),
		accessorKey: "id",
		size: 20,
		enableColumnFilter: false,
		enableSorting: false,
		cell: (props: any) => {
			const id = props.row.original.id
			const aliasId = props.row.original.aliasId
			const handleClickCheckbox = () => {
				handleCheckbox(id)
			}

			return (
				<div className="FR AC JC" style={{ width: "100%" }}>
					<DLCheckbox
						// NOTE: use same test id with file row
						eleTestId={`checkbox-${aliasId}`}
						clickHandler={handleClickCheckbox}
						color="primary"
						isChecked={isSelected(id)}
					/>
				</div>
			)
		},
	},
	{
		header: "Ref. num", // "Ref. Num",
		accessorKey: "aliasId",
		size: 60,
		cell: (props: any) => {
			// NOTE: use same test id with file row
			const aliasId = props.row.original.aliasId
			const cellId = "fileRow-aliasId-of-" + aliasId
			const fileId = props.row.original.id
			return (
				<div
					data-testid={cellId}
					onClick={(e: any) => handleClickFile(e, fileId)}
				>
					{props.getValue()}
				</div>
			)
		},
	},
	{
		header: "Format", // "Format",
		accessorKey: "extension",
		width: 48,
		cell: (props: any) => {
			// NOTE: use same test id with file row
			const aliasId = props.row.original.aliasId
			const cellId = "fileRow-ext-of-" + aliasId
			const fileId = props.row.original.id

			return (
				<div
					data-testid={cellId}
					onClick={(e: any) => handleClickFile(e, fileId)}
				>
					{props.getValue()}
				</div>
			)
		},
	},
	{
		header: "Title",
		accessorKey: "title",
		cell: (props: any) => {
			// NOTE: use same test id with file row
			const aliasId = props.row.original.aliasId
			const cellId = "fileRow-name-of-" + aliasId

			const fileId = props.row.original.id
			return (
				<div
					data-testid={cellId}
					onClick={(e: any) => handleClickFile(e, fileId)}
				>
					{props.getValue()}
				</div>
			)
		},
	},
	{
		header: "Parent Folder",
		accessorKey: "parentFolderName",
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	// {
	// 	header: "Description",
	// 	accessorKey: "description"
	// },
	{
		header: "Assigned To",
		accessorKey: "assignedRoles",
		size: 60,
		cell: (props: any) => {
			// NOTE: use same test id with file row
			const aliasId = props.row.original.aliasId
			const roles = props.getValue()
			const testId = "assigned-roles-" + aliasId
			return (
				<div className="FR AC" data-testid={testId}>
					{roles.map((role: RoleModelProps, i: number) => {
						return (
							<div key={role.id}>
								<DLAvatar
									text={role.shortName}
									eleTestId={`mini-role-icon-${role.shortName}`}
									size={14}
									color={role.color}
									fontSize={10}
								/>
								{/* {role.shortName}
								{roles.length === i + 1 ? "" : ", "}{" "} */}
							</div>
						)
					})}
				</div>
			)
		},
	},
	{
		// @Noah: Must update this part to use sign off policy
		header: (
			<div className="FR AC JSA" style={{ width: "100%" }}>
				{signOffColumnsDisplay.id_prepare && (
					<span data-testid="prepare-column-header">Prepare</span>
				)}
				{signOffColumnsDisplay.id_review && (
					<span data-testid="review-column-header">Review</span>
				)}
				{signOffColumnsDisplay.id_epReview && (
					<span data-testid="epReview-column-header">EP review</span>
				)}
				{signOffColumnsDisplay.id_cpReview && (
					<span data-testid="cpReview-column-header">CP review</span>
				)}
				{signOffColumnsDisplay.id_qcReview && (
					<span data-testid="qcReview-column-header">QC review</span>
				)}
			</div>
		),
		size: 500, // signOffAreaWidth,
		accessorKey: "signOff-dummy-accessor",
		enableColumnFilter: false,
		enableSorting: false,
		cell: (props: any) => {
			const id = props.row.original.id
			const aliasId = props.row.original.aliasId
			const signOffLock = props.row.original.signOffLock
			const cellId = props.cell.column.id + "-cell"
			// ConsoleLog("row in table", row)

			return (
				<div
					className="FR AC JSB sign-off-area-in-table"
					style={{ width: "100%", overflow: "hidden" }}
					data-testid={cellId}
				>
					{/* {signOffArea({
						fileId: id,
						menuId,
						signOffPolicy,
						width: 500,
					})} */}
					<FileRowSignOffArea
						// isHovered={isHovered}
						// hoveredColumn={hoveredColumn}
						width={500}
						fileId={id}
						fileAliasId={aliasId}
						signOff={singleSignOff}
						getColumnSignOffs={getColumnSignOffs}
						cellType={cellType}
						dateFormat={dateFormat}
						signOffPolicy={signOffPolicy}
						signOffColumnsDisplay={signOffColumnsDisplay}
						permission={permission}
						isSignOffLocked={signOffLock}
					/>
				</div>
			)
		},
	},
	{
		header: "Comment",
		accessorKey: "comment-dummy-accessor",
		enableColumnFilter: false,
		size: 20,
		cell: (props: any) => {
			return (
				<FileRowComments
					fileId={props.row.original.id}
					fileAliasId={props.row.original.aliasId}
					setSelectedFile={setSelectedFile}
					menuId={props.row.original.menuId}
					reviewCommentStatus={props.row.original.reviewCommentStatus}
					qcCommentStatus={props.row.original.qcCommentStatus}
					handleOpenCommentListOfFile={handleOpenCommentListOfFile}
				/>
			)
		},
	},
	{
		header: "Status",
		accessorKey: "status-dummy-accessor",
		enableColumnFilter: false,
		size: 20,
		cell: (props: any) => {
			return (
				<StatusArea
					fileId={props.row.original.id}
					fileAliasId={props.row.original.aliasId}
					status={props.row.original.status}
					handleStatus={handleFileStatus}
					permission={permission}
				/>
			)
		},
	},
	{
		header: "Last modified by", //"Last modified by",
		accessorKey: "lastModifiedBy",
		cell: (props: any) => {
			const name = props.getValue().userName
			return <div data-testid="last-modified-by">{name}</div>
		},
	},
	{
		header: "Last modified at", //"Last modified at",
		accessorKey: "lastModifiedAt",
		cell: (props: any) => {
			return <div data-testid="last-modified-at">{props.getValue()}</div>
		},
	},
	{
		header: "Action",
		accessorKey: "x",
		enableColumnFilter: false,
		enableSorting: false,
		cell: (props: any) => {
			return (
				<DLIconButton
					eleTestId={`file-ctx-menu-btn-${props.row.original.aliasId}`}
					eleClassName="file-ctx-menu-btn"
					clickHandler={(e: any) =>
						handleCtxMenuButtonClick(e, props.row.original.id)
					}
				>
					<Icon path={mdiDotsHorizontal} size={0.8} />
				</DLIconButton>
			)
		},
	},
]

export default FileOnlyTableColumns
