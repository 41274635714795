import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

const AuditStepSignOffPrepared = (self: any) => ({
	auditStepSignOffPrepared(payload: any) {
		// 0.
		const actionName = "auditStepSignOffPrepared"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API
		self.mAuditStepSignoffPrepared(payload)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.status === 200) {
					//
					self.getAllAuditSteps(payload.WorkPaperId)
					//
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
						customMessage: response.data.message,
					})
				} else {
					// when fail
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						customMessage: response.data.message,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default AuditStepSignOffPrepared
