import React, { useState } from "react"
import { PageContainer } from "../../../components/app-frame-elements"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import {
	DLBasicToggleButton,
	DLRadioGroup,
	DLButton,
	ConsoleLog,
} from "../../../components/basic-elements"
import styled from "styled-components"
import {
	NotificationProps,
	NotificationType,
} from "./store/data-models/notification-settings.data-models"
import CommonSnackbarComponent from "../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { PermissionAsObjectProps } from "../../../common-models/permission"
import { DLI18nProps } from "../../../common-models/types/common-props"
import CommonNotification from "./CommonNotification"

export default observer(function NotificationSettings({
	partialStore,
	permission,
	contentsHeight,
	actionStatus,
	isLocked,
	i18n,
	timeZone,
	dateFormat,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
	contentsHeight: any
	actionStatus: ActionStatus
	isLocked: boolean
	i18n: DLI18nProps
	timeZone: string
	dateFormat: string
}) {
	//
	const [commentNoti, setCommentNoti] = useState<NotificationProps[]>(
		partialStore.commentNoti
	)
	const [expReportDateNoti, setExpReportDateNoti] =
		useState<NotificationProps>(partialStore.expReportDateNoti)
	const [expArchDeadlineDateNoti, setExpArchDeadlineDateNoti] =
		useState<NotificationProps>(partialStore.expArchDeadlineDateNoti)
	const [finalArchDeadlineDateNoti, setFinalArchDeadlineDateNoti] =
		useState<NotificationProps>(partialStore.finalArchDeadlineDateNoti)

	//
	const [changesArray, setChangesArray] = useState<any[]>([])

	//
	const handleChangeCommentNotification = (
		parentId: string,
		settingId: string
	) => {
		let temp = JSON.parse(JSON.stringify(commentNoti))
		const parentIndex = temp.findIndex(
			(noti: any) => noti.settingId === parentId
		)
		const childIndex = temp[parentIndex].subSettings.findIndex(
			(item: any) => item.settingId === settingId
		)
		temp[parentIndex].subSettings[childIndex].isActive =
			!temp[parentIndex].subSettings[childIndex].isActive
		setCommentNoti(temp)
		setChangedData(temp[parentIndex].subSettings[childIndex])
	}

	const handleChangeNotification = (
		notificationData: NotificationProps,
		notificationType: NotificationType
	) => {
		let temp = JSON.parse(JSON.stringify(notificationData))
		temp.isActive = !temp.isActive
		if (notificationType === NotificationType.expReportDate) {
			setExpReportDateNoti(temp)
		}
		if (notificationType === NotificationType.expArchDeadlineDate) {
			setExpArchDeadlineDateNoti(temp)
		}
		if (notificationType === NotificationType.finArchDeadLineDate) {
			setFinalArchDeadlineDateNoti(temp)
		}
		// setNotificationData(temp)
		setChangedData(temp)
	}

	const handleCommentRadio = (
		parentId: string,
		settingId: string,
		event: any,
		availableSettings: any
	) => {
		const temp = JSON.parse(JSON.stringify(commentNoti))
		const parentIndex = temp.findIndex(
			(noti: any) => noti.settingId === parentId
		)
		const childIndex = temp[parentIndex].subSettings.findIndex(
			(item: any) => item.settingId === settingId
		)
		const currentvalue = availableSettings.find(
			(item: any) => item.value === event.target.value
		)
		temp[parentIndex].subSettings[childIndex].parameterValue =
			event.target.value
		temp[parentIndex].subSettings[childIndex].parameterValueUI =
			currentvalue.parameterValueUI
		setCommentNoti(temp)
		setChangedData(temp[parentIndex].subSettings[childIndex])
	}

	const handleRadio = (
		notificationData: NotificationProps,
		settingId: string,
		event: any,
		availableSettings: any,
		notificationType: NotificationType
	) => {
		let temp = JSON.parse(JSON.stringify(notificationData))
		let childIndex = temp.subSettings.findIndex(
			(item: any) => item.settingId === settingId
		)
		const currentValue = availableSettings.find(
			(item: any) => item.value === event.target.value
		)
		temp.subSettings[childIndex].parameterValue = event.target.value
		temp.subSettings[childIndex].parameterValueUI =
			currentValue.parameterValueUI

		if (notificationType === NotificationType.expReportDate) {
			setExpReportDateNoti(temp)
		}
		if (notificationType === NotificationType.expArchDeadlineDate) {
			setExpArchDeadlineDateNoti(temp)
		}
		if (notificationType === NotificationType.finArchDeadLineDate) {
			setFinalArchDeadlineDateNoti(temp)
		}
		setChangedData(temp.subSettings[childIndex])
	}

	const setChangedData = (tempArr: any) => {
		let temp = [...changesArray]
		if (temp.some((x) => x.SettingId === tempArr.settingId)) {
			temp.splice(
				temp.findIndex(
					(item: any) => item.SettingId === tempArr.settingId
				),
				1
			)
		}
		temp.push({
			IsActive: tempArr.isActive,
			ParameterName: tempArr.parameterName,
			ParameterValue: tempArr.parameterValue,
			ParameterValueUI: tempArr.parameterValueUI,
			ParentId: tempArr.parentId,
			SettingId: tempArr.settingId,
		})
		setChangesArray(temp)
	}

	const saveNotificationSettings = () => {
		partialStore.editNotificationSettings(changesArray)
		setChangesArray([])
	}

	const handleStartDate = (
		notificationData: NotificationProps,
		settingId: string,
		event: any,
		availableSettings: any,
		notificationType: NotificationType
	) => {
		let temp = JSON.parse(JSON.stringify(notificationData))
		const childIndex = temp.subSettings.findIndex(
			(item: any) => item.settingId === settingId
		)
		const currentValue = availableSettings.find(
			(item: any) => item.value === event.target.value
		)
		temp.subSettings[childIndex].parameterValue = event.target.value
		temp.subSettings[childIndex].parameterValueUI =
			currentValue.parameterValueUI
		if (notificationType === NotificationType.expReportDate) {
			setExpReportDateNoti(temp)
		}
		if (notificationType === NotificationType.expArchDeadlineDate) {
			setExpArchDeadlineDateNoti(temp)
		}
		if (notificationType === NotificationType.finArchDeadLineDate) {
			setFinalArchDeadlineDateNoti(temp)
		}
		setChangedData(temp.subSettings[childIndex])
	}

	ConsoleLog([changesArray, "partialStore"])
	return (
		<PageContainer
			pageTools={<div />}
			isLoading={actionStatus === "LOADING"}
		>
			{actionStatus === "SUCCESS" && (
				<StyledNotificationSettings>
					<div className="FR" style={{ marginTop: "1rem" }}>
						<h4 className="notification-title main-header">
							Comment Notification
						</h4>
						<div className="notification-title">Time</div>
					</div>
					<hr />
					{commentNoti.map((noti: any) => {
						return (
							<div key={noti.settingId}>
								<div className="FR">
									<div className="sub-title">
										{noti.parameterName}
									</div>
									<div className="switch-button FC">
										{noti.subSettings.map(
											(setting: any, index: number) => {
												return (
													<div
														key={`toogle-${
															setting.settingId
														}-${index + 1}`}
													>
														<DLBasicToggleButton
															on={
																setting.isActive
															}
															setOn={() =>
																handleChangeCommentNotification(
																	setting.parentId,
																	setting.settingId
																)
															}
															colorWithOn
															tooltipText={
																!setting.isActive
																	? "Turn ON"
																	: "Turn OFF"
															}
															disabled={isLocked}
															eleTestId={`toogle-${
																noti.parameterValue
															}-${index + 1}`}
														/>
													</div>
												)
											}
										)}
									</div>
									<div>
										{noti.subSettings.map(
											(setting: any) => {
												return (
													<div
														key={setting.settingId}
													>
														<DLRadioGroup
															eleTestId={
																noti.parameterValue +
																"-" +
																setting.parameterValue
															}
															items={
																setting.availableSettings
															}
															selectedValue={
																setting.parameterValue
															}
															color="primary"
															size="small"
															itemDirection="row"
															eleOnChange={(
																event: any
															) =>
																handleCommentRadio(
																	setting.parentId,
																	setting.settingId,
																	event,
																	setting.availableSettings
																)
															}
															groupName="feature"
															disabled={
																!setting.isActive
															}
														/>
													</div>
												)
											}
										)}
									</div>
								</div>
							</div>
						)
					})}

					<CommonNotification
						projInfoDates={partialStore.viewProjInfoDates(
							timeZone,
							dateFormat
						)}
						notification={expReportDateNoti}
						handleChangeNotification={handleChangeNotification}
						handleRadio={handleRadio}
						handleStartDate={handleStartDate}
						isLocked={isLocked}
						notificationType={NotificationType.expReportDate}
					/>
					<CommonNotification
						projInfoDates={partialStore.viewProjInfoDates(
							timeZone,
							dateFormat
						)}
						notification={expArchDeadlineDateNoti}
						handleChangeNotification={handleChangeNotification}
						handleRadio={handleRadio}
						handleStartDate={handleStartDate}
						isLocked={isLocked}
						notificationType={NotificationType.expArchDeadlineDate}
					/>
					<CommonNotification
						projInfoDates={partialStore.viewProjInfoDates(
							timeZone,
							dateFormat
						)}
						notification={finalArchDeadlineDateNoti}
						handleChangeNotification={handleChangeNotification}
						handleRadio={handleRadio}
						handleStartDate={handleStartDate}
						isLocked={isLocked}
						notificationType={NotificationType.finArchDeadLineDate}
					/>
					<div style={{ marginTop: "2rem" }}>
						{permission.update && !isLocked && (
							<DLButton
								variant="contained"
								eleTestId="save-notification-btn"
								align="left"
								size="regular"
								color="primary"
								clickHandler={saveNotificationSettings}
								disabled={changesArray.length === 0}
							>
								Save
							</DLButton>
						)}
					</div>
				</StyledNotificationSettings>
			)}
			<CommonSnackbarComponent i18n={i18n} partialStore={partialStore} />
		</PageContainer>
	)
})

const StyledNotificationSettings = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.notification-title {
	}
	.dl-toggle-button {
		margin-bottom: 16px;
		margin-top: 2px;
	}
	.sub-title {
		flex-basis: 12em;
	}
	.switch-button {
		flex-basis: 5rem;
	}
	.main-header {
		flex-basis: 18rem;
	}
	.sub-header {
		flex-basis: 19rem;
	}
	.tooltip {
		margin-left: 30px;
		margin-top: 10px;
	}
`
