import { types, applySnapshot } from "mobx-state-tree"
import {
	OrgProjTemplateFlatItem,
	OrgProjTemplateItemType,
} from "./data-models/org-proj-templates.data-model"
import { initialStore } from "./org-proj-templates.provider"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"

import {
	compareValues,
	getTreeFromFlatData,
} from "../../../../../components/basic-elements/tree-list/getTreeFromFlatData"
import {
	GetOrgProjTemplateGroups,
	AddProjTemplateGroup,
	EditProjTemplateGroup,
	DuplicateProjTemplateGroup,
	RemoveProjTemplateGroup,
} from "./view-model-actions/template-group"
import {
	GetProjTemplateFirstFolders,
	AddProjTemplate,
	EditProjTemplate,
	RemoveProjTemplate,
} from "./view-model-actions/template"
import GetProjTemplateChildrenOfFolder from "./view-model-actions/file-folder/get-proj-template-children-of-folder"
import DuplicateProjTemplateFile from "./view-model-actions/file/duplicate-file"

import DuplicateProjTemplate from "./view-model-actions/template/duplicate-proj-template"
import DuplicateFolder from "./view-model-actions/file-folder/duplicate-file-folder"

import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ConsoleLog } from "../../../../../components/basic-elements"

const OrgTemplatesViewModel = types
	.model({
		storeName: DLOrgSubMenus.proj_templates,
		projTemplates: types.array(OrgProjTemplateFlatItem), // for tree-nav

		selectedTemplateGroup: "",
		selectedTemplate: OrgProjTemplateFlatItem,
		openAuditProgramDialog: false,
		// NOTE: flatList and folder, file realated actions and model
		// are in the "DLFileControlModel"
		// WARNING: Model is able to be shared.
		// but should not add actions under it together
		// if you do, system catch it as a store and it cannot be shared because it will be distingushed one store.

		// selectedFileFolder: "",
		// selectedFile: types.string,
		//
		// NOTE: flatList below is detail about one template.
		// NOTE: To re-use same functions with project-files, must use this 'flatList' as name
		// flatList: types.array(DLFileModel), // take one template's detail like in the project
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})

	.actions((self) => ({
		setSelectedTemplateGroup(id: string) {
			self.selectedTemplateGroup = id
		},
		reflectProjTemplateTitleUpdate(itemId: string, title: string) {
			const target = self.projTemplates.find((item) => item.id === itemId)
			if (target) {
				target.title = title
			} else {
				ConsoleLog(
					"(reflectProjTemplateTitleUpdate) Cannot find matched item"
				)
			}
		},
		reflectRemoveProjTemplate(itemId: string) {
			const targetIndex = self.projTemplates.findIndex(
				(item) => item.id === itemId
			)
			if (targetIndex !== -1) {
				self.projTemplates.splice(targetIndex, 1)
			} else {
				ConsoleLog(
					"(reflectRemoveProjTemplate) Cannot find matched item"
				)
			}
		},
		setProjTemplates(templates: any) {
			self.projTemplates.length = 0
			self.projTemplates = templates
		},
		pushProjTemplate(item: any) {
			self.projTemplates.push(item)
		},
		toggleExpandedTemplateGroup(itemId: string, expanded?: boolean) {
			const target = self.projTemplates.find(
				(item: any) => item.id === itemId
			)
			if (target) {
				target.expanded = expanded ? expanded : !target.expanded
			}
		},
		setSelectedTemplate(templateInfo: any) {
			self.selectedTemplate = templateInfo
		},
		resetSelectedTemplate() {
			self.selectedTemplate = {
				id: "",
				parentId: null,
				title: "",
				roleSetId: "",
				type: OrgProjTemplateItemType.template,
				expanded: false,
			}
		},
		setOpenAuditProgramDialog(request?: boolean) {
			self.openAuditProgramDialog =
				request ?? !self.openAuditProgramDialog
		},
	}))
	.views((self) => ({
		getTemplateCountOfGroup(groupId: string) {
			const targets = self.projTemplates.filter(
				(item) => item.parentId === groupId
			)
			return targets.length
		},
		getTemplateNameById(itemId: string) {
			const target = self.projTemplates.find(
				(item: any) => item.id === itemId
			)
			return target?.title
		},
		get templateCount() {
			const templates = self.projTemplates.filter(
				(item: any) =>
					item.type === OrgProjTemplateItemType.templateGroup
			)
			return templates.length
		},
		get firstTemplate() {
			const templateGroups = [...self.projTemplates]
			const firstTemplateGroup = templateGroups.sort(
				compareValues("title")
			)[0]
			const firstTemplateGroupChildren = templateGroups.filter(
				(item: any) => item.parentId === firstTemplateGroup.id
			)
			const sortedChildren = firstTemplateGroupChildren.sort(
				compareValues("title")
			)
			const firstTemplate = sortedChildren[0]
			// NOTE: TODO: What happens if the first template group has no template in it?
			return firstTemplate
		},
		// for tree-nav
		projTemplatesAsTreeWithQuery(query: string) {
			let list: any = []
			list = self.projTemplates.filter((item: any) =>
				item.title.toLowerCase().includes(query.toLowerCase())
			)
			list.map((item: any) => {
				if (
					list.findIndex(
						(listItem: any) => listItem.id === item.parentId
					) === -1
				) {
					const parent = self.projTemplates.find(
						(originItem: any) => originItem.id === item.parentId
					)
					if (parent) {
						self.toggleExpandedTemplateGroup(parent.id, true)
						list.push(parent)
					}
					// else {
					// 	ConsoleLog(
					// 		"There is no parent even in the original list"
					// 	)
					// }
				}
				// TODO: need to consider better way
			})
			// TODO: Need to check if there are other required process
			const tree = getTreeFromFlatData(list, "title")
			// ConsoleLog("tree for templates", tree)
			return tree
		},

		get templateListToCreateProject() {
			const templates = self.projTemplates.filter(
				(item) => item.type === OrgProjTemplateItemType.template
			)
			let newTemplateList: {
				id: string
				parentId: string
				title: string
				parentName: string
			}[] = []
			templates.map((template) => {
				const parentName = self.projTemplates.find(
					(item) => item.id === template.parentId
				)?.title
				newTemplateList.push({
					...template,
					parentId: template.parentId
						? template.parentId
						: "(Unknown Folder)",
					parentName: parentName ? parentName : "(Unknown Folder)",
				})
			})
			return newTemplateList
		},
		get getTemplateGroupOnly() {
			const templateGroup = self.projTemplates.filter(
				(item) => item.type === OrgProjTemplateItemType.templateGroup
			)
			return templateGroup
		},
		getChildrenInTheFlatList(parentId: string) {
			const list = self.projTemplates.filter(
				(item) => item.parentId === parentId
			)
			const sortedList = list.sort(compareValues("title"))
			return sortedList
		},
	}))
	// ---------

	/**
	 *  * separated actions hierarchy
	 * - Template Group > Template > File Folder > File
	 * ---- Template Group
	 * ---- Template
	 * ---- File Folder (in Template)
	 * ---- File (in Template)
	 */
	//
	//
	// 1. Template Group related
	.actions(GetOrgProjTemplateGroups)
	.actions(AddProjTemplateGroup)
	.actions(EditProjTemplateGroup)
	.actions(DuplicateProjTemplateGroup)
	.actions(RemoveProjTemplateGroup)
	//
	//
	// 2. Template related
	.actions(GetProjTemplateFirstFolders)
	.actions(AddProjTemplate)
	.actions(EditProjTemplate)
	.actions(RemoveProjTemplate)
	.actions(DuplicateProjTemplate)
	//
	//
	// 3. File Folder related
	.actions(GetProjTemplateChildrenOfFolder)
	.actions(DuplicateFolder)
	//
	//
	// 4. File related
	.actions(DuplicateProjTemplateFile)
	//
	//
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgTemplatesViewModel
