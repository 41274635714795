import React, { useState } from "react"
import styled from "styled-components"

import RoleDetailControl from "./RoleDetailControl"
import RoleDetailRow from "./RoleDetailRow"
import { observer } from "mobx-react-lite"

/**
 * -------- 2-2-2. PannelContents
 * ------------- 2-2-1-1. ContentsControl
 * ------------- 2-2-1-2. Contents
 */
export default observer(function RoleDetailPanelContents({
	data,
}: {
	data: any
}) {
	const [query, setQuery] = useState<undefined | string>()
	const handleQuery = (event: any) => {
		setQuery(event.target.value)
	}

	const [on, setOn] = useState(true)
	const handleOn = () => {
		setOn(!on)
	}

	return (
		<>
			{/* ----- 2-2-1-1. ContentsControl ----- */}
			<RoleDetailControl
				query={query}
				handleQuery={handleQuery}
				on={on}
				handleOn={handleOn}
			/>
			{/* ----- 2-2-1-1. Contents ----- */}
			<StyledRoleDetailContents className="role-permission-detail">
				{data.menuPermissions &&
					data.menuPermissions.map((menu: any) => {
						return (
							<div key={menu.menuId}>
								<RoleDetailRow menu={menu} query={query} />
							</div>
						)
					})}
			</StyledRoleDetailContents>
		</>
	)
})

const StyledRoleDetailContents = styled.div`
	height: calc(
		100vh - (${(props) => props.theme.shared.baseUnit} * 4)
	); // TODO: How to get the proper height
	overflow-y: scroll;
	padding-bottom: ${(props) => props.theme.shared.baseUnit};
	/* border: 1px solid green; */
`
