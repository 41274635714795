import React, { useState } from "react"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ProjNoPermissionOnMenu from "../proj-access-control/ProjNoPermissionOnMenu"
import styled from "styled-components"
import {
	InputWithLabel,
	DLSystemMsg,
	DLInputField,
	DLButton,
} from "../../../components/basic-elements"
import { MessageColorType } from "../../../common-models/enumerations/common-enums"
import { Icon } from "@mdi/react"
import { mdiTrashCan } from "@mdi/js"
import { useHistory } from "react-router-dom"
import { ProjI18n } from "../../../common-models/enumerations/translation-sheets"
import { useRootStore } from "../../../stores/root-store/root-store.provider"

export default observer(function ProjectDelete() {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.danger
	const i18nSheet = ProjI18n.danger

	// const isLocked = projStore.projInfo.projInfo.isLocked
	// const isArchived = projStore.projInfo.projInfo.isArchived

	const projectId = projStore.projInfo.projInfo.projectId
	const cabinetId = projStore.projInfo.projInfo.cabinetId

	const menuAccess = projStore.checkin.checkAccess(menuId)
	// const permission = projStore.checkin.getActionsAsObject(
	// 	menuId,
	// 	isLocked,
	// 	isArchived
	// )
	let history = useHistory()

	const [email, setEmail] = useState("")

	const deleteProject = () => {
		projStore.trash.removeProject(
			projectId,
			cabinetId,
			history.replace("/organization/checkin")
		)
	}

	const userEmail = localStorage.getItem("lobbyUserId")
	const i18n = store.i18n.combineI18n(i18nSheet)
	return (
		<>
			{menuAccess ? (
				<StyledProjectDelete>
					<div className="alert-container">
						<h1>{i18n.twAlert || "Alert"}</h1>
						<p>
							Are you sure you want to delete the project? Deleted
							project cannot be retrieved back.
						</p>
					</div>
					<div className="FR JC delete-form">
						<div className="input-section FR">
							<InputWithLabel label={i18n.twEmail}>
								<DLInputField
									autoFocus
									eleTestId="delete_email_input"
									eleFullWidth
									eleName="email"
									eleValue={email}
									eleHandleChange={(e: any) =>
										setEmail(e.target.value)
									}
								/>
								{email !== "" && userEmail !== email && (
									<DLSystemMsg
										eleTestId="msg-warning-email-match"
										type={MessageColorType.red}
										msg={
											i18n.twEmailDoesNotMatch ||
											"Email does not match"
										}
									/>
								)}
							</InputWithLabel>
						</div>
						<DLButton
							variant="contained"
							eleTestId="delete-btn"
							startIcon={<Icon path={mdiTrashCan} size={0.8} />}
							clickHandler={deleteProject}
							color="warning"
							disabled={userEmail !== email}
						>
							{i18n.twDelete}
						</DLButton>
					</div>
				</StyledProjectDelete>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

const StyledProjectDelete = styled.div`
	padding: 1rem;
	.alert-container {
		border: 1px solid red;
		border-radius: 6px;
		padding: 2rem;
		background-color: #ffd3d3;
		text-align: center;
	}
	.delete-form {
		padding: 2rem;
		.input-section {
			margin-bottom: 1.5rem;
			.label {
				min-width: 3rem;
			}
			.input-area {
				min-width: 20rem;
			}
		}
	}
`
