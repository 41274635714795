import { types } from "mobx-state-tree"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../common-models/types/dialog.props"
import {
	DialogRelatedActions,
	TreeNavRelatedActions,
	GroupRelatedViews,
} from "./ui-helper-actions"
import { CreateProjectMethod } from "../../../../components/combined-elements/create-project/create-project.data-props"
import {
	GroupsCurrentPathActions,
	GroupsCurrentPathViews,
} from "./ui-helper-actions/groups-current-path-actions"
import {
	GroupsPathForSelectionActions,
	GroupsPathForSelectionViews,
} from "./ui-helper-actions/groups-path-for-selection-actions"

const navOpenWidth = 320
const navClosedWidth = 48

export enum GroupDetailTab {
	users = "USERS",
	clients = "CLIENTS",
}

const OrgGroupsUiHelper = types
	.model({
		navWidth: navOpenWidth,
		navOpen: true,
		//
		currentPath: types.array(types.string),
		pathForSelection: types.array(types.string),
		//
		rightClickTarget: "", // clicked item ID
		clickPoint: ClickPoint,
		//
		selectedProj: "",
		selectedItems: types.array(types.string),
		selectedProjType: "",
		//
		addGroupDialogOpen: false,
		openMoveDialog: false,
		//
		groupDetailDialogOpenStatus: false,
		currentDetailTab: types.enumeration<GroupDetailTab>(
			Object.values(GroupDetailTab)
		),
		//
		openCreateProjDialog: false,
		openEditProjDialog: false,
		openDeleteProjDialog: false,
		createProjectMethod: types.enumeration<CreateProjectMethod>(
			Object.values(CreateProjectMethod)
		),
		//
		openCreateCabinetDialog: false,
		openEditCabinetDialog: false,
		//
		query: "",
		canDrag: false,
	})
	.actions(GroupsCurrentPathActions)
	.views(GroupsCurrentPathViews)
	.actions(GroupsPathForSelectionActions)
	.views(GroupsPathForSelectionViews)
	.views((self) => ({
		get getLastItemOfPath() {
			const pathLength = self.currentPath.length
			const lastItem = self.currentPath[pathLength - 1]
			return lastItem
		},
	}))
	.actions((self) => ({
		setCurrentDetailTab(tab: GroupDetailTab) {
			self.currentDetailTab = tab
		},
		setRightClickTarget(target: string) {
			self.rightClickTarget = target
		},
		setClickPoint(clickPoint: ClickPointProps) {
			// ConsoleLog("set Clickpoint")
			self.clickPoint = clickPoint
		},
		setSelectedProj(projId: string) {
			self.selectedProj = projId
		},
		setSelectedProjType(type: string) {
			self.selectedProjType = type
		},
		setGroupDetailDialogOpenStatus(request?: boolean) {
			self.groupDetailDialogOpenStatus =
				request ?? !self.groupDetailDialogOpenStatus
		},
		//
		setSelectedItem(id: string) {
			self.selectedItems.length = 0
			self.selectedItems.push(id)
		},
		pushSelectedItem(id: string) {
			if (!self.selectedItems.includes(id)) {
				self.selectedItems.push(id)
			}
		},
		pushOrSpliceItem(id: string) {
			// for checkbox
			const targetIndex = self.selectedItems.indexOf(id)

			if (targetIndex !== -1) {
				self.selectedItems.splice(targetIndex, 1)
			} else {
				self.selectedItems.push(id)
			}
		},
		spliceSelectedItem(id: string) {
			const targetIndex = self.selectedItems.findIndex(
				(item) => item === id
			)
			self.selectedItems.splice(targetIndex, 1)
		},
		resetSelectedItems() {
			self.selectedItems.length = 0
		},
		resetCurrentPath() {
			self.currentPath.length = 0
		},
	}))
	.views((self) => ({
		get viewNavWidth() {
			return self.navWidth
		},
		get viewQuery() {
			return self.query
		},
	}))
	.actions(DialogRelatedActions)
	.actions((self) =>
		TreeNavRelatedActions(self, navOpenWidth, navClosedWidth)
	)
	.views(GroupRelatedViews)

export default OrgGroupsUiHelper
