import React from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLButton,
	DLDialogHeader,
} from "../../../../../components/basic-elements"
import { mdiTrashCan } from "@mdi/js"
import Icon from "@mdi/react"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
} from "../../../../../components/basic-elements/icons/common-icons"

const DeleteRiskAssessmentDialog = observer(
	({ i18n }: { i18n: DLI18nProps }) => {
		// default setting
		const orgStore = useOrgStore()
		const partialStore = orgStore.setupRiskAssessment
		const actionName = "removeRiskAssessment"
		const { tsDeleteMsg, twDeleteTitle, twDelete, twCancel } = i18n
		//
		const deleteRiskAssessment = () => {
			const riskAssessmentId = partialStore.selectedRiskAssessment
			partialStore.removeRiskAssessment(riskAssessmentId)
		}

		const riskAssessmentInfo = partialStore.getRiskAssessmentById(
			partialStore.selectedRiskAssessment
		)

		return (
			<DLDialog
				eleTestId="delete-ra-dialog"
				isOpen={partialStore.removeRiskAssessmentDialogOpen}
				setIsOpen={partialStore.setRemoveRiskAssessmentDialogOpen}
				showCloseBtn={true}
				showOpenBtn={false}
				dialogTitle={
					<DLDialogHeader
						icon={
							<DLIcon
								name={DLIconName.delete}
								size={CommonIconSize.dialogHeaderIcon}
							/>
						}
						dialogName={twDeleteTitle}
						targetName={
							riskAssessmentInfo
								? riskAssessmentInfo?.riskAssessmentName
								: "(Unknown RA)"
						}
					/>
				}
				dialogContents={<span>{tsDeleteMsg}</span>}
				actionReady={true}
				actionBtn={
					<DLButton
						variant="text"
						eleTestId="delete-ra-btn-on-dialog"
						startIcon={<Icon path={mdiTrashCan} size={0.8} />}
						clickHandler={deleteRiskAssessment}
						color="warning"
					>
						{twDelete}
					</DLButton>
				}
				cancelBtnText={twCancel}
				showSpinner={
					partialStore.getActionStatus(actionName) === "LOADING"
				}
				cannotUpdate={
					partialStore.getActionStatus(actionName) === "LOADING"
				}
			/>
		)
	}
)

export default DeleteRiskAssessmentDialog
