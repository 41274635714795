import { types } from "mobx-state-tree"
// model & view-model
import OrgProjectsModel from "./orgProjects.model"
import OrgProjectsViewModel from "./orgProjects.view-model"
import OrgProjectsUiHelper from "./orgProjects.ui-helper"
// common models
import * as api from "./orgProjects.apis"
import * as orgGroupApis from "../../../service-modules/group-module/org-group-shared-apis"
import * as orgProjApis from "../../../service-modules/project-module/org-project-shared-apis"
import { initialClickPoint } from "../../../common-models/types/dialog.props"
import { OrgProjectApiProps } from "./orgProjects.apis"
import { CreateProjectMethod } from "../../../components/combined-elements/create-project/create-project.data-props"
import { readArchPolicyPeriodList } from "../../../screens/organization-side/org-setup/org-setup-arch-policy-periods/arch-policy-period.apis"
import { initialProjectDetail } from "../../../service-modules/project-module/data-model/project-detail.data-model"
import { initialProjUsers } from "../../../screens/organization-side/archive-management/data-models/proj-users-initial-store"
import { InitialPageSetting } from "../../../components/combined-elements/projects-table/proj-page-setting.data-model"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ProjectStatus } from "../../../common-models/enumerations/project-related-enums"
import { initialRFSourceProjInfo } from "../../../service-modules/project-module/data-model/project-module-data-models"
import { DefaultPreConditionData } from "../../../screens/project-side/archive/archive-pre-process/store/data-models/archive-pre-process.data-model"

export const OrgProjectsStore = (orgProjApis: OrgProjectApiProps) =>
	types
		.compose(
			OrgProjectsModel(orgProjApis),
			OrgProjectsViewModel,
			OrgProjectsUiHelper
		)
		.named("OrgProjectsStore")

export const initialStore = {
	clickPoint: initialClickPoint,
	projectDetails: initialProjectDetail,
	projectUsers: initialProjUsers,
	projInfo: {
		statusName: ProjectStatus.unknown,
		createMethod: CreateProjectMethod.byUndefined,
		hasExportAccess: false,
	},
	tableColumnsPageSetting: InitialPageSetting(DLOrgSubMenus.normal_projs),
	preConditionData: DefaultPreConditionData,
	// ui-helper
	createProjectMethod: CreateProjectMethod.byUndefined,
	rfSourceProjInfo: initialRFSourceProjInfo,
	// viewModel related parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgProjectsStoreInstance = OrgProjectsStore({
	apiRead: api.read,
	apiCreateProject: api.createProject,
	apiReadArchPolicyPeriodList: readArchPolicyPeriodList,
	apiReadRfProjects: api.readRfProjects,
	apiReadUsers: orgGroupApis.readGroupUsers,
	apiReadClients: orgGroupApis.readClientsByGroup,
	apiDeleteProject: orgProjApis.deleteProject,
	apiReadPeriodNames: api.readPeriodNames,
	apiReadProjectUsersAndFileCount: orgProjApis.readProjectUsers,
	apiReadProjectDetails: orgProjApis.readProjectDetails,
	apiReadProjInfo: api.readProjInfo,
	apiUpdateProjInfo: api.updateProjInfo,
	apiReadEngType: api.readEngType,
	apiReadRiskAssessment: api.readRiskAssessment,
	apiCreateHardCopyOnlyProject: api.createHardCopyOnlyProject,
	apiCheckPreConditionStatus: api.preConditionStatus,
	apiReadCabinetLocation: api.readCabinetLocation,
	apiForceArchive: api.forceArchive,
}).create(initialStore)

export default OrgProjectsStoreInstance
