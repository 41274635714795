import React, { useEffect, useLayoutEffect } from "react"
import styled from "styled-components"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import Icon from "@mdi/react"
import { mdiFolder, mdiDotsHorizontal } from "@mdi/js"
import {
	DLButton,
	DLIconButton,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { DLTooltip, DLCheckbox } from "@datalobby/components"
import { getProjectIcon } from "../../../../../components/basic-elements/icons/common-project-icons"
import { Link } from "react-router-dom"
import {
	StarIcon,
	StarOutlineIcon,
	DLIcon,
	DLIconName,
} from "../../../../../components/basic-elements/icons/common-icons"
import { DLObjectFormat } from "../../../../../common-models/enumerations/common-enums"
import {
	CreateProjectMethod,
	initialCreateProjectInputs,
} from "../../../../../components/combined-elements/create-project/create-project.data-props"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { getSnapshot } from "mobx-state-tree"
import { formattedDateWithTz2 } from "../../../../../library/converters/date-utc-converter"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function GroupDetailAreaItemRenderer({
	item,
}: {
	item: any
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const { id, title, type, detail, createdByName, createdAt } = item
	console.log(item, "item")
	const projNumId = idToNumber(id, IdType.project)

	const highlightedProjectId =
		localStorage.getItem("highlightedProjectId") || ""

	// ConsoleLog("current item", item)
	const handleClick = () => {
		orgStore.groups.toggleExpanded(id, true)
		orgStore.groups.setRightClickTarget("")
		if (type === DLObjectFormat.cabinet) {
			orgStore.groups.getOrgCabinetDetail(id)
			orgStore.groups.pushItemToCurrentPath(id)
		} else if (type === DLObjectFormat.project) {
			orgStore.groups.pushItemToCurrentPath(id)
		}
	}

	useEffect(() => {
		if (type === DLObjectFormat.project && highlightedProjectId) {
			setTimeout(() => {
				// const projectInfo = orgStore.groups.getTargetDetail(
				// 	highlightedProjectId
				// )
				// checkbox-projId1129
				// const element = document.querySelector(
				// 	`[data-testid="group-item-${projectInfo?.title
				// 		.toLowerCase()
				// 		.replace(/ /g, "-")}"]`
				// ) as HTMLElement
				const element = document.querySelector(
					`[data-testid="checkbox-${highlightedProjectId}"]`
				) as HTMLElement
				const elementRect = element.getBoundingClientRect()
				const isVisible =
					elementRect.top >= 0 &&
					elementRect.bottom <= window.innerHeight
				if (!isVisible) {
					element.scrollIntoView({
						behavior: "smooth",
					})
				}
				localStorage.removeItem("highlightedProjectId")
			}, 500)
		}

		return () => {
			orgStore.groups.resetSelectedItems()
		}
	}, [orgStore.groups.getLastItemOfPath])

	console.log(orgStore.groups.getLastItemOfPath)

	const handleCabinetRightClick = (e: any, id: string) => {
		// ConsoleLog("right click on GroupDetailAreaItemRenderer")
		e.preventDefault()
		const path = orgStore.groups.getItemPath(id)
		orgStore.groups.setPathForSelection(path)
		// set mouse point
		orgStore.groups.setClickPoint({
			mouseX: e.clientX - 2,
			mouseY: e.clientY - 4,
		})
		const selected = orgStore.groups.selectedItems[0]
		if (selected) {
			if (!selected.includes("cabinet")) {
				orgStore.groups.resetSelectedItems()
			}
		}
		orgStore.groups.pushSelectedItem(id)
		orgStore.groups.setRightClickTarget(id)
	}
	//
	/**
	 * NOTE: When you update the below part, please check similar things together
	 * - ProjectInfoHeader (Project Detail) right click
	 * - TreeNav GroupItemRow
	 * - DetailAreaItemRow -Project
	 */
	const handleProjRightClick = (e: any) => {
		// ConsoleLog("right click on project row in tree")
		e.preventDefault()
		// set mouse point
		orgStore.groups.setClickPoint({
			mouseX: e.clientX - 2,
			mouseY: e.clientY - 4,
		})
		const selected = orgStore.groups.selectedItems[0]
		if (selected) {
			if (!selected.includes("proj")) {
				orgStore.groups.resetSelectedItems()
			}
		}
		orgStore.groups.pushSelectedItem(id)
		orgStore.groups.setRightClickTarget(id)
		//
		const currentPath = orgStore.groups.viewCurrentPath
		ConsoleLog("currentPath " + currentPath)
		orgStore.groups.setPathForSelection(currentPath)
		//
		orgStore.groups.setCreateProjectMethod(
			CreateProjectMethod.byRollForward
		)
		//
		// WARNING: When update this project preset for copy, need to update other parts altogether
		// WARNING: 5 parts requires same preset
		// WARNING: Group DetailArea Project Item, Group ProjectInfoHeader, Group treeNav Proj Row, NormalProjectsTablsController, Replica Projects Table Controller
		const projectInfo = {
			...initialCreateProjectInputs,

			cabinetId: item.parentId,
			cabinetName: "loading...",
			title: item.title,
			periodName: item.detail.periodName,
			clientName: item.detail.clientName,
			engTypeName: item.detail.engTypeName,
			createMethod: CreateProjectMethod.byRollForward,
		}
		orgStore.projects.setCreateProjectPresetInputs(projectInfo)
		orgStore.projects.setRfSourceId(item.id)
	}

	const handleClickCheckbox = (type: string) => {
		const selected = orgStore.groups.selectedItems[0]
		if (selected) {
			if (!selected.includes(type)) {
				orgStore.groups.resetSelectedItems()
			}
		}
		orgStore.groups.pushOrSpliceItem(id)
	}

	const { timeZone, timeFormat, dateFormat } = store.global.getDntFormat

	function formatTime(date: string | null) {
		if (date) {
			return formattedDateWithTz2({
				date,
				timeZone,
				timeFormat,
				dateFormat,
			})
		}
		return { date: "", time: "" }
	}

	const { date, time } = formatTime(createdAt)

	const rightClickTarget = highlightedProjectId
		? highlightedProjectId
		: orgStore.groups.rightClickTarget
	const testId = title.toLowerCase().replace(/ /g, "-")
	const isSelected = (id: string) =>
		orgStore.groups.selectedItems.includes(id)
	return (
		<StyledGroupDetailItem
			data-testid={`group-item-${testId}`}
			className={`detail-item ${
				rightClickTarget === id && "right-click-target"
			}`}
		>
			{type === DLObjectFormat.group && (
				<div className="FR AC group" onClick={handleClick}>
					<DLIcon name={DLIconName.group} />
					{/* <Icon path={mdiAccountGroup} size={1} /> */}
					<span className="d">{title}</span>
				</div>
			)}

			{type === DLObjectFormat.cabinet && (
				<div className="FR AC">
					<DLCheckbox
						eleTestId={`checkbox-${id}`}
						clickHandler={() => handleClickCheckbox("cabinet")}
						color="primary"
						isChecked={isSelected(id)}
					/>
					<div
						className="FR AC cabinet"
						onContextMenu={(e: any) =>
							handleCabinetRightClick(e, id)
						}
					>
						<div
							className="FR AC cabinet-name"
							onClick={handleClick}
							style={{ flexBasis: "40%" }}
						>
							<Icon path={mdiFolder} size={1} />
							<span className="title-text">{title}</span>
						</div>
						<div style={{ flexBasis: "30%" }}>{createdByName}</div>
						<div style={{ flexBasis: "25%" }}>
							{date + " " + time}
						</div>
						<DLIconButton
							eleTestId="cabinet-ctx-menu-btn"
							clickHandler={(e: any) =>
								handleCabinetRightClick(e, id)
							}
						>
							<Icon path={mdiDotsHorizontal} size={0.7} />
						</DLIconButton>
					</div>
				</div>
			)}

			{type === DLObjectFormat.project && (
				<div
					className={`FR AC JSB project ${
						!detail.accessible && "cannot-access"
					}`}
					onContextMenu={handleProjRightClick}
					onDoubleClick={() => {
						orgStore.groups.setRightClickTarget(id)
					}}
				>
					<div className="FR AC">
						<DLCheckbox
							eleTestId={`checkbox-${id}`}
							clickHandler={() => handleClickCheckbox("proj")}
							color="primary"
							isChecked={isSelected(id)}
						/>
						{getProjectIcon(detail.projectStatus, 1)}
						<div
							className="title-text FR AC"
							onDoubleClick={handleClick}
							onClick={() => handleClickCheckbox("proj")}
						>
							<span className="year">
								({detail.financialYear})
							</span>
							<DLTooltip title={"Client: " + detail.clientName}>
								<span className="clientName">
									{detail.clientName.length < 16
										? detail.clientName
										: detail.clientName.slice(0, 16) +
										  "..."}
								</span>
							</DLTooltip>
							<span className="periodName">
								{detail.periodName}
							</span>
							<span className="proj-title">{title}</span>
							<DLTooltip title={"Project ID: " + detail.aliasId}>
								<span className="aliasId">
									{detail.aliasId.length < 16
										? detail.aliasId
										: detail.aliasId.slice(0, 16) + "..."}
								</span>
							</DLTooltip>
						</div>
					</div>
					<div className="FR AC">
						<DLButton
							eleTestId="view-project-info-btn"
							variant="text"
							size="thin"
							clickHandler={handleClick}
						>
							info
						</DLButton>
						<Link to={`/project/checkin/${projNumId}`}>
							<DLButton
								eleTestId="open-project-btn"
								size="thin"
								tooltipText={
									!detail.accessible
										? "Cannot open this project"
										: ""
								}
							>
								Open
							</DLButton>
						</Link>
						<DLIconButton
							eleTestId="project-ctx-menu-btn"
							clickHandler={handleProjRightClick}
						>
							<Icon path={mdiDotsHorizontal} size={0.7} />
						</DLIconButton>
					</div>
				</div>
			)}
		</StyledGroupDetailItem>
	)
})

const StyledGroupDetailItem = styled.div`
	cursor: pointer;
	&.detail-item {
		transition: backgrond-color 0.4s;
		:hover {
			background-color: ${(props) => props.theme.secondary};
		}
		margin: 0.2rem 0 0.2rem 0;
		.group {
		}
		.cabinet {
			border: 1px solid ${(props) => props.theme.shade20};
			border-radius: 3px;
			padding: 0.2rem;
			width: 100%;
			.cabinet-name {
				width: 100%;
				:hover {
					color: ${(props) => props.theme.emphasis};
				}
			}
		}
		.project {
			border-radius: 4px;
			padding: 0.2rem 0.3rem;
			&.cannot-access {
				opacity: 0.2;
			}
		}
		.title-text {
			margin-left: 0.6rem;
			max-height: 2rem;
			overflow: hidden;
			.year {
				margin-right: 0.6rem;
				max-width: 3rem;
			}
			.clientName {
				margin-right: 0.6rem;
				width: 8rem;
				min-width: 8rem;
			}
			.periodName {
				margin-right: 0.6rem;
				width: 4rem;
				min-width: 4rem;
				font-size: ${(props) => props.theme.shared.textMd};
			}
			.proj-title {
				font-weight: 700;
				margin-right: 1rem;
				min-width: 12rem;
				width: 12rem;
			}
			.aliasId {
			}
		}
	}
	&.right-click-target {
		background-color: ${(props) => props.theme.shade20};
	}
`
