import { flow } from "mobx-state-tree"
import { getRequest } from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"

const ReadAllAuditSteps = (self: any) => ({
	readAllAuditSteps: flow(function* (wprId: number) {
		const actionName = "readAllAuditSteps"
		try {
			const endpoint = `/GetAllAuditSteps?wprId=${wprId}`
			const response = yield getRequest({
				url: endpoint,
				// params: {},
				tokenType: TOKEN_TYPE.projToken,
			})

			return response
		} catch (error) {
			// ConsoleLog("readAllAuditSteps error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadAllAuditSteps
