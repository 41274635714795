import { types } from "mobx-state-tree"

const ArchivePreProcessUiHelper = types
	.model({
		// dialogs
		cancelPreProcessDialogOpen: false,
		startPreProcessDialogOpen: false,
		archiveProjectDialogOpen: false,
	})
	.actions((self) => ({
		// dialogs

		setCancelPreProcessDialogOpen(request?: boolean) {
			self.cancelPreProcessDialogOpen =
				request ?? !self.cancelPreProcessDialogOpen
		},
		setStartPreProcessDialogOpen(request?: boolean) {
			self.startPreProcessDialogOpen =
				request ?? !self.startPreProcessDialogOpen
		},
		setArchiveProjectDialogOpen(request?: boolean) {
			self.archiveProjectDialogOpen =
				request ?? !self.archiveProjectDialogOpen
		},
	}))

export default ArchivePreProcessUiHelper
