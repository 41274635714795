import { ActionStatus } from "../../../common-models/enumerations/common-enums"

const RemoveOrRestorePost = (self: any) => ({
	removeOrRestorePost({
		postId,
		menuId,
		toDelete,
		by,
		postAction,
	}: {
		postId: string
		menuId: string
		toDelete: boolean
		by: string
		postAction?: any
	}) {
		// 0.
		const actionName = "removeOrRestorePost"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		console.log(actionName, postId, menuId)
		// 3) request API call to Model
		self.deleteOrRestorePost({ postId, menuId, toDelete, by })
			.then((response: any) => {
				// @Noah 이걸 어떻게 지우지? global Noti 로 다 옮겨가야 하나?
				postAction && postAction()
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default RemoveOrRestorePost
