import React from "react"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	InputWithLabel,
	DLTextArea,
} from "../../../../components/basic-elements"
import useForm from "../../../../library/use-form"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export default observer(function OpenedFilesRequestAccessDialog({
	partialStore,
	RequestAccessDetails,
	menuId,
	i18n,
}: {
	partialStore: any
	RequestAccessDetails: any
	menuId: DLProjSubMenus
	i18n: DLI18nProps
}) {
	const addRequestNotification = partialStore.addRequestNotification

	const inputsSchema = {
		request: { value: "", error: "", requestInput: false },
	}
	const validationSchema = {
		request: {
			isRequired: true,
		},
	}

	const handleRequestAccess = () => {
		addRequestNotification(
			menuId,
			RequestAccessDetails,
			inputs.request.value
		)
	}

	const {
		inputs,
		handleOnChange,
		handleOnSubmit,
		isReady,
		// initializeInputs
	} = useForm(inputsSchema, validationSchema, handleRequestAccess)

	const requestAccessDialogOpen =
		partialStore.openedFilesRequestAccessDialogOpen
	const setOpenedFilesRequestAccessDialogOpen =
		partialStore.setOpenedFilesRequestAccessDialogOpen

	return (
		<DLDialog
			eleTestId="opened-files-dialog"
			isOpen={requestAccessDialogOpen}
			setIsOpen={setOpenedFilesRequestAccessDialogOpen}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle="Request Check-In"
			dialogContents={
				<RequestAccessForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={isReady}
			actionBtn={i18n.twSubmit || "Submit"}
			handleAction={handleOnSubmit}
			maxWidth="sm"
			fullWidth={true}
		/>
	)
})

const RequestAccessForm = observer(
	({
		inputs,
		handleOnChange,
		i18n,
	}: {
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
	}) => {
		return (
			<div className="input-section FR">
				<InputWithLabel label={i18n.tsRequestDesc} required>
					<DLTextArea
						eleTestId="input-description-text"
						eleValue={inputs.request.value}
						eleHandleChange={handleOnChange}
						eleName="request"
						eleClassName="request-description-input-area"
						eleFieldHeight="5"
					/>
				</InputWithLabel>
			</div>
		)
	}
)
