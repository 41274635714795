import { types } from "mobx-state-tree"

export const AuditSteps = types.model({
	auditProgramId: types.number,
	wpId: types.number,
	apgWPRef: types.string,
	displayOrder: types.number,
	note: types.string,
	auditSteps: types.string,
	assertions: types.string,
	legend: types.string,
	preparerUserId: types.union(types.number, types.null),
	preparer: types.union(types.string, types.null, types.undefined),
	reviewerUserId: types.union(types.number, types.null),
	reviewer: types.union(types.string, types.null, types.undefined),
	prepared: types.union(types.string, types.null, types.undefined),
	reviewed: types.union(types.string, types.null, types.undefined),
	preparedUserId: types.union(types.number, types.null, types.undefined),
	reviewedUserId: types.union(types.number, types.null, types.undefined),
	preparedAtUtc: types.union(types.string, types.null, types.undefined),
	reviewedAtUtc: types.union(types.string, types.null, types.undefined),
	reviewerDueDate: types.union(types.string, types.null, types.undefined),
	preparerDueDate: types.union(types.string, types.null, types.undefined),
})

export const AssignableUsers = types.model({
	UserId: types.number,
	RoleId: types.number,
	UserName: types.string,
	Name: types.string,
	UserRole: types.string,
})

// export const AssignableWPRef = types.model({
// 	UserId: types.string,
// 	RoleId: types.string,
// 	UserName: types.string,
// 	Name: types.string,
// 	UserRole: types.string,
// })
