import {
	ActionStatus,
	MessageColorType,
	DLObjectFormat,
} from "../../../../common-models/enumerations/common-enums"
import { IdType, idToString } from "../../../../library/converters/id-converter"
import { initialDLFileAsFolder } from "../../data-models/initial-file"
import { reorganizeFile } from "../file/util-reorganize-file"
import { sortFoldersNFiles } from "../../../../library/file-utils/file-utils"

const GetChildrenOfFolder = (self: any) => ({
	getChildrenOfFolder(folderId: string, postAction?: any) {
		/**
		 *  WARNING: Do not use object for parameter or update projTemplate together.
		 * This part must take same structure with project template
		 */

		// 0.
		const actionName = "getChildrenOfFolder"
		const actionNameForUiDetail = "getChildrenOfFolder" + folderId
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		self.responses.setResponse(actionNameForUiDetail, {
			actionName: actionNameForUiDetail,
			status: ActionStatus.loading,
		})
		// 3.
		self.readChildrenOfFolder(folderId, self.storeName)
			.then((response: any) => {
				// NOTE: This API returns an array not an object
				// NOTE: and those individual item has Status
				// NOTE: So, we CANNOT USE the response.data.Status here.
				// NOTE: DO NOT ADD response.data.Status as condition
				if (response.status === 200) {
					// console.log(actionName+ "__response " + response)
					response.data.map((item: any) => {
						// 'Child' means folders
						// 'Fileslist' means files
						const fetchedSubFolders = item.Child
						const fetchedFiles = item.fileslist
						//
						let children: any[] = []
						// 1) folders
						if (fetchedSubFolders.length > 0) {
							// let reOrganizedSubFolders: any[] = []
							fetchedSubFolders.map(
								(folder: any, index: number) => {
									const subFolderId = idToString(
										folder.id,
										IdType.folder
									)
									// ConsoleLog(" ____ subFolderId", subFolderId)
									const reOrganizedSubFolder = {
										...initialDLFileAsFolder,
										menuId: self.storeName,
										id: subFolderId,
										title: folder.title,
										objectFormat: DLObjectFormat.folder,
										parentId: folderId,
										expanded: false,
										index,
									}

									children.push(reOrganizedSubFolder)
								}
							)
						}
						// 2) files
						if (fetchedFiles.length > 0) {
							fetchedFiles.map(
								(fetchedFile: any, index: number) => {
									children.push(
										reorganizeFile({
											fetchedFile,
											index,
											menuId: self.storeName,
											expanded: false,
											folderId,
										})
									)
								}
							)
							// ConsoleLog("newItems...", newItems)
							// self.concatItems(newItems)
						}
						// const newList = self.flatList.concat(children)
						if (
							fetchedSubFolders.length === 0 &&
							fetchedFiles.length === 0
						) {
							self.resetPartialFlatList(folderId)
						} else {
							children = sortFoldersNFiles(children)
							self.updateFlatList(children)
						}
					})

					postAction && postAction()

					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to " + actionName + "in " + self.storeName,
						open: false,
						// autoHide: true,
					})
					self.responses.setResponse(actionNameForUiDetail, {
						actionName: actionNameForUiDetail,
						status: ActionStatus.success,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage:
						// 	"Something Wrong... (UPDATE IS REQUIRED)",
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetChildrenOfFolder
