import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

const AddAuditStep = (self: any) => ({
	addAuditStep(payload: any, postSuccess?: () => void) {
		// 0.
		const actionName = "addAuditStep"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API
		self.createAuditStep(payload)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 200) {
					// Empty the project user list on the model
					const data = response.data.Data
					const item = {
						auditProgramId: data.AuditProgramId,
						wpId: data.WorkPaperId,
						apgWPRef: data.AuditProgramWorkPaperReference,
						displayOrder: data.DisplayOrder,
						note: data.Note,
						auditSteps: data.AuditSteps,
						assertions: data.Assertions,
						legend: data.Legend,
						prepared: data.Prepared,
						reviewed: data.Reviewed,
						preparer: data.Preparer,
						reviewer: data.Reviewer,
						preparerUserId: data.PreparerUserId,
						reviewerUserId: data.ReviewerUserId,
						preparedUserId: data.PreparedUserId,
						reviewedUserId: data.ReviewedUserId,
						preparedAtUtc: data.PreparedAtUtc,
						reviewedAtUtc: data.ReviewedAtUtc,
						reviewerDueDate: data.ReviewerDueDate,
						preparerDueDate: data.PreparerDueDate,
					}

					console.log(item, "data")
					self.pushItemToAuditSteps(item)

					postSuccess && postSuccess()
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
						customMessage: "Successfully created audit steps",
					})
				} else {
					// when fail
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						customMessage: "Failed to create audit steps",
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default AddAuditStep
