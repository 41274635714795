import { DialogActionType } from "../../../../../../common-models/enumerations/common-enums"

const CommentDialogActions = (self: any) => ({
	// setDetailDialogOpenStatusWithObjectId({
	// 	fileId,
	// 	openStatus,
	// }: {
	// 	fileId: string
	// 	openStatus?: boolean
	// }) {
	// 	if (self.selectedItems.length !== 1) {
	// 		self.setSelectedItem(fileId, false)
	// 	}
	// 	const aliasId = self.flatList.find((item: any) => item.id === fileId)
	// 		.aliasId

	// 	//
	// 	if (openStatus !== undefined) {
	// 		self.commentDetailDialogOpenStatus = openStatus
	// 	} else {
	// 		self.commentDetailDialogOpenStatus = !self.commentDetailDialogOpenStatus
	// 	}
	// },
	setDetailDialogMode(purpose: DialogActionType) {
		self.detailDialogMode = purpose
	},
	setDetailDialogOpenStatus(openStatus?: boolean) {
		self.detailDialogOpenStatus = openStatus ?? !self.detailDialogOpenStatus
	},
	setListDialogOpenStatus(openStatus?: boolean) {
		if (openStatus !== undefined) {
			self.listDialogOpenStatus = openStatus
			if (openStatus === false) {
				self.resetCommentsOfCurrentObject()
			}
		} else {
			self.listDialogOpenStatus = !self.listDialogOpenStatus
		}
	},
	setSelectedComment(commentId: string) {
		self.selectedComment = commentId
	},
})

export default CommentDialogActions
