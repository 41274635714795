import { types } from "mobx-state-tree"

const ArchiveDiagnosisUiHelper = types
	.model({
		openDrawer: false,
		firstCalled: true,
		selectedConditionId: "",
		// dialogs
		rationaleDialogOpen: false,
		openArchConditionHelpDialog: false,

		openArchivePolicyDetailDialog: false,
	})
	.actions((self) => ({
		setOpenDrawer(request?: boolean) {
			self.openDrawer = request ?? !self.openDrawer
		},
		setFirstCalled(request: boolean) {
			self.firstCalled = request
		},
		setSelectedConditionId(conditionId: string) {
			self.selectedConditionId = conditionId
		},
		// dialogs
		setRationaleDialogOpen(request?: boolean) {
			self.rationaleDialogOpen = request ?? !self.rationaleDialogOpen
		},

		setOpenArchConditionHelpDialog(targetId: string, request: boolean) {
			self.openArchConditionHelpDialog = request
			self.selectedConditionId = request === true ? targetId : ""
		},
		setOpenArchivePolicyDetailDialog(request?: boolean) {
			self.openArchivePolicyDetailDialog =
				request ?? !self.openArchivePolicyDetailDialog
		},
	}))
	.views((self) => ({
		get drawerOpenStatus() {
			return self.openDrawer
		},
	}))

export default ArchiveDiagnosisUiHelper
