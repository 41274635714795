import { types } from "mobx-state-tree"

const OrgMgmtUiHelper = types
	.model({
		selectedId: types.string,
		openAdd: types.boolean,
		openEdit: types.boolean,
	})
	.actions((self) => ({
		setSelectedId(id: string) {
			self.selectedId = id
		},
		setOpenAdd(request?: boolean) {
			self.openAdd = request ?? !self.openAdd
		},
		setOpenEdit(request?: boolean) {
			self.openEdit = request ?? !self.openEdit
		},
	}))
	.views((self) => ({}))

export default OrgMgmtUiHelper
