import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
//
import {
	CreateFile,
	UpdateFile,
	DeleteFile,
	RequestReplaceFile,
	RequestFileUpload,
	UpdateFileStatus,
	CreateFileFromLib,
	RequestFileOrFolderSearch,
	ReadAllFiles,
} from "../../../../../service-modules/file-module/model-actions/file"
import {
	CreateFolder,
	ReadFirstFolders,
	ReadChildrenOfFolder,
	UpdateFolder,
	DeleteFolder,
	RequestReorder,
	UpdateStructureLock,
} from "../../../../../service-modules/file-module/model-actions/file-folder"
import {
	RequestDownloadFile,
	RequestDownloadFolder,
	RequestDownloadAll,
} from "../../../../../service-modules/file-module/model-actions/download"
//
// NOTE: sign-off part will be shared from the signoff module
import {
	RequestSignOff,
	RequestUnSignOff,
} from "../../../../../service-modules/file-module/model-actions/sign-offs"
//
import RequestAssignRoleToFile from "../../../../../service-modules/file-module/model-actions/assign/request-assign-role-to-file"
//
import { ProjectFilesApiProps } from "../../proj-files-components/project-files-api-types"
import ReadTrialBalanceDetails from "../../../financial-statements/financial-statements/store/model-actions/read-trial-balance-details"
import ReadFinancialStatementType from "../../../financial-statements/fs-setup/store/model-actions/read-financial-statement-type"
import ReadFinalAnalysis from "./model-actions/read-final-analysis"
import UpdateFinalAnalysis from "./model-actions/update-final-analysis"
import ReadFileInfo from "../../../../../service-modules/file-module/model-actions/file/read-file-info"
import ReadFileHistory from "../../../../../service-modules/file-module/model-actions/file/read-file-history"
import UpdateSignOffLock from "../../../../../service-modules/file-module/model-actions/file/update-sign-off-lock"
import CreateFileFromTemp from "../../../../../service-modules/file-module/model-actions/file/create-file-from-temp"
import ReadFileLocation from "../../../../../service-modules/file-module/model-actions/file/read-file-location"

const WpModel = ({
	// default get
	apiReadFirstFolder,
	apiReadChildrenOfFolder,
	// file
	apiCreateFile,
	apiDeleteFile,
	apiUpdateFile,
	apiReplaceFile,
	apiReadAllFiles,
	//
	apiSearchFileOrFolder,
	// folder
	apiCreateFolder,
	apiUpdateFolder,
	apiDeleteFolder,
	apiReorderChildren,
	apiUpdateStructureLock,
	// download
	apiDownloadFile,
	apiDownloadFolder,
	apiDownloadAll,
	//
	apiAssignRole,
	//
	apiCreateFileFromLib,
	apiCreateFileFromTemp,
	apiReadTrialBalance,
	apiReadFS,
	apiReadFA,
	apiSaveFA,
	apiReadFileInfo,
	apiReadFileHistory,
	apiUpdateSignOffLock,
	apiReadFileLocation,
}: ProjectFilesApiProps) =>
	types
		.model({})
		// CRUD
		.actions((self) => ReadFirstFolders(self, apiReadFirstFolder))
		.actions((self) => ReadChildrenOfFolder(self, apiReadChildrenOfFolder))
		//
		//
		//
		//
		// file related parts
		.actions((self) => CreateFile(self, apiCreateFile))
		.actions((self) => UpdateFile(self, apiUpdateFile))
		.actions((self) => DeleteFile(self, apiDeleteFile))
		.actions((self) => RequestReplaceFile(self, apiReplaceFile))
		.actions((self) => RequestFileUpload(self, apiCreateFile))
		.actions(UpdateFileStatus) // @Noah: must compare with pf model
		.actions((self) => ReadAllFiles(self, apiReadAllFiles))
		.actions((self) => ReadFileInfo(self, apiReadFileInfo))
		.actions((self) => ReadFileHistory(self, apiReadFileHistory))
		.actions((self) => UpdateSignOffLock(self, apiUpdateSignOffLock))
		.actions((self) => ReadFileLocation(self, apiReadFileLocation))
		//
		.actions((self) =>
			RequestFileOrFolderSearch(self, apiSearchFileOrFolder)
		)
		//
		//
		//
		// folder related parts
		.actions((self) => CreateFolder(self, apiCreateFolder))
		.actions((self) => UpdateFolder(self, apiUpdateFolder))

		.actions((self) => DeleteFolder(self, apiDeleteFolder))
		//
		//
		// download
		.actions((self) => RequestDownloadFile(self, apiDownloadFile))
		.actions((self) => RequestDownloadFolder(self, apiDownloadFolder))
		.actions((self) => RequestDownloadAll(self, apiDownloadAll))
		//
		//
		// comment related
		// .actions(WpCommentCreate)
		// .actions(WpCommentUpdate)
		// sign offs
		.actions(RequestSignOff)
		.actions(RequestUnSignOff)
		//
		//
		// assign role
		.actions((self) => RequestAssignRoleToFile(self, apiAssignRole))
		//
		// library
		.actions((self) => CreateFileFromLib(self, apiCreateFileFromLib))
		.actions((self) => CreateFileFromTemp(self, apiCreateFileFromTemp))
		// re-order items (by ref number or dnd)
		.actions((self) => RequestReorder(self, apiReorderChildren))
		.actions((self) => UpdateStructureLock(self, apiUpdateStructureLock))
		// download all
		//
		// additional
		.actions((self, readTrialBalance = apiReadTrialBalance) =>
			ReadTrialBalanceDetails(self, readTrialBalance)
		)
		.actions((self, readFS = apiReadFS) =>
			ReadFinancialStatementType(self, readFS)
		)
		.actions((self, readFA = apiReadFA) => ReadFinalAnalysis(self, readFA))
		.actions((self, saveFA = apiSaveFA) =>
			UpdateFinalAnalysis(self, saveFA)
		)
		//
		// model common actions
		.actions(CommonModelActions)

export default WpModel
