import React from "react"
import { observer } from "mobx-react-lite"
import { DLDialog } from "../../../../../components/basic-elements"

export default observer(function DeleteMultipleClients({
	open,
	setOpen,
	clientData,
	partialStore,
}: {
	open: boolean
	setOpen: any
	clientData: any
	partialStore: any
}) {
	const handleOnSubmit = () => {
		const clients = clientData.map((item: any) => item.clientId)
		partialStore.removeClients(clients, 0)
	}

	return (
		<DLDialog
			eleTestId="delete-multiple-client-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			showOpenBtn={false}
			dialogTitle="Delete Duplicated Clients"
			dialogContents={
				<div className="FC" style={{ height: "300px" }}>
					{clientData.map((item: any) => {
						return (
							<div className="FR JSB">
								<span>{item.clientId}</span>
								<span>{item.clientName}</span>
							</div>
						)
					})}
				</div>
			}
			handleAction={handleOnSubmit}
			actionReady={true}
			actionBtn="Delete"
			cancelBtnText="Cancel"
		/>
	)
})
