import { types } from "mobx-state-tree"

const ProjInfoUiHelper = types
	.model({
		updateDialogOpenStatus: false,
		openProjLocationDialog: false,
	})
	.actions((self) => ({
		// dialogs
		setUpdateDialogOpenStatus(openStatus?: boolean) {
			self.updateDialogOpenStatus =
				openStatus ?? !self.updateDialogOpenStatus
		},
		setOpenProjLocationDialog(request?: boolean) {
			self.openProjLocationDialog =
				request ?? !self.openProjLocationDialog
		},
	}))
	.views((self) => ({}))

export default ProjInfoUiHelper
