import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { GetFileInfoApiMenu } from "../../../../../service-modules/file-module/apis/file-system-file-apis"
import FileOnlyTableView from "../file-only-table/FileOnlyTableView"

export default observer(function FileMenuQCView({
	partialStore,
}: {
	partialStore: any
}) {
	const projStore = useProjStore()

	useEffect(() => {
		const projectId = projStore.checkin.projId
		const financialYear = projStore.projInfo.projInfo.financialYearDate

		partialStore.searchFileOrFolder({
			projectId,
			type: 3,
			searchText: "",
			financialYear,
			fileType:
				partialStore.storeName === DLProjSubMenus.wp
					? GetFileInfoApiMenu.wp
					: GetFileInfoApiMenu.pf,
		})
	}, [])

	return (
		<StyledFileMenuQCView data-testid="file-menu-qc-view">
			<FileOnlyTableView partialStore={partialStore} />
		</StyledFileMenuQCView>
	)
})

const StyledFileMenuQCView = styled.div``
