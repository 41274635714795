import React from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { DLDialog } from "../../../../../components/basic-elements"
import { mdiTrashCan } from "@mdi/js"
import Icon from "@mdi/react"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

const CannotDeleteDialog = observer(({ i18n }: { i18n: DLI18nProps }) => {
	// default setting
	const orgStore = useOrgStore()
	const { twDeleteTitle, twCancel } = i18n
	const riskAssessmentInfo =
		orgStore.setupRiskAssessment.getRiskAssessmentById(
			orgStore.setupRiskAssessment.selectedRiskAssessment
		)

	return (
		<DLDialog
			eleTestId="unable-to-delete-ra-dialog"
			isOpen={orgStore.setupRiskAssessment.cannotRemoveDialogOpen}
			setIsOpen={orgStore.setupRiskAssessment.setCannotRemoveDialogOpen}
			showCloseBtn={true}
			showOpenBtn={false}
			dialogTitle={
				<div className="FR AC">
					<Icon
						path={mdiTrashCan}
						size={1}
						// color={MessageColorType.orange}
						style={{ marginRight: 8 }}
					/>
					{twDeleteTitle} - {riskAssessmentInfo?.riskAssessmentName}
				</div>
			}
			dialogContents={<ProjectDetails />}
			cancelBtnText={twCancel}
		/>
	)
})

const ProjectDetails = observer(() => {
	const orgStore = useOrgStore()
	const projectDetails = orgStore.setupRiskAssessment.projectDetails
	return (
		<div data-testid="cannot-delete-ra-contents-container">
			<p data-testid="cannot-delete-ra-msg">
				The risk assessment cannot be deleted because it's been used in
				the following projects.
			</p>
			<ol data-testid="ra-related-project-list">
				{projectDetails.map((proj) => {
					return (
						<li key={proj.projectAliasId}>
							{proj.periodName} {proj.year} {proj.clientName}:{" "}
							{proj.projectName} ({proj.projectAliasId}) (
							{proj.projectStatus})
						</li>
					)
				})}
			</ol>
		</div>
	)
})

export default CannotDeleteDialog
