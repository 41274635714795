import React, { useEffect } from "react"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import OrgSetupUsers from "./OrgSetupUsers"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import { useLocation } from "react-router-dom"

export default observer(function OrgSetupUsersController() {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()
	const location = useLocation()

	const currentMenu = location.pathname.split("/")[2]
	const menuId =
		currentMenu === "org-normal-setup"
			? DLOrgSubMenus.readonly_users
			: DLOrgSubMenus.setup_users
	const actionName = "getUserList"
	const i18nSheet = OrgI18n.users
	// access & permission
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const needRefresh = orgStore.setupUsers.needRefresh

	useEffect(() => {
		menuAccess && needRefresh && orgStore.setupUsers.getUserList()
	}, [menuAccess, needRefresh])

	const i18n = store.i18n.combineI18n(i18nSheet)

	console.log(permission, "permission")

	return (
		<>
			{menuAccess ? (
				<OrgSetupUsers
					partialStore={orgStore.setupUsers}
					actionStatus={orgStore.setupUsers.getActionStatus(
						actionName
					)}
					clickPointExist={
						orgStore.setupUsers.clickPoint.mouseX !== null
					}
					permission={permission}
					i18n={i18n}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
