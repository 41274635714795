import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import {
	DLBadge,
	DLButton,
	DLRadioGroup,
} from "../../../../components/basic-elements"
import { Tabs, Tab } from "@material-ui/core"
import { MessageColorType } from "../../../../common-models/enumerations/common-enums"
import OpenedFilesTableColumns from "./opened-files-table-columns"
import styled from "styled-components"
import OpenedFilesRequestAccessDialog from "./OpenedFilesRequestAccessDialog"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"

const OpenedFilesListDialogForm = observer(
	({
		menuId,
		partialStore,
		i18n,
	}: {
		menuId: DLProjSubMenus
		partialStore: any
		i18n: DLI18nProps
	}) => {
		const projStore = useProjStore()
		const [openedBy, setOpenedBy] = useState(0) // 0->user, 1->other
		const [selectAllFiles, setSelectAllFiles] = useState(false)
		const [selectAllFilesByOther, setSelectAllFilesByOther] =
			useState(false)
		const [requestAccessDetails, setRequestAccessDetails] = useState<any>(
			[]
		)
		const [feature, setFeature] = useState<DLProjSubMenus>(menuId)
		//
		const openedBySameUser = partialStore.openedBySameUser
		const openedByOther = partialStore.openedByOther
		const selectAllOpenedFile = partialStore.selectAllOpenedFile
		const closeOpenedFiles = partialStore.closeOpenedFiles
		const requestAccessDialogOpen =
			partialStore.openedFilesRequestAccessDialogOpen
		const setOpenedFilesRequestAccessDialogOpen =
			partialStore.setOpenedFilesRequestAccessDialogOpen

		const handleOpenedBy = (event: any, value: any) => {
			setOpenedBy(value)
		}
		const handleToggleCheckbox = (id: any, type: number) => {
			partialStore.selectOpenedFile(id, type)
		}
		const handleFeature = (event: any) => {
			setFeature(event.target.value)
			partialStore.getOpenedFiles(event.target.value)
		}
		const handleSelectAll = (type: number) => {
			if (type === 0) {
				const select = !selectAllFiles
				setSelectAllFiles(select)
				selectAllOpenedFile(type, select)
			} else {
				const select = !selectAllFilesByOther
				setSelectAllFilesByOther(select)
				selectAllOpenedFile(type, select)
			}
		}

		const handleRequestAccess = (rowData: any) => {
			const payload = [rowData]
			setRequestAccessDetails(payload)
			setOpenedFilesRequestAccessDialogOpen(true)
		}

		const handleAllRequestAccess = () => {
			const payload = openedByOther.filter((item: any) => item.selected)
			setRequestAccessDetails(payload)
			setOpenedFilesRequestAccessDialogOpen(true)
		}

		const closeAllFilesOpenedByUser = () => {
			let proceed = window.confirm(
				"Unsaved content can be lost by close. Do you really want to close all?"
			)
			if (proceed) {
				const payload = openedBySameUser.filter(
					(item: any) => item.selected
				)
				closeOpenedFiles(feature, payload, openedBy)
			}
		}

		const closeAllFilesOpenedByOther = () => {
			let proceed = window.confirm(
				"Unsaved content can be lost by close. Do you really want to close all?"
			)
			if (proceed) {
				const payload = openedByOther.filter(
					(item: any) => item.selected
				)
				closeOpenedFiles(feature, payload, openedBy)
			}
		}

		const closeFile = (rowData: any) => {
			let proceed = window.confirm(
				"Unsaved content can be lost by close. Do you really want to close?"
			)
			if (proceed) {
				const payload = [rowData]
				closeOpenedFiles(feature, payload, openedBy)
			}
		}

		const openedBySameUserLength = partialStore.openedBySameUser.length
		const openedByOtherLength = partialStore.openedByOther.length
		const viewOpenedFilesByUser = partialStore.viewOpenedFilesByUser
		const viewOpenedFilesByOther = partialStore.viewOpenedFilesByOther
		const selectedFilesByUserLength = partialStore.selectedFilesByUserLength
		const selectedFilesByOtherLength =
			partialStore.selectedFilesByOtherLength
		const permission = projStore.checkin.getActionsAsObject(menuId)
		const isLoading =
			projStore.openedFiles.getActionStatus("getOpenedFiles") ===
			"LOADING"

		return (
			<StyledOpenedFiles>
				<div className="radio-btns">
					<DLRadioGroup
						items={[
							{
								value: DLProjSubMenus.wp,
								label: `${i18n.twWP}`,
							},
							{
								value: DLProjSubMenus.pf,
								label: `${i18n.twPF}`,
							},
						]}
						selectedValue={feature}
						color="primary"
						size="small"
						itemDirection="row"
						eleOnChange={handleFeature}
						groupName="feature"
						eleClassName="file-type-radio"
					/>
				</div>
				{!isLoading && (
					<>
						<Tabs
							value={openedBy}
							onChange={handleOpenedBy}
							indicatorColor="primary"
							textColor="primary"
						>
							<Tab
								label={
									<DLBadge
										eleTestId="badge-for-opened-by-me"
										content={openedBySameUserLength}
										color={MessageColorType.red}
										eleClassName="margin-bottom-normal"
									>
										Checked-Out By Me
									</DLBadge>
								}
							/>
							<Tab
								label={
									<DLBadge
										eleTestId="badge-for-opened-by-other"
										content={openedByOtherLength}
										color={MessageColorType.red}
										eleClassName="margin-bottom-normal"
									>
										Checked-Out By Other
									</DLBadge>
								}
							/>
						</Tabs>
						<ReactTableV8
							// TODO: height setting
							tableColumns={OpenedFilesTableColumns(
								handleRequestAccess,
								openedBy,
								handleToggleCheckbox,
								selectAllFiles,
								selectAllFilesByOther,
								handleSelectAll,
								closeFile,
								permission,
								i18n
							)}
							data={
								openedBy === 0
									? viewOpenedFilesByUser()
									: viewOpenedFilesByOther()
							}
							menuId="OpenedFilesListDialog"
							height={480}
							showPageSetting={false}
							hasPagination={false}
						/>
						<hr />
						<div className="contents-area FR AC">
							<div>
								{openedBy === 0
									? selectedFilesByUserLength
									: selectedFilesByOtherLength}
								/
								{openedBy === 0
									? openedBySameUserLength
									: openedByOtherLength}{" "}
								Of Checked-Out Files Selected
							</div>
							<div className="FR AC">
								{openedBy === 0 ? (
									<DLButton
										eleTestId="close-all-for-user-btn"
										variant="contained"
										color="primary"
										clickHandler={closeAllFilesOpenedByUser}
										disabled={selectedFilesByUserLength < 1}
									>
										Check-In & Close All
									</DLButton>
								) : (
									<>
										{permission.forceClose && (
											<DLButton
												eleTestId="force-close-all-for-other"
												variant="contained"
												color="primary"
												clickHandler={
													closeAllFilesOpenedByOther
												}
												disabled={
													selectedFilesByOtherLength <
													1
												}
											>
												Discard Checked-Out
											</DLButton>
										)}
										<DLButton
											eleTestId="request-access-btn"
											variant="contained"
											color="primary"
											clickHandler={
												handleAllRequestAccess
											}
											disabled={
												selectedFilesByOtherLength < 1
											}
										>
											Request Check-In
										</DLButton>
									</>
								)}
							</div>
						</div>
					</>
				)}
				{requestAccessDialogOpen && (
					<OpenedFilesRequestAccessDialog
						partialStore={partialStore}
						RequestAccessDetails={requestAccessDetails}
						menuId={menuId}
						i18n={i18n}
					/>
				)}
			</StyledOpenedFiles>
		)
	}
)

export default OpenedFilesListDialogForm

const StyledOpenedFiles = styled.div`
	.rt-table {
		padding-left: 0rem !important;
	}
	.radio-btns {
		padding-left: 1rem;
	}
	table > thead > tr > th {
			.header {
				min-height: 2rem;
				padding-left: 8px;
				justify-content: center;
			}
		}
	}
`
