import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import OrgTrash from "./OrgTrash"
import OrgNoPermissionOnMenu from "../org-access-control/OrgNoPermissionOnMenu"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { OrgI18n } from "../../../common-models/enumerations/translation-sheets"

export default observer(function OrgTrashController() {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.file_trash

	const i18nSheet = OrgI18n.trash

	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const partialStore = orgStore.trash

	//
	const i18n = store.i18n.combineI18n(i18nSheet)
	return (
		<>
			{menuAccess ? (
				<OrgTrash
					partialStore={partialStore}
					permission={permission}
					i18n={i18n}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
