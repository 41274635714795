import { types } from "mobx-state-tree"
import {
	SimpleUserModel,
	SimpleUserModelType,
} from "../../user-module/user-module-data-models"
import { ProjectStatus } from "../../../common-models/enumerations/project-related-enums"

/**
 * 1. location info: group, cabinet
 * 2. client info
 * 3. project basic info
 * 4. project status info
 * 5. access info
 * 6. schedule info
 * 7. hisotry info
 * 8. assigned users
 * 9. extras
 * + others...
 */
export const DLProjectModel = types.model({
	//
	//
	// * 1. location info: group, cabinet
	groupId: types.string, // system ID
	groupName: types.string,
	cabinetId: types.string, // system ID
	cabinetName: types.string, // Not using
	//
	//
	// * 2. client info
	clientId: types.string, // system ID
	clientName: types.string,
	clientAliasId: types.string,
	//
	//
	// * 3. project basic info
	id: types.string,
	aliasId: types.string,
	title: types.string,
	//
	engTypeId: types.string,
	engTypeName: types.string,
	raId: types.string, // Not using
	raName: types.string,
	//
	//
	// * 4. project status info
	statusId: types.string, // system ID / project status: normal, replica, archived, unarchived
	statusName: types.string,
	periodId: types.string, // system ID / period: Full year, Half year...
	periodName: types.string,
	archiveDatePolicy: types.string,
	//
	//
	// Additional
	replicaStatus: types.boolean,
	archiveZipStatus: types.boolean,
	unarchiveStatus: types.boolean,
	//
	//
	version: types.integer,
	isArchived: types.boolean,
	isLocked: types.boolean,
	//
	//
	// * 5. access info
	roleId: "", // types.string, // system ID
	roleName: types.string, // Not using
	accessible: types.boolean,
	//
	//
	// * 6. schedule info
	financialYear: types.integer,
	periodEndDate: types.string,
	//
	expectedReportDate: types.string, // Not using
	finalReportDate: types.union(types.null, types.string),
	//
	// archPolicyPeriodId: types.string, // system ID
	// archPolicyPeriodName: types.string,
	// archPolicyPeriodValue: types.integer,
	//
	expectedArchiveDate: types.string, // Not using
	finalArchiveDeadlineDate: types.string, // watch out. This is different with ArchivedAt
	//
	//
	// * 7. history info
	createdBy: types.union(types.null, SimpleUserModel),
	createdAt: types.string,
	//
	lastAccessBy: types.union(types.null, SimpleUserModel),
	lastAccessAt: types.union(types.null, types.string),
	lastAccessDate: "",
	lastAccessTime: "",
	//
	archivedBy: types.union(types.null, SimpleUserModel), // converted
	archivedAt: "",
	archivedDate: "",
	archivedTime: "",
	//
	unarchivedBy: types.union(types.null, SimpleUserModel),
	unarchivedAt: "",
	// archOrUnArchBy: types.union(types.null, types.undefined, types.string), // [ArchivedBy], ex) 0
	// archOrUnArchAt: types.union(types.null, types.undefined, types.string), // [ProjectArchivedDate], ex) ""
	//
	//
	// * 8. assigned users
	epUsers: types.array(SimpleUserModel),
	qcUsers: types.array(SimpleUserModel),
	//
	//
	// * 9. extras
	//
	//
	// *
	hasExportPermission: types.boolean,
	//
	//
	// * ???
})

export const DLRemindProjectModel = types.model({
	//
	//
	// * 1. location info: group, cabinet
	groupId: types.string, // system ID
	groupName: types.string,
	cabinetId: types.string, // system ID
	cabinetName: types.string, // Not using
	//
	//
	// * 2. client info
	clientId: types.string, // system ID
	clientName: types.string,
	clientAliasId: types.string,
	//
	//
	// * 3. project basic info
	id: types.string,
	aliasId: types.string,
	title: types.string,
	//
	engTypeId: types.string,
	engTypeName: types.string,
	raId: types.string, // Not using
	raName: types.string,
	//
	//
	// * 4. project status info
	statusId: types.string, // system ID / project status: normal, replica, archived, unarchived
	statusName: types.string,
	periodId: types.string, // system ID / period: Full year, Half year...
	periodName: types.string,
	archiveDatePolicy: types.string,
	//
	//
	//
	version: types.integer,
	isArchived: types.boolean,
	isLocked: types.boolean,
	//
	//
	// * 5. access info
	accessible: types.boolean,
	//
	//
	// * 6. schedule info
	financialYear: types.integer,
	periodEndDate: types.string,
	dueDays: types.union(types.number, types.string),
	//
	expectedReportDate: types.string, // Not using
	finalReportDate: types.union(types.null, types.string),
	//
	// archPolicyPeriodId: types.string, // system ID
	// archPolicyPeriodName: types.string,
	// archPolicyPeriodValue: types.integer,
	//
	expectedArchiveDate: types.string, // Not using
	finalArchiveDeadlineDate: types.string, // watch out. This is different with ArchivedAt
	//
	//
	// * 7. history info
	createdBy: types.union(types.null, SimpleUserModel),
	createdAt: types.string,
	//
	// * 8. assigned users
	epUsers: types.array(SimpleUserModel),
	qcUsers: types.array(SimpleUserModel),
	//
	// * 9. extras
	// *
	hasExportPermission: types.boolean,
	//
})

export const RFSourceProjInfoModel = types.model({
	groupName: types.string,
	cabinetName: types.string,
	clientName: types.string,
	clientAliasId: types.string,
	title: types.string,
	projectAliasId: types.string,
	version: types.integer,
	periodName: types.string,
	financialYear: types.integer,
	periodEndDate: types.string,
})

export const initialRFSourceProjInfo = {
	groupName: "",
	cabinetName: "",
	clientName: "",
	clientAliasId: "",
	title: "",
	projectAliasId: "",
	version: 0,
	periodName: "",
	financialYear: 0,
	periodEndDate: "",
}

export type DLProjectModelType = {
	//
	//
	// * 1. location info: group, cabinet
	groupId: string // system ID
	groupName: string
	cabinetId: string // system ID
	cabinetName: string // Not using
	//
	//
	// * 2. client info
	clientId: string // system ID
	clientName: string
	clientAliasId: string
	//
	//
	// * 3. project basic info
	id: string
	aliasId: string
	title: string
	//
	engTypeId: string
	engTypeName: string
	raId: string // Not using
	raName: string
	//
	//
	// * 4. project status info
	statusId: string // system ID / project status: normal, replica, archived, unarchived
	statusName: string
	periodId: string // system ID / period: Full year, Half year...
	periodName: string
	//
	//
	// Additional
	replicaStatus: boolean
	archiveZipStatus: boolean
	unarchiveStatus: boolean
	//
	//
	version: number
	isArchived: boolean
	isLocked: boolean
	//
	//
	// * 5. access info
	roleId: string // system ID
	roleName: string // Not using
	accessible: boolean
	//
	//
	// * 6. schedule info
	financialYear: number
	periodEndDate: string
	//
	expectedReportDate: string // Not using
	finalReportDate: null | string
	//
	// archPolicyPeriodId: types.string, // system ID
	// archPolicyPeriodName: types.string,
	// archPolicyPeriodValue: types.integer,
	//
	expectedArchiveDate: string // Not using
	finalArchiveDeadlineDate: string // watch out. This is different with ArchivedAt
	//
	//
	// * 7. history info
	createdBy: null | SimpleUserModelType
	createdAt: string
	//
	lastAccessBy: null | SimpleUserModelType
	lastAccessAt: null | string
	//
	archivedBy: null | SimpleUserModelType // converted
	archivedAt: string | undefined // WARNING: string | undefined..? null | string..??
	//
	unarchivedBy: null | SimpleUserModelType
	unarchivedAt: string | undefined // WARNING: string | undefined..? null | string..??
	// archOrUnArchBy: null | undefined | string , // [ArchivedBy], ex) 0
	// archOrUnArchAt: null | string, // [ProjectArchivedDate], ex) ""
	//
	//
	// * 8. assigned users
	epUsers: SimpleUserModelType[]
	qcUsers: SimpleUserModelType[]
	//
	//
	// * 9. extras
	//
	//
	// *
	hasExportPermission: boolean
	//
	//
	// * ???
}

/**
 * for Report management screen & CDK workspaces
 */
export const DLWorkspaceModel = types.model({
	// * New
	checklistId: types.union(types.string, types.null),
	reportId: types.union(types.string, types.null),
	//
	//
	// * 1. location info: group, cabinet
	groupId: types.string, // system ID
	groupName: types.string,
	cabinetId: types.string, // system ID
	cabinetName: types.string, // Not using
	//
	//
	// * 2. client info
	clientId: types.string, // system ID
	clientName: types.string,
	clientAliasId: types.string,
	//
	//
	// * 3. project basic info
	id: types.string,
	aliasId: types.string,
	title: types.string,
	//
	engTypeId: types.string,
	engTypeName: types.string,
	raId: types.string, // Not using
	raName: types.string,
	//
	//
	// * 4. project status info
	statusId: types.string, // system ID / project status: normal, replica, archived, unarchived
	statusName: types.string,
	periodId: types.string, // system ID / period: Full year, Half year...
	periodName: types.string,
	//
	version: types.integer,
	isArchived: types.boolean,
	isLocked: types.boolean,
	//
	//
	// * 5. access info
	roleId: "", // types.string, // system ID
	roleName: types.string, // Not using
	accessible: types.boolean,
	//
	//
	// * 6. schedule info
	financialYear: types.integer,
	periodEndDate: types.string,
	//
	expectedReportDate: types.string, // Not using
	finalReportDate: types.union(types.null, types.string),
	//
	expectedArchiveDate: types.string, // Not using
	finalArchiveDeadlineDate: types.string, // watch out. This is different with ArchivedAt
	//
	//
	// * 7. history info
	createdBy: types.union(types.null, SimpleUserModel),
	createdAt: types.union(types.null, types.string),
	//
	archivedBy: types.union(types.null, SimpleUserModel), // converted
	archivedAt: "",
	//
	unarchivedBy: types.union(types.null, SimpleUserModel),
	unarchivedAt: "",
	//
	// * 8. assigned users
	epUsers: types.array(SimpleUserModel),
	qcUsers: types.array(SimpleUserModel),
})

export const DLProjectStatusList = [
	{
		oldId: 1,
		id: "proj_status_normal",
		name: ProjectStatus.normal,
	},
	{
		oldId: 2,
		id: "proj_status_replica",
		name: ProjectStatus.replica,
	},
	{
		oldId: 3,
		id: "proj_status_archived",
		name: ProjectStatus.archived,
	},
	{
		oldId: 4,
		id: "proj_status_unarchived",
		name: ProjectStatus.unarchived,
	},
	{
		oldId: 5,
		id: "proj_status_force_archived",
		name: ProjectStatus.forceArchived,
	},
]
