import { types } from "mobx-state-tree"
import {
	DLCommentType,
	DialogActionType,
} from "../../../common-models/enumerations/common-enums"
import { ClickPoint } from "../../../common-models/types/dialog.props"
import { LibFile } from "../../../screens/project-side/lib-in-proj/store/lib-in-proj.data-model"
import { DLFileModel, FileHistoryModel, FileInfoModel } from "./file.data-model"
import { OldSignOffType } from "./sign-off.data-model"

export const allowedFormats =
	"application/pdf,.xlsx,.xls,.doc,.docx,.hwp,.png,.jpg,.xbrl,.XBRL,.ixbrl,.IXBRL,.dsd,.DSD,.xsd,.xml,.rtf,.zip,.ppt,.pptx"

export const SelectedItem = types.model({
	id: types.string,
	isParent: false,
})

export type SelectedFileItemProps = {
	id: string
	isParent: boolean
}

export enum SignOffDisplayType {
	min = "MIN",
	nameOnly = "NAME_ONLY",
	dateOnly = "DATE_ONLY",
	dateAndTime = "DATE_AND_TIME",
	nameAndDate = "NAME_AND_DATE",
	max = "MAX",
}

// NOTE: UPDATE MODEL TOGETHER: dl-file-control-model.ts
export enum FileScreenDialog {
	createFolder = "createFolderDialog",
	editFolder = "editFolderDialog",
	//
	createFile = "createFileDialog",
	fileEdit = "fileEditDialog",
	fileInfo = "fileInfoDialog",
	replaceFile = "replaceFileDialog",
	//
	assign = "assignDialog",
	//
	multipleSignOff = "multipleSignOff",
	//
	commentList = "commentListDialog",
	commentDetail = "commentDetailDialog", // TODO: Consider to move
	//
	pageSetting = "pageSettingDialog",
	// fileFromLib = "fileFromLibDialog",
	searchResult = "searchResultDialog",
	signOffDetail = "signOffDetailDialog",
	//
	folderTree = "folderTreeDialog",
	fileTree = "fileTreeDialog",
	//
	moveFileOrFolder = "moveFileOrFolderDialog",
	signOffLockDialog = "signOffLockDialog",
	signOffLockUnlockHistoryDialog = "signOffLockUnlockHistoryDialog",
	FileHistoryDialog = "FileHistoryDialog",
	exportFolderDialog = "exportFolderDialog",
	dragAndDropConfirmDialog = "dragAndDropConfirmDialog",
}

export enum FileScreenColumn {
	assignedRoles = "assignedRolesWidth",
	aliasId = "aliasIdWidth",
	title = "titleWidth",
	format = "formatWidth",
	signOffArea = "signOffAreaWidth",
	history = "historyWidth",
	size = "sizeWidth",
	signOffLock = "signOffLockWidth",
}

export enum FileMenuViewType {
	tree = "TREE",
	fileTable = "FILE_TABLE",
	search = "SEARCH",
	qcView = "QC_VIEW",
}

const DndContentsDataModel = types.model({
	nextParent: types.model({
		menuId: "",
		id: "",
		title: "",
		// children:
	}),
	nextPath: types.array(types.string),
	node: LibFile,
})

export enum FileUploadFromType {
	local = "LOCAL",
	lib = "LIBRARY",
	temp = "TEMPLATE",
}

export const DLFileControlModel = types.model({
	flatList: types.array(DLFileModel),
	//
	folderClickPoint: ClickPoint,
	fileClickPoint: ClickPoint,
	//
	fileInfo: FileInfoModel,
	fileHistory: types.array(FileHistoryModel),
	//
	selectedItems: types.array(SelectedItem),
	prevTreeItem: "", // from the last selected item
	nextTreeItem: "", // from the last selected item
	//
	dndContents: types.union(DndContentsDataModel, types.undefined),
	//
	totalFileCount: "",
	//
	// dialogs
	fileTreeDialogOpenStatus: types.model({
		/**
		 * - file delete, folder delete are using window.confirm
		 * - folder edit is using inline edit
		 */
		createFolderDialog: false,
		editFolderDialog: false,
		createFileDialog: false,
		//
		fileEditDialog: false,
		replaceFileDialog: false,
		fileInfoDialog: false,
		//
		assignDialog: false,
		//
		multipleSignOff: false,
		//
		commentListDialog: false, // TBD
		commentDetailDialog: false, // TBD
		//
		pageSettingDialog: false,
		//
		signOffDetailDialog: false,
		//
		folderTreeDialog: false,
		fileTreeDialog: false,
		//
		moveFileOrFolderDialog: false,
		signOffLockDialog: false,
		signOffLockUnlockHistoryDialog: false,
		FileHistoryDialog: false,
		exportFolderDialog: false,
		dragAndDropConfirmDialog: false,
	}),
	// for drag and drop upload from local
	fileUploadFrom: types.union(
		types.enumeration<FileUploadFromType>(
			Object.values(FileUploadFromType)
		),
		types.undefined
	),
	// tempLocalFiles:
	commentClosingDialogOpenStatus: false,
	// for multiple sign off
	multipleSignOffType: types.model({
		type: types.enumeration<OldSignOffType>(Object.values(OldSignOffType)),
		forCancel: false,
	}),
	//
	commentDetailDialogMode: types.enumeration<DialogActionType>(
		Object.values(DialogActionType)
	),
	commentType: types.enumeration<DLCommentType>(Object.values(DLCommentType)),
	// view type
	viewType: types.enumeration<FileMenuViewType>(
		Object.values(FileMenuViewType)
	),
	// search
	searchType: "",
	searchQuery: "",
	searchResults: types.array(DLFileModel),

	//
	showCheckbox: false,
	structureLock: true,
	// columns
	showColumns: types.model({
		assignedRoles: true,
		assignedUsers: false,
		fileAliasId: true, // reference number
		format: true,
		title: true,
		size: true,
		signOffs: true,
		comment: true,
		attention: true,
		history: true,
		signOffLock: true,
	}),
	showSignOffColumns: types.model({
		id_prepare: true,
		id_review: true,
		id_epReview: true,
		id_cpReview: true,
		id_qcReview: true,
	}),
	// showSignOffColumns: types.model({
	// 	// Seems not good, because the sign off columns are different on all
	// }),
	columnWidth: types.model({
		assignedRolesWidth: 40,
		aliasIdWidth: 60,
		formatWidth: 40,
		titleWidth: 240,
		sizeWidth: 60,
		signOffLockWidth: 120,
		//
		signOffAreaWidth: 700,
		historyWidth: 80,
	}),
	columnHighlight: "",
	//
	signOffCellType: types.enumeration<SignOffDisplayType>(
		Object.values(SignOffDisplayType)
	), // TODO: update is required
	signOffDisplayRange: types.union(
		types.integer,
		types.enumeration(["LATEST", "OLDEST", "ALL"]) // TODO: update is required
	),
	signOffSortType: types.string, // TODO: update is required
	downloadType: "",
})

export enum FileColumn {
	assignedRoles = "assignedRoles",
	assignedUsers = "assignedUsers",
	fileAliasId = "fileAliasId",
	format = "format",
	title = "title",
	size = "size",
	signOffs = "signOffs",
	comment = "comment",
	attention = "attention", // Status? Attention?
	history = "history",
	signOffLock = "signOffLock",
}
