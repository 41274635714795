import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ProjectRollForward from "./ProjectRollForward"
import ProjNoPermissionOnMenu from "../proj-access-control/ProjNoPermissionOnMenu"
import { DLSpinner } from "../../../components/basic-elements"

export default observer(function ProjectRollForwardController() {
	const projStore = useProjStore()

	const menuId = DLProjSubMenus.roll_forward
	const menuAccess = projStore.checkin.checkAccess(menuId)

	const { isArchived, projectId } = projStore.projInfo.projInfo

	const needRefresh = projStore.projRollForward.needRefresh

	useEffect(() => {
		if (menuAccess && needRefresh) {
			const userId = localStorage.getItem("orgUserId") || ""
			projStore.projRollForward.getOrgGroupList(userId)
		}
	}, [menuAccess, needRefresh, projectId])

	const actionStatus =
		projStore.projRollForward.getActionStatus("getOrgGroupList")

	return (
		<>
			{menuAccess && !isArchived ? (
				<>
					{/* ----- Spinner ----- */}
					{actionStatus === "LOADING" && (
						<div
							className="FR AC JC"
							style={{ width: "100%", height: "100%" }}
						>
							<DLSpinner />
						</div>
					)}
					{/* ----- Contents ----- */}
					{actionStatus === "SUCCESS" && (
						<ProjectRollForward
							partialStore={projStore.projRollForward}
						/>
					)}
				</>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
