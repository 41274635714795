import { ConsoleLog } from "../../../../../components/basic-elements"

const GroupRelatedViews = (self: any) => ({
	currentGroupInfo() {
		if (self.currentPath[0]) {
			return self.orgGroupFlatList.find(
				(item: any) => item.id === self.currentPath[0]
			)
		} else {
			ConsoleLog("No selected group")
		}
	},
	get rightClickTargetInfo() {
		return self.orgGroupFlatList.find(
			(item: any) => item.id === self.rightClickTarget
		)
	},
	getSelectedItemInfo(id: string) {
		return self.orgGroupFlatList.find((item: any) => item.id === id)
	},
	getSelectedProjInfo(id: string) {
		const target = self.orgGroupFlatList.find((item: any) => item.id === id)
		console.log(target, "target")
		if (target) {
			return {
				periodName: target.detail.periodName,
				financialYear: target.detail.financialYear,
				clientName: target.detail.clientName,
				title: target.title,
				aliasId: target.detail.aliasId,
				projectType: target.detail.projectStatus,
				version: target.detail.version,
			}
		} else {
			return undefined
		}
	},
})

export default GroupRelatedViews
