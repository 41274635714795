import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import PageContainer from "../../../../components/app-frame-elements/sub-components/PageContainer"
import styled from "styled-components"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { ProjScreenType } from "../../../../components/combined-elements/projects-table/projects-table-props"
import SharedProjectTableV8 from "../../../../components/combined-elements/projects-table/SharedProjectTableV8"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import SharedCtxMenuForAssignedProjects from "../shared-components/SharedCtxMenuForAssignedProjects"
import {
	getPageSettings,
	savePageSetting,
} from "../../../../library/get-page-settings"
import ProjectDetailsDialog from "../../../../components/combined-elements/project-dialogs/ProjectDetailsDialog"
import ProjLocationDetailsDialog from "../../../project-side/project-information/sub-components/ProjLocationDetailsDialog"

export default observer(function NormalProjectsController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.assignedNormal // Need to update to assigned normal
	const projStatus = ProjectStatus.normal
	const actionName = "getOrgProjectList_" + projStatus
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	const needRefresh = orgStore.projects.needRefreshForAssignedNormal
	const i18nSheet = OrgI18n.projects
	const dntFormat = store.global.getDntFormat

	useEffect(() => {
		// const defaultYear = getDefaultFinancialYear(menuId)
		const pageSetting = getPageSettings(menuId)
		if (pageSetting?.columnFilters) {
			const value = pageSetting?.columnFilters.filter(
				(item: any) => item.id !== "financialYear"
			)
			savePageSetting("columnFilters", value, `pageSetting_${menuId}`)
		}
		if (menuAccess && needRefresh) {
			orgStore.projects.getOrgProjectList({
				projStatus,
				year: "All Years",
				PTMAssignedOnly: true,
				menuId,
				ForROAM: false,
				dntFormat,
			})
		}
	}, [menuAccess, needRefresh])

	const loadingStatus = orgStore.projects.getActionStatus(actionName)
	const i18n = store.i18n.combineI18n(i18nSheet)
	const clickPointExist = orgStore.projects.clickPoint.mouseX !== null

	console.log(clickPointExist, "clickPointExist")
	return (
		<>
			{menuAccess ? (
				<PageContainer
					pageTools={<div />}
					hasToolbar={false}
					fullWidth
					fixedHeight
					isLoading={loadingStatus === "LOADING"}
				>
					<StyledNormalProjects>
						{loadingStatus === "SUCCESS" && (
							<SharedProjectTableV8
								partialStore={orgStore.projects}
								i18n={i18n}
								// common part 2
								projScreenType={ProjScreenType.normal}
								menuId={DLOrgSubMenus.assignedNormal}
								projectStatus={ProjectStatus.normal}
								permission={permission}
							/>
						)}
					</StyledNormalProjects>
					{clickPointExist && (
						<SharedCtxMenuForAssignedProjects
							partialStore={orgStore.projects}
							permission={permission}
							projStatus={ProjectStatus.normal}
						/>
					)}
					{orgStore.projects.projectDetailsDialogOpen && (
						<ProjectDetailsDialog
							partialStore={orgStore.projects}
						/>
					)}
					{orgStore.projects.openProjLocationDialog && (
						<ProjLocationDetailsDialog
							partialStore={orgStore.projects}
							view={store.global.sidebarViewState}
						/>
					)}
				</PageContainer>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

const StyledNormalProjects = styled.div`
	padding: 0 1rem;
`
