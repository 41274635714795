import { AxiosResponse } from "axios"
import {
	DeleteProjectApiProps,
	ReadProjectUsersApiProps,
	ReadProjectDetailsApiProps,
} from "../../../../../service-modules/project-module/org-project-shared-apis"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { postFormData } from "../../../../../library/api-requests/request-post-others"
import {
	getRequest,
	putRequest,
	postRequest,
} from "../../../../../library/api-requests"

// common apis for both archived and unarchived projects
export async function read(payload: {
	PageSize: number
	PageIndex?: number
	usage?: number
	SearchText?: string
}) {
	const response = await postRequest({
		url: "/GetArchivedManagementProjects",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

// ---- archived apis
export async function addAccessMgmtUser(payload: {
	clientid: number
	UserId: number
	IsExportPerm: boolean
	ExpiredDate: string
}) {
	const response = await postRequest({
		url: "/AssignAccessMngmntUsers",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function readAccessMgmtUser(params: { clientid: number }) {
	const response = await getRequest({
		url: "/GetFetchAccessMngmntUsers",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function readFilteredUsers(params: {
	type: number
	searchText: string
}) {
	const response = await getRequest({
		url: "/GetSearchAccessMngmntUsers",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function deleteReadOnlyUser(payload: {
	clientId: number
	id: number
}) {
	const response = await putRequest({
		url: "/UnAssignAccessManagementUsers",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function releaseForceArchive(payload: {
	ClientId: number
	Reason: string
}) {
	const response = await postRequest({
		url: "/WorkspaceReleaseForceArchived",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

type UpdateROU = {
	clientId: string
	Id: string
	IsExportPerm: boolean
	ExpiredDate: string
}

export async function updateReadOnlyUser(payload: UpdateROU[]) {
	const response = await putRequest({
		url: "/UpdateAccessMngmntUsers",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

type Unarchive = {
	params: {
		ClientID: number
		IsArchivedProcess: boolean
		ArchivedBy: number
		Step: number
		ReplicaClientID: number
		reason: string
	}
	files: File
}

export async function unarchiveProject(formData: Unarchive) {
	const response = await postFormData({
		url: "/StartArchive",
		formData: formData,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

type RepairReplicaApiProps = {
	clientId: number
	custId: number
	userId: number
	replicaId: number
}
export async function repairReplica(params: RepairReplicaApiProps) {
	const { clientId, custId, userId, replicaId } = params

	// NOTE: even if provide null instead of 0, it is changed as 0
	const response = await postRequest({
		url: `/RepairBrokenProject?clientId=${clientId}&custId=${custId}&userId=${userId}&incompleteReplicaId=${replicaId}`,
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})
	return response
}

export async function preConditionStatus(clientId: any) {
	const response = await getRequest({
		url: `/PreConditionCheckStatus?clientId=${clientId}`,
		params: {},
		// tokenType: TOKEN_TYPE.orgToken,
		// WARNING: This is temporary solution. MUST UDPATE AFTER BACKEND SIDE UPDATE
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function readFilterData(params: { usage: number }) {
	const response = await getRequest({
		url: "/GetFilters",
		params,
		// tokenType: TOKEN_TYPE.orgToken,
		// WARNING: This is temporary solution. MUST UDPATE AFTER BACKEND SIDE UPDATE
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

// export async function startPreProcess(payload: {
// 	ArchivedBy: number
// 	ClientID: number
// 	IsLocked: boolean
// 	ReplicaClientID: number
// }) {
// 	// NOTE: NEED TO INTEGRATE WITH archive-pre-process.apis.ts
// 	const { ArchivedBy, ClientID, IsLocked, ReplicaClientID } = payload
// 	const response = await postRequest({
// 		url: `/PreConditionCheck?ArchivedBy=${ArchivedBy}&ClientID=${ClientID}&IsLocked=${IsLocked}&ReplicaClientID=${ReplicaClientID}`,
// 		params: {}, // payload,
// 		tokenType: TOKEN_TYPE.orgToken,
// 	})

// 	return response
// }

export type AmArchivedApiProps = {
	apiRead: (payload: {
		PageSize: number
		PageIndex?: number
		usage?: number
		SearchText?: string
	}) => Promise<AxiosResponse<any>>
	apiUnarchiveProject: (formData: Unarchive) => Promise<AxiosResponse<any>>
	apiDelete: (payload: DeleteProjectApiProps) => Promise<AxiosResponse<any>>
	// readonly assign related
	apiAddAccessMgmtUser: (payload: {
		clientid: number
		UserId: number
		IsExportPerm: boolean
		ExpiredDate: string
	}) => Promise<AxiosResponse<any>>
	apiReadAccessMgmtUser: (payload: {
		clientid: number
	}) => Promise<AxiosResponse<any>>

	apiReadFilteredUsers: (payload: {
		type: number
		searchText: string
	}) => Promise<AxiosResponse<any>>
	apiDeleteReadOnlyUser: (payload: {
		clientId: number
		id: number
	}) => Promise<AxiosResponse<any>>
	apiUpdateReadOnlyUser: (payload: UpdateROU[]) => Promise<AxiosResponse<any>>
	apiRepairReplica: (
		params: RepairReplicaApiProps
	) => Promise<AxiosResponse<any>>

	apiCheckPreConditionStatus: (clientId: any) => Promise<AxiosResponse<any>>
	apiReadFilters: (payload: { usage: number }) => Promise<AxiosResponse<any>>
	apiReleaseForceArchive: (payload: {
		ClientId: number
		Reason: string
	}) => Promise<AxiosResponse<any>>
	// apiStartPreProcess: (payload: {
	// 	ArchivedBy: number
	// 	ClientID: number
	// 	IsLocked: boolean
	// 	ReplicaClientID: number
	// }) => Promise<AxiosResponse<any>>
}
