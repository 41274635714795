import React, { useEffect, useState } from "react"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import OrgGroups from "./OrgGroups"
import CreateProjectDialog from "../../../components/combined-elements/create-project/CreateProjectDialog"
import {
	GroupCtxMenu,
	CabinetCtxMenu,
	ProjectCtxMenu,
} from "./sub-components/context-menus"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import CreateOrgGroupCabinetDialog from "./sub-components/dialogs/CreateOrgGroupCabinetDialog"
import EditOrgGroupCabinetDialog from "./sub-components/dialogs/EditOrgGroupCabinetDialog"
import { DLObjectFormat } from "../../../common-models/enumerations/common-enums"
import { OrgI18n } from "../../../common-models/enumerations/translation-sheets"
import {
	DLOrgSubMenus,
	DLOrgMenuGroups,
} from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

import MoveGroupItemDialog from "./sub-components/dialogs/MoveGroupItemDialog"
import GroupDetailDialog from "./sub-components/dialogs/GroupDetailDialog"
import CommonSnackbarComponent from "../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import OrgNoPermissionOnMenu from "../org-access-control/OrgNoPermissionOnMenu"
import ProjInfoUpdateDialog from "../../project-side/project-information/sub-components/ProjInfoUpdateDialog"
import { useLocation } from "react-router-dom"
import { getSnapshot } from "mobx-state-tree"
import DeleteProjectDialog from "../../../components/combined-elements/delete-project-dialog/DeleteProjectDialog"

export default observer(function OrgGroupsController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const actionName = "getOrgGroupList"
	const menuId = DLOrgSubMenus.groups
	const i18nSheet = OrgI18n.groups
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const location = useLocation()
	const state: any = location.state
	const [locState, setLocState] = useState(state)

	console.log(state, "yourState")

	const needRefresh = orgStore.groups.needRefresh

	if (locState && !needRefresh) {
		orgStore.groups.setNeedRefresh(true)
	}

	let path = getSnapshot(orgStore.groups.currentPath)
	let cabinetId = orgStore.groups.getLastItemOfPath

	const pathSnapshotString = localStorage.getItem("pathSnapshot")
	if (locState && pathSnapshotString) {
		cabinetId = locState[locState.length - 1].id
		const pathSnapshot = JSON.parse(pathSnapshotString)
		if (locState.length === pathSnapshot.length) {
			localStorage.removeItem("pathSnapshot")
			orgStore.groups.resetCurrentPath()
			orgStore.groups.resetQuery()
			orgStore.groups.resetFlatList()
			orgStore.groups.setCurrentPath(locState.map((item: any) => item.id))

			const projId = localStorage.getItem("latestProjId") || ""
			orgStore.groups.setRightClickTarget(projId)
			pushArrayItemsToList(locState, orgStore.groups)
			// orgStore.groups.setSelectedItem(projId)
		}
	}

	useEffect(() => {
		if (menuAccess && needRefresh) {
			if (locState) {
				if (!cabinetId && path.length === 0) {
					setLocState(null)
					return
				}
				if (path.length === 1) {
					getAssignedGroups(orgStore, locState)
					setLocState(null)
					return
				}
				if (cabinetId && cabinetId.startsWith("cabinet")) {
					orgStore.groups.getOrgCabinetDetail(cabinetId)
					orgStore.groups.pushItemToCurrentPath(cabinetId)
				}
				const projId = localStorage.getItem("latestProjId") || ""
				orgStore.groups.pushOrSpliceItem(projId)
			} else {
				getAssignedGroups(orgStore, locState)
			}
		}
	}, [menuAccess, needRefresh, cabinetId, locState])

	const contentsHeight = store.ui.contentsAreaHeight
	//
	let selectedItemType = orgStore.groups.rightClickTargetInfo?.type

	let groupCtxMenuOpen = selectedItemType === DLObjectFormat.group
	let cabinetCtxMenuOpen = selectedItemType === DLObjectFormat.cabinet
	let projCtxMenuOpen = selectedItemType === DLObjectFormat.project

	//
	let openCreateProjDialog = orgStore.groups.openCreateProjDialog
	let updateDialogOpenStatus = orgStore.projects.updateDialogOpenStatus
	//
	let openCreateCabinetDialog = orgStore.groups.openCreateCabinetDialog
	let openEditCabinetDialog = orgStore.groups.openEditCabinetDialog
	//
	let openMoveDialog = orgStore.groups.openMoveDialog
	//
	// const path = orgStore.groups.selectionPathWithNameAndFormat

	const setIdPath = orgStore.groups.setPathForSelection
	const presetInputs = orgStore.projects.createProjectPresetInputs
	//
	const i18n = store.i18n.combineI18n(
		[i18nSheet, OrgI18n.projects],
		"OrgGroupsController"
	)

	return (
		<>
			{menuAccess ? (
				<>
					<OrgGroups contentsHeight={contentsHeight} />
					<CommonSnackbarComponent
						i18n={i18n}
						partialStore={orgStore.groups}
					/>

					{openCreateProjDialog && (
						<CreateProjectDialog
							open={orgStore.groups.openCreateProjDialog}
							setOpen={orgStore.groups.setOpenCreateProjDialog}
							//
							currentMenu={DLOrgMenuGroups.groups} // TODO: update is required
							createMethod={orgStore.groups.createProjectMethod}
							//
							path={
								orgStore.groups
									.selectionPathWithNameAndFormatToCreateProject
							}
							setIdPath={setIdPath}
							presetInputs={presetInputs}
						/>
					)}
					{updateDialogOpenStatus && (
						<ProjInfoUpdateDialog
							i18n={i18n}
							partialStore={orgStore.projects}
							// open={partialStore.updateDialogOpenStatus}
							// setOpen={partialStore.setUpdateDialogOpenStatus}
						/>
					)}
					{openCreateCabinetDialog && <CreateOrgGroupCabinetDialog />}
					{openEditCabinetDialog && <EditOrgGroupCabinetDialog />}
					{openMoveDialog && (
						<MoveGroupItemDialog
							i18n={i18n}
							targetItemId={orgStore.groups.rightClickTarget}
							currentMenu={menuId}
						/>
					)}

					{/* Do not remove condition or set additional condition on the ctx menu  */}
					{/* issue can be occurred when the cabinet or project do not exist,  */}
					{groupCtxMenuOpen && (
						<GroupCtxMenu permission={permission} />
					)}
					{cabinetCtxMenuOpen && (
						<CabinetCtxMenu permission={permission} />
					)}
					{projCtxMenuOpen && (
						<ProjectCtxMenu permission={permission} />
					)}
					{orgStore.groups.groupDetailDialogOpenStatus && (
						<GroupDetailDialog i18n={i18n} />
					)}
					{orgStore.groups.openDeleteProjDialog && (
						<DeleteProjectDialog
							i18n={i18n}
							isOpen={orgStore.groups.openDeleteProjDialog}
							setIsOpen={orgStore.groups.setOpenDeleteProjDialog}
							deleteAction={() =>
								orgStore.groups.removeProject({
									projectId: orgStore.groups.selectedProj,
									cabinetId,
									handleResponse:
										orgStore.groups.handleResponse,
									postWorkForSuccess: () => {
										orgStore.groups.reflectDelete(
											orgStore.groups.selectedProj
										)
										orgStore.groups.setOpenDeleteProjDialog(
											false
										)
									},
								})
							}
							projectInfo={orgStore.groups.getSelectedProjInfo(
								orgStore.groups.selectedProj
							)}
						/>
					)}
				</>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

type BreadCrumbsArrayType = { id: number; name: string; type: string }

function pushArrayItemsToList(
	breadCrumbArr: BreadCrumbsArrayType[],
	state: any
) {
	breadCrumbArr.map((item: BreadCrumbsArrayType, i: number, arr) =>
		state.pushItemToList({
			createdAt: "",
			createdBy: "",
			detail: null,
			expanded: true,
			id: item.id,
			parentId: i === 0 ? null : arr[i - 1].id,
			title: item.name,
			type: item.type,
		})
	)
	return state
}

function getAssignedGroups(orgStore: any, state: any) {
	orgStore.groups.resetCurrentPath()
	orgStore.groups.resetQuery()
	orgStore.groups.getOrgGroupList(orgStore.checkin.orgInfo.userId, () => {
		if (state) {
			const groupId = state[0].id
			orgStore.groups.toggleExpanded(groupId)
			orgStore.groups.setCurrentPath([groupId])
			orgStore.groups.getOrgGroupCabinetList(groupId, () => {
				// const cabinetId = state[1].id
				// orgStore.groups.getOrgCabinetDetail(
				// 	cabinetId
				// )
				// orgStore.groups.pushItemToCurrentPath(
				// 	cabinetId
				// )
			})
		}
	})
}
