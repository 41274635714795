import React from "react"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { PageContainer } from "../../../../components/app-frame-elements"
import { DLSpinner } from "../../../../components/basic-elements"
import FinancialStatementType from "./sub-components/FinancialStatementType"
import FinancialGroup from "./sub-components/FinancialGroup"
import FinancialSubGroup from "./sub-components/FinancialSubGroup"
import LeadSheet from "./sub-components/LeadSheet"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import MapUnmapGroupDialog from "./sub-components/dialogs/MapUnmapGroupDialog"
import MapUnmapSubGroupDialog from "./sub-components/dialogs/MapUnmapSubGroupDialog"
import MapUnmapLeadSheetDialog from "./sub-components/dialogs/MapUnmapLeadSheet"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import styled from "styled-components"

export default observer(function FSSetup({
	partialStore,
	contentsHeight,
	fetchingStatus,
	permission,
}: {
	partialStore: any
	contentsHeight: any
	fetchingStatus: ActionStatus
	permission: PermissionAsObjectProps
}) {
	return (
		<PageContainer hasToolbar={false} pageTools={<div />}>
			<StyledFSSetup>
				{fetchingStatus === ActionStatus.loading && (
					<div
						className="FR AC JC"
						style={{ width: "100%", height: contentsHeight }}
					>
						<DLSpinner />
					</div>
				)}
				{fetchingStatus === ActionStatus.success && (
					<div className="fs-setup-container FR AC JSA">
						<FinancialStatementType permission={permission} />
						<FinancialGroup permission={permission} />
						<FinancialSubGroup permission={permission} />
						<LeadSheet permission={permission} />
					</div>
				)}
				<CommonSnackbarComponent
					i18n={{ x: "x" }}
					partialStore={partialStore}
				/>
				{partialStore.mapUnmapGroupDialogOpen && (
					<MapUnmapGroupDialog permission={permission} />
				)}
				{partialStore.mapUnmapSubGroupDialogOpen && (
					<MapUnmapSubGroupDialog permission={permission} />
				)}
				{partialStore.mapUnmapLeadSheetDialogOpen && (
					<MapUnmapLeadSheetDialog permission={permission} />
				)}
			</StyledFSSetup>
		</PageContainer>
	)
})

const StyledFSSetup = styled.div`
	.fs-setup-container {
		display: flex;
		flex-wrap: wrap;
	}
`
