import React, { useState, useEffect } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLListTransfer,
	DLDialogHeader,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { AssignStatus } from "../../../../../stores/org-sub-stores/org-clients-store/data-models/org-clients.data-models"

const AssignClientsToGroupDialog = observer(
	({ i18n }: { i18n: DLI18nProps }) => {
		ConsoleLog(" -=-=-=-=- AssignClientsToGroupDialog -=-=-=-=- ")
		const orgStore = useOrgStore()
		const partialStore = orgStore.setupGroups

		const groupId = partialStore.selectedGroup
		const groupName = partialStore.getGroupInfoById(groupId)?.groupName

		const [searchClient, setSearchClient] = useState<string>("")
		const [searchAssignedClient, setSearchAssignedClient] =
			useState<string>("")
		const [selectedClients, setSelectedClients] = useState<any>([])
		const [selectedAssignedClients, setSelectedAssignedClients] =
			useState<any>([])

		const selectHandler = (clientMasterID: string, type: number) => {
			let tempArray =
				type === 1 ? [...selectedClients] : [...selectedAssignedClients]
			if (tempArray.some((x) => x.ClientMasterID === clientMasterID)) {
				tempArray.splice(
					tempArray.findIndex(
						(item: any) => item.ClientMasterID === clientMasterID
					),
					1
				)
			} else tempArray.push({ ClientMasterID: clientMasterID })

			if (type === 1) {
				setSelectedClients(tempArray)
				partialStore.setClientSelect(clientMasterID)
			} else {
				setSelectedAssignedClients(tempArray)
				partialStore.setAssignedClientSelect(clientMasterID)
			}
		}

		const handleCheckedRight = () => {
			partialStore.addClientsToGroup({
				groupId,
				clientList: selectedClients,
				type: AssignStatus.unAssigned,
				postSuccess: () => setSelectedClients([]),
			})
		}

		const handleCheckedLeft = () => {
			partialStore.addClientsToGroup({
				groupId,
				clientList: selectedAssignedClients,
				type: AssignStatus.assigned,
				postSuccess: () => setSelectedAssignedClients([]),
			})
		}

		const handleSearchLeft = (event: any) => {
			const query = event.target.value
			setSearchClient(query)
		}

		const handleSearchRight = (event: any) => {
			const query = event.target.value
			setSearchAssignedClient(query)
		}

		const handleSelect50Items = (event: any) => {
			const notAssignedClients = partialStore.clientList
			notAssignedClients.forEach((client: any, i: number) => {
				const isUserSelected = selectedClients.find(
					(item: any) => item.ClientMasterID === client.id
				)
				if (!isUserSelected) {
					if (i < 50) {
						setSelectedClients((prev: any) => [
							...prev,
							{ ClientMasterID: client.id },
						])
						partialStore.setClientSelect(client.id)
					}
				} else {
					if (i < 50) {
						return
					} else {
						setSelectedClients((prev: any) => {
							return prev.filter(
								(item: any) => item.ClientMasterID !== client.id
							)
						})
						partialStore.setClientSelect(client.id)
					}
				}
			})
		}

		useEffect(() => {
			partialStore.getClientsByGroup(groupId, AssignStatus.unAssigned)
			partialStore.getClientsByGroup(groupId, AssignStatus.assigned)
		}, [groupId])

		const isLoading =
			partialStore.getActionStatus("addClientsToGroup") === "LOADING" ||
			partialStore.getActionStatus("clientListASSIGNED") === "LOADING" ||
			partialStore.getActionStatus("clientListUNASSIGNED") === "LOADING"

		const assignedClientsLength =
			partialStore.viewAssignedClientsList("").length
		const unassignedClientsLength = partialStore.viewClientsList("").length

		return (
			<DLDialog
				eleTestId="assign-clients-to-group-dialog"
				isOpen={partialStore.assignClientsToGroupDialogOpen}
				setIsOpen={partialStore.setAssignClientsToGroupDialogOpen}
				showOpenBtn={false}
				showCloseBtn
				dialogTitle={
					<DLDialogHeader
						dialogName={
							i18n.twAssignClientstoGroup || "Assign Clients"
						}
						targetName={groupName ? groupName : "(Unknown group)"}
					/>
				}
				dialogContents={
					<DLListTransfer
						unassignedList={partialStore.viewClientsList(
							searchClient
						)}
						assignedList={partialStore.viewAssignedClientsList(
							searchAssignedClient
						)}
						selectListHandler={selectHandler}
						assignedListName={`${i18n.twAssignedClientList} (${assignedClientsLength})`}
						unassignedListName={`${i18n.twClientList} (${unassignedClientsLength})`}
						handleCheckedRight={handleCheckedRight}
						handleCheckedLeft={handleCheckedLeft}
						handleSearchLeft={handleSearchLeft}
						handleSearchRight={handleSearchRight}
						handleSelect50Items={handleSelect50Items}
					/>
				}
				maxWidth="md"
				showSpinner={isLoading}
				cannotUpdate={isLoading}
			/>
		)
	}
)

export default AssignClientsToGroupDialog
