import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import {
	idToNumber,
	idToString,
	IdType,
} from "../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 */

const GetFileLocation = (self: any) => ({
	getFileLocation({
		projId,
		fileId,
		fileType,
		postSuccess,
	}: {
		projId: string
		fileId: string
		fileType: string
		postSuccess: (data: any) => void
	}) {
		// 0. set actionName
		const actionName = "getFileLocation"
		// 1.
		// 2. set responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. request API call

		const clientId = idToNumber(projId, IdType.project)
		const mediaId = parseInt(fileId)
		if (typeof clientId === "string") {
			ConsoleLog("Invalid project Id..." + projId)
			return
		}

		self.readFileLocation(clientId, mediaId, fileType)
			.then((response: any) => {
				ConsoleLog([actionName, response.data])
				if (response.status === 200 && response.data.status === 1) {
					// if success
					// 1) reset the stored list

					const folders = response.data.folderLocation.map(
						(item: any) => ({
							folderId: idToString(
								item.mediaFolderId,
								IdType.folder
							),
							folderDepth: item.folderDepth,
						})
					)

					self.setGoToFolders(folders)
					self.setGoToFile(
						idToString(
							response.data.targetFileInfo.WPRID,
							IdType.file
						)
					)

					// self.setFileLocation(histories)
					postSuccess && postSuccess(response.data)
					// when success
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						customMessage: response.data.message,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetFileLocation
