import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { DLDialog } from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import AuditProgramEditor from "../../../../../lobby-side/file-editor/sub-components/AuditProgramEditor"

export default observer(function AuditProgramDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()
	// const actionName = "addOrgLibraryFolder"

	// const actionStatus = orgStore.orgFileLib.getActionStatus(actionName)

	const handleOnSubmit = () => {}

	return (
		<DLDialog
			eleTestId="audit-program-dialog"
			isOpen={orgStore.orgProjTemplates.openAuditProgramDialog}
			setIsOpen={orgStore.orgProjTemplates.setOpenAuditProgramDialog}
			handleAction={handleOnSubmit}
			showCloseBtn={true}
			dialogTitle="Audit Program"
			dialogContents={
				<AuditProgramEditor
					partialStore={orgStore.orgFileLib}
					fromDialog={true}
				/>
			}
			showOpenBtn={false}
			// cancelBtnText={i18n.twCancel || "Cancel"}
			// actionBtn={i18n.twAdd || "Add"}
			maxWidth="xl"
			fullWidth={true}
			// showSpinner={actionStatus === "LOADING"}
			// cannotUpdate={actionStatus === "LOADING"}
		/>
	)
})
