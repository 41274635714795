import { types } from "mobx-state-tree"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../common-models/types/dialog.props"
import { ConsoleLog } from "../../../components/basic-elements"

const OrgSetupClientsUiHelper = types
	.model({
		//
		// selectedClient: types.string,
		selectedItems: types.array(types.string),
		clickPoint: ClickPoint,
		pauseMultipleClients: false,
		// dialogs
		addClientDialogOpen: false,
		bulkAddClientsDialogOpen: false,
		editClientDialogOpen: false,
		assignClientsToGroupDialogOpen: false,
		removeClientDialogOpen: false,
	})
	.actions((self) => ({
		setBulkAddClientsDialogOpen(request?: boolean) {
			self.bulkAddClientsDialogOpen =
				request ?? !self.bulkAddClientsDialogOpen
		},
		setPauseMultipleClients(pause: boolean) {
			self.pauseMultipleClients = pause
		},
		setSelectedItem(clientId: string) {
			// self.selectedClient = clientId
			self.selectedItems.length = 0
			self.selectedItems.push(clientId)
		},
		resetSelectedItems() {
			self.selectedItems.length = 0
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		pushOrSpliceItem(id: string) {
			// for checkbox
			ConsoleLog("id by checkbox " + id)
			const duplicationCheck = self.selectedItems.find(
				(item) => item === id
			)
			if (duplicationCheck) {
				const targetIndex = self.selectedItems.findIndex(
					(item) => item === id
				)
				self.selectedItems.splice(targetIndex, 1)
			} else self.selectedItems.push(id)
		},
		// dialogs
		setAddClientDialogOpen(request?: boolean) {
			self.addClientDialogOpen = request ?? !self.addClientDialogOpen
		},
		setEditClientDialogOpen(request?: boolean) {
			self.editClientDialogOpen = request ?? !self.editClientDialogOpen
		},
		setAssignClientToGroupDialogOpen(request?: boolean) {
			self.assignClientsToGroupDialogOpen =
				request ?? !self.assignClientsToGroupDialogOpen
		},
		setRemoveClientDialogOpen(request?: boolean) {
			self.removeClientDialogOpen =
				request ?? !self.removeClientDialogOpen
		},
	}))

export default OrgSetupClientsUiHelper
