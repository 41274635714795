import React from "react"
import styled from "styled-components"
import {
	DLBasicToggleButton,
	DLIconButton,
} from "../../../../../components/basic-elements"
import { mdiHelpCircleOutline } from "@mdi/js"
import Icon from "@mdi/react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default function ArchPolicyCondition({
	data,
	i18n,
	permission,
	setMandatory,
}: {
	data: {
		title: string
		description: string
		[x: string]: any
	}
	i18n: DLI18nProps
	permission: PermissionAsObjectProps
	setMandatory: any
}) {
	const orgStore = useOrgStore()
	const { id, aliasId, title, description, isMandatory } = data

	const handleToggle = () => {
		setMandatory(id, !isMandatory)
	}

	const handleClick = () => {
		orgStore.archPolicy.setTargetCondition(aliasId)
		orgStore.archPolicy.setOpenArchConditionHelpDialog(true)
	}

	let ableToMove = true
	if (aliasId.includes(12) || aliasId.includes(16)) {
		// 12: opened files, 16: lock --> cannot move to non-mandatory section
		ableToMove = false
	}

	return (
		<StyledCondition className={`FR ${isMandatory && "mandatory"}`}>
			<div className="shortName">{i18n["twTitle-" + aliasId]}</div>
			<div className="description FR AC JSB">
				<div className="FR AC">{i18n["twDesc-" + aliasId]}</div>
				<div className="FR AC">
					<DLIconButton
						eleTestId={`help-dialog-${data.title}`}
						eleClassName="help-contents"
						clickHandler={handleClick}
					>
						<Icon path={mdiHelpCircleOutline} size={0.6} />
					</DLIconButton>
					{permission.update && (
						<DLBasicToggleButton
							on={isMandatory}
							setOn={handleToggle}
							tooltipText={
								isMandatory
									? `${i18n.twTurnToOptional}`
									: `${i18n.twTurnToMandatory}`
							}
							disabled={!ableToMove}
						/>
					)}
				</div>
			</div>
		</StyledCondition>
	)
}

const StyledCondition = styled.div`
	border: 1px solid ${(props) => props.theme.shade20};
	border-radius: 4px;
	margin-bottom: 0.4rem;
	.shortName {
		height: inherit;
		padding: 0.4rem 1rem;
		background-color: ${(props) => props.theme.secondary};
		margin-right: 1rem;
		width: 10rem;
	}
	.description {
		width: 100%;
		padding-right: 0.6rem;
		.help-contents {
			color: ${(props) => props.theme.shade40};
		}
	}
	&.mandatory {
		border: 1px solid ${(props) => props.theme.emphasis};
		.shortName {
			background-color: ${(props) => props.theme.emphasis};
			font-weight: 700;
		}
	}
`
