import { flow } from "mobx-state-tree"
import { postRequest } from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"

const MAuditStepSignoffReviewed = (self: any) => ({
	mAuditStepSignoffReviewed: flow(function* (payload: any) {
		const actionName = "mAuditStepSignoffReviewed"
		try {
			const endpoint = `/AuditStepSignOffReviewed`
			const response = yield postRequest({
				url: endpoint,
				params: payload,
				tokenType: TOKEN_TYPE.projToken,
			})

			return response
		} catch (error) {
			// ConsoleLog("MAuditStepSignoffReviewed error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default MAuditStepSignoffReviewed
