import { flow } from "mobx-state-tree"
import { OrgSetupClientsApiProps } from "../org-clients.apis"

const CreateClient = (
	self: any,
	create: OrgSetupClientsApiProps["apiCreate"]
) => ({
	createClient: flow(function* (clientInfo) {
		const actionName = "createClient"
		try {
			//TODO: Move this payload to the viewModel
			const payload = {
				ClientId: clientInfo.aliasId,
				Name: clientInfo.name,
			}
			const response = yield create(payload)

			// ConsoleLog("createClient response", response)
			return response
		} catch (error) {
			// console.error("createClient error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default CreateClient
