import { types } from "mobx-state-tree"
import { ProjectStatus } from "../../../../../common-models/enumerations/project-related-enums"
import { CreateProjectMethod } from "../../../../../components/combined-elements/create-project/create-project.data-props"

const UserPropsForAssign = types.model({
	id: types.string,
	roleId: types.string,
	accessRight: types.string,
})

export const ProjInfo = types.model({
	//
	//
	// * 1. location info: group, cabinet
	groupId: "",
	groupName: "",
	cabinetId: "",
	cabinetName: "",
	//
	//
	// * 2. client info
	clientId: "",
	clientName: "",
	clientAliasId: "",
	//
	//
	// * 3. project basic info
	projectId: "",
	projAliasId: "",
	projTitle: "",
	//
	engTypeId: "",
	engTypeName: "",
	raId: "", // types.union(types.string, types.null),
	raName: "", // types.union(types.string, types.null),
	//
	//
	// * 4. project status info
	statusId: "",
	statusName: types.enumeration<ProjectStatus>(Object.values(ProjectStatus)),
	periodId: "",
	periodName: "",
	version: 0,
	//
	isArchived: false,
	isLocked: true,
	//
	//
	// * 5. access info
	// roleId: ...,
	// roleName: ...,
	// accessible: ...,
	//
	//
	// * 6. schedule info
	financialYear: 0,
	financialYearDate: "",
	year: 0,
	periodEndDate: "",
	expectedReportDate: "",
	finalReportDate: "",
	archPolicyPeriodId: "",
	archPolicyPeriodName: "",
	expectedArchDeadlineDate: "",
	finalArchDeadlineDate: "",
	//
	//
	// * 7. history info
	createdBy: "",
	createdAt: "",
	// lastAccessBy: ...,
	// lastAccessAt: ...,
	// archivedBy: ...,
	// archivedFinalDate: types.union(types.null, types.string),
	// unarchivedBy: ...,
	// unarchivedAt: ...,
	//
	//
	// * 8. assigned users
	engPartner: "",
	// qcUsers: ...,
	//
	//
	// * 9. extras
	//
	//
	// * + optional part
	clientAddress: "",
	fee: 0, // TODO: need to add currency
	otherUsers: types.optional(types.array(UserPropsForAssign), []),
	createMethod: types.enumeration<CreateProjectMethod>(
		Object.values(CreateProjectMethod)
	),
	archiveChecklistItem1: "",
	archiveChecklistItem2: "",
	archiveChecklistLastUpdatedBy: "",
	archiveChecklistLastUpdatedByUserAlias: "",
	archiveChecklistLastUpdatedByUserName: "",
	archiveChecklistLastUpdatedDate: "",
	//
	hasExportAccess: types.boolean,
	//
	// * ???
})

// for updaet dialog
export const ClientInGroup = types.model({
	clientId: types.string,
	clientAliasId: types.string,
	clientName: types.string,
})

export const CabinetDetails = types.model({
	id: types.string,
	type: types.string,
	name: types.string,
})
