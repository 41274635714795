import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const DownloadFile = (self: any) => ({
	downloadFile(fileId: string) {
		// 0.
		const actionName = "downloadFile"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 111,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		self.requestDownloadFile(fileId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 200) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					const url = response.data.Data
					// FileSaver.saveAs(url, "blah")
					const downloadWPFile = document.createElement("a")
					downloadWPFile.href = url

					downloadWPFile.setAttribute("download", "")
					document.body.appendChild(downloadWPFile)
					downloadWPFile.click()
					downloadWPFile.remove()
					// getRequest({
					// 	url: url,
					// 	tokenType: TOKEN_TYPE.none,
					// })
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						// autoHide: true,
						// autoHideDuration: 2600,
					})
				} else {
					// set fail case response
					// const serverMsg = response.data.Message
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default DownloadFile
