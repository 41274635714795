import { types } from "mobx-state-tree"
import FileEditorModel from "./file-editor.model"
import FileEditorViewModel from "./file-editor.view-model"
import { FileEditorApiProps } from "./file-editor.api-props"
import * as api from "./file-editor.apis"
import * as orgApi from "../../../organization-side/org-access-control/org-checkin/store/org-checkin.apis"
import * as projApi from "../../../project-side/project-information/store/proj-info.apis"
import { requestToken as requestProjTokenApi } from "../../../project-side/proj-access-control/store/proj-checkin.apis"
import {
	ActionStatus,
	DLSector,
} from "../../../../common-models/enumerations/common-enums"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { CreateProjectMethod } from "../../../../components/combined-elements/create-project/create-project.data-props"

const initialRoleSet = {
	id: "",
	title: "",
	createdAt: "",
	createdBy: "",
	modifiedAt: null,
	modifiedBy: null,
	roles: [],
}

export const FileEditorStore = ({
	apiDiscardFileCheckout,
	apiRequestCheckinFile,
	apiReadOrgInfo,
	apiReadProjInfo,
	apiRequestToken,
}: FileEditorApiProps) =>
	types
		.compose(
			FileEditorModel({
				apiDiscardFileCheckout,
				apiRequestCheckinFile,
				apiReadOrgInfo,
				apiReadProjInfo,
				apiRequestToken,
			}),
			FileEditorViewModel
		)
		.named("FileEditorStore")

export const initialStore = {
	editorCheckin: ActionStatus.standby,
	orgInfo: {},
	projInfo: {
		statusName: ProjectStatus.unknown,
		createMethod: CreateProjectMethod.byUndefined,
		hasExportAccess: false,
	},
	fileStatus: {
		// id: "",
		checkedOutById: null,
		checkedOutByName: null,
	},
	// user, client, roleSet, assignedRole are same with the project access control
	user: {},
	client: {},
	roleSet: initialRoleSet,
	assignedRole: {
		roleId: "",
		usePeriod: {},
		globalPermissions: [],
		menuPermissions: [],
		//
		name: "",
		shortName: "",
		color: "",
	},
	urlInfo: {
		sector: DLSector.lobby,
	},
	// common parts for ViewModel
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const FileEditorStoreInstance = FileEditorStore({
	apiDiscardFileCheckout: api.discardFileCheckout,
	apiRequestCheckinFile: api.requestCheckinFile,
	apiReadOrgInfo: orgApi.readOrgInfo,
	apiReadProjInfo: projApi.read,
	apiRequestToken: requestProjTokenApi,
}).create(initialStore)

export default FileEditorStoreInstance
