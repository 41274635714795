import React from "react"
import { DLIconButton } from "../../../../../components/basic-elements"
import { DLCheckbox } from "@datalobby/components"
//
import Icon from "@mdi/react"
import { mdiDotsHorizontal } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { dateRangeFilter } from "../../../../../library/react-table-utils"

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	console.log(props.column.getSize(), "props")
	const size = props.column.getSize()
	return (
		<div
			data-testid={cellId}
			style={{
				width: size,
				textOverflow: "ellipsis",
				overflow: "hidden",
				// "text-wrap": "nowrap",
			}}
		>
			{props.getValue()}
		</div>
	)
}

export const OrgSetupClientsTableColumn = (
	handleContextMenu: any,
	handleCheckbox: any,
	isSelected: any,
	i18n: DLI18nProps
) => [
	{
		header: "",
		accessorKey: "clientId",
		size: 20,
		enableColumnFilter: false,
		cell: (props: any) => {
			const id = props.row.original.clientId
			const handleClick = () => {
				handleCheckbox(id)
			}

			return (
				<DLCheckbox
					eleTestId={`checkbox-${id}`}
					clickHandler={() => handleClick()}
					color="primary"
					isChecked={isSelected(id)}
				/>
			)
		},
	},
	{
		header: "S.no",
		size: 30,
		cell: (props: any) => {
			return props.row.index + 1
		},
	},
	{
		header: i18n.twClientId || "Client ID",
		accessorKey: "clientAliasId",
		cell: renderCustomCell,
	},
	{
		header: i18n.twClientName || "Client Name",
		accessorKey: "clientName",
		cell: renderCustomCell,
	},
	{
		header: i18n.twCreatedAt || "Created At",
		accessorKey: "createdAt",
		filterFn: dateRangeFilter,
		cell: renderCustomCell,
	},
	{
		header: i18n.twCreatedBy || "Created By",
		accessorKey: "createdBy",
		cell: renderCustomCell,
	},
	{
		header: "Actions",
		accessorKey: "xxx",
		enableColumnFilter: false,
		size: 20,
		cell: (props: any) => {
			const clientId = props.row.original.clientId
			return (
				<div className="FR AC JC" style={{ width: "100%" }}>
					<DLIconButton
						aria-label="more"
						clickHandler={(event: any) =>
							handleContextMenu(event, clientId)
						}
						eleTestId="client-ctx-btn-on-row"
					>
						<Icon path={mdiDotsHorizontal} size={0.8} />
					</DLIconButton>
				</div>
			)
		},
	},
]
