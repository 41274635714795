import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { findPrevRight } from "../../../../../temporary-data/project-side/find-proj-role"
import {
	idToString,
	IdType,
} from "../../../../../library/converters/id-converter"
import { reOrganizeProjInfo } from "../../../project-information/store/view-model-actions/get-proj-info"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { convertProjRole } from "../../../../../library/converters/convert-role-id"

const GetProjInfo = (self: any) => ({
	getProjInfo(projId: number, additionalAction?: any) {
		// 0.
		const actionName = "getProjInfo"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3).
		// let projRoleSet: any // NOTE: this part should be added on the project info API or...
		// self.readProjRoleSet().then((response: RoleSetProps) => {
		// 	projRoleSet = response
		// })

		self.readProjInfo(projId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const projInfo = response.data
					/** NOTE:
					 * required project default info in access control
					 * standard: without project information screen loading, able to match the requirements of project side layout? (ex. project side header)
					 * --> because some user re-open the proj side screen which is not the project information
					 */

					ConsoleLog([actionName, "__response ", response])

					const projRoleInfo = convertProjRole(projInfo.RoleId)

					let prevRight: undefined | string = ""
					if (projInfo.userType === 1) {
						// internal
						prevRight = findPrevRight(
							projInfo.AccessRights,
							true,
							projInfo.RoleId
						)
					} else if (projInfo.userType === 2) {
						// external
						prevRight = findPrevRight(
							projInfo.AccessRights,
							false,
							projInfo.RoleId
						)
					}

					const orgUserName = localStorage.getItem("orgUserName") // @Noah
					let user = {
						userId: idToString(projInfo.UserID, IdType.user), // NOTE: WARNING: UserID seems always 0. Update is required
						isReadonly: projInfo.AccessRights === "ReadOnly",
						roleId: projRoleInfo.roleId
							? projRoleInfo.roleId
							: "No matched role",
						roleColor: projRoleInfo.color,
						roleShortName: projRoleInfo.shortName,
						prevRight,
						userType:
							projInfo.userType === 1
								? "internal"
								: projInfo.userType === 2
								? "external"
								: "x",
						name: orgUserName ? orgUserName : "",
						oldAccessRightId:
							projInfo.AccessRights === "Project & Archive Admin"
								? 1
								: projInfo.AccessRights === "Project Admin"
								? 2
								: projInfo.AccessRights === "Project User"
								? 3
								: projInfo.AccessRights === "Readonly"
								? 4
								: 5,
						oldNumId: projInfo.RoleId,
					}
					let client = {
						id: idToString(projInfo.ClientMasterID, IdType.client),
						name: projInfo.ClientName,
						aliasId: projInfo.clientShortId, // client aliasId
					}

					self.setUser(user)
					self.setClient(client)
					// self.setRoleSet(projRoleSet)

					// 1. in the project chekcin, update the project information store together
					// 2. in the editor, update the project information part together
					additionalAction &&
						additionalAction(reOrganizeProjInfo(projInfo))

					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						customMessage:
							"Success to get the default project informations",
						color: MessageColorType.blue,
						open: false,
					})
				} else {
					self.setProjCheckin(ActionStatus.fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						customMessage: "Something wrong",
						color: MessageColorType.orange,
						open: false,
					})
				}
				//
			})
			.catch((error: Error) => {
				ConsoleLog([actionName, "-error: ", error])
				self.setProjCheckin(ActionStatus.fail)
				self.handleResponse({
					actionName,
					status: ActionStatus.fail,
					code: 999,
					customMessage:
						"Fail to get the default project informations",
					color: MessageColorType.red,
					open: true,
				})
			})
	},
})

export default GetProjInfo
