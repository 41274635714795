import React from "react"
// for store
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
// for input area
import sharedRegEx from "../../../../../library/sharedRegEx"
// validate form
import useForm from "../../../../../library/use-form"
// for UI
import styled from "styled-components"
import {
	DLButton,
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLDialogHeader,
} from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { I18nDialogFormProps } from "../../../../../common-models/types/dialog.props"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
} from "../../../../../components/basic-elements/icons/common-icons"

export const AddGroupBtn = ({ i18n }: { i18n: DLI18nProps }) => {
	const orgStore = useOrgStore()
	return (
		<DLButton
			variant="contained"
			size="regular"
			eleClassName="top-row-btns"
			eleTestId="add-group-dialog-open-btn"
			startIcon={<Icon path={mdiPlus} size={1} />}
			color="primary"
			clickHandler={() =>
				orgStore.setupGroups.setOpenAddGroupDialog(true)
			}
		>
			{i18n.twAddGroup}
		</DLButton>
	)
}

export default observer(function AddGroupDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	// ConsoleLog(" -=-=-=-=- AddGroupDialog -=-=-=-=- ")
	const orgStore = useOrgStore()
	const actionName = "addGroup"
	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
	}

	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}

	const addGroup = () => {
		orgStore.setupGroups.addGroup(inputs.name.value)
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		addGroup,
		orgStore.setupGroups.addGroupDialogOpen // --> resetPoint
	)

	// if (orgStore.setupGroups.actionStatus === "SUCCESS") {
	// 	setOpen(false)
	// }
	const { twSubmit, twCancel } = i18n
	return (
		<DLDialog
			eleTestId="add-org-group-dialog"
			isOpen={orgStore.setupGroups.addGroupDialogOpen}
			setIsOpen={orgStore.setupGroups.setOpenAddGroupDialog}
			handleAction={handleOnSubmit}
			showCloseBtn
			dialogTitle={
				<DLDialogHeader
					dialogName={i18n.twAddGroup}
					targetName={""}
					icon={
						<DLIcon
							name={DLIconName.add}
							size={CommonIconSize.dialogHeaderIcon}
						/>
					}
				/>
			}
			dialogContents={
				<AddGroupDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			cancelBtnText={twCancel}
			actionReady={
				isReady &&
				!orgStore.setupGroups.isDuplicatedName(inputs.name.value)
			}
			actionBtn={twSubmit}
			maxWidth="sm"
			fullWidth={true}
			showSpinner={
				orgStore.setupGroups.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				orgStore.setupGroups.getActionStatus(actionName) === "LOADING"
			}
			useEnterKeyForSubmit
		/>
	)
})

const AddGroupDialogForm = observer(
	({ inputs, handleOnChange, i18n }: I18nDialogFormProps) => {
		const orgStore = useOrgStore()

		return (
			<StyledAddGroupDialog>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twName}>
						<DLInputField
							autoFocus
							eleTestId="group-name-input"
							eleFullWidth
							elePlaceholder={i18n.twNamePlaceHolder}
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								inputs.name.error ||
								(orgStore.setupGroups.isDuplicatedName(
									inputs.name.value
								) &&
									i18n.twNameValidation)
							}
							warningType={
								orgStore.setupGroups.isDuplicatedName(
									inputs.name.value
								)
									? "red"
									: undefined
							}
							requestInput={inputs.name.requestInput}
						/>
					</InputWithLabel>
				</div>
			</StyledAddGroupDialog>
		)
	}
)

const StyledAddGroupDialog = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
