/**
 *
 * 1. my page
 * 2. notifications
 * 3. dashboard
 * 4. resource mgmt
 * 5. archive mgmt
 * 6. governance
 * 7. projects
 * 8. library
 * 9. trashes
 * 10. setup
 * 11. org mgmt
 * 12. org search
 *
 */

export enum DLOrgMenuGroups {
	my_page = "G_MyPage",
	assigned_projects = "G_AssignedProjects",
	archiving_reminder = "G_ArchivingReminder",
	noti = "G_Notifications",
	dashboard = "G_OrgDashboard",
	resource_mgmt = "G_ScheduleAndBudget",
	arch_mgmt = "G_ArchiveMgmt",
	groups = "G_Groups",
	hard_copy_management = "G_HardCopyManagement",
	reports_mgmt = "G_OrgReportsMgmt",
	clients = "G_Clients",
	engagements = "G_Engagements",
	projects = "G_Projects",
	org_normal_setup = "G_OrgNormalSetup",
	lib = "G_OrgLib",
	trash = "G_OrgTrash",
	setup = "G_OrgSetup",
	org_mgmt = "G_OrgMgmt",
	search = "G_OrgSearch",
	admin_remind = "G_AdminRemind",
}

export enum DLOrgSubMenus {
	// 1. my page
	recent = "RecentProjects",
	assigned = "AssignedProjects",

	my_info = "MyInfo",
	my_history = "MyHistory",
	my_lib = "MyLib",
	my_rental_list = "MyRentalList",
	remind_archiving = "RemindArchiving",
	remind_report = "RemindReport",

	// X. assigned projects (G_AssignedProjects)
	assignedNormal = "AssignedNormalProjects",
	assignedReplica = "AssignedReplicaProjects",
	assignedArchived = "AssignedArchivedProjects",
	assignedUnarchived = "AssignedUnarchivedProjects",
	assignedArchivedReadonlyProjects = "AssignedArchivedReadonlyProjects",
	assignedForceArchivedProjects = "AssignedForceArchivedProjects",
	// 2. notifications
	org_noti = "NotiFromOrg",
	// sys_noti = "NotiFromSystem",
	// 3. dashboard
	dash_chart = "OrgDashboardChart",
	dash_graph = "OrgDashboardGraph",
	dash_analysis = "OrgAnalysis",
	dash_table_summary = "OrgDashboardTable",
	//
	hard_copy = "HardCopy",
	hard_copy_rental = "HardCopyRental",
	hard_copy_dashboard = "HardCopyDashboard",
	hard_copy_config = "HardCopyConfig",
	hard_copy_history = "HardCopyHistory",
	hard_copy_trash = "HardCopyTrash",
	// 4. resource mgmt (schedule and budgeting)
	schedule = "OrgSchedule",
	budget = "OrgBudget",
	// 5. archive management
	archived_mgmt = "ArchProjMgmt",
	archived_mgmt2 = "ArchProjMgmt2",
	unarchived_mgmt = "UnarchProjMgmt",
	am_force_archived = "AMForceArchivedProjects",
	// 6. governance (groups)
	groups = "Groups",
	// report management & checklist
	online_projects = "OnlineProjects", // previous: final_reports & FinalReports
	other_projects = "OtherProjects",
	checklists = "Checklists",
	// x1. clients
	clients = "Clients",
	engagements = "Engagements",
	// x2. engagements
	// 7. projects
	normal_projs = "NormalProj",
	replicas = "ReplicaProj",
	force_archived = "ForceArchivedProjects",
	archived = "ArchivedProj",
	unarchived = "UnarchivedProj",
	// 8. library (templates and file library)
	proj_templates = "ProjTemplates",
	file_lib = "OrgFileLib",
	// 9. trashes
	file_trash = "OrgTrash", // TODO: WARNING: Check the name. seems not a file trash...
	// 10. setup
	setup_users = "SetupUsers",
	setup_groups = "SetupGroups",
	setup_clients = "SetupClients",
	setup_eng_types = "SetupEngType",
	setup_risk = "SetupRiskAssessment",
	setup_arch_policy = "SetupArchPolicy",
	setup_roles = "SetupOrgRoleSet",
	setup_proj_role_set = "SetupProjRoleSet",
	setup_sys = "SetupSys",
	setup_billing = "SetupBilling",
	setup_checklist = "SetupChecklist",
	setup_notifications = "SetupNotifications",
	// X.
	readonly_users = "ReadonlyOrgUserList",
	readonly_clients = "ReadonlyOrgClientList",
	readonly_groups = "ReadonlyOrgGroupList",
	// 11. org account mgmt
	org_mgmt = "OrgMgmt",
	// 12. org search
	search = "Search",
	// 13. Admin Remind
	admin_remind_report = "AdminRemindReport",
	admin_remind_archiving = "AdminRemindArchiving",
	//
	arc_rem_norm_projs = "ArchivingReminderNormalProjects",
}
