import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
	IconPlaceholder,
} from "../../../../components/basic-elements/icons/common-icons"
import {
	DLOrgMenuGroups,
	DLOrgSubMenus,
} from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { ActionStatus } from "@datalobby/types"
import {
	DLContextMenu,
	ConsoleLog,
} from "../../../../components/basic-elements"
import { CreateProjectMethod } from "../../../../components/combined-elements/create-project/create-project.data-props"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"

const SharedCtxMenuForAssignedProjects = observer(
	({
		partialStore,
		permission,
		projStatus,
	}: {
		partialStore: any
		permission: PermissionAsObjectProps
		projStatus: ProjectStatus
	}) => {
		const orgStore = useOrgStore()
		const projId = partialStore.selectedProj
		const projNumId = idToNumber(projId, IdType.project)
		const projectInfo = partialStore.viewSelectedProjInfo(
			projId,
			projStatus
		)

		console.log(projectInfo, "projInfo")

		const handleClose = () => {
			partialStore.setClickPoint({
				mouseX: null,
				mouseY: null,
			})
		}

		const clickPoint = {
			mouseX: partialStore.clickPoint.mouseX,
			mouseY: partialStore.clickPoint.mouseY,
		}

		const menuOptions = [
			{
				label: "Open",
				value: "open",
				disabled: !projectInfo?.accessible,
				available: projectInfo?.accessible && permission.read,
				isLink: true,
				linkTo: `/project/checkin/${projNumId}`,
				icon: <IconPlaceholder />,
			},
			{
				label: "Export Archived Zip File",
				value: "ctx-export_archived_zip_file",
				available:
					permission.export &&
					projStatus === ProjectStatus.roamArchived,
				disabled:
					!projectInfo?.accessible ||
					!projectInfo?.hasExportPermission,
				tooltip: !projectInfo?.accessible
					? "Readonly access is required"
					: "",
				clickEvent: () =>
					orgStore.archiveMgmt.setArchiveZipRegenerationDialog(true),
				icon: <IconPlaceholder />,
			},
			{
				label: "Project Information",
				value: "ctx-proj_info",
				available: permission.read,
				clickEvent: () =>
					partialStore.setProjectDetailsDialogOpen(true),
				icon: <IconPlaceholder />,
			},
			{
				label: "Pre-Process PDFs",
				value: "ctx-pre_process_pdf",
				available:
					projStatus === ProjectStatus.replica && permission.read,
				// disabled: !projectInfo?.hasExportPermission,
				// tooltip: !projectInfo?.hasExportPermission
				// 	? "Readonly access is required"
				// 	: "",
				// clickEvent: handlePreProcessPdfs,
				clickEvent: () => partialStore.setPreProcessDialog(true),
				icon: <IconPlaceholder />,
			},
			{
				label: "Roll Forward",
				value: "ctx-roll_forward",
				// NOTE: Arch mgmt use different ctx menu... (AM UnArch uses this basic ctx menu)
				// TODO: Is this right method? Isn't it better to use same ctx menu on the all proejcts screen?
				// disabled: projStatus !== ProjectStatus.archived,
				// WARNING: Only the user who has roll-forward permission can do that (also SA and AA)
				// NOTE: what happens if the user doesn't have the roll forward permission?
				available:
					projStatus !== ProjectStatus.archived &&
					projStatus !== ProjectStatus.unarchived &&
					projStatus !== ProjectStatus.roamArchived &&
					permission.create,
				clickEvent: () => {
					if (partialStore.selectedItems.length === 1) {
						partialStore.setCreateProjectMethod(
							CreateProjectMethod.byRollForward
						)
						// TODO: ProjectStatus part should be updated
						// WARNING:

						partialStore.setOpenCreateProjDialog(true)
					} else if (partialStore.selectedItems.length > 1) {
						alert(
							`Cannot do roll forward multiple projects. Please try again`
						)
					} else if (partialStore.selectedItems.length === 0) {
						alert(`Please select a project first`)
					} else {
						ConsoleLog("What happens here..??")
					}
				},
				icon: (
					<DLIcon
						name={DLIconName.duplicate}
						size={CommonIconSize.ctxMenuIcon}
					/>
				),
			},
			{
				label: "Cabinet Info",
				value: "cabinet_info",
				available: permission.read,
				clickEvent: () => partialStore.setOpenProjLocationDialog(true),
				icon: <IconPlaceholder />,
			},
			// {
			// 	label: "Delete",
			// 	value: "ctx-proj_delete",
			// 	// WARNING: Only the project archive admin can delete the project (except the SA and AA)
			// 	available:
			// 		// getProjDetailStatus === ActionStatus.success &&
			// 		partialStore.storeName === DLOrgMenuGroups.projects ||
			// 		partialStore.storeName === DLOrgMenuGroups.dashboard
			// 			? (userRole === "AA" || userRole === "SA") &&
			// 			  projStatus !== ProjectStatus.archived &&
			// 			  projStatus !== ProjectStatus.unarchived &&
			// 			  permission.delete
			// 			: (userRole === "AA" || userRole === "SA") &&
			// 			  permission.delete,

			// 	clickEvent: () => partialStore.setOpenDeleteProjDialog(true),
			// 	icon: (
			// 		<DLIcon
			// 			name={DLIconName.delete}
			// 			size={CommonIconSize.ctxMenuIcon}
			// 		/>
			// 	),
			// },
		]

		return (
			<DLContextMenu
				eleId="assigned-project-ctx-menus"
				clickPoint={clickPoint}
				handleClose={handleClose}
				menuOptions={menuOptions}
				hasDivider={[1]}
			/>
		)
	}
)

export default React.memo(SharedCtxMenuForAssignedProjects)
