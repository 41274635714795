import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import {
	BulkUserImportType,
	OrgUserListType,
} from "../data-models/org-users.data-models"

export const BulkUserImportRelated = (self: any) => ({
	resetBulkUserList() {
		self.bulkUserList.length = 0
	},
	pushItemToBulkList(item: any) {
		self.bulkUserList.push(item)
	},
	setBulkUserList(list: any) {
		self.bulkUserList = list
	},
	updateBulkUserStatus(
		statusMsg: string,
		status: ActionStatus,
		userId: string,
		fetchedUserId?: string
	) {
		const index = self.bulkUserList.findIndex(
			(user: any) => user.userId === userId
		)
		self.bulkUserList[index].status = status
		self.bulkUserList[index].statusMessage = statusMsg
		if (fetchedUserId) {
			self.bulkUserList[index].userId = fetchedUserId
		}
	},
	spliceBulkUser(userId: string) {
		const targetIndex = self.bulkUserList.findIndex(
			(user: any) => user.userId === userId
		)
		self.bulkUserList.splice(targetIndex, 1)
	},
	setBulkUserFileReadStatus(status: ActionStatus) {
		self.bulkUserFileReadStatus = status
	},
})

export type DuplicatedUserItemType = {
	id: string
	name: string
	email: string
	duplicatedEmail: boolean
	aliasId: string
	duplicatedAliasId: boolean
}

// NOTE: views
export const BulkUserImportRelatedViews = (self: any) => ({
	get viewBulkUserList() {
		return self.bulkUserList
	},
	get viewDuplicatedInput() {
		let allEmailsInSheet: string[] = []
		let allAliasIdsInSheet: string[] = []

		self.bulkUserList.map((item: any) => {
			allEmailsInSheet.push(item.email)
			allAliasIdsInSheet.push(item.aliasId)
		})

		let duplicatedItems: DuplicatedUserItemType[] = []
		let duplicatedItemsInSheet: DuplicatedUserItemType[] = []

		self.orgUserList.map((item: OrgUserListType) => {
			let duplicatedItem: DuplicatedUserItemType = {
				id: "",
				name: "",
				email: "",
				duplicatedEmail: false,
				aliasId: "",
				duplicatedAliasId: false,
			}
			if (allEmailsInSheet.includes(item.email)) {
				duplicatedItem.id = item.id
				duplicatedItem.name = item.name
				duplicatedItem.email = item.email
				duplicatedItem.aliasId = item.aliasId
				duplicatedItem.duplicatedEmail = true
			}
			if (allAliasIdsInSheet.includes(item.aliasId)) {
				duplicatedItem.id = item.id
				duplicatedItem.name = item.name
				duplicatedItem.email = item.email
				duplicatedItem.aliasId = item.aliasId
				duplicatedItem.duplicatedAliasId = true
			}
			if (
				duplicatedItem.duplicatedEmail ||
				duplicatedItem.duplicatedAliasId
			) {
				duplicatedItems.push(duplicatedItem)
			}
		})

		self.bulkUserList.map((item: BulkUserImportType) => {
			let duplicatedItem: DuplicatedUserItemType = {
				id: "",
				name: "",
				email: "",
				duplicatedEmail: false,
				aliasId: "",
				duplicatedAliasId: false,
			}
			if (allEmailsInSheet.filter((x) => x === item.email).length > 1) {
				duplicatedItem.id = item.userId
				duplicatedItem.name = item.name
				duplicatedItem.email = item.email
				duplicatedItem.aliasId = item.aliasId
				duplicatedItem.duplicatedEmail = true
			}
			if (
				allAliasIdsInSheet.filter((x) => x === item.aliasId).length > 1
			) {
				duplicatedItem.id = item.userId
				duplicatedItem.name = item.name
				duplicatedItem.email = item.email
				duplicatedItem.aliasId = item.aliasId
				duplicatedItem.duplicatedAliasId = true
			}
			if (
				duplicatedItem.duplicatedEmail ||
				duplicatedItem.duplicatedAliasId
			) {
				duplicatedItemsInSheet.push(duplicatedItem)
			}
		})

		return { duplicatedItems, duplicatedItemsInSheet }
	},
	viewSubmitStatusCount(status: ActionStatus) {
		return self.bulkUserList.filter((item: any) => item.status === status)
			.length
	},
})
