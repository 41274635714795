import React from "react"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { PageContainer } from "../../../../components/app-frame-elements"
import AddRiskAssessmentDialog from "./dialogs/AddRiskAccessmentDialog"
import EditRiskAssessmentDialog from "./dialogs/EditRiskAssessmentDialog"
import DeleteRiskAssessmentDialog from "./dialogs/DeleteRiskAccessmentDialog"
import OrgSetupRiskAssessmentTable from "./sub-components/OrgSetupRiskAssessmentTable"
import OrgSetupRiskAssessmentTableMenus from "./sub-components/OrgSetupRiskAssessmentTableMenus"
import styled from "styled-components"
import CannotDeleteDialog from "./dialogs/CannotDeleteDialog"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { PermissionAsObjectProps } from "../../../../common-models/permission"

export default observer(function OrgSetupRiskAssessment({
	partialStore,
	fetchingStatus,
	permission,
	i18n,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			fixedHeight
			isLoading={fetchingStatus === "LOADING"}
		>
			<StyledSetupRiskAssessment>
				<OrgSetupRiskAssessmentTable
					i18n={i18n}
					permission={permission}
				/>
			</StyledSetupRiskAssessment>
			{/* response snackbar -- can be removed or commented in the future */}
			<CommonSnackbarComponent i18n={i18n} partialStore={partialStore} />

			<OrgSetupRiskAssessmentTableMenus permission={permission} />
			{partialStore.addRiskAssessmentDialogOpen && (
				<AddRiskAssessmentDialog i18n={i18n} />
			)}
			{partialStore.editRiskAssessmentDialogOpen && (
				<EditRiskAssessmentDialog i18n={i18n} />
			)}
			{partialStore.removeRiskAssessmentDialogOpen && (
				<DeleteRiskAssessmentDialog i18n={i18n} />
			)}
			{partialStore.cannotRemoveDialogOpen && (
				<CannotDeleteDialog i18n={i18n} />
			)}
		</PageContainer>
	)
})

const StyledSetupRiskAssessment = styled.div`
	padding: 0 1rem;
`
