import React from "react"
import { Route } from "react-router-dom"

import OrgDashboardCardViewController from "./card-view/OrgDashboardCardViewController"
import OrgDashboardGraphViewController from "./graph-view/OrgDashboardGraphViewController"
import OrgDashboardWideCardView from "./wide-view/OrgDashboardWideCardView"
import OrgDashboardListView from "./list-view/OrgDashboardListView"
import OrgDashboardTableViewController from "./table-view/OrgDashboardTableViewController"

export default function OrgDashboard() {
	return (
		<>
			<Route
				exact
				path="/organization/org-dashboard"
				component={OrgDashboardTableViewController}
			/>
			<Route
				exact
				path="/organization/org-dashboard/card-view"
				component={OrgDashboardCardViewController}
			/>
			<Route
				exact
				path="/organization/org-dashboard/graph-view"
				component={OrgDashboardGraphViewController}
			/>
			<Route
				exact
				path="/organization/org-dashboard/widecard-view"
				component={OrgDashboardWideCardView}
			/>
			<Route
				exact
				path="/organization/org-dashboard/list-view"
				component={OrgDashboardListView}
			/>
			<Route
				exact
				path="/organization/org-dashboard/table-view"
				component={OrgDashboardTableViewController}
			/>
		</>
	)
}
