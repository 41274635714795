// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const GetFinancialStatementType = (self: any) => ({
	getFinancialStatementType() {
		// 0. set actionName
		const actionName = "getFinancialStatementType"
		// 1. set response cases
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		// 3) request API call to Model
		self.readFinancialStatementType()
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200) {
						//
						const fetchedData = response.data
						let reOrganizedTypes: any[] = []
						fetchedData.map((item: any) => {
							reOrganizedTypes.push({
								fsTypeId: item.id,
								fsTypeName: item.name,
							})
						})
						self.setFSType(reOrganizedTypes)
						self.setFinancialGroups([])
						self.setNeedRefresh(false)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully fetched financial statement types",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: "Failed",
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetFinancialStatementType
