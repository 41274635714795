import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLButton,
	DLIconButton,
	DLInputField,
} from "../../../../../components/basic-elements"
import CabinetInfoHeader from "./CabinetInfoHeader"
import ProjectInfoHeader from "./ProjectInfoHeader"
import GroupInfoHeader from "./GroupInfoHeader"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
} from "../../../../../components/basic-elements/icons/common-icons"
import OrgGroupBreadCrumbs from "../OrgGroupBreadCrumbs"
import { DLObjectFormat } from "../../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { getSnapshot } from "mobx-state-tree"

export default observer(function GroupDetailAreaHeader({
	i18n,
	permission,
}: {
	i18n: { [x: string]: string }
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const groupId = orgStore.groups.currentPath[0]
	//

	const handleClickGroupTitle = () => {
		if (groupId) {
			orgStore.groups.setCurrentPath([groupId])
		}
	}
	// ---- Rename the group
	const [editMode, setEditMode] = useState(false)
	const [nameInput, setNameInput] = useState("")

	const handleEditMode = (groupName: string) => {
		setEditMode(!editMode)
		setNameInput(groupName)
	}
	const handleInput = (event: any) => {
		const input = event.target.value
		setNameInput(input)
	}
	const handleRename = () => {
		const groupId = orgStore.groups.currentPath[0]
		orgStore.groups.editGroupName(groupId, nameInput)
		setEditMode(false)
	}

	const handleRightClick = (e: any) => {
		e.preventDefault()
		// set mouse point
		orgStore.groups.setClickPoint({
			mouseX: e.clientX - 2,
			mouseY: e.clientY - 4,
		})
		// set clicked target
		orgStore.groups.setRightClickTarget(groupId)
	}

	const currentGroup = orgStore.groups.currentGroupInfo()
	const info = orgStore.groups.getTargetDetail(
		orgStore.groups.getLastItemOfPath
	)

	return (
		<StyledGroupDetailInfo
			className={`${info?.type} `}
			data-testid="group-detail-area-header-container"
		>
			<div className="FR AC" data-testid="fixed-group-info">
				{currentGroup ? (
					<div className="fixed-title main-title FR AC">
						{editMode ? (
							<div className="FR AC title editMode">
								<DLInputField
									eleTestId="group-name-input"
									eleHandleChange={handleInput}
									eleValue={nameInput}
								/>
								<DLButton
									eleTestId="update"
									size="thin"
									color="primary"
									clickHandler={handleRename}
									disabled={
										!permission.update ||
										nameInput.trim() === ""
									}
								>
									{i18n.twUpdate}
								</DLButton>
								<DLButton
									eleTestId="cancel"
									size="thin"
									clickHandler={() => handleEditMode("")}
								>
									{i18n.twCancel}
								</DLButton>
							</div>
						) : (
							<div>
								<div
									className="FR AC JSB"
									style={{ width: "100%" }}
								>
									<div className="FR AC title">
										<span
											className="group-title"
											onClick={handleClickGroupTitle}
										>
											{currentGroup?.title}
										</span>
										{permission.update && (
											<DLIconButton
												eleTestId="edit-group-name-btn"
												eleClassName="edit-btn"
												tooltipText={i18n.twEditGroup}
												clickHandler={() =>
													handleEditMode(
														currentGroup?.title
													)
												}
											>
												<DLIcon
													name={DLIconName.edit}
													size={CommonIconSize.small}
													noMargin
												/>
											</DLIconButton>
										)}
									</div>
									<DLIconButton
										eleTestId="detail-area-group-ctx-btn"
										clickHandler={(e: any) =>
											handleRightClick(e)
										}
									>
										<DLIcon
											name={DLIconName.ctx}
											eleClassName="ctx-btn"
											noMargin
										/>
									</DLIconButton>
								</div>
								<OrgGroupBreadCrumbs />
							</div>
						)}
					</div>
				) : (
					<div className="total-counter FR AC">
						{/* Total Groups: {orgStore.groups.groupsCount} */}
					</div>
				)}
			</div>

			{/* <hr /> */}
			<div className="detail-section-header FR AC">
				{info?.type === DLObjectFormat.group && (
					<GroupInfoHeader info={info} permission={permission} />
				)}
				{info?.type === DLObjectFormat.cabinet && (
					<CabinetInfoHeader info={info} permission={permission} />
				)}
				{info?.type === DLObjectFormat.project && (
					<ProjectInfoHeader info={info} />
				)}
			</div>
		</StyledGroupDetailInfo>
	)
})

const StyledGroupDetailInfo = styled.div`
	.fixed-title {
		/* padding: 1rem 0; */
		.group-title {
			font-weight: 700;
			/* margin-left: 1rem; */
			font-size: 24px;
		}
		.edit-btn {
			opacity: 0;
			transition: 0.4s;
		}
		.ctx-btn {
			opacity: 0;
			transition: 0.4s;
		}
	}
	.dl-bread-crumbs-container {
		font-size: 1.3rem;
		margin-top: 2px;
	}
	.fixed-title:hover {
		.edit-btn {
			opacity: 1;
		}
		.ctx-btn {
			opacity: 1;
		}
	}
	/* padding-bottom: 1rem; */
	.detail-section-header {
	}
	&.GROUP {
	}
`
