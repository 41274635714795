import React, { useCallback } from "react"
import { observer } from "mobx-react-lite"
import {
	DLIconButton,
	DLSpinnerCenterAligned,
	DLButton,
	DLInputField,
} from "../../../../../components/basic-elements"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"

import Icon from "@mdi/react"
import { mdiMagnify, mdiChevronLeft, mdiChevronRight, mdiPlus } from "@mdi/js"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import styled from "styled-components"
import StyledTreeWithoutExpandBtn from "../../../../../components/basic-elements/tree-list/StyledTreeWithoutExpandBtn"
import SortableTree from "react-sortable-tree"
import { OrgProjTemplateItemType } from "../store/data-models/org-proj-templates.data-model"
import TemplateGroupRowController from "./tree-nav-parts/ProjTemplateGroupRowController"
import TemplateRowController from "./tree-nav-parts/ProjTemplateRowController"
import { ProjTemplateDialog } from "../store/org-proj-templates.ui-helper"
import { InputAdornment } from "@material-ui/core"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import keyboardJS from "keyboardjs"

export default observer(function OrgProjTemplateTreeNav({
	permission,
	i18n,
}: {
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const actionName = "getOrgProjTemplateGroups"
	const templateStore = orgStore.orgProjTemplates
	//
	const handleQueryText = useCallback((e: any) => {
		// ConsoleLog("query", e.target.value)
		keyboardJS.pause()
		templateStore.setNavQueryText(e.target.value)
	}, [])
	//
	const handleNavOpen = () => {
		templateStore.setNavOpen()
	}

	//
	//
	// Template group actions
	const handleClickTemplateGroup = (templateGroupId: string) => {
		templateStore.toggleExpandedTemplateGroup(templateGroupId)
	}

	const handleRightClickTemplateGroup = (e: any, templateGroupId: string) => {
		e.preventDefault()
		// templateStore.setRightClickTargetTemplateGroup(
		// 	templateGroupId
		// )
		templateStore.setCurrentTargetId(templateGroupId)
		templateStore.setTemplateGroupClickPoint({
			mouseX: e.clientX - 2,
			mouseY: e.clientY - 4,
		})
	}

	// const handleAddTemplate = () => {}

	const handleEditTemplateGroup = (
		templateGroupId: string,
		title: string
	) => {
		templateStore.editProjTemplateGroup(templateGroupId, title)
	}

	const handleDeleteTemplateGroup = (templateGroupId: string) => {
		templateStore.removeProjTemplateGroup(templateGroupId)
	}

	//
	//
	// Template actions
	const handleClickTemplate = (template: {
		id: string
		parentId: string
		title: string
	}) => {
		templateStore.resetFlatList()
		templateStore.getProjTemplateFirstFolders(template.id)
		templateStore.setSelectedTemplate({
			id: template.id,
			parentId: template.parentId,
			title: template.title,
			type: OrgProjTemplateItemType.template,
			expanded: undefined,
		})
	}

	const handleRightClickTemplate = () => {}

	const handleEditTemplate = ({
		templateId,
		title,
	}: {
		templateId: string
		title: string
	}) => {
		templateStore.editProjTemplate({ templateId, title })
	}

	const handleDeleteTemplate = (templateId: string) => {
		templateStore.removeProjTemplate(templateId)
	}

	const handleDuplicateTemplate = (templateId: string) => {
		templateStore.duplicateProjTemplate(templateId)
	}

	let queryText = templateStore.viewNavQuery
	let treeData = templateStore.projTemplatesAsTreeWithQuery(queryText)
	//
	let contentsHeight = store.ui.contentsAreaHeight
	let navWidth = templateStore.viewNavWidth
	let navHeaderHeight = 60
	let navOpen = templateStore.navOpen
	//
	let canDrag = templateStore.canDrag
	let treeHeight = contentsHeight - navHeaderHeight - 160
	let treeIndent = 24

	let loadingStatus = templateStore.getActionStatus(actionName)
	return (
		<StyledProjTemplateTreeNav
			navWidth={navWidth}
			// navHeaderHeight={navHeaderHeight}
			navHeight={contentsHeight}
			className="FC tree-nav-container"
			// onMouseOver={() => keyboardJS.pause()}
			// onMouseLeave={() => keyboardJS.resume()}
		>
			<div className={`tree-nav-header ${!navOpen && "FR JC"}`}>
				<div className="FR AC JSB icons-area">
					{navOpen && (
						<div className="FR AC icons-when-nav-open">
							{permission.create && (
								<DLButton
									eleTestId="add-template-group"
									clickHandler={() =>
										templateStore.setDialogOpen(
											ProjTemplateDialog.createTemplateGroup,
											true
										)
									}
									startIcon={
										<Icon path={mdiPlus} size={0.8} />
									}
								>
									{i18n.twAddTemplateGroup ||
										"Add Template Group"}
								</DLButton>
							)}
						</div>
					)}

					<DLIconButton
						eleTestId="collapse-tree-list"
						clickHandler={handleNavOpen}
					>
						{navOpen ? (
							<Icon path={mdiChevronLeft} size={0.8} />
						) : (
							<Icon path={mdiChevronRight} size={0.8} />
						)}
					</DLIconButton>
				</div>
			</div>

			<DLInputField
				eleType="search"
				eleClassName={`tree-nav-search-field ${
					navOpen ? "show" : "hide"
				}`}
				eleTestId="proj-template-search-input-field-in-tree-nav"
				eleValue={queryText}
				eleHandleChange={handleQueryText}
				eleStartAdornment={
					<InputAdornment position="start">
						<Icon path={mdiMagnify} size={0.8} />
					</InputAdornment>
				}
			/>

			{loadingStatus === "LOADING" && <DLSpinnerCenterAligned />}
			{loadingStatus === "SUCCESS" &&
				(orgStore.orgProjTemplates.projTemplates.length === 0 ? (
					<div className="no-template-group FR AC">
						No Template Group Exist
					</div>
				) : (
					<StyledTreeWithoutExpandBtn
						className={`tree-nav-body ${navOpen ? "show" : "hide"}`}
					>
						<SortableTree
							shouldCopyOnOutsideDrop // for file lib drawer in project
							//
							treeData={treeData}
							rowHeight={40}
							isVirtualized={false}
							canDrag={canDrag}
							getNodeKey={({ node }: { node: any }) => node.id}
							generateNodeProps={({ node, path }) => {
								return {
									title:
										node.type ===
										OrgProjTemplateItemType.templateGroup ? (
											<TemplateGroupRowController
												node={node}
												handleClick={
													handleClickTemplateGroup
												}
												handleRightClick={
													handleRightClickTemplateGroup
												}
												// handleAdd={handleAddTemplate}
												handleEdit={
													handleEditTemplateGroup
												}
												handleDelete={
													handleDeleteTemplateGroup
												}
												permission={permission}
											/>
										) : (
											<TemplateRowController
												node={node}
												handleClick={
													handleClickTemplate
												}
												handleRightClick={
													handleRightClickTemplate
												}
												handleEdit={handleEditTemplate}
												handleDelete={
													handleDeleteTemplate
												}
												handleDuplicate={
													handleDuplicateTemplate
												}
												permission={permission}
											/>
										),
								}
							}}
							onChange={(treeData) => {
								// ConsoleLog("onChanged tree data", treeData)
								// eleOnChange && eleOnChange(treeData)
							}}
							scaffoldBlockPxWidth={treeIndent}
							// style related
							style={{
								// width: navWidth,
								height: treeHeight,
								overflow: "hidden",
								overflowY: "scroll",
							}}
						/>
					</StyledTreeWithoutExpandBtn>
				))}
		</StyledProjTemplateTreeNav>
	)
})

const StyledProjTemplateTreeNav = styled.div<{
	navWidth: number
	// navHeaderHeight: number
	navHeight: number
}>`
	width: ${(props) => props.navWidth}px;
	height: ${(props) => props.navHeight}px;
	overflow: hidden;
	border-top-right-radius: 5px;
	transition: 0.2s;
	background-color: ${(props) => props.theme.secondary};

	.tree-nav-header {
		z-index: 1;
		width: ${(props) => props.navWidth}px;

		padding: 0.6rem 1rem;
		border-top-right-radius: 5px;
		transition: 0.2s;
		.icons-area {
			height: ${(props) => props.theme.shared.baseUnit};
		}
		.search-field {
			padding: 0 1rem;
			margin-bottom: 1rem;
		}
	}
	.tree-nav-search-field {
		padding: 0 2rem 1rem;
		&.hide {
			opacity: 0;
		}
	}
	.no-template-group {
		padding: 2rem;
		width: 100%;
	}
	.tree-nav-body {
		overflow-y: scroll;
		padding-left: 1rem;
		padding-bottom: 3rem;
		&.hide {
			opacity: 0;
		}
	}
`

// height: ${(props) => props.navHeaderHeight}px;

// margin-top: ${(props) => props.navHeaderHeight}px;
