import { types } from "mobx-state-tree"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export type SignoffColumnProps = {
	id: string
	label: string
	display: boolean
}

const SignoffColumn = types.model({
	id: types.string,
	label: types.string,
	display: types.boolean,
})

const SignOffHistoryUiHelper = types
	.model({
		listCollapsed: false,
		listWidth: 320,
		selectedTab: types.enumeration<DLProjSubMenus>(
			Object.values(DLProjSubMenus)
		),
		selectedFile: "",
		treeNavQuery: "",
		detailHistoryDialogOpen: false,
		//
		columns: types.array(SignoffColumn),
	})
	.actions((self) => ({
		setTreeNavQuery(query: string) {
			self.treeNavQuery = query
		},
		setListCollapsed(request: boolean) {
			self.listCollapsed = request
			if (request === false) {
				self.listWidth = 320
			} else {
				self.listWidth = 40
			}
		},
		setSelectedFile(file: string) {
			self.selectedFile = file
		},
		setListWidth(width: number) {
			self.listWidth = width
		},
		setSelectedTab(tab: DLProjSubMenus) {
			self.selectedTab = tab
		},
		//
		setColumns(columns: any) {
			self.columns = columns
		},
		toggleColumn(id: string) {
			self.columns.map((item) => {
				if (item.id === id) {
					item.display = !item.display
				}
			})
		},
		setDetailHistoryDialogOpen(request: boolean) {
			self.detailHistoryDialogOpen =
				request ?? !self.detailHistoryDialogOpen
		},
	}))
	.views((self) => ({
		get viewListWidth() {
			return self.listWidth
		},
		get viewColumns() {
			return self.columns
		},
	}))

export default SignOffHistoryUiHelper
