import React, { useState, useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	InputWithLabel,
	DLSingleSelect,
	DLDatepicker,
	DLTextArea,
	DLInputField,
} from "../../../../components/basic-elements"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import styled from "styled-components"
import { IdType, idToNumber } from "../../../../library/converters/id-converter"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { format } from "date-fns"

export interface AddStepInputProps {
	legend: string
	auditSteps: string
	assertions: string
	note: string
	apgWPRef: string | number
	preparer: number
	preparerDueDate: string
	reviewer: number
	reviewerDueDate: string
}

export default observer(function AddNewStepDialog({
	open,
	setOpen,
	selectedId,
	fromDialog,
	wprId,
}: {
	open: boolean
	setOpen: any
	selectedId?: any
	fromDialog: boolean
	wprId: number
}) {
	const store = useRootStore()
	const projStore = useProjStore()

	const isEditMode = selectedId

	const [inputs, setInputs] = useState<any>({
		legend: "",
		auditSteps: "",
		assertions: "",
		note: "",
		apgWPRef: 0,
		preparer: 0,
		preparerDueDate: "",
		reviewer: 0,
		reviewerDueDate: "",
	})

	useEffect(() => {
		if (isEditMode) {
			const item = projStore.apg.getAuditStepById(selectedId)
			if (item) {
				const users = projStore.apg.getAssignableUsers()

				console.log(users, item.preparer)
				setInputs({
					legend: item.legend,
					auditSteps: item.auditSteps,
					assertions: item.assertions,
					note: item.note,
					apgWPRef: item.apgWPRef,
					preparer: item.preparerUserId || 0,
					preparerDueDate: item.preparerDueDate || "",
					reviewer: item.preparerUserId || 0,
					reviewerDueDate: item.reviewerDueDate || "",
				})
			}
		}
	}, [selectedId])

	const handleInputs = useCallback((event: any) => {
		const name = event.target.name
		let value = event.target.value
		console.log(name, value)

		setInputs((inputs: any) => ({
			...inputs,
			[name]: value,
		}))
	}, [])

	console.log(inputs)

	const assignableUsers = projStore.apg.getAssignableUsers()
	const assignableWPRefs = projStore.apg.getAssignableWPRefs()

	const handleDateInput = (name: string, date: string) => {
		setInputs((inputs: any) => ({
			...inputs,
			[name]: date,
		}))
	}

	const handleAdd = () => {
		const payload = {
			WorkPaperId: wprId,
			AuditStepWorkPaperReference:
				inputs.apgWPRef === 0 ? "" : inputs.apgWPRef,
			AuditSteps: inputs.auditSteps,
			Note: inputs.note,
			Assertions: inputs.assertions,
			Legend: inputs.legend,
			PreparerDueDate: inputs.preparerDueDate
				? format(new Date(inputs.preparerDueDate), "yyyy-MM-dd")
				: "",
			ReviewerDueDate: inputs.reviewerDueDate
				? format(new Date(inputs.reviewerDueDate), "yyyy-MM-dd")
				: "",
			Preparer: inputs.preparer === 0 ? "" : inputs.preparer,
			Reviewer: inputs.reviewer === 0 ? "" : inputs.reviewer,
		}

		projStore.apg.addAuditStep(payload, () => setOpen(false))
	}

	const handleEdit = () => {
		const payload = {
			AuditProgramId: selectedId,
			WorkPaperId: wprId,
			AuditStepWorkPaperReference:
				inputs.apgWPRef === 0 ? "" : inputs.apgWPRef,
			AuditSteps: inputs.auditSteps,
			Note: inputs.note,
			Assertions: inputs.assertions,
			Legend: inputs.legend,
			PreparerDueDate: inputs.preparerDueDate
				? format(new Date(inputs.preparerDueDate), "yyyy-MM-dd")
				: "",
			ReviewerDueDate: inputs.reviewerDueDate
				? format(new Date(inputs.reviewerDueDate), "yyyy-MM-dd")
				: "",
			Preparer: inputs.preparer === 0 ? "" : inputs.preparer,
			Reviewer: inputs.reviewer === 0 ? "" : inputs.reviewer,
		}

		projStore.apg.editAuditStep(payload, () => setOpen(false))
	}

	return (
		<DLDialog
			eleTestId={`${isEditMode ? "edit" : "add-new"}-step-dialog`}
			isOpen={open}
			setIsOpen={setOpen}
			handleAction={isEditMode ? handleEdit : handleAdd}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={`${isEditMode ? "Edit" : "Add New"} Step`}
			dialogContents={
				<AddProjUserForm
					inputs={inputs}
					handleInputs={handleInputs}
					handleDateInput={handleDateInput}
					dateFormat={store.global.dateFormat.value}
					fromDialog={fromDialog}
					assignableUsers={assignableUsers}
					assignableWPRefs={assignableWPRefs}
				/>
			}
			cancelBtnText="Cancel"
			actionReady={inputs.auditSteps !== ""}
			actionBtn={isEditMode ? "Edit" : "Add"}
			maxWidth="sm"
			fullWidth={true}
		/>
	)
})

function AddProjUserForm({
	inputs,
	handleInputs,
	handleDateInput,
	dateFormat,
	fromDialog,
	assignableUsers,
	assignableWPRefs,
}: {
	inputs: AddStepInputProps
	handleInputs: any
	handleDateInput: any
	dateFormat: string
	fromDialog: boolean
	assignableUsers: any
	assignableWPRefs: any
}) {
	return (
		<StyledAddNewStep>
			<div className="input-section FR">
				<InputWithLabel label="Legend">
					<DLInputField
						autoFocus
						eleTestId="legend_input"
						eleFullWidth
						eleName="legend"
						eleValue={inputs.legend}
						eleHandleChange={handleInputs}
						elePlaceholder="Please input legend"
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel label="Audit Steps">
					<DLTextArea
						eleTestId="input-comment-text"
						elePlaceholder="Please input audit steps"
						eleValue={inputs.auditSteps}
						eleHandleChange={handleInputs}
						eleName="auditSteps"
						eleClassName="auditSteps-text-input-area"
						eleFieldHeight={3}
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel label="Assertions">
					<DLTextArea
						eleTestId="input-comment-text"
						elePlaceholder="Please input assertions"
						eleValue={inputs.assertions}
						eleHandleChange={handleInputs}
						eleName="assertions"
						eleClassName="assertions-text-input-area"
						eleFieldHeight={3}
					/>
				</InputWithLabel>
			</div>
			{!fromDialog && (
				<div className="input-section FR">
					<InputWithLabel label="Notes">
						<DLTextArea
							eleTestId="input-comment-text"
							elePlaceholder="Please input notes"
							eleValue={inputs.note}
							eleHandleChange={handleInputs}
							eleName="note"
							eleClassName="notes-text-input-area"
							eleFieldHeight={3}
						/>
					</InputWithLabel>
				</div>
			)}
			<div className="input-section FR">
				<InputWithLabel label="Ref">
					<DLSingleSelect
						eleTestId="select-ref"
						eleValue={inputs.apgWPRef}
						eleOnChange={handleInputs}
						eleName="apgWPRef"
						eleFullWidth
						withLabel={false}
						// eleRequired
						placeholder="Select Ref"
						optionList={[
							{ name: "[No Selection]", value: 0 },
							...assignableWPRefs,
						]}
					/>
				</InputWithLabel>
			</div>
			{!fromDialog && (
				<>
					<div className="input-section FR">
						<InputWithLabel label="Preparer">
							<DLSingleSelect
								eleTestId="select-preparer"
								eleValue={inputs.preparer}
								eleOnChange={handleInputs}
								eleName="preparer"
								eleFullWidth
								withLabel={false}
								placeholder="Select Preparer"
								optionList={[
									{ name: "[No Selection]", value: 0 },
									...assignableUsers,
								]}
							/>
						</InputWithLabel>
					</div>
					<div className="input-section FR">
						<InputWithLabel label="Preparer Due Date">
							<DLDatepicker
								eleTestId="preparerDueDate-date"
								eleName="preparerDueDate"
								selected={
									inputs.preparerDueDate === ""
										? null
										: inputs.preparerDueDate
								}
								onChange={(date: any) =>
									handleDateInput("preparerDueDate", date)
								}
								format={dateFormat}
								clearable={true}
							/>
						</InputWithLabel>
					</div>
					<div className="input-section FR">
						<InputWithLabel label="Reviewer">
							<DLSingleSelect
								eleTestId="select-project-role"
								eleValue={inputs.reviewer}
								eleOnChange={handleInputs}
								eleName="reviewer"
								eleFullWidth
								withLabel={false}
								placeholder="Select Reviewer"
								optionList={[
									{ name: "[No Selection]", value: 0 },
									...assignableUsers,
								]}
							/>
						</InputWithLabel>
					</div>
					<div className="input-section FR">
						<InputWithLabel label="Reviewer Due Date">
							<DLDatepicker
								eleTestId="reviewerDueDate-date"
								eleName="reviewerDueDate"
								selected={
									inputs.reviewerDueDate === ""
										? null
										: inputs.reviewerDueDate
								}
								onChange={(date: any) =>
									handleDateInput("reviewerDueDate", date)
								}
								format={dateFormat}
								clearable={true}
							/>
						</InputWithLabel>
					</div>
				</>
			)}
		</StyledAddNewStep>
	)
}

const StyledAddNewStep = styled.div`
	padding-left: 0.5rem;
	height: 500px;
	min-height: 50vh;
	.input-section {
		margin-bottom: 0.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
