import React from "react"
import { PageContainer } from "../../../components/app-frame-elements"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "@datalobby/types"
import { DLI18nProps } from "../../../common-models/types/common-props"
import ReactTableV8 from "../../../components/basic-elements/tables/DLReactTable"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import styled from "styled-components"
import { dateRangeFilter } from "../../../library/react-table-utils"
import { InputFieldForTableV8 } from "../../../components/basic-elements/tables/InputFieldForTable"
import DLDateRangepicker from "../../../components/basic-elements/date-picker/DLDateRangePicker"
import { Column, Table } from "@tanstack/react-table"
import { useRootStore } from "../../../stores/root-store/root-store.provider"

export default observer(function PBCClientFiles({
	partialStore,
	contentsHeight,
	fetchingStatus,
	i18n,
}: {
	partialStore: any
	contentsHeight: any
	fetchingStatus: ActionStatus
	i18n: DLI18nProps
}) {
	const store = useRootStore()

	const dateFormat = store.global.dateFormat.value

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			isLoading={fetchingStatus === "LOADING"}
		>
			<StyledPBC>
				<div className="table-container">
					<ReactTableV8
						tableColumns={columns(partialStore, i18n)}
						customFilters={(props: any) =>
							filterComponent({ ...props, dateFormat })
						}
						data={[]}
						menuId={DLProjSubMenus.pbc_team_files}
						showPageSetting={false}
						hasPagination={false}
						height={contentsHeight - 30}
					/>
				</div>
			</StyledPBC>
		</PageContainer>
	)
})

const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "updatedDate":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const columns = (partialStore: any, i18n: DLI18nProps) => [
	{
		header: i18n.twArea || "Area",
		accessorKey: "area",
		cell: (props: any) => {
			return <div data-testid="area-cell">{props.getValue()}</div>
		},
	},
	{
		header: "Control Sheet Ref Num",
		accessorKey: "controlRefNo",
		cell: (props: any) => {
			return <div data-testid="area-cell">{props.getValue()}</div>
		},
	},
	{
		header: "Control Sheet Description",
		accessorKey: "description",
		cell: (props: any) => {
			return <div data-testid="description-cell">{props.getValue()}</div>
		},
	},
	{
		header: "Index (Ref Num)",
		accessorKey: "index",
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return (
				<div data-testid={cellId}>
					{props.getValue() ? props.getValue() : "-"}
				</div>
			)
		},
	},
	{
		header: "Document Type",
		accessorKey: "docType",
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return (
				<div data-testid={cellId}>
					{props.getValue() ? props.getValue() : "-"}
				</div>
			)
		},
	},
	{
		header: "File Name",
		accessorKey: "fileName",
		cell: (props: any) => {
			return <div data-testid="fileName-cell">{props.getValue()}</div>
		},
	},
	{
		header: "Uploaded Date",
		accessorKey: "updatedDate",
		filterFn: dateRangeFilter,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return (
				<div data-testid={cellId}>
					{props.getValue() ? props.getValue() : "-"}
				</div>
			)
		},
	},
	{
		header: "Uploaded By",
		accessorKey: "uploadedBy",
		cell: (props: any) => {
			return <div data-testid="uploadedBy-cell">{props.getValue()}</div>
		},
	},

	{
		header: i18n.twActions || "Actions",
		cell: (props: any) => {
			return <div data-testid="actions-cell"></div>
		},
	},
]

const StyledPBC = styled.div``
