import { types } from "mobx-state-tree"
import FSSetupViewModel from "./fs-setup.view-model"
import FSSetupModel from "./fs-setup.model"
import FSSetupUiHelper from "./fs-setup.ui-helper"
import * as api from "./fs-setup.apis"

export const FSSetupStore = ({
	apiRead,
	apiReadFSGroups,
	apiReadFSSubGroups,
	apiReadLeadSheets,
	apiReadAvailableGroups,
	apiCreateFinGroup,
	apiUnmapGroup,
	apiMapGroup,
	apiDelFinGroup,
	apiReadAvailableSubGroups,
	apiCreateFinSubGroup,
	apiDelFinSubGroup,
	apiUnmapSubGroup,
	apiMapSubGroup,
	apiReadAvaLeadSheets,
	apiUnmapLeadSheet,
	apiMapLeadSheet,
}: api.FSSetupApiProps) =>
	types
		.compose(
			FSSetupModel({
				apiRead,
				apiReadFSGroups,
				apiReadFSSubGroups,
				apiReadLeadSheets,
				apiReadAvailableGroups,
				apiCreateFinGroup,
				apiUnmapGroup,
				apiMapGroup,
				apiDelFinGroup,
				apiReadAvailableSubGroups,
				apiCreateFinSubGroup,
				apiDelFinSubGroup,
				apiUnmapSubGroup,
				apiMapSubGroup,
				apiReadAvaLeadSheets,
				apiUnmapLeadSheet,
				apiMapLeadSheet,
			}),
			FSSetupViewModel,
			FSSetupUiHelper
		)
		.named("FSSetupStore")

export const initialStore = {
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const FSSetupStoreInstance = FSSetupStore({
	apiRead: api.read,
	apiReadFSGroups: api.readFSGroups,
	apiReadFSSubGroups: api.readFSSubGroups,
	apiReadLeadSheets: api.readLeadSheets,
	apiReadAvailableGroups: api.readAvailableGroups,
	apiCreateFinGroup: api.createFinGroup,
	apiUnmapGroup: api.unmapGroup,
	apiMapGroup: api.mapGroup,
	apiDelFinGroup: api.delFinGroup,
	apiReadAvailableSubGroups: api.readAvailableSubGroups,
	apiCreateFinSubGroup: api.createFinSubGroup,
	apiDelFinSubGroup: api.delFinSubGroup,
	apiUnmapSubGroup: api.unmapSubGroup,
	apiMapSubGroup: api.mapSubGroup,
	apiReadAvaLeadSheets: api.readAvaLeadSheets,
	apiUnmapLeadSheet: api.unmapLeadSheet,
	apiMapLeadSheet: api.mapLeadSheet,
}).create(initialStore)

export default FSSetupStoreInstance
