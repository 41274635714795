// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import {
	TOKEN_TYPE,
	selectedToken,
} from "../../../../../library/api-requests/shared-for-api-requests"
import { getProjIdFromUrl } from "../../../../../library/api-requests/request-get-others"

const GetPBCUrl = (self: any) => ({
	getPBCUrl() {
		// 0. set actionName
		const actionName = "getPBCUrl"
		// 1. set response cases
		// self.response.setCases(GetPBCUrlReponseCases)
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) request API call to Model
		self.readProjInfo()
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						const projInfo = response.data
						// ----- default
						let accessRight
						if (
							projInfo.AccessRights === "ReadOnly" ||
							projInfo.Archive
						) {
							accessRight = 1
						} else if (
							projInfo.AccessRights === "PBC Upload" ||
							projInfo.RoleName === "PBC Upload"
						) {
							accessRight = 2
						} else if (projInfo.AccessRights === "Project User") {
							accessRight = 3
						} else {
							accessRight = 0
						}
						const userId = localStorage.getItem("orgUserId") || ""
						const projId = getProjIdFromUrl()

						if (!projId) {
							console.log("Cannot find the project ID")
							return
						}

						const projNumberId = idToNumber(projId, IdType.project)
						const userNumberId = idToNumber(userId, IdType.user)
						if (userNumberId !== "" && projNumberId !== "") {
							const url =
								process.env.REACT_APP_DEV_EXPRESS_ENDPOINT +
								"/PBC.aspx?clientid=" +
								projNumberId +
								"&finyear=" +
								projInfo.FiscalEnd +
								"&userid=" +
								userNumberId +
								"&Lang=" +
								"en" +
								"&AR=" +
								accessRight +
								"&token=" +
								selectedToken(TOKEN_TYPE.projToken)
							self.setUrl(url)
						}
						//
						self.setNeedRefresh(false)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully get the project information",
							color: MessageColorType.blue,
							open: false,
							autoHide: true,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: "Failed",
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetPBCUrl
