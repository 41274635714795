import { types } from "mobx-state-tree"
import ProjInfoModel from "./proj-info.model"
import { ProjInfoViewModel } from "./proj-info.view-model"
import ProjInfoUiHelper from "./proj-info.ui-helper"
import * as api from "./proj-info.apis"
import * as orgGroupApis from "../../../../service-modules/group-module/org-group-shared-apis"
import { readArchPolicyPeriodList } from "../../../organization-side/org-setup/org-setup-arch-policy-periods/arch-policy-period.apis"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { CreateProjectMethod } from "../../../../components/combined-elements/create-project/create-project.data-props"

export const ProjInfoStore = ({
	apiRead,
	apiUpdate,
	apiReadEngType,
	apiReadRiskAssessment,
	apiReadPeriodNames,
	apiReadClients,
	apiReadArchPolicyPeriodList,
	apiUpdateArchiveChecklist,
	apiReadCabinetLocation,
}: api.ProjInfoApiProps) =>
	types
		.compose(
			ProjInfoModel({
				apiRead,
				apiUpdate,
				apiReadEngType,
				apiReadRiskAssessment,
				apiReadPeriodNames,
				apiReadClients,
				apiReadArchPolicyPeriodList,
				apiUpdateArchiveChecklist,
				apiReadCabinetLocation,
			}),
			ProjInfoViewModel,
			ProjInfoUiHelper
		)
		.named("ProjInfoStore")

export const initialStore = {
	projInfo: {
		statusName: ProjectStatus.unknown,
		createMethod: CreateProjectMethod.byUndefined,
		hasExportAccess: false,
	},
	refresh: false,
	//
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ProjInfoStoreInstance = ProjInfoStore({
	apiRead: api.read,
	apiUpdate: api.update,
	apiReadEngType: api.readEngType,
	apiReadRiskAssessment: api.readRiskAssessment,
	apiReadPeriodNames: api.readPeriodNames,
	apiReadClients: orgGroupApis.readClientsByGroup,
	apiReadArchPolicyPeriodList: readArchPolicyPeriodList,
	apiUpdateArchiveChecklist: api.updateArchChecklist,
	apiReadCabinetLocation: api.readCabinetLocation,
}).create(initialStore)

export default ProjInfoStoreInstance
