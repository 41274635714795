import React from "react"
import styled from "styled-components"
import {
	DLSingleSelect,
	InputWithLabel,
	DLSpinner,
	DLButton,
	DLChip,
	DLComboBox,
} from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
//
//for example
import { format } from "date-fns"

// import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz'
import DntUsageInfo from "./sub-components/DntUsageInfo"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { OrgSysSettingItemProps } from "../../../../../service-modules/sys-setting-module/sys-setting.data-model"
import CommonSnackbarComponent from "../../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function DateAndTimeFormat({
	partialStore,
	selectedDateFormat,
	selectedTimeFormat,
	selectedTimeZone,
	actionStatus,
	handleDateFormat,
	handleTimeFormat,
	handleTimeZone,
	handleSubmit,
	handleCancel,
	dateFormat,
	i18n,
}: {
	partialStore: any
	selectedDateFormat: any
	selectedTimeFormat: any
	selectedTimeZone: OrgSysSettingItemProps
	actionStatus: ActionStatus
	handleDateFormat: any
	handleTimeFormat: any
	handleTimeZone: any
	handleSubmit: any
	handleCancel: any
	dateFormat: string
	i18n: DLI18nProps
}) {
	const store = useRootStore()

	const {
		twDateAndTimeSetting,
		twDateFormat,
		twTimeFormat,
		twTimeZone,
		twSave,
		twCancel,
	} = i18n
	const deactivateSaveButton = () => {
		if (
			store.global.dateFormat.value === selectedDateFormat?.value &&
			store.global.timeFormat.value === selectedTimeFormat?.value &&
			store.global.timeZone.value === selectedTimeZone?.value
		) {
			return true
		}
		return false
	}
	const timeZone = store.global.timeZone.uiValue

	return (
		<StyledDateAndTimeFormat>
			<div className="panel-title">{twDateAndTimeSetting}</div>

			{actionStatus === "LOADING" && (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: "100%" }}
				>
					<DLSpinner />
				</div>
			)}
			{actionStatus === "SUCCESS" && (
				<div className="panel-contents-container">
					<div className="FC settings-container">
						<div className="setting-section FR AC">
							<InputWithLabel
								label={`${twDateFormat} (${partialStore.availableDateFormat.length})`}
								eleClassName="label-and-input-container"
							>
								<DLSingleSelect
									eleTestId="date-format-selection"
									eleValue={selectedDateFormat?.value}
									eleFullWidth
									withLabel={false}
									optionList={
										partialStore.availableDateFormat
									}
									eleOnChange={handleDateFormat}
								/>
							</InputWithLabel>
							<div className="example">
								ex:{" "}
								{format(
									new Date(),
									selectedDateFormat?.value
										? selectedDateFormat?.value
										: ""
								)}
							</div>
						</div>
						<div className="setting-section FR AC">
							<InputWithLabel
								label={`${twTimeFormat} (${partialStore.availableTimeFormat.length})`}
								eleClassName="label-and-input-container"
							>
								<DLSingleSelect
									eleTestId="time-format-selection"
									eleValue={selectedTimeFormat?.value}
									eleFullWidth
									withLabel={false}
									optionList={
										partialStore.availableTimeFormat
									}
									eleOnChange={handleTimeFormat}
								/>
							</InputWithLabel>
							<div className="example">
								ex:
								{format(
									new Date(),
									selectedTimeFormat?.value
										? selectedTimeFormat?.value
										: ""
								)}
							</div>
						</div>
						<div className="setting-section FR AC">
							<InputWithLabel
								label={`${twTimeZone} (${partialStore.availableTimeZone.length})`}
								eleClassName="label-and-input-container"
							>
								<DLComboBox
									eleTestId="tz-select"
									onChange={(value: any) =>
										handleTimeZone(value)
									}
									options={partialStore.availableTimeZone}
									getOptionLabel={(option: any) =>
										option.label +
										" | " +
										option.value +
										" | " +
										option.uiValue
									}
									// renderOption={(option: any) => (
									// 	<div>
									// 		{option.label +
									// 			" | " +
									// 			option.value +
									// 			" | " +
									// 			option.uiValue}
									// 	</div>
									// )}
									eleValue={selectedTimeZone}
									// defaultValue={{
									// 	value: selectedTimeZone?.value,
									// 	uiValue: selectedTimeZone?.uiValue,
									// 	label: selectedTimeZone?.label,
									// }}
								/>
							</InputWithLabel>
							<div className="example FR AC">
								<DLChip
									size="small"
									eleTestId="timezone-info"
									label={selectedTimeZone?.value}
									color={
										selectedTimeZone?.value ===
										store.global.timeZone.value
											? "default"
											: "primary"
									}
								/>
							</div>
						</div>

						{/* --------- Save button --------- */}
						<div className="btn-area FR AC">
							<DLButton
								eleTestId="data-and-time-setting-cancel-button"
								clickHandler={() => handleCancel()}
								disabled={deactivateSaveButton()}
								eleClassName="cancel-btn"
							>
								{twCancel}
							</DLButton>
							<DLButton
								color="primary"
								eleTestId="data-and-time-setting-save-button"
								clickHandler={() => handleSubmit()}
								disabled={deactivateSaveButton()}
							>
								{twSave}
							</DLButton>
						</div>
						<div
							className="modified-details"
							data-testid="modification-info"
						>
							<span>
								*This configuration is last modified by{" "}
								{
									partialStore.viewModifiedDetails(
										dateFormat,
										timeZone
									).userName
								}{" "}
								[
								{
									partialStore.viewModifiedDetails(
										dateFormat,
										timeZone
									).userId
								}
								] on{" "}
								{
									partialStore.viewModifiedDetails(dateFormat)
										.modifiedDate
								}
							</span>
						</div>
					</div>
					{/* --------- Usage information to help user --------- */}
					<DntUsageInfo
						i18n={i18n}
						selectedTimeZone={selectedTimeZone}
						selectedTimeFormat={selectedTimeFormat}
						selectedDateFormat={selectedDateFormat}
					/>
				</div>
			)}
			{/* response snackbar -- can be removed or commented in the future */}
			<CommonSnackbarComponent i18n={i18n} partialStore={partialStore} />
		</StyledDateAndTimeFormat>
	)
})

const StyledDateAndTimeFormat = styled.div`
	width: 100%;
	.panel-title {
		height: ${(props) => props.theme.shared.baseUnit};
		font-weight: 900;
	}
	.settings-container {
		margin-right: 4rem;
		.setting-section {
			margin-bottom: 1rem;
			.label-and-input-container {
				min-width: 20rem;
				width: 75%;
				.label {
					width: 8rem;
				}
				.input-area {
					width: calc(100% - 8rem);
				}
			}

			.example {
				width: 15%;
				margin-left: 2rem;
				color: ${(props) => props.theme.shade40};
				padding-bottom: 1rem;
			}
		}
		.btn-area {
			margin-top: 2rem;
			.cancel-btn {
				margin-right: 1rem;
			}
		}
	}

	.usage-informations {
		margin-top: 4rem;
		.usage-info-item {
			margin-bottom: 1.5rem;
			.item-title {
				font-weight: 700;
				margin-bottom: 0.4rem;
			}
			.sample-area {
				.arrow {
					margin: 0 2rem;
				}
			}
		}
	}
	.MuiAutocomplete-clearIndicator {
		display: none;
	}
	.modified-details {
		font-size: 0.8rem;
		margin-top: 0.6rem;
	}
`
