import { ProjScreenType } from "../../../../components/combined-elements/projects-table/projects-table-props"
import { getTzDate2 } from "../../../../library/converters/date-utc-converter"
import { DnTFormatProps } from "../../../../common-models/types/common-props"

const ForExport = (self: any) => ({
	/**
	 * org > projects
	 * @param dateFormat
	 * @param timeZone
	 * @param projScreenType
	 * @param filteredData
	 * @returns
	 */
	forExport(
		dntFormat: DnTFormatProps,
		projScreenType: ProjScreenType,
		filteredData?: any
	) {
		let defaultList: any
		let formattedList: any[] = []

		const isAppAdminUser = JSON.parse(
			localStorage.getItem("isAdminAppUser") || "false"
		)

		if (filteredData) {
			defaultList = filteredData
		} else {
			if (projScreenType === ProjScreenType.normal) {
				defaultList = self.normalProjList
			} else if (projScreenType === ProjScreenType.replica) {
				defaultList = self.replicaProjList
			} else if (projScreenType === ProjScreenType.archived) {
				defaultList = self.archivedProjList
			} else if (projScreenType === ProjScreenType.unarchived) {
				defaultList = self.unarchivedProjList
			}
		}

		function formatDate(date: string | null) {
			if (!date) return "-"
			return getTzDate2({
				date,
				dntFormat,
			})
		}

		defaultList.map((project: any) => {
			/**
			 * Required date information
			 * 1. Period end date
			 * 2. Expected report date
			 * 3. Expected archive deadline date
			 * 4. Final reprot date
			 * 5. Final archive deadline date
			 */
			const formattedPeriodEndDate = formatDate(project.periodEndDate)
			const formattedExpectedReportDate = formatDate(
				project.expectedReportDate
			)
			const formattedExpectedArchiveDeadlineDate = formatDate(
				project.expectedArchiveDate
			)
			const formattedFinalReportDate = formatDate(project.finalReportDate)
			const formattedFinalArchiveDeadlineDate = formatDate(
				project.finalArchiveDeadlineDate
			)
			// --------------------------------------
			const formattedLastAccessAt = formatDate(project.lastAccessAt)

			// --------------------------------------
			const qcUsers = project.qcUsers
				? project.qcUsers
						.map((user: any) => {
							return user.name
						})
						.join(",")
				: "-"
			const epUsers = project.epUsers
				? project.epUsers
						.map((user: any) => {
							return user.name
						})
						.join(",")
				: "-"
			// --------------------------------------
			let formattedProject: any = {
				EngagementType: project.engTypeName,
				Group: project.groupName,
				ProjectTitle: project.title,
				ProjectId: project.aliasId,
				Version: project.version,
				// createdBy: project.createdBy,
				Client: project.clientName,
				EngagementPartner: epUsers,
				QualityControl: qcUsers,
				FinancialYear: project.financialYear,
				PeriodName: project.periodName,
				// ----- common date informations
				PeriodEndDate: formattedPeriodEndDate,
				ExpectedReportDate: formattedExpectedReportDate,
				ExpectedArchiveDeadlineDate:
					formattedExpectedArchiveDeadlineDate,
				FinalReportDate: formattedFinalReportDate,
				FinalArchiveDeadlineDate: formattedFinalArchiveDeadlineDate,
				// -------------------------
				IsLocked: project.isLocked,
				IsArchived: project.isArchived,
				LastAccessAt: formattedLastAccessAt,
				LastAccessBy: project.lastAccessBy?.name,
			}

			if (isAppAdminUser) {
				formattedProject = {
					...formattedProject,
					projectId: project.id,
				}
			}
			formattedList.push(formattedProject)
		})

		return formattedList
	},
})

export default ForExport
