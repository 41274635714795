import React, { useState } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLSingleSelect,
	InputWithLabel,
	DLRadioGroup,
	DLInputField,
	DLButton,
	DLDialog,
	DLSystemMsg,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import sharedRegEx from "../../../../../library/sharedRegEx"
import useForm from "../../../../../library/use-form"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { MessageColorType } from "../../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { isEmptyString } from "@datalobby/common"

// button
export const AddUserButton = ({ i18n }: { i18n: DLI18nProps }) => {
	const orgStore = useOrgStore()
	// ConsoleLog("AddUserButton rendered")

	return (
		<DLButton
			variant="contained"
			color="primary"
			size="regular"
			eleClassName="top-row-btns"
			eleTestId="add-org-user-btn"
			startIcon={<Icon path={mdiPlus} size={1} />}
			clickHandler={() => orgStore.setupUsers.setAddDialogOpen(true)}
		>
			{i18n.twAddUser}
		</DLButton>
	)
}

// dialog
export const AddUserDialog = observer(({ i18n }: { i18n: DLI18nProps }) => {
	ConsoleLog(" -=-=-=-=- AddUserDialog -=-=-=-=- ")
	const orgStore = useOrgStore()
	const actionaName = "addUser"

	const [highlight, setHighlight] = useState(false)
	// default inputs
	const inputsSchema = {
		type: { value: "internal", error: "", requestInput: false },
		email: { value: "", error: "", requestInput: false },
		name: { value: "", error: "", requestInput: false },
		aliasId: { value: "", error: "", requestInput: false },
		accessRight: { value: "User Access", error: "", requestInput: false },
		isArchiveAdmin: { value: "false", error: "", requestInput: false },
		isHardCopyAdmin: { value: "false", error: "", requestInput: false },
		title: { value: "", error: "", requestInput: false },
	}

	const validationSchema = {
		email: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.email,
				error: "Invalid format",
			},
		},
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
		aliasId: {
			isRequired: true,
			// TODO: which special characters are restricted in here?
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
		// NOTE: external user's title is mandatory but cannot add here. So added its alert on the form directly
	}

	const addUser = () => {
		const creatorAliasId = orgStore.checkin.orgInfo.userAliasId
		orgStore.setupUsers.addUser(inputs, creatorAliasId)
	}

	const {
		inputs,
		handleOnChange,
		handleOnSubmit,
		isReady,
		// initializeInputs
	} = useForm(inputsSchema, validationSchema, addUser) // TODO: is 'open' required?

	let actionReady = false
	if (
		isReady &&
		!orgStore.setupUsers.isDuplicatedEmail(inputs.email.value) &&
		!orgStore.setupUsers.isDuplicatedAliasId(inputs.aliasId.value)
	) {
		if (inputs.type.value === "internal") {
			// when user is internal, 'title' is not mandatory
			actionReady = true
		} else {
			// when user is external, 'title' is mandatory
			if (inputs.title.value !== "" && inputs.title.value.length > 0) {
				actionReady = true
			}
		}
	}

	return (
		<DLDialog
			eleTestId="add-org-user-dialog"
			draggable
			isOpen={orgStore.setupUsers.addDialogOpen}
			setIsOpen={orgStore.setupUsers.setAddDialogOpen}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={i18n.twAddUser || "Add User"}
			dialogContents={
				<AddUserDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
					highlight={highlight}
				/>
			}
			// openBtn={<AddUserButton />}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionBtn={
				<DLButton
					eleTestId="add-new-row"
					startIcon={<Icon path={mdiPlus} size={0.8} />}
					clickHandler={handleOnSubmit}
					disabled={!actionReady}
					color="primary"
					onMouseOver={() => !actionReady && setHighlight(true)}
					onMouseLeave={() => !actionReady && setHighlight(false)}
				>
					{i18n.twSubmit || "Submit"}
				</DLButton>
			}
			maxWidth="sm"
			fullWidth={true}
			// dialogError={}
			showSpinner={
				// show spinner on the dialog TODO: spinner is too small..
				orgStore.setupUsers.responses.getResponse(actionaName)
					?.status === "LOADING"
					? true
					: false
			}
			cannotUpdate={
				orgStore.setupUsers.responses.getResponse(actionaName)
					?.status === "LOADING"
			}
		/>
	)
})

// form (dialog inner contents)
const AddUserDialogForm = observer(
	({
		inputs,
		handleOnChange,
		i18n,
		highlight,
	}: {
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
		highlight: boolean
	}) => {
		const orgStore = useOrgStore()

		return (
			<StyledAddUserDialog>
				{/* input name (data-testid)
				1. User type (user-type-selection)
				2. Email (email-input-field)
				3. Name (username-input-field)
				4. Alias ID (alias-id-input-field)
				5. Access Right (org-role-selection)
				6. Title (title-input-field)
			*/}
				{/* ----- 1. User Type: Internal or External */}
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twUserType}>
						<DLSingleSelect
							eleTestId="user-type-selection"
							eleName="type"
							eleValue={inputs.type.value}
							eleFullWidth
							withLabel={false}
							optionList={[
								{ value: "internal", name: "Internal" },
								{ value: "external", name: "External" },
							]}
							placeholder={
								i18n.twSelectUserType || "Select User Type"
							}
							eleOnChange={handleOnChange}
						/>
					</InputWithLabel>
				</div>
				{/* ----- 2. Email  */}
				<div className="input-section FR">
					<InputWithLabel
						required
						label={i18n.twEmail}
						highlight={
							highlight && isEmptyString(inputs.email.value)
						}
					>
						<DLInputField
							autoFocus
							eleTestId="email-input-field"
							eleFullWidth
							eleName="email"
							eleValue={inputs.email.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								orgStore.setupUsers.isDuplicatedEmail(
									inputs.email.value
								)
									? i18n.tsSameEmailExist ||
									  "The same email already exist"
									: inputs.email.error
							}
							warningType={
								orgStore.setupUsers.isDuplicatedEmail(
									inputs.email.value
								)
									? "red"
									: undefined
							}
							requestInput={inputs.email.requestInput}
						/>
					</InputWithLabel>
				</div>
				{/* ----- 3. Name  */}
				<div className="input-section FR">
					<InputWithLabel
						required
						label={i18n.twName}
						highlight={
							highlight && isEmptyString(inputs.name.value)
						}
					>
						<DLInputField
							eleTestId="username-input-field"
							eleFullWidth
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								// orgStore.setupUsers.isDuplicatedName(
								// 	inputs.name.value
								// )
								// 	? "User name is already exist."
								inputs.name.error
							}
							// warningType={
							// 	orgStore.setupUsers.isDuplicatedName(
							// 		inputs.name.value
							// 	)
							// 		? "orange"
							// 		: undefined
							// }
							requestInput={inputs.name.requestInput}
						/>
					</InputWithLabel>
				</div>
				{/* ----- 4. Alias ID  */}
				<div className="input-section FR">
					<InputWithLabel
						required
						label={i18n.twAliasId}
						highlight={
							highlight && isEmptyString(inputs.aliasId.value)
						}
					>
						<DLInputField
							eleTestId="alias-id-input-field"
							eleFullWidth
							eleName="aliasId"
							eleValue={inputs.aliasId.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								orgStore.setupUsers.isDuplicatedAliasId(
									inputs.aliasId.value
								)
									? i18n.tsSameIdExist ||
									  "The same ID already exist"
									: inputs.aliasId.error
							}
							warningType={
								orgStore.setupUsers.isDuplicatedAliasId(
									inputs.aliasId.value
								)
									? "red"
									: undefined
							}
							requestInput={inputs.aliasId.requestInput}
						/>
						{orgStore.setupUsers.isDuplicatedAliasId(
							inputs.aliasId.value
						) && (
							<DLSystemMsg
								type={MessageColorType.red}
								msg="Alias ID is already exist."
							/>
						)}
					</InputWithLabel>
				</div>
				{/* ----- 5. Access Right  */}
				{inputs.type.value === "internal" && (
					<>
						<div className="input-section FR">
							<InputWithLabel required label={i18n.twOrgRole}>
								<DLSingleSelect
									eleTestId="org-role-selection"
									eleName="accessRight"
									eleValue={inputs.accessRight.value}
									eleFullWidth
									withLabel={false}
									optionList={[
										// {
										// 	value: "Archive Admin",
										// 	name: "Archive Admin",
										// },
										{
											value: "Super Admin",
											name: "Super Admin",
										},
										{
											value: "Group Admin",
											name: "Group Admin",
										},
										{
											value: "User Access",
											name: "User Access",
										},
									]}
									placeholder="Select User Access Right"
									eleOnChange={handleOnChange}
								/>
							</InputWithLabel>
						</div>

						{inputs.accessRight.value === "Super Admin" && (
							<>
								<div
									className="input-section FR"
									data-testid="is-archive-admin-section"
								>
									<InputWithLabel
										required
										label={
											i18n.twIsOrgArchiveAdmin ||
											"Is this user Org. Archive Admin?"
										}
									>
										<DLRadioGroup
											itemDirection="row"
											groupName="isArchiveAdmin"
											selectedValue={
												inputs.isArchiveAdmin.value
											}
											items={[
												{
													value: "true",
													label: "Yes",
												},
												{
													value: "false",
													label: "No",
												},
											]}
											eleOnChange={handleOnChange}
										/>
									</InputWithLabel>
								</div>
								<div
									className="input-section FR"
									data-testid="is-hard-copy-admin-section"
								>
									<InputWithLabel
										required
										label={
											i18n.twIsHardCopyAdmin ||
											"Is this user Hard Copy Admin?" // Need i18n
										}
									>
										<DLRadioGroup
											itemDirection="row"
											groupName="isHardCopyAdmin"
											selectedValue={
												inputs.isHardCopyAdmin.value
											}
											items={[
												{
													value: "true",
													label: "Yes",
												},
												{
													value: "false",
													label: "No",
												},
											]}
											eleOnChange={handleOnChange}
										/>
									</InputWithLabel>
								</div>
							</>
						)}
					</>
				)}
				{/* ----- 6. Title  */}
				<div className="input-section FR">
					<InputWithLabel
						label={
							inputs.type.value === "external"
								? i18n.twDesc || "Description"
								: i18n.twUserTitle || "Title"
						}
						required={
							inputs.type.value === "internal" ? false : true
						}
					>
						<DLInputField
							eleTestId="title-input-field"
							eleFullWidth
							eleName="title"
							eleValue={inputs.title.value}
							eleHandleChange={handleOnChange}
							warningMsg={
								inputs.type.value === "external" &&
								inputs.title.value.length <= 0 &&
								inputs.title.value === ""
									? "Please input the external user description"
									: undefined
							}
							// warningType="orange"
						/>
					</InputWithLabel>
				</div>
			</StyledAddUserDialog>
		)
	}
)

const StyledAddUserDialog = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
