import { types, applySnapshot } from "mobx-state-tree"
import { ArchiveHistoryItem } from "./data-models/archive-history.data-models"

// ---------- common models

import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"

// ----------- view model actions
import GetArchiveHistoryList from "./view-model-actions/get-archive-history-list"
import { initialStore } from "./archive-history.provider"
import { getTzDateAndTime } from "../../../../../library/converters/date-utc-converter"

const ArchiveHistoryViewModel = types
	.model({
		archiveHistoryList: types.array(ArchiveHistoryItem),

		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		resetList() {
			self.archiveHistoryList.length = 0
		},
		pushItemToList(item: any) {
			self.archiveHistoryList.push(item)
		},
	}))
	.actions(GetArchiveHistoryList)
	.views((self) => ({
		viewArchiveHistoryList() {
			return self.archiveHistoryList
		},
		formattedArchiveHistoryList(
			dateFormat: string,
			timeZone: string,
			timeFormat: string
		) {
			// ConsoleLog(dateFormat)
			let formattedList: any[] = []
			self.archiveHistoryList.map((item: any) => {
				const formattedArchivedUnArchivedAt = item.archivedUnArchivedAt
					? getTzDateAndTime({
							date: item.archivedUnArchivedAt,
							timeZone,
							dateFormat,
							timeFormat,
					  })
					: ""
				formattedList.push({
					...item,
					archivedUnArchivedAt: formattedArchivedUnArchivedAt,
				})
			})
			// ConsoleLog("formattedArchiveHistoryList", formattedList)
			return formattedList
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ArchiveHistoryViewModel
