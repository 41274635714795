import React, { useCallback } from "react"
import styled from "styled-components"
import Icon from "@mdi/react"
import {
	mdiDotsHorizontal,
	mdiFolderOpen,
	mdiPlus,
	mdiFolderPlusOutline,
	mdiRefresh,
	mdiContentCopy,
	mdiTrashCan,
} from "@mdi/js"
import {
	DLButton,
	DLIconButton,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { CreateProjectMethod } from "../../../../../components/combined-elements/create-project/create-project.data-props"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { DLSearchBoxBasic } from "../../../../../components/basic-elements/search/DLSearchBox"
import { ProjectStatus } from "../../../../../common-models/enumerations/project-related-enums"

export default observer(function CabinetInfoHeader({
	info,
	permission,
}: {
	info: any
	permission: PermissionAsObjectProps
}) {
	const orgStore = useOrgStore()
	const partialStore = orgStore.groups

	const readyToCreateProject = useCallback(() => {
		ConsoleLog("readyToCreateProject")
		const currentPath = orgStore.groups.viewCurrentPath
		orgStore.groups.setCreateProjectMethod(CreateProjectMethod.byUndefined)
		orgStore.projects.setCreateProjectPresetInputs(undefined)
		orgStore.groups.setPathForSelection(currentPath)
	}, [])

	const handleRightClick = (e: any) => {
		e.preventDefault()
		partialStore.setClickPoint({
			mouseX: e.clientX - 2,
			mouseY: e.clientY - 4,
		})
		readyToCreateProject()
		partialStore.setRightClickTarget(info.id)
	}
	const handleOpenCreateProjectDialog = () => {
		readyToCreateProject()
		partialStore.setOpenCreateProjDialog(true)
	}

	const handleAddCabinet = () => partialStore.setOpenCreateCabinetDialog(true)

	const handleCabinetRefresh = () => partialStore.getOrgCabinetDetail(info.id)

	const searchElement = (e: any) => partialStore.setSearch(e.target.value)

	const count = orgStore.groups.selectedItems.length
	const selectedItemInfo =
		count > 0
			? orgStore.groups.getSelectedItemInfo(
					orgStore.groups.selectedItems[0]
			  )
			: ""

	const handleRollForwardProjectDialog = () => {
		if (selectedItemInfo) {
			const currentPath = orgStore.groups.viewCurrentPath
			orgStore.projects.setSelectedProj(selectedItemInfo.id)
			orgStore.groups.setRFSourceProjInfo({
				groupName: "",
				cabinetName: info.title,
				clientName: selectedItemInfo.detail.clientName,
				clientAliasId: selectedItemInfo.detail.clientAliasId,
				title: selectedItemInfo.title,
				projectAliasId: selectedItemInfo.detail.aliasId,
				version: selectedItemInfo.detail.version,
				periodName: selectedItemInfo.detail.periodName,
				financialYear: selectedItemInfo.detail.financialYear,
				periodEndDate: selectedItemInfo.detail.periodEndDate,
			})
			orgStore.groups.setCreateProjectMethod(
				CreateProjectMethod.byRollForward
			)
			orgStore.groups.setPathForSelection(currentPath)
			orgStore.groups.setOpenCreateProjDialog(true)
		}
	}

	return (
		<StyledCabinetInfoHeader
			className="cabinet-info-header FC"
			onContextMenu={handleRightClick}
			data-testid="cabinet-info-header"
		>
			<div className="FR AC JSB title-area">
				<div className="title FR AC">
					<Icon path={mdiFolderOpen} size={1} />
					<span className="title-text heading2">{info.title}</span>
					<DLIconButton
						eleTestId="refresh-cabinet-btn"
						tooltipText="Refresh"
						clickHandler={handleCabinetRefresh}
					>
						<Icon path={mdiRefresh} size={0.8} />
					</DLIconButton>
					<DLSearchBoxBasic
						id="searchbox"
						eleTestId="search_text_box"
						placeholder="Search..."
						iconPlacement="end"
						onChange={searchElement}
						eleValue={partialStore.search}
					/>
				</div>
				<div className="icon-area FR AC">
					{permission.delete &&
						count === 1 &&
						selectedItemInfo?.type === "PROJECT" && (
							<DLButton
								eleClassName="delete-btn"
								eleTestId="delete-project"
								variant="text"
								color="primary"
								startIcon={
									<Icon path={mdiTrashCan} size={0.8} />
								}
								clickHandler={() => {
									orgStore.groups.setSelectedProj(
										selectedItemInfo.id
									)
									orgStore.groups.setOpenDeleteProjDialog(
										true
									)
								}}
							>
								Delete
							</DLButton>
						)}

					{permission.create &&
						count === 1 &&
						selectedItemInfo?.type === "PROJECT" &&
						selectedItemInfo.detail?.projectStatus !==
							ProjectStatus.archived && (
							<DLButton
								eleClassName="rf-btn"
								eleTestId="rf-project"
								variant="text"
								color="primary"
								startIcon={
									<Icon path={mdiContentCopy} size={0.8} />
								}
								clickHandler={handleRollForwardProjectDialog}
							>
								Roll Forward
							</DLButton>
						)}

					{permission.create && (
						<DLButton
							eleClassName="add-btn"
							eleTestId="add-project-in-cabinet"
							variant="text"
							color="primary"
							startIcon={<Icon path={mdiPlus} size={0.8} />}
							clickHandler={handleOpenCreateProjectDialog}
						>
							New Project
						</DLButton>
					)}

					{permission.create && (
						<DLButton
							eleClassName="add-btn"
							eleTestId="add-new-cabinet-in-cabinet"
							variant="text"
							color="primary"
							startIcon={
								<Icon path={mdiFolderPlusOutline} size={0.8} />
							}
							clickHandler={handleAddCabinet}
						>
							New Cabinet
						</DLButton>
					)}

					{/* cabinet context menu uses create, update, move, delete */}
					{permission.create ||
						permission.update ||
						permission.move ||
						(permission.delete && (
							<DLIconButton
								eleTestId="cabinet-ctx-btn"
								clickHandler={handleRightClick}
							>
								<Icon path={mdiDotsHorizontal} size={0.7} />
							</DLIconButton>
						))}
				</div>
			</div>
		</StyledCabinetInfoHeader>
	)
})

const StyledCabinetInfoHeader = styled.div`
	width: 100%;
	border-top: 1px solid ${(props) => props.theme.shade10};
	border-bottom: 1px solid ${(props) => props.theme.shade10};
	.back-btn {
		opacity: 0.6;
		transition: 0.4s;
		:hover {
			opacity: 1;
		}
	}
	.title-area {
		padding: 1rem 0;
		.title {
			color: ${(props) => props.theme.emphasis};
			svg {
				color: ${(props) => props.theme.emphasis};
				fill: ${(props) => props.theme.emphasis};
			}
			.title-text {
				margin-left: 0.4rem;
				max-width: 18rem;
				overflow-wrap: break-word;
			}
		}
	}
	.icon-area {
		.add-btn {
			margin-right: 0.6rem;
		}
	}
`
