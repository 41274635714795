import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { FileScreenDialog } from "../../data-models/dl-file-control-model"

export type ReplaceFileProps = {
	parentId: string
	fileId: string
	srcId: string
	srcFormat: string
	extension: string // for reflection
	file: any
	size: string // for reflect the result
	prepared: boolean
	reviewed: boolean
}

export type ReflectReplaceFileProps = {
	fileId: string
	size: string
	srcFormat: string
	prepared: boolean
	reviewed: boolean
	signOff: { roleName: string; userName: string; Date: string }
	url: string
	extension: string
}

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const ReplaceFile = (self: any) => ({
	replaceFile({
		parentId,
		fileId,
		srcId,
		srcFormat,
		extension,
		file,
		size,
		prepared,
		reviewed,
	}: ReplaceFileProps) {
		// 0.
		const actionName = "replaceFile"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 111,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		self.requestReplaceFile({
			fileId,
			srcId,
			srcFormat,
			file,
			prepared,
			reviewed,
		})
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// const fetchedSignoffs =
					// 	response.data.SignOff !== null
					// 		? response.data.SignOff
					// 		: []
					// const fetchedUrl = response.data.url
					// NOTE: even the prepare & review requested, response has only one item in the array..
					// NOTE: Need to check to backend team

					self.setFileTreeDialogOpen(
						FileScreenDialog.replaceFile,
						false
					)
					// NOTE: because sign off reflection logic is too complicated.. just refresh the folder
					self.getChildrenOfFolder(parentId)
					// self.reflectFileReplace({
					// 	fileId,
					// 	size,
					// 	srcFormat,
					// 	prepared,
					// 	reviewed,
					// 	signOff: fetchedSignoffs[0],
					// 	url: fetchedUrl,
					// 	extension,
					// })
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						// message: response.data.Message, --> Message from the server is null when fail
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default ReplaceFile
