import { types } from "mobx-state-tree"
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import GetAllFiles from "../../../../service-modules/file-module/view-model-actions/file/get-all-files"
import GetDashboardDetails from "./view-model-actions/get-dashboard-details"
import {
	CommentsList,
	Adjustments,
	WPStatusDetails,
	AuditProgramStatus,
} from "./data-models/proj-dashboard.data-model"

const ProjectDashboardViewModel = types
	.model({
		commentList: types.array(CommentsList),
		adjustments: Adjustments,
		wpStatusDetails: types.array(WPStatusDetails),
		auditProgramDetails: types.array(AuditProgramStatus),
		// common parts
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setAdjustments(adjustments: any) {
			self.adjustments = adjustments
		},
		setCommentList(commentList: any) {
			self.commentList.length = 0
			self.commentList = commentList
		},
		setWPStatusDetails(wpDetails: any) {
			self.wpStatusDetails.length = 0
			self.wpStatusDetails = wpDetails
		},
		setAuditProgramDetails(apDetails: any) {
			self.auditProgramDetails.length = 0
			self.auditProgramDetails = apDetails
		},
	}))
	.actions(GetAllFiles)
	.actions(GetDashboardDetails)
	.views((self) => ({
		viewCommentList() {
			return self.commentList
		},
		viewWPStatusDetails() {
			return self.wpStatusDetails
		},
		viewAuditProgramStatusDetails() {
			return self.auditProgramDetails
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			// applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ProjectDashboardViewModel
