import { types } from "mobx-state-tree"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"

const OrgEngTypesUiHelper = types
	.model({
		openAdd: types.boolean,
		openEdit: types.boolean,
		openDelete: types.boolean,
		openEditConnectedChecklist: types.boolean,
		clickPoint: ClickPoint,
	})
	.actions((self) => ({
		setOpenAdd(request?: boolean) {
			self.openAdd = request || !self.openAdd
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setOpenEdit(request?: boolean) {
			self.openEdit = request || !self.openEdit
		},
		setOpenEditConnectedChecklist(request?: boolean) {
			self.openEditConnectedChecklist =
				request || !self.openEditConnectedChecklist
		},
		setOpenDelete(request?: boolean) {
			self.openDelete = request || !self.openDelete
		},
	}))

export default OrgEngTypesUiHelper
