import { flow } from "mobx-state-tree"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"

const ReadFileLocation = (
	self: any,
	apiReadFileLocation: FileSystemApiTypes["apiReadFileLocation"]
) => ({
	readFileLocation: flow(function* (
		clientId: number,
		mediaId: number,
		fileType: string
	) {
		const actionName = "readFileLocation"

		try {
			const params = {
				clientId,
				mediaId,
				mediaType: fileType,
			}
			const response = yield apiReadFileLocation(params)

			// console.log(actionName, "__ response", response)
			return response
		} catch (error) {
			// console.log(actionName, "__ error", error)
			self.handleModelError({ actionName, error, open: true })

			// return
		}
	}),
})

export default ReadFileLocation
