import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import OtherSettings from "./OtherSettings"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function OtherSettingsController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const actionName = "getEmailNotificationSettings"
	const menuId = DLOrgSubMenus.setup_sys
	const moduleId = "OrgSetupEmailNoti"

	useEffect(() => {
		orgStore.emailNotification.getEmailNotificationSetting()
	}, [])

	const i18n = store.i18n.combineI18n(moduleId)
	return (
		<OtherSettings
			partialStore={orgStore.emailNotification}
			actionStatus={orgStore.emailNotification.getActionStatus(
				actionName
			)}
			permission={orgStore.checkin.getActionsAsObject(menuId)}
			i18n={i18n}
		/>
	)
})
