import React from "react"
import { DLButton } from "../../../../components/basic-elements"
import { DLCheckbox } from "@datalobby/components"
import styled from "styled-components"
import { DLI18nProps } from "../../../../common-models/types/common-props"

const renderCustomCell = (props: any) => {
	const aliasId = props.row.original.referenceNumber
	const cellId = props.cell.column.id + "-cell-" + aliasId
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const OpenedFilesTableColumns = (
	handleRequestAccess: any,
	openedBy: any,
	handleToggleCheckbox: any,
	selectAllFiles: boolean,
	selectAllFilesByOther: boolean,
	handleSelectAll: any,
	closeFile: any,
	permission: any,
	i18n: DLI18nProps
) => [
	{
		header: (
			<DLCheckbox
				eleTestId={`checkbox-header-${openedBy}`}
				isChecked={
					openedBy === 0 ? selectAllFiles : selectAllFilesByOther
				}
				color="primary"
				eleClassName={`checkbox-header-${openedBy}`}
				clickHandler={() => handleSelectAll(openedBy)}
			/>
		),
		accessorKey: "xxx",
		cell: (props: any) => {
			const aliasId = props.row.original.referenceNumber
			const testId = "checkbox-" + aliasId
			return (
				<DLCheckbox
					eleTestId={testId} //{`checkbox-${row.original.id}`}
					isChecked={props.row.original.selected}
					color="primary"
					clickHandler={() =>
						handleToggleCheckbox(props.row.original.id, openedBy)
					}
				/>
			)
		},
	},
	{
		header: `${i18n.tsReferenceNumber}`,
		accessorKey: "referenceNumber",
		cell: renderCustomCell,
	},
	{
		header: `${i18n.twDesc}`,
		accessorKey: "description",
		cell: renderCustomCell,
	},
	{
		header: `${i18n.twOpenedAt}`,
		accessorKey: "openedAt",
		cell: renderCustomCell,
	},
	{
		header:
			openedBy === 0
				? `${i18n.tsAccessRequestedBy}`
				: `${i18n.twOpenedBy}`,
		accessorKey: openedBy === 0 ? "accessRequestedBy" : "openedUserRole",
		width: 160,
		cell: renderCustomCell,
	},
	{
		header: "Actions",
		filterable: false,
		sortable: false,
		cell: (props: any) => {
			const aliasId = props.row.original.referenceNumber
			return (
				<StyledButton className="contents-area FR AC JC">
					{openedBy === 0 ? (
						<DLButton
							eleTestId={`close-btn-${aliasId}`}
							variant="contained"
							color="primary"
							clickHandler={() => closeFile(props.row.original)}
						>
							Check-In & Close
						</DLButton>
					) : (
						<>
							{permission.forceClose && (
								<DLButton
									eleTestId={`force-close-btn-${aliasId}`}
									variant="contained"
									color="primary"
									clickHandler={() =>
										closeFile(props.row.original)
									}
								>
									Discard Checked-Out
								</DLButton>
							)}
							<DLButton
								eleTestId={`request-access-btn-${aliasId}`}
								variant="contained"
								color="primary"
								align="right"
								disabled={props.row.original.accessRequestedBy}
								clickHandler={() =>
									handleRequestAccess(props.row.original)
								}
							>
								Request Check-In
							</DLButton>
						</>
					)}
				</StyledButton>
			)
		},
	},
]

export default OpenedFilesTableColumns

const StyledButton = styled.div`
	.button-base.regular {
		height: calc(37px / 1.5);
		font-size: 0.599rem;
	}
	.right {
		margin-left: 18px;
	}
`
