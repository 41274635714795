import React from "react"
import { Link } from "react-router-dom"
import { ProjectStatus } from "../../../../../common-models/enumerations/project-related-enums"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import DLComboBoxWithCheckbox from "../../../../../components/basic-elements/autocomplete-fields/DLComboBoxWithCheckbox"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"
import DLYearpicker from "../../../../../components/basic-elements/date-picker/DLYearpicker"
import { getProjectIcon } from "../../../../../components/basic-elements/icons/common-project-icons"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import { Column, Table } from "@tanstack/react-table"
import {
	dateRangeFilter,
	filterMethodForQCandEP,
	multiSelectFilter,
	singleSelectFilter,
	yearFilter,
} from "../../../../../library/react-table-utils"
import { StyledColumn } from "../../../../../components/combined-elements/projects-table/projects-table-columns/NormalProjectsTableColumns"

export const filterComponent = ({
	column,
	table,
	dateFormat,
	epList,
	qcList,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
	epList: any
	qcList: any
}) => {
	switch (column.id) {
		case "statusName":
			return (
				<select
					onChange={(e: any) => {
						column.setFilterValue(e.target.value)
					}}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string[]}
					data-testid="project-status-select"
				>
					<option value="all">All</option>
					<option value={ProjectStatus.normal}>Normal</option>
					<option value={ProjectStatus.replica}>Replica</option>
					<option value={ProjectStatus.archived}>Archived</option>
					<option value={ProjectStatus.unarchived}>Unarchived</option>
				</select>
			)

		case "periodName":
		case "groupName":
		case "clientName":
		case "engTypeName":
		case "raName":
		case "archPolicyPeriod":
			return (
				<DLComboBoxWithCheckbox
					options={[...column.getFacetedUniqueValues().keys()]}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						column.setFilterValue(value)
					}}
					eleValue={(column.getFilterValue() ?? []) as string[]}
					eleTestId={`${column.id}-select`}
				/>
			)

		case "epUsers":
		case "qcUsers":
			return (
				<DLComboBoxWithCheckbox
					options={column.id === "epUsers" ? epList : qcList}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						column.setFilterValue(value)
					}}
					eleValue={(column.getFilterValue() ?? []) as string[]}
					eleTestId={`${column.id}-select`}
				/>
			)

		case "expectedReportDate":
		case "expectedArchiveDeadlineDate":
		case "finalReportDate":
		case "finalArchiveDeadlineDate":
		case "archivedAt":
		case "createdAt":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		case "financialYear":
			return (
				<DLYearpicker
					eleTestId="financial-year-select"
					eleName="financialYear"
					selected={
						column.getFilterValue()
							? column.getFilterValue()
							: new Date().getFullYear()
					}
					onChange={(value: any) => {
						column.setFilterValue(value)
					}}
					showClearIcon
				/>
			)

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	const size = props.column.getSize()
	return (
		<StyledColumn data-testid={cellId} style={{ width: size }}>
			{props.getValue()}
		</StyledColumn>
	)
}

const OrgDashboardColumns = (i18n: DLI18nProps) => [
	{
		header: i18n.twStatus || "Status",
		accessorKey: "statusName",
		filterFn: singleSelectFilter,
		cell: (props: any) => {
			const projStatus = props.getValue()
			const cellId = props.cell.column.id + "-cell"
			return (
				<div data-testid={cellId} className="FR JSB">
					{getProjectIcon(projStatus, 0.6)} {projStatus}
				</div>
			)
		},
	},
	{
		header: i18n.twPeriodType || "Period Type",
		accessorKey: "periodName",
		filterFn: multiSelectFilter,
		cell: renderCustomCell,
	},
	{
		header: i18n.twFinancialYear || "Financial Year",
		accessorKey: "financialYear",
		filterFn: yearFilter,
		cell: renderCustomCell,
	},
	{
		header: i18n.twClient || "Client",
		accessorKey: "clientName",
		filterFn: multiSelectFilter,
		cell: renderCustomCell,
	},
	{
		header: i18n.twProjectTitle || "Project Name",
		accessorKey: "title",
		cell: (props: any) => {
			const projId = props.row.original.id
			const projNumId = idToNumber(projId, IdType.project)
			const projTitle = props.getValue()
			const cellId = props.cell.column.id + "-cell"
			const projStage = props.row.original.statusName
			const size = props.column.getSize()
			return (
				<StyledColumn style={{ width: size }}>
					{projStage === ProjectStatus.archived ? (
						<>{projTitle}</>
					) : (
						<Link
							to={`/project/checkin/${projNumId}`}
							data-testid={cellId}
						>
							{projTitle}
						</Link>
					)}
				</StyledColumn>
			)
		},
	},
	{
		header: i18n?.twProjectId || "Project ID", // NOTE: Keep "project id" for end user
		accessorKey: "aliasId",
		cell: (props: any) => {
			const size = props.column.getSize()
			return (
				<StyledColumn
					data-testid="project-aliasId-cell"
					style={{ width: size }}
				>
					{props.getValue()}
				</StyledColumn>
			)
		},
	},
	{
		header: i18n.twVersion || "Version",
		accessorKey: "version",
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			const version =
				props.getValue() === 0 ? "-" : "v." + props.getValue()
			return <div data-testid={cellId}>{version}</div>
		},
	},
	{
		header: i18n.twGroup || "Group",
		accessorKey: "groupName",
		filterFn: multiSelectFilter,
		cell: renderCustomCell,
	},
	{
		header: i18n.twEngType || "Eng. Type",
		accessorKey: "engTypeName",
		filterFn: multiSelectFilter,
		cell: renderCustomCell,
	},
	{
		header: i18n.twEP || "E.P.",
		accessorKey: "epUsers",
		filterFn: filterMethodForQCandEP,
		cell: (props: any) => {
			const size = props.column.getSize()
			return (
				<StyledColumn data-testid="ep-cell" style={{ width: size }}>
					{props.getValue().length > 0 ? (
						<>
							{props.getValue().map((item: any) => {
								return (
									<React.Fragment key={item.name}>
										{item.name}
										{props.getValue().length > 1 &&
											","}{" "}
									</React.Fragment>
								)
							})}
						</>
					) : (
						<span>-</span>
					)}
				</StyledColumn>
			)
		},
	},
	{
		header: i18n.twQC || "Q.C.",
		accessorKey: "qcUsers",
		filterFn: filterMethodForQCandEP,
		cell: (row: any) => {
			const size = row.column.getSize()
			return (
				<StyledColumn data-testid="qc-cell" style={{ width: size }}>
					{row.getValue().length > 0 ? (
						<>
							{row.getValue().map((item: any) => {
								return (
									<React.Fragment key={item.name}>
										{item.name}
										{row.getValue().length > 1 && ","}{" "}
									</React.Fragment>
								)
							})}
						</>
					) : (
						<span>-</span>
					)}
				</StyledColumn>
			)
		},
	},
	{
		header: i18n.twRiskAssessment || "R.A.",
		accessorKey: "raName",
		filterFn: multiSelectFilter,
		cell: renderCustomCell,
	},
	{
		header: i18n.twArchivePolicyPeriod || "Archive Policy - Period",
		accessorKey: "archPolicyPeriod",
		filterFn: multiSelectFilter,
		cell: renderCustomCell,
	},
	// ---------------------------------------------------------
	// ---------------------------------------------------------
	// ---------------------------------------------------------
	{
		header: i18n.twExpectedReportDate || "Expected Report Date",
		accessorKey: "expectedReportDate",
		filterFn: dateRangeFilter,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return (
				<div data-testid={cellId}>
					{props.getValue() ? props.getValue() : "-"}
				</div>
			)
		},
	},
	{
		header: i18n.twFinalReportDate || "Final Report Date",
		accessorKey: "finalReportDate",
		filterFn: dateRangeFilter,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return (
				<div data-testid={cellId}>
					{props.getValue() ? props.getValue() : "-"}
				</div>
			)
		},
	},
	// ---------------------------------------------------------
	// ---------------------------------------------------------
	// ---------------------------------------------------------
	{
		header:
			i18n.twExpectedArchiveDealineDate ||
			"Expected Archive Deadline Date",
		accessorKey: "expectedArchiveDeadlineDate",
		filterFn: dateRangeFilter,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return (
				<div data-testid={cellId}>
					{props.getValue() ? props.getValue() : "-"}
				</div>
			)
		},
	},
	{
		header: i18n.twFinalArchiveDealineDate || "Final Archive Deadline Date",
		accessorKey: "finalArchiveDeadlineDate",
		filterFn: dateRangeFilter,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return (
				<div data-testid={cellId}>
					{props.getValue() ? props.getValue() : "-"}
				</div>
			)
		},
	},
	// ---------------------------------------------------------
	// ---------------------------------------------------------
	// ---------------------------------------------------------
	{
		header: i18n.twArchivedAt || "Archived At",
		accessorKey: "archivedAt",
		filterFn: dateRangeFilter,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return (
				<div data-testid={cellId}>
					{props.getValue() ? props.getValue() : "-"}
				</div>
			)
		},
	},
	// ---------------------------------------------------------
	// ---------------------------------------------------------
	// ---------------------------------------------------------
	{
		header: i18n.twCreatedAt || "Created At",
		accessorKey: "createdAt",
		filterFn: dateRangeFilter,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return (
				<div data-testid={cellId}>
					{props.getValue() ? props.getValue() : "-"}
				</div>
			)
		},
	},
	{
		header: i18n.twCreatedBy || "Created By",
		accessorKey: "createdBy",
		accessorFn: (props: any) => props.createdBy.name,
		cell: renderCustomCell,
	},
	// ---------------------------------------------------------
	// ---------------------------------------------------------
	// ---------------------------------------------------------
	{
		header: i18n.twCompletionStatus || "Completion Status",
		columns: [
			{
				header: "Prepared",
				accessorKey: "preparedStatus",
				cell: renderCustomCell,
			},
			{
				header: "Reviewed",
				accessorKey: "reviewedStatus",
				cell: renderCustomCell,
			},
			{
				header: "E.P. Reviewed",
				accessorKey: "partnerStatus",
				cell: renderCustomCell,
			},
			{
				header: "C.P. Reviewed",
				accessorKey: "cpStatus",
				cell: renderCustomCell,
			},
		],
	},
	{
		header: "Prepared",
		columns: [
			{
				header: "Total",
				accessorKey: "totalPreparedCount",
				cell: renderCustomCell,
			},
			{
				header: "Completed",
				accessorKey: "preparedCompletedCount",
				cell: renderCustomCell,
			},
		],
	},
	{
		header: "Reviewed",
		columns: [
			{
				header: "Total",
				accessorKey: "totalReviewedCount",
				cell: renderCustomCell,
			},
			{
				header: "Completed",
				accessorKey: "reviewedCompletedCount",
				cell: renderCustomCell,
			},
		],
	},
	{
		header: "Engagement Partner",
		columns: [
			{
				header: "Total",
				accessorKey: "totalPartnerCount",
				cell: renderCustomCell,
			},
			{
				header: "Completed",
				accessorKey: "partnerCompletedCount",
				cell: renderCustomCell,
			},
		],
	},
	{
		header: "Conc. Partner",
		columns: [
			{
				header: "Total",
				accessorKey: "totalCpCount",
				cell: renderCustomCell,
			},
			{
				header: "Completed",
				accessorKey: "cpCompletionCount",
				cell: renderCustomCell,
			},
		],
	},
]

export default OrgDashboardColumns
